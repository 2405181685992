class ConsoleLog {
  static debug = true; // Set this to false to suppress log messages

  static extractCallerInfo() {
    const err = new Error();
    const stack = err.stack.split('\n');
    const caller = stack[3].trim(); // Adjust the index based on your environment
    return caller;
  }

  static log(...args) {
    if (!this.debug) return; // Skip logging if debug is false
    const callerInfo = this.extractCallerInfo();
    console.log(callerInfo, ...args);
  }

  static warn(...args) {
    const callerInfo = this.extractCallerInfo();
    console.warn(callerInfo, ...args);
  }

  static error(...args) {
    const callerInfo = this.extractCallerInfo();
    console.error(callerInfo, ...args);
  }
}

export default ConsoleLog;
