import React, { useState /* , useRef */ } from 'react';
import '@components/Modal windows/Modal window - name scene/ConfirmName.scss';
import Input from '@components/input/Input';
import PropTypes from 'prop-types';

/* import { AWSPut } from '@services/api/AWS';
import { scenesService } from '@services/api/scenes/scenes.service'; */
import { useSessionStorage } from '@hooks/General';

const ConfirmName = ({ nameObject, cancelAction, initialName = '', file }) => {
  const [inputValue, setInputValue] = useState('');
  const userDataStore = useSessionStorage('userData', 'get');

  const haveInput = file === 'new';

  // console.log(haveInput);

  /* const fileInputRef = useRef(null); */

  if (!userDataStore) {
    return console.error('No user data found');
  }

  const handleInput = (event) => {
    setInputValue(event.target.value);
  };

  const handleConfirm = () => {
    nameObject(inputValue);
  };

  /* const handelImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = function (loadEvent) {
        const imageUrl = loadEvent.target.result;
        // Here you have the image URL (base64), you can use it as needed
        console.log(imageUrl); // For demonstration

        // Now, you can trigger your existing upload to S3 method from here
        // For example:
        // uploadImageToS3(imageUrl);

        (async () => {
          // we first have to save the scene in the database
          const sceneBody = {
            companyName: userDataStore.company,
            scenes_Id: userDataStore.scenesId,
            sceneName: inputValue
          };

          const response = await scenesService.addScene(sceneBody);

          console.log('new scene id: ', response.data.scenes.sceneId);
          // we then have to name the file with the scene id
          const Bucket = 'exvirience-media';
          const Key = response.data.scenes.sceneId;
          const Body = file;
          await AWSPut(Bucket, Key, Body);
        })();

        nameObject(inputValue);
      };

      reader.readAsDataURL(file);
    }
  }; */

  return (
    <div className="modal-wrapper" onClick={() => cancelAction()}>
      <div className="confirm-rename-container" onClick={(e) => e.stopPropagation()}>
        <h2>Save Scene</h2>
        <p>
          {haveInput
            ? "Enter the new name to save the scene and then click 'Save'."
            : 'Please confirm to save the scene.'}
        </p>
        {haveInput && (
          <Input
            name="rename"
            type="text"
            value={inputValue}
            handleChange={(value) => handleInput(value)}
            placeholder={'New name'}
            autocomplete="off"
            style={{ marginTop: '10px' }}
          />
        )}
        <div className="buttons-container">
          <button
            className="button-new"
            onClick={() => cancelAction()}
            style={{ cursor: 'pointer', color: 'rgba(255, 0, 0, 1)' }}
          >
            Cancel
          </button>
          <div className="divider"></div>
          <button
            className="button-new"
            onClick={handleConfirm}
            disabled={haveInput ? !inputValue : false} // Disabled logic based on haveInput
            style={
              !inputValue && haveInput ? { color: 'var(--white-17)', cursor: 'not-allowed' } : { cursor: 'pointer' }
            }
          >
            Save
          </button>
          {/* <div className="divider"></div>
          <div
            className="button-new"
            onClick={() => fileInputRef.current.click()}
            style={!inputValue ? { color: 'var(--white-17)', cursor: 'not-allowed' } : { cursor: 'pointer' }}
          >
            <input
              type="file"
              accept="image/png, image/jpeg"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handelImage}
            />
            Save with image
          </div> */}
        </div>
      </div>
    </div>
  );
};

ConfirmName.propTypes = {
  nameObject: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  initialName: PropTypes.string,
  file: PropTypes.string
};

export default ConfirmName;
