import React from 'react';
import '@pages/unauthorized/UnauthorizedPage.scss';
import logoOriginal from '@assets/images/Logo_Exvirience_Original.png';

const UnauthorizedPage = () => {
  return (
    <div className="error-page-container">
      <a href="https://www.exvirience.com/">
        <img src={logoOriginal} alt="Logo" className="logo" />
      </a>
      <div className="error-content">
        <h1>Page not authorized on this device</h1>
        <p>Sorry, the device you are using is not authorized to access this page.</p>
        <p>To access this page you must have a computer.</p>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
