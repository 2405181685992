import { useDispatch } from 'react-redux';

import { userService } from '@services/api/user/user.service';
import { useLocalStorage, useSessionStorage } from '@hooks/General';
import { Utils } from '@services/utils/utils.service';

const LogOut = () => {
  const [deleteStorageUsername] = useLocalStorage('username', 'delete');
  const [setLoggedIn] = useLocalStorage('keepLoggedIn', 'set');
  const [deleteSessionPageReload] = useSessionStorage('pageReload', 'delete');
  const [deleteSessionData] = useSessionStorage('sessionData', 'delete');
  const dispatch = useDispatch();

  const logOutMethod = async () => {
    try {
      deleteSessionData();
      Utils.clearStore({ dispatch, deleteStorageUsername, deleteSessionPageReload, setLoggedIn });
      const response = await userService.logoutUser();
      console.log(response);

      return true;
    } catch (error) {
      console.error('Failed to log out:', error);

      return false;
      // Optionally, display an error message to the user
    }
  };

  return logOutMethod;
};

export default LogOut;
