import { Color3, Vector3, MeshBuilder, StandardMaterial, SceneLoader, Plane } from '@babylonjs/core'; // Ensure the necessary BabylonJS modules are imported.
import { UniqueIDGenerator } from '@hooks/Mesh/index';

import { AdvancedDynamicTexture, TextBlock } from '@babylonjs/gui';

/*  sceneInstance,
    setBoxes,
    setCanvases,
    setSpheres,
    setCylinders,
    setPlane,
    clickSimulator,
    (mesh) => shadowPlaneCreator.create(mesh),
    C1M,
    planeConfirm,
    familyIDs,
    loadingSpheresCreator,
    setMeshes,
    importableMeshes.current,
    canvases */

class MeshCreator {
  constructor(
    sceneInstance,
    setBoxes,
    setCanvases,
    setSpheres,
    setCylinders,
    setPlane,
    clickSimulator,
    createShadowPlane,
    C1M,
    planeConfirm,
    familyIDs,
    loadingSpheresCreator,
    setMeshesCallback,
    importableMeshes,
    canvases
  ) {
    this.sceneInstance = sceneInstance;
    this.setBoxes = setBoxes;
    this.setCanvases = setCanvases;
    this.setSpheres = setSpheres;
    this.setCylinders = setCylinders;
    this.setPlane = setPlane;
    this.clickSimulator = clickSimulator;
    this.createShadowPlane = createShadowPlane;
    this.C1M = C1M;
    this.planeConfirm = planeConfirm;
    this.familyIDs = familyIDs;
    this.loadingSpheresCreator = loadingSpheresCreator;
    this.setMeshesCallback = setMeshesCallback;
    this.meshes = [];
    this.isImporting = false;
    this.importableMeshes = importableMeshes;
    this.canvases = canvases;
  }

  createBox(boxes) {
    const idGenerator = new UniqueIDGenerator(this.familyIDs);
    const newId = idGenerator.generateUniqueId();

    if (this.sceneInstance.current) {
      // eslint-disable-next-line prefer-const
      let box = MeshBuilder.CreateBox(`box${boxes.length}`, { size: 1 }, this.sceneInstance.current);
      box.position = new Vector3(2, 0, 2); // Modify position for each box
      box.metadata = {
        canToggleGizmo: true,
        canToggleFocus: true,
        targetPosition: box.position.clone(),
        position: box.position.clone(), // clone the initial position
        rotation: box.rotation.clone(), // clone the initial rotation
        scale: box.scaling.clone(), // clone the initial scale
        isolatable: true,
        familyID: newId,
        isIsolated: false,
        importable: true,
        clippable: true,
        importData: null
      };
      // Move the box upward 1/2 its height
      box.position.y = 1;

      box.layerMask = this.C1M;

      box.metadata.importData = {
        familyID: newId,
        type: 'box',
        name: box.name,
        url: null,
        position: null,
        rotation: null,
        scaling: null,
        quaternion: null
      };

      // Add the mesh to the shadow generator's list of shadow casters
      // eslint-disable-next-line prefer-const
      let shadowPlane = this.createShadowPlane(box);
      box.metadata.shadowPlane = shadowPlane;
      box.renderingGroupId = 1;

      // Create a default material
      const defaultMaterial = new StandardMaterial('defaultMaterial', this.sceneInstance.current);

      // Set the material properties (you can customize these as needed)
      defaultMaterial.diffuseColor = new Color3(1, 1, 1); // White color
      defaultMaterial.specularColor = new Color3(0, 0, 0); // No specular highlights
      defaultMaterial.ambientColor = new Color3(0.2, 0.2, 0.2); // Ambient color
      defaultMaterial.emissiveColor = new Color3(0, 0, 0); // No emissive color
      box.material = defaultMaterial;

      this.setBoxes([...boxes, box]);

      this.importableMeshes.push(box.uniqueId);

      // Call this function immediately after creating a new mesh
      this.clickSimulator.simulateClickAndTargetCamera(box);
    }
  }

  createCanvasBox(type, bucketName, keyName, mediasId, mediaName) {
    const idGenerator = new UniqueIDGenerator(this.familyIDs);
    const newId = idGenerator.generateUniqueId();

    if (this.sceneInstance.current) {
      const material = new StandardMaterial('canvasMaterial', this.sceneInstance.current);
      material.diffuseColor = new Color3(0, 0, 212 / 255); // Blue color
      material.emissiveColor = new Color3(0, 0, 212 / 255);
      material.alpha = 0.2; // Transparency

      const box = MeshBuilder.CreateBox(
        `${type}${this.canvases.length}`,
        { depth: 0.05, width: 1, height: 1 * (9 / 16) },
        this.sceneInstance.current
      );
      box.position = new Vector3(0, 0, 0);
      box.metadata = {
        canToggleGizmo: true,
        canToggleFocus: true,
        targetPosition: box.position.clone(),
        position: box.position.clone(), // clone the initial position
        rotation: box.rotation.clone(), // clone the initial rotation
        scale: box.scaling.clone(), // clone the initial scale
        isolatable: false,
        familyID: newId,
        isIsolated: false,
        importable: true,
        clippable: true,
        importData: null
      };

      box.metadata.importData = {
        familyID: newId,
        type: `${type}`,
        name: box.name,
        url: {
          bucket: bucketName,
          key: keyName,
          mediasId,
          mediaName
        },
        position: null,
        rotation: null,
        scaling: null,
        quaternion: null
      };

      box.material = material;

      // Assuming the capsule handle's position is already set
      const planeHeight = 1 * (9 / 16);
      const planeWidth = 1;
      const plane = MeshBuilder.CreatePlane(
        `textPlane1_${type}${this.canvases.length}`,
        { height: planeHeight, width: planeWidth },
        this.sceneInstance.current
      );

      // Position the plane above the capsule handle
      plane.position = new Vector3(0, 0, 0.025);

      // Tilt the plane by 30 degrees along the X-axis
      // plane.rotation.x = Math.PI / 4; // 30 degrees in radians

      // Create a dynamic texture for displaying text
      const dynamicTexture = new AdvancedDynamicTexture(this.sceneInstance.current);

      // Create and configure the text block
      const textBlock = new TextBlock();
      textBlock.text = `${type}:${mediaName}`;
      textBlock.fontSize = 100;
      textBlock.color = 'black';
      dynamicTexture.addControl(textBlock);

      // Apply the dynamic texture to the plane's material
      plane.material = new StandardMaterial('planeMaterial', this.sceneInstance.current);
      plane.material.diffuseTexture = dynamicTexture;

      plane.setParent(box);

      const plane2 = MeshBuilder.CreatePlane(
        `textPlane2${type}_${this.canvases.length}`,
        { height: planeHeight, width: planeWidth },
        this.sceneInstance.current
      );

      // Position the plane above the capsule handle
      plane2.position = new Vector3(0, 0, 0.025);
      plane2.rotate(new Vector3(0, 1, 0), Math.PI);

      // Tilt the plane by 30 degrees along the X-axis
      // plane.rotation.x = Math.PI / 4; // 30 degrees in radians

      // Create a dynamic texture for displaying text
      const dynamicTexture2 = new AdvancedDynamicTexture(this.sceneInstance.current);

      // Create and configure the text block
      const textBlock2 = new TextBlock();
      textBlock2.text = `${type}:${mediaName}`;
      textBlock2.fontSize = 100;
      textBlock2.color = 'black';
      dynamicTexture2.addControl(textBlock2);

      // Apply the dynamic texture to the plane's material
      plane2.material = new StandardMaterial('planeMaterial2', this.sceneInstance.current);
      plane2.material.diffuseTexture = dynamicTexture2;

      plane2.setParent(box);

      // Move the box upward 1/2 its height
      box.position.y = 1;

      box.layerMask = this.C1M;
      plane.layerMask = this.C1M;
      plane2.layerMask = this.C1M;
      // eslint-disable-next-line prefer-const
      let shadowPlane = this.createShadowPlane(box);
      box.metadata.shadowPlane = shadowPlane;
      box.renderingGroupId = 1;

      this.setCanvases([...this.canvases, box]);

      this.importableMeshes.push(box.uniqueId);

      // Call this function immediately after creating a new mesh
      this.clickSimulator.simulateClickAndTargetCamera(box);
    }
  }

  createSphere(spheres) {
    const idGenerator = new UniqueIDGenerator(this.familyIDs);
    const newId = idGenerator.generateUniqueId();

    if (this.sceneInstance.current) {
      // eslint-disable-next-line prefer-const
      let sphere = MeshBuilder.CreateSphere(`sphere${spheres.length}`, { diameter: 1 }, this.sceneInstance.current);
      sphere.position = new Vector3(2, 0, 2); // Modify position for each sphere
      sphere.metadata = {
        canToggleGizmo: true,
        canToggleFocus: true,
        targetPosition: sphere.position.clone(),
        position: sphere.position.clone(), // clone the initial position
        rotation: sphere.rotation.clone(), // clone the initial rotation
        scale: sphere.scaling.clone(), // clone the initial scale
        isolatable: true,
        familyID: newId,
        isIsolated: false,
        importable: true,
        clippable: true,
        importData: null
      };
      // Move the sphere upward 1/2 its height
      sphere.position.y = 1;

      sphere.layerMask = this.C1M;

      sphere.metadata.importData = {
        familyID: newId,
        type: 'sphere',
        name: sphere.name,
        url: null,
        position: null,
        rotation: null,
        scaling: null,
        quaternion: null
      };

      // Create a default material
      const defaultMaterial = new StandardMaterial('defaultMaterial', this.sceneInstance.current);

      // Set the material properties (you can customize these as needed)
      defaultMaterial.diffuseColor = new Color3(1, 1, 1); // White color
      defaultMaterial.specularColor = new Color3(0, 0, 0); // No specular highlights
      defaultMaterial.ambientColor = new Color3(0.2, 0.2, 0.2); // Ambient color
      defaultMaterial.emissiveColor = new Color3(0, 0, 0); // No emissive color
      sphere.material = defaultMaterial;

      // Add the mesh to the shadow generator's list of shadow casters
      // eslint-disable-next-line prefer-const
      let shadowPlane = this.createShadowPlane(sphere);
      sphere.metadata.shadowPlane = shadowPlane;
      sphere.renderingGroupId = 1;

      this.setSpheres([...spheres, sphere]);

      this.importableMeshes.push(sphere.uniqueId);

      // Call this function immediately after creating a new mesh
      this.clickSimulator.simulateClickAndTargetCamera(sphere);
    }
  }

  createCylinder(cylinders) {
    const idGenerator = new UniqueIDGenerator(this.familyIDs);
    const newId = idGenerator.generateUniqueId();

    if (this.sceneInstance.current) {
      // eslint-disable-next-line prefer-const
      let cylinder = MeshBuilder.CreateCylinder(
        `cylinders${cylinders.length}`,
        { diameterTop: 0.5, diameterBottom: 0.5, height: 1 },
        this.sceneInstance.current
      );
      cylinder.position = new Vector3(2, 0, 2); // Modify position for each cylinder
      cylinder.metadata = {
        canToggleGizmo: true,
        canToggleFocus: true,
        targetPosition: cylinder.position.clone(),
        position: cylinder.position.clone(), // clone the initial position
        rotation: cylinder.rotation.clone(), // clone the initial rotation
        scale: cylinder.scaling.clone(), // clone the initial scale
        isolatable: true,
        familyID: newId,
        isIsolated: false,
        importable: true,
        clippable: true,
        importData: null
      };
      // Move the cylinder upward 1/2 its height
      cylinder.position.y = 1;

      cylinder.layerMask = this.C1M;

      cylinder.metadata.importData = {
        familyID: newId,
        type: 'cylinder',
        name: cylinder.name,
        url: null,
        position: null,
        rotation: null,
        scaling: null,
        quaternion: null
      };

      // Create a default material
      const defaultMaterial = new StandardMaterial('defaultMaterial', this.sceneInstance.current);

      // Set the material properties (you can customize these as needed)
      defaultMaterial.diffuseColor = new Color3(1, 1, 1); // White color
      defaultMaterial.specularColor = new Color3(0, 0, 0); // No specular highlights
      defaultMaterial.ambientColor = new Color3(0.2, 0.2, 0.2); // Ambient color
      defaultMaterial.emissiveColor = new Color3(0, 0, 0); // No emissive color
      cylinder.material = defaultMaterial;

      // Add the mesh to the shadow generator's list of shadow casters
      // eslint-disable-next-line prefer-const
      let shadowPlane = this.createShadowPlane(cylinder);
      cylinder.metadata.shadowPlane = shadowPlane;
      cylinder.renderingGroupId = 1;

      this.setCylinders([...cylinders, cylinder]);

      this.importableMeshes.push(cylinder.uniqueId);

      // Call this function immediately after creating a new mesh
      this.clickSimulator.simulateClickAndTargetCamera(cylinder);
    }
  }

  createPlane(x, y, planes) {
    if (this.sceneInstance.current) {
      // eslint-disable-next-line prefer-const
      let plane = MeshBuilder.CreatePlane(
        `workPlane`,
        { width: (10 * x) / 1.8, height: (10 * y) / 1.8 },
        this.sceneInstance.current
      );
      plane.scaling = new Vector3(1, 1, 1);
      plane.position = new Vector3(0, 0, 0);
      plane.rotation = new Vector3(Math.PI / 2, 0, 0);

      // Create default material
      const defaultPlaneGrey = new StandardMaterial('defaultPlaneMaterial', this.sceneInstance.current);
      defaultPlaneGrey.diffuseColor = new Color3(100 / 255, 100 / 255, 100 / 255); // Grey color
      defaultPlaneGrey.disableLighting = true;
      defaultPlaneGrey.alpha = 0.3;
      plane.material = defaultPlaneGrey;

      plane.metadata = {
        canToggleGizmo: true,
        canToggleFocus: false,
        targetPosition: plane.position.clone(),
        position: plane.position.clone(), // clone the initial position
        rotation: plane.rotation.clone(), // clone the initial rotation
        scale: plane.scaling.clone(), // clone the initial scale
        plane: true,
        isolatable: false,
        importable: true
      };

      plane.isPickable = true;
      plane.layerMask = this.C1M;
      plane.renderingGroupId = 1;

      this.setPlane([...planes, plane]);

      this.planeConfirm();
    }
  }

  async importHeadMesh(assetURL, fileName) {
    if (this.isImporting) {
      console.warn('Import already in progress. Ignoring the call.');
      return; // Exit if an import is already in progress
    }

    this.isImporting = true; // Set the flag to indicate that an import is in progress

    if (this.sceneInstance.current) {
      const position = new Vector3(0, 0, 0);
      const rotation = new Vector3(0, 0, 0);
      const scale = new Vector3(1, 1, 1);
      assetURL = assetURL || 'https://raw.githubusercontent.com/BabylonJS/Assets/master/meshes/Elf/';
      fileName = fileName || 'Elf.gltf';

      const idGenerator = new UniqueIDGenerator(this.familyIDs);
      const newId = idGenerator.generateUniqueId();

      this.loadingSpheresCreator.create(position);

      try {
        const result = await SceneLoader.ImportMeshAsync('', assetURL, fileName, this.sceneInstance.current);
        const [mainMesh, ...otherMeshes] = result.meshes;
        mainMesh.scaling = new Vector3(0, 0, 0);

        mainMesh.clippingPlane = new Plane(0, 0, 0, 0);

        mainMesh.position = position;
        mainMesh.rotation = rotation;

        [mainMesh, ...otherMeshes].forEach((mesh) => {
          mesh.metadata = {
            canToggleGizmo: true,
            canToggleFocus: true,
            targetPosition: mainMesh.position.clone(),
            position: mesh.position.clone(),
            rotation: mesh.rotation.clone(),
            scale: mesh.scaling.clone(),
            clone: mainMesh,
            isolatable: true,
            familyID: newId,
            isIsolated: false,
            importable: true,
            // eslint-disable-next-line object-shorthand
            assetURL: assetURL,
            // eslint-disable-next-line object-shorthand
            fileName: fileName
          };

          mesh.layerMask = this.C1M;
          mesh.renderingGroupId = 1;
        });

        setTimeout(() => {
          this.loadingSpheresCreator.disposeSpheres();
          mainMesh.scaling = scale;

          const shadowPlane = this.createShadowPlane(mainMesh);
          mainMesh.metadata.shadowPlane = shadowPlane;
          mainMesh.renderingGroupId = 1;
        }, 1500);

        this.setMeshesCallback([...this.meshes, mainMesh, ...otherMeshes]);

        if (otherMeshes.length > 0) {
          this.clickSimulator.simulateClickAndTargetCamera(otherMeshes[0]);
        }
      } catch (error) {
        console.error('Error loading mesh', error);
      }
    }

    this.isImporting = false; // Reset the flag at the end
  }
}

export default MeshCreator;
