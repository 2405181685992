import PropTypes from 'prop-types';

import {
  MdOutlineRotateRight,
  MdOutlineZoomOutMap,
  MdDeleteOutline,
  MdOutlineTouchApp,
  MdArrowBack,
  MdCrop
} from 'react-icons/md';
import { TbEdit } from 'react-icons/tb';

import '@pages/user/editor/Tool bars/Handle model toolbar/Mesh toolbar/ObjectToolbar.scss';

const BUTTON_TYPES = {
  MOVE: 'move',
  SCALE: 'scale',
  ROTATE: 'rotate'
};

const ObjectToolbar = ({
  deleteObject,
  isMovable,
  isScalable,
  isRotatable,
  gizmoState,
  addBehaviour,
  sectionToolbar,
  headBack,
  isUnSelected,
  onlyDelete,
  isolate
}) => {
  const buttonColor = (buttonType) => (gizmoState === buttonType ? 'rgba(255, 255, 255, 1)' : '');

  const iconBackgroundColor = (buttonType) =>
    gizmoState === buttonType ? (isUnSelected ? 'rgba(0, 0, 212, 1)' : 'rgba(0, 0, 212, 0.2)') : '';

  const disableColor = isUnSelected ? 'rgba(0, 0, 212, 1)' : 'rgba(0, 0, 212, 0.4)';

  const disableCursor = isUnSelected ? 'pointer' : 'not-allowed';

  return (
    <div className="ShortcutsToolbar-container">
      <div
        className="ShortcutsToolbar-element"
        onClick={isUnSelected ? deleteObject : undefined}
        style={{ cursor: disableCursor }}
      >
        <div
          className="ShortcutsToolbar-icon"
          style={{
            color: disableColor,
            cursor: disableCursor
          }}
        >
          <MdDeleteOutline />
        </div>
        <div
          className="ShortcutsToolbar-text"
          style={{
            color: disableColor,
            cursor: disableCursor
          }}
        >
          Delete
        </div>
      </div>

      {!onlyDelete && (
        <>
          <div
            className="ShortcutsToolbar-element"
            onClick={isUnSelected ? isMovable : undefined}
            style={{
              color: buttonColor(BUTTON_TYPES.MOVE),
              backgroundColor: iconBackgroundColor(BUTTON_TYPES.MOVE),
              cursor: disableCursor
            }}
          >
            <div
              className="ShortcutsToolbar-icon rotate-icon"
              style={{
                color: isUnSelected ? buttonColor(BUTTON_TYPES.MOVE) : 'rgba(0, 0, 212, 0.4)',
                cursor: disableCursor
              }}
            >
              <MdOutlineZoomOutMap />
            </div>
            <div
              className="ShortcutsToolbar-text"
              style={{
                color: isUnSelected ? buttonColor(BUTTON_TYPES.MOVE) : 'rgba(0, 0, 212, 0.4)',
                cursor: disableCursor
              }}
            >
              Move
            </div>
          </div>

          <div
            className="ShortcutsToolbar-element"
            onClick={isUnSelected ? isRotatable : undefined}
            style={{
              color: buttonColor(BUTTON_TYPES.ROTATE),
              backgroundColor: iconBackgroundColor(BUTTON_TYPES.ROTATE),
              cursor: disableCursor
            }}
          >
            <div
              className="ShortcutsToolbar-icon"
              style={{
                color: isUnSelected ? buttonColor(BUTTON_TYPES.ROTATE) : 'rgba(0, 0, 212, 0.4)',
                cursor: disableCursor
              }}
            >
              <MdOutlineRotateRight />
            </div>
            <div
              className="ShortcutsToolbar-text"
              style={{
                color: isUnSelected ? buttonColor(BUTTON_TYPES.ROTATE) : 'rgba(0, 0, 212, 0.4)',
                cursor: disableCursor
              }}
            >
              Rotate
            </div>
          </div>

          <div
            className="ShortcutsToolbar-element"
            onClick={isUnSelected ? isScalable : undefined}
            style={{
              color: buttonColor(BUTTON_TYPES.SCALE),
              backgroundColor: iconBackgroundColor(BUTTON_TYPES.SCALE),
              cursor: disableCursor
            }}
          >
            <div
              className="ShortcutsToolbar-icon"
              style={{
                color: isUnSelected ? buttonColor(BUTTON_TYPES.SCALE) : 'rgba(0, 0, 212, 0.4)',
                cursor: disableCursor
              }}
            >
              <MdOutlineZoomOutMap />
            </div>
            <div
              className="ShortcutsToolbar-text"
              style={{
                color: isUnSelected ? buttonColor(BUTTON_TYPES.SCALE) : 'rgba(0, 0, 212, 0.4)',
                cursor: disableCursor
              }}
            >
              Scale
            </div>
          </div>

          <div
            className="ShortcutsToolbar-element"
            onClick={isUnSelected ? sectionToolbar : undefined}
            style={{
              cursor: disableCursor
            }}
          >
            <div
              className="ShortcutsToolbar-icon"
              style={{
                color: disableColor,
                cursor: disableCursor
              }}
            >
              <MdCrop />
            </div>
            <div
              className="ShortcutsToolbar-text"
              style={{
                color: disableColor,
                cursor: disableCursor
              }}
            >
              View Sections
            </div>
          </div>

          <div
            className="ShortcutsToolbar-element"
            onClick={isUnSelected ? addBehaviour : undefined}
            style={{
              cursor: disableCursor
            }}
          >
            <div
              className="ShortcutsToolbar-icon"
              style={{
                color: disableColor,
                cursor: disableCursor
              }}
            >
              <MdOutlineTouchApp />
            </div>
            <div
              className="ShortcutsToolbar-text"
              style={{
                color: disableColor,
                cursor: disableCursor
              }}
            >
              Set Scene Logic
            </div>
          </div>

          <div
            className="ShortcutsToolbar-element"
            onClick={isUnSelected ? isolate : undefined}
            style={{
              cursor: disableCursor
            }}
          >
            <div
              className="ShortcutsToolbar-icon"
              style={{
                color: disableColor,
                cursor: disableCursor,
                scale: '1.2'
              }}
            >
              <TbEdit />
            </div>
            <div
              className="ShortcutsToolbar-text"
              style={{
                color: disableColor,
                cursor: disableCursor
              }}
            >
              Edit Object
            </div>
          </div>
        </>
      )}

      <div className="ShortcutsToolbar-element" onClick={headBack}>
        <div className="ShortcutsToolbar-icon">
          <MdArrowBack />
        </div>
        <div className="ShortcutsToolbar-text">Back</div>
      </div>
    </div>
  );
};

ObjectToolbar.propTypes = {
  deleteObject: PropTypes.func.isRequired,
  isMovable: PropTypes.func.isRequired,
  isScalable: PropTypes.func.isRequired,
  isRotatable: PropTypes.func.isRequired,
  gizmoState: PropTypes.string.isRequired,
  addBehaviour: PropTypes.func.isRequired,
  sectionToolbar: PropTypes.func.isRequired,
  headBack: PropTypes.func.isRequired,
  isUnSelected: PropTypes.bool.isRequired,
  onlyDelete: PropTypes.bool.isRequired,
  isolate: PropTypes.func.isRequired
};

export default ObjectToolbar;
