import '@pages/documentation/editorDocumentation.scss';

/*
import videoTrailer from '@assets/videos/Quest_2_trailer_3.mp4';
import image1 from '@assets/images/Documentation/Editor Overview/pexels-luis-gomes-546819.jpg';
*/

const EditorGetStarted = () => {
  const briefDescription =
    "In this documentation, you'll find a comprehensive guide covering every facet of our editor. It delves into each functionality, offering clear, step-by-step instructions and insights that empower you to fully leverage the tool's capabilities.";

  const explanationText =
    'These instructions will be a guide for both the usage of the tool and the understanding of code structure.';
  return (
    <div className="content-section">
      <h1 className="title">Exvirience Documentation</h1>
      <div className="text-content" dangerouslySetInnerHTML={{ __html: briefDescription }}></div>
      <div className="text-content" dangerouslySetInnerHTML={{ __html: explanationText }}></div>
      {/*
      <img src={image1} alt="Documentation" className="doc-image" width={'50%'} />
      <video controls className="doc-video">
        <source src={videoTrailer} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      */}
    </div>
  );
};

export default EditorGetStarted;
