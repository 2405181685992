import React, { useState } from 'react';
import '@components/Modal windows/Modal window - remove/ConfirmRemove.scss';
import Input from '@components/input/Input';
import PropTypes from 'prop-types';

const ConfirmRemove = ({
  deleteObject,
  deleteObjectContext,
  cancelAction,
  context,
  Title = null,
  Description = null
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleInput = (event) => {
    setInputValue(event.target.value);
  };

  const checkConfirm = (inputValue) => {
    return inputValue === 'confirm' || inputValue === 'Confirm';
  };

  const handleConfirm = () => {
    if (checkConfirm(inputValue)) {
      context ? deleteObjectContext() : deleteObject();
    }
  };

  return (
    <div className="modal-wrapper-remove" onClick={() => cancelAction()}>
      <div className="confirm-remove-container" onClick={(e) => e.stopPropagation()}>
        <h2>{Title || 'Delete Object'}</h2>
        <p>{Description || "To delete the object from the scene, type 'Confirm' and click the remove button."}</p>
        <Input
          name="delete"
          type="text"
          value={inputValue}
          handleChange={handleInput}
          placeholder={'Confirm'}
          autocomplete="off"
          style={{ marginTop: '10px' }}
        />
        <div className="buttons-container">
          <button className="button-new" onClick={() => cancelAction()} style={{ cursor: 'pointer' }}>
            Cancel
          </button>
          <div className="divider"></div>
          <button
            className="button-new"
            onClick={handleConfirm}
            disabled={!checkConfirm(inputValue)}
            style={
              !checkConfirm(inputValue)
                ? { color: 'var(--white-17)', cursor: 'not-allowed' }
                : { cursor: 'pointer', color: 'rgba(255, 0, 0, 1)' }
            }
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

ConfirmRemove.propTypes = {
  deleteObject: PropTypes.func.isRequired,
  deleteObjectContext: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  context: PropTypes.bool.isRequired,
  Title: PropTypes.string,
  Description: PropTypes.string
};

export default ConfirmRemove;
