/* eslint-disable no-unused-vars */
import '@pages/user/manager/User.scss';

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { GrVmMaintenance } from 'react-icons/gr';
import { RxAvatar } from 'react-icons/rx';
import { FaSearch } from 'react-icons/fa';
import { HiOutlineCubeTransparent } from 'react-icons/hi';
import {
  MdOutlineMenu,
  MdOutlineMenuOpen,
  MdOutlineHome,
  MdOutlineAdminPanelSettings,
  MdOutlinePermMedia
} from 'react-icons/md';
import { LuFileBox } from 'react-icons/lu';

import logoImage from '@assets/images/Logo_Exvirience_White.png';
import { LogOut, useSessionStorage } from '@hooks/General';
import Button from '@components/button/Button';
import { defaultLoadingTime } from '@root/App';
import PageLoader from '@components/page-loader/PageLoader'; // the page loader component
import EditorManager from '@pages/user/manager/editor/EditorManager';
import ModelManager from '@pages/user/manager/model library/ModelManager';
import MediaManager from '@pages/user/manager/media library/MediaManager';

const Header = ({ username, openMenu, HandleSideMenu, removeVoiceFlow }) => {
  const [enableSettings, setEnableSettings] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const logout = LogOut();
  const HandleLogOut = async () => {
    setLoading(true);

    const logoutSuccess = await logout();

    removeVoiceFlow(true);

    if (logoutSuccess) {
      navigate('/auth', { replace: true }); // Redirect to auth page
      // Force a reload after navigating to completely remove the widget and related scripts
      window.location.reload();
    } else {
      setLoading(false);
    }
  };

  const HandleSettings = () => {
    setEnableSettings((value) => !value);
  };

  return (
    <div className="header-wrapper">
      <header className="header-container">
        <div className="leftside-bar">
          <div className="menu-icon" onClick={HandleSideMenu}>
            {openMenu ? <MdOutlineMenuOpen /> : <MdOutlineMenu />}
          </div>
          <img src={logoImage} alt="Logo" className="logo" />
        </div>
        <div className="search-bar">
          <FaSearch />
          <input type="text" placeholder="Search..." />
        </div>
        <div className="rightside-bar">
          <div className="user-info">
            <span className="username">{username}</span>
            <div className="user-avatar" onClick={HandleSettings}>
              <RxAvatar />
            </div>
          </div>
        </div>
      </header>
      {enableSettings && (
        <div className="user-settings-container">
          <div className="user-settings-back" onClick={HandleSettings}>
            <div className="user-settings-back-background"></div>
          </div>
          <div className="user-settings-sidebar">
            <div className="setting-buttons"></div>
            <div className="setting-logout">
              <Button
                label={`${loading ? 'LOGGING OUT...' : 'LOG OUT'}`}
                className="logout-button button"
                handleClick={HandleLogOut}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  username: PropTypes.string.isRequired,
  openMenu: PropTypes.bool.isRequired,
  HandleSideMenu: PropTypes.func.isRequired,
  removeVoiceFlow: PropTypes.func.isRequired
};

const User = () => {
  const userData = useSessionStorage('userData', 'get');

  const Scenes = userData.scenesId;
  const Models = userData.modelsId;
  const Medias = userData.mediasId;

  // console.log(Medias);

  const [isLoading, setIsLoading] = useState(true);

  const [openMenu, setOpenMenu] = useState(true);

  // this is for the ui
  const [activateHome, setActivateHome] = useState(true);
  const [activateEditor, setActivateEditor] = useState(false);
  const [activateAssistance, setActivateAssistance] = useState(false);
  const [activateAdmin, setActivateAdmin] = useState(false);
  const [activateLibrary, setActivateLibrary] = useState(false);
  const [activateMedia, setActivateMedia] = useState(false);

  const HandleSideMenuActive = (index) => {
    if (index === 0 && !activateHome) {
      setActivateHome((value) => !value);
      setActivateEditor(false);
      setActivateAssistance(false);
      setActivateAdmin(false);
      setActivateLibrary(false);
      setActivateMedia(false);
    } else if (index === 1 && !activateEditor) {
      setActivateEditor((value) => !value);
      setActivateAssistance(false);
      setActivateAdmin(false);
      setActivateHome(false);
      setActivateLibrary(false);
      setActivateMedia(false);
    } else if (index === 2 && !activateAssistance) {
      setActivateAssistance((value) => !value);
      setActivateEditor(false);
      setActivateAdmin(false);
      setActivateHome(false);
      setActivateLibrary(false);
      setActivateMedia(false);
    } else if (index === 3 && !activateAdmin) {
      setActivateAdmin((value) => !value);
      setActivateEditor(false);
      setActivateAssistance(false);
      setActivateHome(false);
      setActivateLibrary(false);
      setActivateMedia(false);
    } else if (index === 4 && !activateLibrary) {
      setActivateAdmin(false);
      setActivateEditor(false);
      setActivateAssistance(false);
      setActivateHome(false);
      setActivateLibrary((value) => !value);
      setActivateMedia(false);
    } else if (index === 5 && !activateMedia) {
      setActivateAdmin(false);
      setActivateEditor(false);
      setActivateAssistance(false);
      setActivateHome(false);
      setActivateLibrary(false);
      setActivateMedia((value) => !value);
    }
  };

  const HandleSideMenu = () => {
    setOpenMenu((value) => !value);
  };

  // this is to disable the ui accordingly to the user features
  const [disableSuperAdmin, setDisableSuperAdmin] = useState(true);
  const [disableEditor, setDisableEditor] = useState(false);
  const [disableAssistance, setDisableAssistance] = useState(false);
  const [disableAdmin, setDisableAdmin] = useState(false);

  useEffect(() => {
    const featureData = userData.featureAccess;
    // console.log(userData);

    if (featureData) {
      setDisableSuperAdmin(!featureData.superAdmin);
      setDisableEditor(!featureData.editor);
      setDisableAssistance(!featureData.assistance);
      setDisableAdmin(!featureData.admin);
    }

    console.log(featureData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let loadingTimer;
    let importingTimer;

    // eslint-disable-next-line prefer-const
    loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, defaultLoadingTime);

    return () => {
      clearTimeout(loadingTimer);
      clearTimeout(importingTimer);
    };
  }, []);

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  const voiceFlowDisable = useRef(null);

  // Assuming `isLoading` is a state or prop that gets passed to this component
  // eslint-disable-next-line no-unused-vars
  function VoiceflowWidget() {
    useEffect(() => {
      // Only run the code when `isLoading` is false
      if (!isLoading) {
        const script = document.createElement('script');
        script.src = 'https://cdn.voiceflow.com/widget/bundle.mjs';
        script.type = 'module'; // Assuming the script is an ES module
        script.async = true; // To load it asynchronously
        script.onload = () => {
          window.voiceflow.chat.load({
            verify: { projectID: '65bb71ee8867b45c58aa1452' },
            url: 'https://general-runtime.voiceflow.com',
            versionID: 'production'
          });

          // Set z-index after the widget has loaded
          const interval = setInterval(() => {
            const widgetContainer = document.querySelector('.voiceflow-container'); // Adjust the selector based on the actual container class
            if (widgetContainer) {
              widgetContainer.style.zIndex = '10';
              clearInterval(interval);
            }
          }, 100); // Check every 100ms until the widget is found
        };

        document.body.appendChild(script);

        // Cleanup function to remove the script when the component unmounts or `isLoading` changes
        return () => {
          document.body.removeChild(script);
        };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]); // Only re-run the effect if `isLoading` changes

    return null; // This component doesn't render anything itself
  }

  VoiceflowWidget();

  return (
    <div className="user-page">
      {isLoading && (
        <div className="user-Editor-loader-container">
          <PageLoader type="original-l" isImporting={false} />
        </div>
      )}
      <Header
        username={userData.company}
        openMenu={openMenu}
        HandleSideMenu={HandleSideMenu}
        removeVoiceFlow={setIsLoading}
      />
      <div className="main-container">
        {openMenu && (
          <div className="user-sidebar">
            <div className="icons-container">
              <div
                className="function-container"
                onClick={() => HandleSideMenuActive(0)}
                style={{
                  borderLeft: activateHome ? '3px solid rgba(0, 0, 212, 1)' : '3px solid rgba(255, 255, 255, 1)'
                }}
              >
                <div className="function-icon">
                  <MdOutlineHome />
                </div>
                <div className="function-text">Home</div>
              </div>
              <div className="icon-divider"></div>
              {!disableEditor && (
                <>
                  <div
                    className="function-container"
                    onClick={() => HandleSideMenuActive(1)}
                    style={{
                      borderLeft: activateEditor ? '3px solid rgba(0, 0, 212, 1)' : '3px solid rgba(255, 255, 255, 1)'
                    }}
                  >
                    <div className="function-icon">
                      <HiOutlineCubeTransparent />
                    </div>
                    <div className="function-text">Editor 3D</div>
                  </div>
                  <div className="icon-divider"></div>
                  <div
                    className="function-container"
                    onClick={() => HandleSideMenuActive(4)}
                    style={{
                      borderLeft: activateLibrary ? '3px solid rgba(0, 0, 212, 1)' : '3px solid rgba(255, 255, 255, 1)'
                    }}
                  >
                    <div className="function-icon">
                      <LuFileBox />
                    </div>
                    <div className="function-text">CAD Library</div>
                  </div>
                  <div className="icon-divider"></div>
                  <div
                    className="function-container"
                    onClick={() => HandleSideMenuActive(5)}
                    style={{
                      borderLeft: activateMedia ? '3px solid rgba(0, 0, 212, 1)' : '3px solid rgba(255, 255, 255, 1)'
                    }}
                  >
                    <div className="function-icon">
                      <MdOutlinePermMedia />
                    </div>
                    <div className="function-text">Media</div>
                  </div>
                  <div className="icon-divider"></div>
                </>
              )}
              {!disableAssistance && (
                <>
                  <div
                    className="function-container"
                    onClick={() => HandleSideMenuActive(2)}
                    style={{
                      borderLeft: activateAssistance
                        ? '3px solid rgba(0, 0, 212, 1)'
                        : '3px solid rgba(255, 255, 255, 1)'
                    }}
                  >
                    <div className="function-icon">
                      <GrVmMaintenance />
                    </div>
                    <div className="function-text">Assistance</div>
                  </div>
                  <div className="icon-divider"></div>
                </>
              )}
              {!disableAdmin && (
                <>
                  <div
                    className="function-container"
                    onClick={() => HandleSideMenuActive(3)}
                    style={{
                      borderLeft: activateAdmin ? '3px solid rgba(0, 0, 212, 1)' : '3px solid rgba(255, 255, 255, 1)'
                    }}
                  >
                    <div className="function-icon">
                      <MdOutlineAdminPanelSettings />
                    </div>
                    <div className="function-text">Admin console</div>
                  </div>
                  <div className="icon-divider"></div>
                </>
              )}
            </div>
            <div className="sidebar-divider"></div>
          </div>
        )}
        <div className="content-container" style={{ width: openMenu ? 'calc(100vw - 100px)' : '100vw' }}>
          {activateEditor && <EditorManager Scenes={Scenes} CompanyName={userData.company} />}
          {activateLibrary && <ModelManager Models={Models} CompanyName={userData.company} />}
          {activateMedia && <MediaManager Medias={Medias} CompanyName={userData.company} />}
        </div>
      </div>
    </div>
  );
};

export default User;
