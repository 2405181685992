class UniqueIDGenerator {
  constructor(existingIds) {
    this.existingIds = existingIds;
  }

  generateUniqueId() {
    let uniqueId;
    do {
      uniqueId = this._generateRandomId();
    } while (this.existingIds.includes(uniqueId));
    this.existingIds.push(uniqueId);
    return uniqueId;
  }

  _generateRandomId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }
}

export default UniqueIDGenerator;
