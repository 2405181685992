/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import '@pages/user/editor/Modal windows/Model library/ModelLibrary.scss';

import { MdOutlineCloudUpload, MdOutlineClose, MdOutlineFileDownload } from 'react-icons/md';

import { modelsService } from '@services/api/models/models.service';
import PageLoader from '@components/page-loader/PageLoader'; // the page loader component
import { useSessionStorage } from '@hooks/General';

const ModelLibrary = ({ headBack, inputMeshMethod }) => {
  const [models, setModels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [downloadingModelId, setDownloadingModelId] = useState(null); // Track the ID of the currently downloading model

  const userDataStore = useSessionStorage('userData', 'get');

  const Models = userDataStore.modelsId;

  const downloadPercentage = useRef(0);
  const [loadingPrecent, setLoadingPercent] = useState(0);

  useEffect(() => {
    // Update the loadingArray state with the SceneLoaderCSS component
    downloadPercentage.current = loadingPrecent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPrecent]);

  const handleDownloading = (modelId) => {
    console.log('Setting downloading model ID to:', modelId);
    setDownloadingModelId(modelId); // Set downloading state for the specific model
    console.log('Downloading model ID set to:', modelId);
  };

  // this is the method that takes in input the modelmanager meshinput method
  const handleModelInputScene = async (modelId, modelName) => {
    try {
      console.log('Starting download for model:', modelId);
      handleDownloading(modelId);

      // first let's call the input mesh method
      await inputMeshMethod(modelId, modelName, setLoadingPercent);
    } catch (error) {
      console.error('Error adding model to scene:', error);
    } finally {
      console.log('Finished download for model:', modelId);
      headBack();
      setDownloadingModelId(null); // Reset the downloading model ID
    }
  };

  const fetchModels = async () => {
    try {
      const body = {
        models_Id: Models
      };

      const response = await modelsService.listModel(body);
      setIsLoading(false);
      console.log('List Models Response:', response.data.models);
      setModels(response.data.models); // Set models directly
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchModels();
  }, []);

  const nothingFunction = () => {
    console.log('');
  };

  useEffect(() => {
    console.log('Current downloading model ID:', downloadingModelId !== null);
  }, [downloadingModelId]);

  const iconAdd = <MdOutlineCloudUpload style={{ scale: '1.5', marginRight: '10px' }} />;

  const iconDownload = <MdOutlineFileDownload style={{ scale: '1.5', marginRight: '10px' }} />;

  return (
    <div className="model-manager-model-container">
      <div className="model-manager-modal">
        <div
          className="close-icon-modal"
          onClick={downloadingModelId !== null ? nothingFunction : headBack}
          style={downloadingModelId !== null ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
        >
          <MdOutlineClose />
        </div>
        <h1 className="Editor-Title-modal"> Model Library </h1>
        <div className="editor-title-divider-modal"></div>

        {isLoading && (
          <div className="user-Editor-Model-loader-container-modal">
            <PageLoader type="original-l" isImporting={false} height="100%" width="100%" />
          </div>
        )}

        <div className="ModelContainer-modal">
          {models.map((model, index) => (
            <div className="buttonRow-modal" key={index}>
              <div
                className="topContainer-modal"
                style={{
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              >
                <div className="buttonText-modal">{model.modelName}</div>
              </div>
              <div className="bottomContainer-modal">
                <div className="rightContainer-modal">
                  <button
                    className="edit-viewbutton-modal"
                    onClick={() => handleModelInputScene(model.modelId, model.modelName)}
                    disabled={downloadingModelId !== null} // Disable other buttons except the downloading one
                  >
                    <div
                      className="button-content-modal"
                      style={
                        downloadingModelId === model.modelId
                          ? loadingPrecent < 50
                            ? { color: 'black' }
                            : { color: 'white' }
                          : {}
                      }
                    >
                      {downloadingModelId === model.modelId ? iconDownload : iconAdd}{' '}
                      {downloadingModelId === model.modelId ? 'Downloading ...' : 'Add to scene'}
                    </div>
                    {downloadingModelId === model.modelId && (
                      <div className="selectedButtonProgress" style={{ width: `${loadingPrecent}%` }}></div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

ModelLibrary.propTypes = {
  headBack: PropTypes.func.isRequired,
  inputMeshMethod: PropTypes.func.isRequired
};

export default ModelLibrary;
