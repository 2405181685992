import { SceneSerializer, Vector3 } from '@babylonjs/core';

import { scenesService } from '@services/api/scenes/scenes.service';

class SceneDownloader {
  constructor(importableMeshes, scene, userDataStore, sessionData, handleSceneEdit = () => {}, file, setIsloading) {
    this.objectUrl = null;
    this.importableMeshes = importableMeshes;
    this.scene = scene;
    this.userDataStore = userDataStore;
    this.sessionData = sessionData;
    this.handleSceneEdit = handleSceneEdit;
    this.file = file;
    this.setIsloading = setIsloading;
  }

  sceneJson(sceneName, objDescription) {
    if (this.objectUrl) {
      window.URL.revokeObjectURL(this.objectUrl);
    }

    // eslint-disable-next-line prefer-const
    let downloadableJson = {};

    this.setIsloading(true);

    // console.log(this.userDataStore);
    console.log(this.sessionData.data.accessInformation);

    const getMeshMetadataByUniqueId = (uniqueId) => {
      const mesh = this.scene.getMeshByUniqueID(uniqueId);
      if (mesh.metadata && mesh.metadata.importData) {
        if (mesh.metadata.importData.type === 'cloneBox') {
          mesh.metadata.importData.scaling = new Vector3(1, 1, 1);
        } else {
          mesh.metadata.importData.scaling = mesh.scaling;
        }
        mesh.metadata.importData.position = mesh.position;
        mesh.metadata.importData.rotation = mesh.rotation;
        mesh.metadata.importData.quaternion = mesh.rotationQuaternion;

        if (mesh.metadata.importData.type === 'mesh') {
          mesh.metadata.importData.description = objDescription;
        }

        const json = mesh.metadata.importData;
        return json;
      } else {
        return null;
      }
    };

    this.importableMeshes.forEach((value) => {
      const json = getMeshMetadataByUniqueId(value);
      downloadableJson[value] = json;
    });

    (async () => {
      const Bucket = 'exvirience-scenes';
      const Body = JSON.stringify(downloadableJson);

      const awsInformation = {
        accessKeyId: this.sessionData.data.accessInformation.AccessKeyId,
        secretAccessKey: this.sessionData.data.accessInformation.SecretAccessKey,
        sessionToken: this.sessionData.data.accessInformation.SessionToken,
        bucketName: Bucket,
        accelerationStatus: true,
        jsonFile: Body
      };

      if (this.file === 'new') {
        const sceneBody = {
          companyName: this.userDataStore.company,
          scenes_Id: this.userDataStore.scenesId,
          sceneName,
          awsInformation
        };

        console.log('here');
        console.log(sceneBody);

        const response = await scenesService.addScene(sceneBody);

        console.log(response.data);

        // then we go into the editor mode as if it were an existing scene
        this.handleSceneEdit(response.data.scene.sceneId);
      } else {
        const sceneBody = {
          scenes_Id: this.userDataStore.scenesId,
          sceneId: this.file,
          awsInformation
        };

        console.log(sceneBody);

        const response = await scenesService.saveScene(sceneBody);

        console.log(response.data);
      }

      this.setIsloading(false);
    })();
  }

  download(filename, scene) {
    if (this.objectUrl) {
      window.URL.revokeObjectURL(this.objectUrl);
    }

    const serializedScene = SceneSerializer.Serialize(scene);
    const originalMeshesMap = {};
    scene.meshes.forEach((mesh) => {
      originalMeshesMap[mesh.id] = mesh;
    });

    serializedScene.meshes.forEach((serializedMesh) => {
      const originalMesh = originalMeshesMap[serializedMesh.id];
      if (originalMesh && originalMesh.metadata) {
        serializedMesh.metadata = originalMesh.metadata;
      }
    });

    const cache = new Set();
    const replacer = (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache.has(value)) {
          return;
        }
        cache.add(value);
      }
      return value;
    };

    const strScene = JSON.stringify(serializedScene, replacer);

    if (filename.toLowerCase().lastIndexOf('.babylon') !== filename.length - 8 || filename.length < 9) {
      filename += '.babylon';
    }

    const blob = new Blob([strScene], { type: 'octet/stream' });
    this.objectUrl = (window.webkitURL || window.URL).createObjectURL(blob);

    const link = window.document.createElement('a');
    link.href = this.objectUrl;
    link.download = filename;
    const click = document.createEvent('MouseEvents');
    click.initEvent('click', true, false);
    link.dispatchEvent(click);
  }
}

export default SceneDownloader;
