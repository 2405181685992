import { StandardMaterial, Color3 } from '@babylonjs/core'; // Replace with actual import path
import { MeshHierarchyBuilder } from '@hooks/Mesh/index';
// Import other necessary classes or functions

class HandelIsolationAndEdit {
  constructor(
    sceneInstance,
    setIsTransparent,
    setIsolation,
    disableAllGizmo,
    changeToolbar,
    hideContextMenu,
    removeHighlightRef,
    originalMaterialsState,
    setOriginalMaterialsState,
    isNotIsolationref,
    isTransparent,
    pickedMeshRef,
    emptyMeshTreeRef
  ) {
    // Setting the properties
    this.sceneInstance = sceneInstance;
    this.setIsTransparent = setIsTransparent;
    this.setIsolation = setIsolation;
    this.disableAllGizmo = disableAllGizmo;
    this.changeToolbar = changeToolbar;
    this.hideContextMenu = hideContextMenu;
    this.removeHighlightRef = removeHighlightRef;
    this.isTransparent = isTransparent;
    this.originalMaterialsState = originalMaterialsState;
    this.setOriginalMaterialsState = setOriginalMaterialsState;
    this.isNotIsolationref = isNotIsolationref;
    this.pickedMeshRef = pickedMeshRef;
    this.emptyMeshTreeRef = emptyMeshTreeRef;

    // Binding methods
    this.toggleMeshTransparency = this.toggleMeshTransparency.bind(this);
    this.handleIsolation = this.handleIsolation.bind(this);
  }

  toggleMeshTransparency() {
    try {
      if (!this.sceneInstance || !this.sceneInstance.current) {
        console.error('sceneInstance or sceneInstance.current is undefined');
        return;
      }

      if (this.isTransparent) {
        if (this.sceneInstance && this.sceneInstance.current && this.sceneInstance.current.meshes) {
          // Revert to original materials
          this.sceneInstance.current.meshes.forEach((mesh) => {
            if (mesh.metadata && mesh.metadata.isolatable) {
              const originalState = this.originalMaterialsState.get(mesh.uniqueId);
              if (originalState) {
                mesh.material = originalState.material;
                mesh.metadata.canToggleGizmo = originalState.canToggleGizmo;
                mesh.metadata.canToggleFocus = originalState.canToggleFocus;
                mesh.metadata.isIsolated = originalState.isIsolated;
              }
            }
          });

          this.emptyMeshTreeRef.current = {
            id: null,
            name: null,
            children: []
          };

          this.handleIsolation(true);
        } else {
          console.error('sceneInstance or sceneInstance.meshes is undefined');
        }
      } else {
        // First let's save the mesh tree
        const cloneID = this.pickedMeshRef.current.metadata.clone
          ? this.pickedMeshRef.current.metadata.clone
          : this.pickedMeshRef.current;
        const meshTreeManager = new MeshHierarchyBuilder();
        const meshTreeObject = meshTreeManager.buildHierarchyTree(cloneID);

        // console.log('Selected mesh: ', cloneID);
        // console.log(meshTreeObject);
        this.emptyMeshTreeRef.current = meshTreeObject;

        // Now let's toggle transparency
        const pickedFamilyID = this.pickedMeshRef.current.metadata.familyID;

        const transparentGrayMaterial = new StandardMaterial('transparentGray', this.sceneInstance.current);
        transparentGrayMaterial.alpha = 0.5;
        transparentGrayMaterial.diffuseColor = new Color3(0.5, 0.5, 0.5);

        const defaultMaterial = new StandardMaterial('defaultMaterial', this.sceneInstance.current);
        // eslint-disable-next-line prefer-const
        let updatedMaterials = new Map(this.originalMaterialsState);

        this.sceneInstance.current.meshes.forEach((mesh) => {
          if (!mesh.metadata) return;

          if (mesh.metadata && mesh.metadata.isolatable) {
            updatedMaterials.set(mesh.uniqueId, {
              material: mesh.material || defaultMaterial,
              canToggleGizmo: mesh.metadata.canToggleGizmo,
              canToggleFocus: mesh.metadata.canToggleFocus,
              isIsolated: mesh.metadata.isIsolated
            });

            // Set metadata values to false if they were true
            if (mesh.metadata.canToggleGizmo) {
              mesh.metadata.canToggleGizmo = false;
            }

            if (mesh.metadata.familyID !== pickedFamilyID) {
              if (mesh.metadata.canToggleFocus) {
                mesh.metadata.canToggleFocus = false;
              }

              mesh.material = transparentGrayMaterial;
            } else {
              if (!mesh.metadata.isIsolated) {
                mesh.metadata.isIsolated = true;
              }
            }
          }
        });

        this.setOriginalMaterialsState(updatedMaterials);

        // console.log('Original Materials Size:', this.originalMaterialsState.size);

        this.handleIsolation(false);
      }
      this.setIsTransparent(!this.isTransparent);
    } catch (error) {
      console.log(error);
    }
  }

  handleIsolation(value) {
    // Check if isNotIsolationref is defined
    if (!this.isNotIsolationref) {
      console.warn('isNotIsolationref is undefined.');
      return;
    }

    if (value) {
      if (this.removeHighlightRef && this.removeHighlightRef.current) {
        this.removeHighlightRef.current();
      }
      this.setIsolation(value);
      this.changeToolbar('default');
    } else {
      if (this.isNotIsolationref && this.isNotIsolationref.current) {
        if (this.removeHighlightRef && this.removeHighlightRef.current) {
          this.removeHighlightRef.current();
        }
        this.setIsolation(value);
        this.disableAllGizmo();
        this.changeToolbar('Isolation');
        this.hideContextMenu();
      }
    }
  }
}

export default HandelIsolationAndEdit;
