import {
  FaBirthdayCake,
  FaComments,
  FaGlobe,
  FaHeart,
  FaImages,
  FaKey,
  FaLock,
  FaNewspaper,
  FaRegBell,
  FaRegUser,
  FaUser,
  FaUserCheck,
  FaUserPlus,
  FaUsers,
  FaVideo
} from 'react-icons/fa';

export const sideBarItems = [
  {
    index: 1,
    name: 'Streams',
    url: '/app/social/streams',
    iconName: 'FaNewspaper'
  },
  {
    index: 2,
    name: 'Chat',
    url: '/app/social/chat/messages',
    iconName: 'FaComments'
  },
  {
    index: 3,
    name: 'People',
    url: '/app/social/people',
    iconName: 'FaUsers'
  },
  {
    index: 4,
    name: 'Following',
    url: '/app/social/following',
    iconName: 'FaUserPlus'
  },
  {
    index: 5,
    name: 'Followers',
    url: '/app/social/followers',
    iconName: 'FaHeart'
  },
  {
    index: 6,
    name: 'Photos',
    url: '/app/social/photos',
    iconName: 'FaImages'
  },
  {
    index: 7,
    name: 'Videos',
    url: '/app/social/videos',
    iconName: 'FaVideo'
  },
  {
    index: 8,
    name: 'Notifications',
    url: '/app/social/notifications',
    iconName: 'FaRegBell'
  },
  {
    index: 9,
    name: 'Profile',
    url: '/app/social/profile',
    iconName: 'FaRegUser'
  }
];

export const fontAwesomeIcons = {
  FaNewspaper: <FaNewspaper className="icon" />,
  FaComments: <FaComments className="icon" />,
  FaUsers: <FaUsers className="icon" />,
  FaUserPlus: <FaUserPlus className="icon" />,
  FaHeart: <FaHeart className="icon" />,
  FaImages: <FaImages className="icon" />,
  FaVideo: <FaVideo className="icon" />,
  FaRegBell: <FaRegBell className="icon" />,
  FaBirthdayCake: <FaBirthdayCake className="icon" />,
  FaRegUser: <FaRegUser className="icon" />
};

export const privacyList = [
  {
    topText: 'Public',
    subText: 'Anyone on Exvirience',
    icon: <FaGlobe className="globe-icon globe" />
  },
  {
    topText: 'Followers',
    subText: 'Your followers on Exvirience',
    icon: <FaUserCheck className="globe-icon globe" />
  },
  {
    topText: 'Private',
    subText: 'For you only',
    icon: <FaLock className="globe-icon globe" />
  }
];

export const bgColors = [
  '#ffffff',
  '#f44336',
  '#e91e63',
  '#2196f3',
  '#9c27b0',
  '#3f51b5',
  '#00bcd4',
  '#4caf50',
  '#ff9800',
  '#8bc34a',
  '#009688',
  '#03a9f4',
  '#cddc39'
];

export const accountType = ['professor', 'student', 'university'];

export const userColors = [
  '#f44336',
  '#e91e63',
  '#2196f3',
  '#9c27b0',
  '#3f51b5',
  '#00bcd4',
  '#4caf50',
  '#ff9800',
  '#8bc34a',
  '#009688',
  '#03a9f4',
  '#cddc39',
  '#2962ff',
  '#448aff',
  '#84ffff',
  '#00e676',
  '#43a047',
  '#d32f2f',
  '#ff1744',
  '#ad1457',
  '#6a1b9a',
  '#1a237e',
  '#1de9b6',
  '#d84315'
];

export const emptySceneData = {
  _id: '',
  scene: '',
  bgColor: '',
  privacy: '',
  gifUrl: '',
  profilePicture: '',
  image: '',
  userId: '',
  username: '',
  email: '',
  accounttype: '',
  commentsCount: '',
  ratings: [],
  imageVersion: '',
  imageId: '',
  createdAt: '',
  video: ''
};

export const notificationItems = [
  {
    index: 0,
    title: 'Scene Rating',
    description: 'New Rating for your scenes notifications.',
    toggle: true,
    type: 'ratings'
  },
  {
    index: 1,
    title: 'Comments',
    description: 'New comments for your scenes notifications.',
    toggle: true,
    type: 'comments'
  }
];

export const tabItems = (showPassword, showNotification) => {
  const items = [
    { key: 'Timeline', show: true, icon: <FaUser className="banner-nav-item-name-icon" /> },
    { key: 'Followers', show: true, icon: <FaHeart className="banner-nav-item-name-icon" /> },
    { key: 'Gallery', show: true, icon: <FaImages className="banner-nav-item-name-icon" /> },
    {
      key: 'Change Password',
      show: showPassword,
      icon: <FaKey className="banner-nav-item-name-icon" />
    },
    {
      key: 'Notifications',
      show: showNotification,
      icon: <FaRegBell className="banner-nav-item-name-icon" />
    }
  ];
  return items;
};
