const hideCertainMeshes = (scene, familyID) => {
  scene.meshes.forEach((mesh) => {
    if (mesh.metadata && mesh.metadata.familyID === familyID) {
      if (!mesh.name.startsWith('__root') && mesh.name !== 'ClonedMesh') {
        mesh.isVisible = false;
      } else {
        // console.log(mesh);
        mesh.isVisible = true;
      }
    }
  });
};

const unhideCertainMeshes = (scene, familyID) => {
  scene.meshes.forEach((mesh) => {
    if (mesh.metadata && mesh.metadata.familyID === familyID) {
      mesh.isVisible = true; // Unhide all meshes with metadata.familyID
      if (mesh.name.startsWith('ClonedMesh')) {
        mesh.isVisible = false; // Hide the ClonedMesh
      }
    }
  });
};

export { hideCertainMeshes, unhideCertainMeshes };
