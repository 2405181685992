import React from 'react';
import PropTypes from 'prop-types';
import '@components/page-loader/PageLoader.scss';

import Spinner from '@components/page-loader/spinner-loader/Spinner';

import logoOriginal from '@assets/images/Logo_Exvirience_Original.png';
import logoWhite from '@assets/images/Logo_Exvirience_White.png';
import logoBlack from '@assets/images/Logo_Exvirience_Black.png';

import { defaultLoadingTime } from '@root/App';

const PageLoader = ({ type, isImporting, height, width }) => {
  let logoImage;
  let sType;
  let sColor;

  const fadeOutTime = (defaultLoadingTime - 1000) / 1000;

  switch (type) {
    case 'white-r':
      logoImage = logoWhite;
      sColor = 'white';
      sType = 'ring';

      break;
    case 'black-r':
      logoImage = logoBlack;
      sColor = 'black';
      sType = 'ring';

      break;
    case 'original-r':
      logoImage = logoOriginal;
      sColor = 'black';
      sType = 'ring';

      break;
    case 'white-l':
      logoImage = logoWhite;
      sColor = 'white';
      sType = 'ellipsis';

      break;
    case 'black-l':
      logoImage = logoBlack;
      sColor = 'black';
      sType = 'ellipsis';

      break;
    case 'original-l':
    default:
      logoImage = logoOriginal;
      sColor = 'black';
      sType = 'ellipsis';

      break;
  }

  const fadeOutStyle = {
    animation: `fadeOut 1s ${fadeOutTime}s forwards, unblur 1s ${fadeOutTime}s forwards, transparentBackground 1s ${fadeOutTime}s forwards`,
    ...(height && { height }),
    ...(width && { width })
  };

  return (
    <div className={isImporting ? 'page-loader' : 'page-loader'} style={fadeOutStyle}>
      <div className="container-logo" style={{ backgroundImage: `url(${logoImage})` }}></div>
      <div className="spinner-container">
        <Spinner type={sType} mode={sColor} />
      </div>
    </div>
  );
};

PageLoader.propTypes = {
  type: PropTypes.oneOf(['original-l', 'white-l', 'black-l', 'original-r', 'white-r', 'black-r']),
  isImporting: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string
};

PageLoader.defaultProps = {
  type: 'original-l',
  isImporting: false
};

export default PageLoader;
