class DeleteMeshConfirm {
  constructor(
    setBoxes,
    setCanvases,
    setSpheres,
    setCylinders,
    setMeshes,
    setToolbarState,
    setSelectedMesh,
    disableAllGizmo,
    DeleteConfirm,
    previousToolbarStateRef,
    sceneInstance,
    importableMeshes
  ) {
    this.setBoxes = setBoxes;
    this.setSpheres = setSpheres;
    this.setCylinders = setCylinders;
    this.setMeshes = setMeshes;
    this.setToolbarState = setToolbarState;
    this.setSelectedMesh = setSelectedMesh;
    this.disableAllGizmo = disableAllGizmo;
    this.DeleteConfirm = DeleteConfirm;
    this.previousToolbarStateRef = previousToolbarStateRef;
    this.sceneInstance = sceneInstance;
    this.importableMeshes = importableMeshes;
  }

  deleteSelectedObject(selectedMesh, boxes, spheres, cylinders, meshes) {
    if (selectedMesh) {
      let targetMesh;
      let meshesArray = [];

      if (selectedMesh.metadata.clone) {
        targetMesh = selectedMesh.metadata.clone;
      } else {
        targetMesh = selectedMesh;
      }

      // eslint-disable-next-line prefer-const
      let shadowPlane = targetMesh.metadata.shadowPlane;
      if (shadowPlane) {
        shadowPlane.dispose();
      }

      if (selectedMesh.metadata && selectedMesh.metadata.familyID) {
        for (const mesh of this.sceneInstance.current.meshes) {
          if (mesh.metadata && mesh.metadata.familyID && mesh.metadata.familyID === selectedMesh.metadata.familyID) {
            meshesArray.push(mesh);
          }
        }
        meshesArray.forEach((element) => {
          element.dispose();

          // After disposing, create a new array that excludes the disposed meshes
          meshesArray = meshesArray.filter((element) => !element.isDisposed());
        });
        targetMesh.dispose();
      } else {
        targetMesh.dispose();
      }

      this.setBoxes(boxes.filter((box) => box !== targetMesh));
      this.setSpheres(spheres.filter((sphere) => sphere !== targetMesh));
      this.setCylinders(cylinders.filter((cylinder) => cylinder !== targetMesh));
      this.setMeshes(meshes.filter((mesh) => mesh !== targetMesh));

      this.setToolbarState(this.previousToolbarStateRef.current);
      this.setSelectedMesh(null);
      this.disableAllGizmo();
      this.DeleteConfirm();
    }
  }

  deleteSelectedObjectContext(pickedMeshRef, boxes, spheres, cylinders, meshes) {
    if (pickedMeshRef.current) {
      let targetMesh;

      if (pickedMeshRef.current.metadata.clone) {
        targetMesh = pickedMeshRef.current.metadata.clone;
      } else {
        targetMesh = pickedMeshRef.current;
      }

      // eslint-disable-next-line prefer-const
      let shadowPlane = targetMesh.metadata.shadowPlane;
      if (shadowPlane) {
        shadowPlane.dispose();
      }

      targetMesh.dispose();

      this.setBoxes(boxes.filter((box) => box !== targetMesh));
      this.setSpheres(spheres.filter((sphere) => sphere !== targetMesh));
      this.setCylinders(cylinders.filter((cylinder) => cylinder !== targetMesh));
      this.setMeshes(meshes.filter((mesh) => mesh !== targetMesh));

      this.setToolbarState(this.previousToolbarStateRef.current);
      this.setSelectedMesh(null);
      this.disableAllGizmo();
      this.DeleteConfirm();
    }
  }
}

export default DeleteMeshConfirm;
