import { S3Client } from '@aws-sdk/client-s3';

const AWSClient = () => {
  // eslint-disable-next-line prefer-const
  const client = new S3Client({
    region: 'us-east-1',
    credentials: {}
  });

  return client;
};

export default AWSClient;
