import { ArcRotateCamera, Vector3, ArcRotateCameraPointersInput, Viewport } from '@babylonjs/core';

class SetCameraToScene {
  constructor(scene, C1M, canvasInstance, cameraRefInstance) {
    this.scene = scene;
    this.C1M = C1M;
    this.canvasInstance = canvasInstance;
    this.cameraRefInstance = cameraRefInstance;

    this.createAndSetupCamera();
  }

  createAndSetupCamera() {
    const camera = new ArcRotateCamera('camera1', Math.PI / 2, Math.PI / 4, 1, new Vector3(0, 0, 0), this.scene);
    camera.position = new Vector3(7, 5, -7);

    // eslint-disable-next-line prefer-const
    let cameraInputManager = new ArcRotateCameraPointersInput();
    cameraInputManager.buttons = [0, 1, 2];

    // Adjust camera button inputs
    camera.inputs.attached.pointers.buttons[0] = undefined;
    camera.inputs.attached.pointers.buttons[2] = undefined;
    camera._panningMouseButton = cameraInputManager.buttons[2];

    camera.wheelPrecision = 10;
    camera.inputs.attached.pointers._useCtrlForPanning = true;

    const canvas = this.scene.getEngine().getRenderingCanvas();
    this.canvasInstance.current = canvas;

    camera.attachControl(canvas, true);
    camera.layerMask = this.C1M;
    camera.viewport = new Viewport(0, 0, 1, 1);

    this.cameraInstance = camera;
    this.cameraRefInstance.current = camera;
    this.canvasInstance = canvas;
  }

  getCamera() {
    return this.cameraInstance;
  }

  // Not needed for now
  getCameraRefCurrent() {
    return this.cameraRefInstance.current;
  }

  getCanvas() {
    return this.canvasInstance;
  }
}

export default SetCameraToScene;
