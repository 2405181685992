import React, { useState } from 'react';
import '@components/Modal windows/Modal window - rename/ConfirmRename.scss';
import InputNew from '@components/input/input new/InputNew';

import PropTypes from 'prop-types';

const ConfirmRename = ({ renameObject, cancelAction, initialName = '' }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInput = (event) => {
    setInputValue(event.target.value);
  };

  const handleConfirm = () => {
    renameObject(inputValue);
  };

  return (
    <div className="modal-wrapper-rename" onClick={() => cancelAction()}>
      <div className="confirm-rename-container" onClick={(e) => e.stopPropagation()}>
        <h2>Rename Object</h2>
        <p>{"Enter the new name for the object/part and click 'Rename'."}</p>
        <p>{`Current Name: ${initialName}`}</p> {/* Add this line */}
        <InputNew
          name="rename"
          type="text"
          value={inputValue}
          onChange={handleInput}
          placeholder={'New name'}
          autocomplete="off"
          style={{ marginTop: '10px' }}
        />
        <div className="buttons-container">
          <button
            className="button-new"
            onClick={() => cancelAction()}
            style={{ cursor: 'pointer', color: 'rgba(255, 0, 0, 1)' }}
          >
            Cancel
          </button>
          <div className="divider"></div>
          <button
            className="button-new"
            onClick={handleConfirm}
            disabled={!inputValue}
            style={!inputValue ? { color: 'var(--white-17)', cursor: 'not-allowed' } : { cursor: 'pointer' }}
          >
            Rename
          </button>
        </div>
      </div>
    </div>
  );
};

ConfirmRename.propTypes = {
  renameObject: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  initialName: PropTypes.string
};

export default ConfirmRename;
