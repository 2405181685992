import {
  Color3,
  Vector3,
  MeshBuilder,
  StandardMaterial,
  SceneLoader,
  Quaternion,
  Axis,
  Space,
  Mesh,
  TransformNode
} from '@babylonjs/core'; // Ensure the necessary BabylonJS modules are imported.

import { AdvancedDynamicTexture, TextBlock } from '@babylonjs/gui';

import { scenesService } from '@services/api/scenes/scenes.service';
import { modelsService } from '@services/api/models/models.service';

/*  sceneInstance,
    C1M,
    familyIDs,
    shadowPlanes,
    loadingSpheresCreator,
    setBoxes,
    setSpheres,
    setCylinders,
    (mesh) => shadowPlaneCreator.create(mesh),
    boxes,
    canvases,
    spheres,
    cylinders,
    meshes,
    importableMeshes.current,
    setMeshes */

class FileLoader {
  constructor(
    sceneInstance,
    C1M,
    familyIDs,
    shadowPlanes,
    loadingSpheresCreator,
    setBoxes,
    setCanvases,
    setSpheres,
    setCylinders,
    createShadowPlane,
    boxes,
    canvases,
    spheres,
    cylinders,
    meshes,
    importableMeshes,
    setMeshesCallback,
    descriptionObject,
    userDataStore,
    sessionData
  ) {
    this.sceneInstance = sceneInstance;
    this.C1M = C1M;
    this.familyIDs = familyIDs;
    this.shadowPlanes = shadowPlanes;
    this.loadingSpheresCreator = loadingSpheresCreator;
    this.setBoxes = setBoxes;
    this.setCanvases = setCanvases;
    this.setSpheres = setSpheres;
    this.setCylinders = setCylinders;
    this.createShadowPlane = createShadowPlane;
    this.boxes = boxes;
    this.canvases = canvases;
    this.spheres = spheres;
    this.cylinders = cylinders;
    this.meshes = meshes;
    this.importableMeshes = importableMeshes;
    this.name = '';
    this.setMeshesCallback = setMeshesCallback;
    this.descriptionObject = descriptionObject;
    this.userDataStore = userDataStore;
    this.sessionData = sessionData;
  }

  async importScene(sceneName) {
    this.loadingSpheresCreator.create(new Vector3(0, 0, 0));

    try {
      const Bucket = 'exvirience-scenes';
      const Key = `${sceneName}`;

      const awsInformation = {
        accessKeyId: this.sessionData.data.accessInformation.AccessKeyId,
        secretAccessKey: this.sessionData.data.accessInformation.SecretAccessKey,
        sessionToken: this.sessionData.data.accessInformation.SessionToken,
        bucketName: Bucket
      };

      const sceneBody = {
        scenes_Id: this.userDataStore.scenesId,
        sceneId: Key,
        awsInformation
      };

      const response = await scenesService.getScene(sceneBody);
      const sceneData = response.data;

      console.log(sceneData);

      // Directly iterate over the object and instantiate the scene
      for (const key in sceneData) {
        // eslint-disable-next-line no-prototype-builtins
        if (sceneData.hasOwnProperty(key)) {
          const value = sceneData[key];
          console.log(`Key: ${key}, Value:`, value);

          await this.InstantiateInScene(value);
        }
      }

      this.loadingSpheresCreator.disposeSpheres();
    } catch (error) {
      console.error('Error fetching scene: ', error);
    }
  }

  async InstantiateInScene(value) {
    if (value.type === 'mesh') {
      await this.createMesh(value);
    } else if (value.type === 'box') {
      this.createBox(value);
    } else if (value.type === 'sphere') {
      this.createSphere(value);
    } else if (value.type === 'cylinder') {
      this.createCylinder(value);
    } else if (value.type === 'image' || value.type === 'video') {
      this.createCanvasBox(value);
    }
  }

  /*
  async importMeshesFromFile(file) {
    const shadowPlaneManager = new ShadowPlaneCreator(this.sceneInstance, this.C1M, this.shadowPlanes);

    const setUpManager = new SetLoadFiles(
      (mesh) => {
        shadowPlaneManager.create(mesh);
      },
      this.C1M,
      this.familyIDs
    );

    if (file && this.sceneInstance.current) {
      // Step 1: Create a set of the current mesh names
      const existingMeshNames = new Set(this.sceneInstance.current.meshes.map((mesh) => mesh.name));

      try {
        const result = await SceneLoader.ImportMeshAsync(null, '', file, this.sceneInstance.current);
        for (const mesh of result.meshes) {
          // Add "NI_" in front of the mesh's name
          mesh.name = 'NI_' + mesh.name;

          this.sceneInstance.current.meshes.forEach((mesh) => {
            if (mesh.name.startsWith('NI_shadowPlane')) {
              mesh.dispose();
            }
          });

          if (
            (mesh.metadata && mesh.metadata.editor) ||
            mesh.name.startsWith('NI_Cylinder') ||
            mesh.name.startsWith('NI_Sphere') ||
            mesh.name.startsWith('NI_ground')
          ) {
            mesh.dispose();
          }

          if (mesh.metadata && mesh.metadata.assetURL) {
            // eslint-disable-next-line no-unused-vars
            // console.log(mesh.name + '\n    '); // This will log an array of child meshes
            mesh.dispose();
          }

          if (!mesh.name.startsWith('NI___root__')) {
            mesh.setParent(null, true);
          } else {
            mesh.dispose();
          }
        }

        // Step 2: Loop through all the meshes in the scene
        this.sceneInstance.current.meshes.forEach((mesh) => {
          console.log(mesh.name);
          // If it's a newly added mesh
          if (!existingMeshNames.has(mesh.name)) {
            // Step 3: Process the newly added mesh
            setUpManager.loadedObjectsSetup(mesh);
          }
        });
        // this.sceneInstance.current.render();
      } catch (error) {
        console.error('Error:', error);
      }
    }
  } */

  async createMesh(value) {
    if (value.description) {
      this.descriptionObject.current = value.description;
    }

    const Bucket = value.url.bucket;
    const Key = value.url.key;
    const models_Id = value.url.modelsId; // this is required

    const awsInformation = {
      accessKeyId: this.sessionData.data.accessInformation.AccessKeyId,
      secretAccessKey: this.sessionData.data.accessInformation.SecretAccessKey,
      sessionToken: this.sessionData.data.accessInformation.SessionToken,
      bucketName: Bucket
    };

    const modelsBody = {
      models_Id,
      modelId: Key,
      awsInformation
    };

    const response = await modelsService.getModelNoPercent(modelsBody);

    console.log(response.data);

    const dataURL = await this.readFileAndLoadMesh(response.data, response.headers['content-type']);

    console.log(dataURL);

    const result = await SceneLoader.ImportMeshAsync(null, '', dataURL, this.sceneInstance.current);

    const newMeshes = result.meshes;
    // console.log(newMeshes[0]);

    newMeshes.showBoundingBox = false;

    this.importableMeshes.push(newMeshes[0].uniqueId);

    newMeshes[0].scaling = new Vector3(1, 1, 1);
    // GENERATE A NAME FOR THE ROOT OBJECT FOR IT TO NOT ONLY BE CALLED __root__
    // ConsoleLog.log(this.meshes.length);
    this.name = this.changeRootName(newMeshes[0], this.meshes);

    // CREATE THE CLONED MESH AND STORE IT AS A CHILD OF THE ROOT
    // eslint-disable-next-line no-unused-vars
    const clonedMesh = this.createClone(newMeshes, value.familyID, this.sceneInstance.current);
    // ConsoleLog.log(clonedMesh);

    newMeshes[0].position = new Vector3(0, 0, 0);

    clonedMesh[1].parent = newMeshes[0];

    // console.log(value.position._x, value.position._y, value.position._z);

    // clonedMesh.position = new Vector3(4, 0, 1);
    // this.hideAllExceptClonedMeshes(this.sceneInstance.current);

    newMeshes[0].scaling = new Vector3(0, 0, 0);

    newMeshes[0].scaling = new Vector3(-1, 1, 1);
    clonedMesh[0].scaling = new Vector3(-1, 1, -1);

    newMeshes[0].scaling = new Vector3(value.scaling._x, value.scaling._y, value.scaling._z);
    newMeshes[0].position = new Vector3(value.position._x, value.position._y, value.position._z);
    newMeshes[0].rotationQuaternion = new Quaternion(
      value.quaternion._x,
      value.quaternion._y,
      value.quaternion._z,
      value.quaternion._w
    );

    console.log(newMeshes[0].scaling);

    newMeshes.forEach((value) => {
      value.metadata = {
        canToggleGizmo: true,
        canToggleFocus: true,
        targetPosition: newMeshes[0].position,
        position: value.position.clone(),
        rotation: value.rotation.clone(),
        scale: value.scaling.clone(),
        clone: newMeshes[0],
        isolatable: true,
        familyID: value.familyID,
        isIsolated: false,
        importable: true,
        clippable: true,
        importData: null
      };

      value.layerMask = this.C1M;
      value.renderingGroupId = 1;
    });

    newMeshes[0].metadata.importData = {
      familyID: value.familyID,
      type: 'mesh',
      name: value.name,
      url: {
        bucket: value.url.bucket,
        key: value.url.key,
        modelsId: value.url.modelsId
      },
      position: null,
      rotation: null,
      scaling: null
    };

    const shadowPlane = this.createShadowPlane(newMeshes[0]);
    newMeshes[0].metadata.shadowPlane = shadowPlane;
    newMeshes[0].renderingGroupId = 1;

    this.setMeshesCallback([...this.meshes, ...newMeshes]);
  }

  createCanvasBox(value) {
    if (this.sceneInstance.current) {
      const material = new StandardMaterial('canvasMaterial', this.sceneInstance.current);
      material.diffuseColor = new Color3(0, 0, 212 / 255); // Blue color
      material.emissiveColor = new Color3(0, 0, 212 / 255);
      material.alpha = 0.2; // Transparency

      const box = MeshBuilder.CreateBox(
        `${value.name}`,
        { depth: 0.05, width: 1, height: 1 * (9 / 16) },
        this.sceneInstance.current
      );
      box.position = new Vector3(0, 0, 0);
      box.scaling = new Vector3(1, 1, 1);
      box.metadata = {
        canToggleGizmo: true,
        canToggleFocus: true,
        targetPosition: box.position.clone(),
        position: box.position.clone(), // clone the initial position
        rotation: box.rotation.clone(), // clone the initial rotation
        scale: box.scaling.clone(), // clone the initial scale
        isolatable: false,
        familyID: value.familyID,
        isIsolated: false,
        importable: true,
        clippable: true,
        importData: null
      };

      box.metadata.importData = {
        familyID: value.familyID,
        type: `${value.type}`,
        name: value.name,
        url: {
          bucket: value.url.bucket,
          key: value.url.key,
          mediasId: value.url.mediasId,
          mediaName: value.url.mediaName
        },
        position: null,
        rotation: null,
        scaling: null,
        quaternion: null
      };

      box.material = material;

      // Assuming the capsule handle's position is already set
      const planeHeight = 1 * (9 / 16);
      const planeWidth = 1;
      const plane = MeshBuilder.CreatePlane(
        `textPlane1_${value.type}${value.name}`,
        { height: planeHeight, width: planeWidth },
        this.sceneInstance.current
      );

      // Position the plane above the capsule handle
      plane.position = new Vector3(0, 0, 0.025);

      // Tilt the plane by 30 degrees along the X-axis
      // plane.rotation.x = Math.PI / 4; // 30 degrees in radians

      // Create a dynamic texture for displaying text
      const dynamicTexture = new AdvancedDynamicTexture(this.sceneInstance.current);

      // Create and configure the text block
      const textBlock = new TextBlock();
      textBlock.text = `${value.type}:${value.url.mediaName}`;
      textBlock.fontSize = 100;
      textBlock.color = 'black';
      dynamicTexture.addControl(textBlock);

      // Apply the dynamic texture to the plane's material
      plane.material = new StandardMaterial('planeMaterial', this.sceneInstance.current);
      plane.material.diffuseTexture = dynamicTexture;

      plane.setParent(box);

      const plane2 = MeshBuilder.CreatePlane(
        `textPlane2${value.type}_${value.name}`,
        { height: planeHeight, width: planeWidth },
        this.sceneInstance.current
      );

      // Position the plane above the capsule handle
      plane2.position = new Vector3(0, 0, 0.025);
      plane2.rotate(new Vector3(0, 1, 0), Math.PI);

      // Tilt the plane by 30 degrees along the X-axis
      // plane.rotation.x = Math.PI / 4; // 30 degrees in radians

      // Create a dynamic texture for displaying text
      const dynamicTexture2 = new AdvancedDynamicTexture(this.sceneInstance.current);

      // Create and configure the text block
      const textBlock2 = new TextBlock();
      textBlock2.text = `${value.type}:${value.url.mediaName}`;
      textBlock2.fontSize = 100;
      textBlock2.color = 'black';
      dynamicTexture2.addControl(textBlock2);

      // Apply the dynamic texture to the plane's material
      plane2.material = new StandardMaterial('planeMaterial2', this.sceneInstance.current);
      plane2.material.diffuseTexture = dynamicTexture2;

      plane2.setParent(box);

      // Move the box upward 1/2 its height
      box.position.y = 1;

      box.layerMask = this.C1M;
      // eslint-disable-next-line prefer-const
      let shadowPlane = this.createShadowPlane(box);
      box.metadata.shadowPlane = shadowPlane;
      box.renderingGroupId = 1;
      plane2.layerMask = this.C1M;
      plane2.renderingGroupId = 1;
      plane.layerMask = this.C1M;
      plane.renderingGroupId = 1;

      box.position = new Vector3(value.position._x, value.position._y, value.position._z);
      box.scaling = new Vector3(value.scaling._x, value.scaling._y, value.scaling._z);
      box.rotation = new Vector3(value.rotation._x, value.rotation._y, value.rotation._z);

      this.setCanvases([...this.canvases, box]);

      this.importableMeshes.push(box.uniqueId);
    }
  }

  createBox(value) {
    if (this.sceneInstance.current) {
      // eslint-disable-next-line prefer-const
      let box = MeshBuilder.CreateBox(value.name, { size: 1 }, this.sceneInstance.current);
      box.position = new Vector3(value.position._x, value.position._y, value.position._z);
      box.rotation = new Vector3(value.rotation._x, value.rotation._y, value.rotation._z);
      box.scaling = new Vector3(value.scaling._x, value.scaling._y, value.scaling._z);

      box.metadata = {
        canToggleGizmo: true,
        canToggleFocus: true,
        targetPosition: box.position.clone(),
        position: box.position.clone(), // clone the initial position
        rotation: box.rotation.clone(), // clone the initial rotation
        scale: box.scaling.clone(), // clone the initial scale
        isolatable: true,
        familyID: value.familyID,
        isIsolated: false,
        importable: true,
        clippable: true,
        importData: null
      };
      // Move the box upward 1/2 its height
      box.position.y = 1;

      box.layerMask = this.C1M;

      box.metadata.importData = {
        familyID: value.familyID,
        type: 'box',
        name: box.name,
        url: null,
        position: null,
        rotation: null,
        scaling: null
      };

      // Add the mesh to the shadow generator's list of shadow casters
      // eslint-disable-next-line prefer-const
      let shadowPlane = this.createShadowPlane(box);
      box.metadata.shadowPlane = shadowPlane;
      box.renderingGroupId = 1;

      // Create a default material
      const defaultMaterial = new StandardMaterial('defaultMaterial', this.sceneInstance.current);

      // Set the material properties (you can customize these as needed)
      defaultMaterial.diffuseColor = new Color3(1, 1, 1); // White color
      defaultMaterial.specularColor = new Color3(0, 0, 0); // No specular highlights
      defaultMaterial.ambientColor = new Color3(0.2, 0.2, 0.2); // Ambient color
      defaultMaterial.emissiveColor = new Color3(0, 0, 0); // No emissive color
      box.material = defaultMaterial;

      this.setBoxes([...this.boxes, box]);

      this.importableMeshes.push(box.uniqueId);
    }
  }

  createSphere(value) {
    if (this.sceneInstance.current) {
      // eslint-disable-next-line prefer-const
      let sphere = MeshBuilder.CreateSphere(value.name, { diameter: 1 }, this.sceneInstance.current);
      sphere.position = new Vector3(value.position._x, value.position._y, value.position._z);
      sphere.rotation = new Vector3(value.rotation._x, value.rotation._y, value.rotation._z);
      sphere.scaling = new Vector3(value.scaling._x, value.scaling._y, value.scaling._z);

      sphere.metadata = {
        canToggleGizmo: true,
        canToggleFocus: true,
        targetPosition: sphere.position.clone(),
        position: sphere.position.clone(), // clone the initial position
        rotation: sphere.rotation.clone(), // clone the initial rotation
        scale: sphere.scaling.clone(), // clone the initial scale
        isolatable: true,
        familyID: value.familyID,
        isIsolated: false,
        importable: true,
        clippable: true,
        importData: null
      };
      // Move the sphere upward 1/2 its height
      sphere.position.y = 1;

      sphere.layerMask = this.C1M;

      sphere.metadata.importData = {
        familyID: value.familyID,
        type: 'sphere',
        name: sphere.name,
        url: null,
        position: null,
        rotation: null,
        scaling: null
      };

      // Create a default material
      const defaultMaterial = new StandardMaterial('defaultMaterial', this.sceneInstance.current);

      // Set the material properties (you can customize these as needed)
      defaultMaterial.diffuseColor = new Color3(1, 1, 1); // White color
      defaultMaterial.specularColor = new Color3(0, 0, 0); // No specular highlights
      defaultMaterial.ambientColor = new Color3(0.2, 0.2, 0.2); // Ambient color
      defaultMaterial.emissiveColor = new Color3(0, 0, 0); // No emissive color
      sphere.material = defaultMaterial;

      // Add the mesh to the shadow generator's list of shadow casters
      // eslint-disable-next-line prefer-const
      let shadowPlane = this.createShadowPlane(sphere);
      sphere.metadata.shadowPlane = shadowPlane;
      sphere.renderingGroupId = 1;

      this.setSpheres([...this.spheres, sphere]);

      this.importableMeshes.push(sphere.uniqueId);
    }
  }

  createCylinder(value) {
    if (this.sceneInstance.current) {
      // eslint-disable-next-line prefer-const
      let cylinder = MeshBuilder.CreateCylinder(
        value.name,
        { diameterTop: 0.5, diameterBottom: 0.5, height: 1 },
        this.sceneInstance.current
      );
      cylinder.position = new Vector3(value.position._x, value.position._y, value.position._z);
      cylinder.rotation = new Vector3(value.rotation._x, value.rotation._y, value.rotation._z);
      cylinder.scaling = new Vector3(value.scaling._x, value.scaling._y, value.scaling._z);

      cylinder.metadata = {
        canToggleGizmo: true,
        canToggleFocus: true,
        targetPosition: cylinder.position.clone(),
        position: cylinder.position.clone(), // clone the initial position
        rotation: cylinder.rotation.clone(), // clone the initial rotation
        scale: cylinder.scaling.clone(), // clone the initial scale
        isolatable: true,
        familyID: value.familyID,
        isIsolated: false,
        importable: true,
        clippable: true,
        importData: null
      };
      // Move the cylinder upward 1/2 its height
      cylinder.position.y = 1;

      cylinder.layerMask = this.C1M;

      cylinder.metadata.importData = {
        familyID: value.familyID,
        type: 'cylinder',
        name: cylinder.name,
        url: null,
        position: null,
        rotation: null,
        scaling: null
      };

      // Create a default material
      const defaultMaterial = new StandardMaterial('defaultMaterial', this.sceneInstance.current);

      // Set the material properties (you can customize these as needed)
      defaultMaterial.diffuseColor = new Color3(1, 1, 1); // White color
      defaultMaterial.specularColor = new Color3(0, 0, 0); // No specular highlights
      defaultMaterial.ambientColor = new Color3(0.2, 0.2, 0.2); // Ambient color
      defaultMaterial.emissiveColor = new Color3(0, 0, 0); // No emissive color
      cylinder.material = defaultMaterial;

      // Add the mesh to the shadow generator's list of shadow casters
      // eslint-disable-next-line prefer-const
      let shadowPlane = this.createShadowPlane(cylinder);
      cylinder.metadata.shadowPlane = shadowPlane;
      cylinder.renderingGroupId = 1;

      this.setCylinders([...this.cylinders, cylinder]);

      this.importableMeshes.push(cylinder.uniqueId);
    }
  }

  // ADD THIS LINE
  changeRootName(mesh, meshesArray) {
    // To get the length of the array
    const arrayLength = meshesArray.length;

    mesh.name = `__root_${arrayLength}__`;

    return `__root_${arrayLength}__`;
  }

  createClone(newMeshes, newId, scene) {
    // eslint-disable-next-line prefer-const
    // set the meshes to merge into a single variable
    let meshesToMerge = newMeshes.slice(1); // This will create a new array without the first element

    meshesToMerge = meshesToMerge.filter((element) => element instanceof Mesh); // Now filter only Mesh instances

    let clonedMesh;

    // ConsoleLog.log(newMeshes);

    // merge the meshes
    try {
      if (meshesToMerge.length > 0 && meshesToMerge.every((mesh) => mesh instanceof Mesh)) {
        clonedMesh = Mesh.MergeMeshes(meshesToMerge, false, true, undefined, false, true);
        // ConsoleLog.log(clonedMesh);

        clonedMesh.name = 'ClonedMesh';
        clonedMesh.isVisible = false;

        clonedMesh.showBoundingBox = false;
      } else {
        clonedMesh = newMeshes[0].clone('clonedMesh');
        console.error('No cloned mesh created');
      }
    } catch (error) {
      console.error(error);
      this.loadingSpheresCreator.disposeSpheres();

      newMeshes.forEach((value) => {
        value.dispose();
      });

      // clonedMesh.dispose();
      return;
    }

    clonedMesh.metadata = {
      canToggleGizmo: false,
      canToggleFocus: false,
      targetPosition: newMeshes[0].position,
      position: clonedMesh.position.clone(),
      rotation: clonedMesh.rotation.clone(),
      scale: clonedMesh.scaling.clone(),
      clone: newMeshes[0],
      isolatable: false,
      familyID: newId,
      isIsolated: false,
      importable: true,
      clippable: true
    };

    clonedMesh.layerMask = this.C1M;
    clonedMesh.renderingGroupId = 1;

    clonedMesh.scaling = new Vector3(1, 1, -1);

    clonedMesh.parent = newMeshes[0];

    const cloneBox = this.createBoxFromBoundingBox(newMeshes, clonedMesh, scene, newId);

    return [clonedMesh, cloneBox];
  }

  createBoxFromBoundingBox(newMeshes, mesh, scene, newId) {
    // mesh.parent = null;

    mesh.computeWorldMatrix(true);
    const boundingInfo = mesh.getBoundingInfo();

    newMeshes[0].computeWorldMatrix(true);
    // eslint-disable-next-line no-unused-vars
    const boundingInfoRoot = mesh.getBoundingInfo();
    // console.log(boundingInfo);

    // Create a box with the same dimensions
    const box = MeshBuilder.CreateBox(
      'BoundingBoxEquivalent',
      { size: 1 }, // Start with unit size
      scene
    );

    box.name = 'ClonedMeshBox' + this.name;

    // box.parent = newMeshes[0];

    // Scale the box to match the bounding box size
    box.scaling = boundingInfo.boundingBox.extendSize.scale(2);
    const initialBoxScaling = box.scaling;
    // box.scaling.z *= -1;

    // Set the position of the new box to match the center of the bounding box in world space
    box.position = boundingInfo.boundingBox.centerWorld.clone();

    // box.position.y = boundingBoxSize.y / 4;

    box.metadata = {
      canToggleGizmo: false,
      canToggleFocus: false,
      targetPosition: box.position,
      position: box.position.clone(),
      rotation: box.rotation.clone(),
      scale: box.scaling.clone(),
      clone: newMeshes[0],
      isolatable: true,
      familyID: newId,
      isIsolated: false,
      importable: false,
      clippable: true
    };

    box.layerMask = this.C1M;
    box.renderingGroupId = 1;

    // Assign a semi-transparent material to the plane
    const boxMaterial = new StandardMaterial(`CloneBoxMaterial`, scene);
    boxMaterial.diffuseColor = new Color3(0.5, 0.5, 0.5); // Grey color
    boxMaterial.alpha = 0.5; // Semi-transparent
    box.material = boxMaterial;

    box.isVisible = false;

    // Calculate the initial relative position
    // eslint-disable-next-line no-unused-vars
    const initialRelativePosition = box.position.subtract(newMeshes[0].position);

    if (!box.rotationQuaternion) {
      box.rotationQuaternion = Quaternion.RotationYawPitchRoll(box.rotation.y, box.rotation.x, box.rotation.z);
    }
    if (!newMeshes[0].rotationQuaternion) {
      newMeshes[0].rotationQuaternion = Quaternion.RotationYawPitchRoll(
        newMeshes[0].rotation.y,
        newMeshes[0].rotation.x,
        newMeshes[0].rotation.z
      );
    }

    // Calculate initial scales and ratio
    const initialNewMeshesScale = newMeshes[0].scaling.clone();
    initialNewMeshesScale.z *= -1;
    // eslint-disable-next-line no-unused-vars
    const scaleRatio = initialBoxScaling.divide(initialNewMeshesScale);

    // Calculate the initial relative rotation
    // eslint-disable-next-line no-unused-vars
    const initialRelativeRotation = box.rotationQuaternion.multiply(newMeshes[0].rotationQuaternion.invert());

    // eslint-disable-next-line no-unused-vars
    const node = this.createEmptyNode(newMeshes, box, box.name);
    // box.parent = node;

    // Calculate the rotation difference between object1 and object2
    const rotationDifference = box.rotation.subtract(newMeshes[0].rotation);

    // Apply the rotation difference to object2
    box.rotate(Axis.Y, rotationDifference.y, Space.WORLD); // Adjust the axis as needed

    this.sceneInstance.current.onBeforeRenderObservable.add(() => {
      mesh.computeWorldMatrix(true);
      const boundingInfo = mesh.getBoundingInfo();
      // Update the box's position to maintain the same relative position to newMeshes[0]
      // Set the position of the new box to match the center of the bounding box in world space
      box.position = boundingInfo.boundingBox.centerWorld.clone();

      // Update the box's rotation to maintain the same relative rotation to newMeshes[0]
      // Calculate the rotation difference between object1 and its previous rotation
      // Calculate the updated rotation difference on each frame
      const newRotationDifference = newMeshes[0].rotation.subtract(box.rotation);

      // Apply the difference to object2 to keep it aligned with object1
      box.rotate(Axis.Y, newRotationDifference.y, Space.WORLD); // Adjust the axis as needed
      // box.rotationQuaternion = newMeshes[0].rotationQuaternion.multiply(initialRelativeRotation);

      // Apply the scaling ratio to the box
      // box.scaling = newMeshes[0].scaling.multiply(scaleRatio);
    });

    // Calculate the initial relative position
    // const initialRelativePosition = box.position.subtract(newMeshes[0].position);

    // this.createEmptyNode(newMeshes, box, box.name);

    return box;
  }

  createEmptyNode(rootMesh, name) {
    const emptyNode = new TransformNode(name, this.sceneInstance.current);
    emptyNode.name = name + 'Root';

    emptyNode.position = rootMesh[0].position.clone();
    // emptyNode.rotation = rootMesh[0].rotation.clone();
    emptyNode.scaling = rootMesh[0].scaling.clone();

    if (!emptyNode) {
      console.error('Cloning failed');
      return null;
    }

    // Dispose of all children
    if (emptyNode.getChildren()) {
      emptyNode.getChildren().forEach((child) => {
        if (child.dispose) {
          child.dispose();
        }
      });
    }

    // Delete all metadata
    emptyNode.metadata = null;
    emptyNode.isPickable = false; // Ensure the node is not pickable
    emptyNode.isVisible = false;

    console.log(emptyNode);

    if (!emptyNode.rotationQuaternion) {
      emptyNode.rotationQuaternion = Quaternion.RotationYawPitchRoll(
        emptyNode.rotation.y,
        emptyNode.rotation.x,
        emptyNode.rotation.z
      );
    }

    /*
    this.sceneInstance.current.onBeforeRenderObservable.add(() => {
      // Update the box's position to maintain the same relative position to newMeshes[0]
      emptyNode.position = rootMesh[0].position;

      // Update the box's rotation to maintain the same relative rotation to newMeshes[0]
      emptyNode.rotationQuaternion = rootMesh[0].rotationQuaternion;

      // Apply the scaling ratio to the box
      emptyNode.scaling = rootMesh[0].scaling;
      // Assuming 'box' is your mesh
      // box.scaling.y *= -1; // Invert the scale along the X-axis
    }); */

    return emptyNode;
  }

  readFileAndLoadMesh = (arrayBuffer, contentType) => {
    return new Promise((resolve, reject) => {
      try {
        const byteArray = new Uint8Array(arrayBuffer);
        const base64String = btoa(byteArray.reduce((data, byte) => data + String.fromCharCode(byte), ''));
        const dataUrl = `data:${contentType};base64,${base64String}`;
        resolve(dataUrl);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default FileLoader;
