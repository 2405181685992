// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '@components/page-loader/SceneLoader.scss';

const SceneLoaderCSS = ({ percentage }) => {
  // Calculate background size based on percentage
  console.warn('Scene loader: ', percentage);
  let text;

  if (percentage < 97) {
    text = 'Downloading';
  } else {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    text = 'Loading';
  }

  // Function to update the CSS variable based on the percentage value
  const updateProgressAnimation = (percentage) => {
    const loader = document.querySelector('.loader');
    if (loader) {
      loader.style.setProperty('--progress', percentage + '%');
    }
  };

  // Update the progress animation when percentage changes
  useEffect(() => {
    updateProgressAnimation(percentage);

    // console.log(percentage);
  }, [percentage]);

  return (
    <div className="page-loadercss">
      <div className="percentage-text">{`${text} ... ${percentage}%`}</div>
      <div className="loader"></div>
    </div>
  );
};

SceneLoaderCSS.propTypes = {
  percentage: PropTypes.number.isRequired
};

export default SceneLoaderCSS;
