import React, { useState } from 'react';
import '@components/Modal windows/Modal window - add plane/AddPlane.scss';
import InputNew from '@components/input/input new/InputNew';
import PropTypes from 'prop-types';

import planePNG from '@assets/images/Plane_PNG.png';

const AddPlane = ({ addPlane, cancelAction }) => {
  const [lengthValue, setLengthValue] = useState('');
  const [widthValue, setWidthValue] = useState('');

  const handleInputLength = (event) => {
    setLengthValue(event.target.value);
  };
  const handleInputWidth = (event) => {
    setWidthValue(event.target.value);
  };

  const checkConfirm = (lengthValue, widthValue) => {
    return lengthValue > 0 && widthValue > 0;
  };

  const handleConfirm = () => {
    if (checkConfirm(lengthValue, widthValue)) {
      addPlane(lengthValue, widthValue);
    }
  };

  return (
    <div className="modal-wrapper" onClick={() => cancelAction()}>
      <div className="confirm-plane-container" onClick={(e) => e.stopPropagation()}>
        <h2>Define Workarea Plane</h2>
        <p>{'Define plane length and width and click on confirm.'}</p>
        <img src={planePNG} alt="Description of Image" style={{ height: '150px', width: '600px' }} />
        <div className="input-wrapper">
          <div className="confirm-plane-absoluteInput-Length">
            <InputNew
              name="length"
              type="number"
              labelText="m"
              value={lengthValue}
              onChange={handleInputLength}
              placeholder={'Length'}
              autocomplete="off"
              min="0.01" // Ensure value is greater than 0
              step="0.01" // Allow decimal values with a precision of 0.01
              style={{ width: '100px' }}
            />
          </div>
          <div className="confirm-plane-absoluteInput-Width">
            <InputNew
              name="width"
              type="number"
              labelText="m"
              value={widthValue}
              onChange={handleInputWidth}
              placeholder={'Width'}
              autocomplete="off"
              min="0.01" // Ensure value is greater than 0
              step="0.01" // Allow decimal values with a precision of 0.01
              style={{ width: '100px' }}
            />
          </div>
        </div>
        <p></p>
        <p></p>
        <div className="buttons-container">
          <button className="button-new" onClick={() => cancelAction()} style={{ cursor: 'pointer' }}>
            Cancel
          </button>
          <div className="divider"></div>
          <button
            className="button-new"
            onClick={() => handleConfirm()}
            disabled={!checkConfirm(lengthValue, widthValue)}
            style={
              !checkConfirm(lengthValue, widthValue)
                ? { color: 'var(--white-17)', cursor: 'not-allowed' }
                : { cursor: 'pointer', color: 'rgba(0, 0, 212, 1)' }
            }
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

AddPlane.propTypes = {
  addPlane: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired
};

export default AddPlane;
