/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import '@pages/user/manager/model library/ModelManager.scss';

import { TbEdit, TbUserShare, TbWorldCancel } from 'react-icons/tb';
import { HiOutlineShare } from 'react-icons/hi2';
import { BiBadge } from 'react-icons/bi';
import { MdOutlineManageAccounts, MdOutlineClose, MdDeleteOutline, MdOutlineCloudUpload } from 'react-icons/md';
import { LuFileEdit } from 'react-icons/lu';

import { modelsService } from '@services/api/models/models.service';
import PageLoader from '@components/page-loader/PageLoader'; // the page loader component
import { useSessionStorage } from '@hooks/General';
import { ConfirmRename, ConfirmRemove, ConfirmSceneDescription } from '@components/Modal windows';

import { ScenePreviewer } from '@hooks/Scene setup';

import ModelInputHandler from '@hooks/Mesh/hooks/ModelInutHandler';

const ModelManager = ({ Models, CompanyName }) => {
  const [models, setModels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModelSettings, setModelSettings] = useState(false);

  // get the file in input from the manager page
  const getModels = useSessionStorage('modelsList', 'get');
  // console.log(getModels);
  const [setModelsList] = useSessionStorage('modelsList', 'set');
  const [deleteModelsList] = useSessionStorage('modelsList', '');

  // modal window for modelRename
  const [modelRename, setModelRename] = useState(false);
  const [modelName, setModelName] = useState('');
  const [modelId, setModelId] = useState('');
  const [modelLastDescription, setModelLastDescription] = useState('');

  const [percentage, setPercentage] = useState(0);

  // to rerender the component
  const [forceRender, setForceRender] = useState(false); // New state variable

  // Define a state variable for model remove
  const [confirmRemove, setConfirmRemove] = useState(false);

  // session data to get access keys
  const getSessionData = useSessionStorage('sessionData', 'get');
  const userDataStore = useSessionStorage('userData', 'get');

  // Handles the input of the glb file
  const handleInputManager = new ModelInputHandler(userDataStore, getSessionData);

  const fileInputRef = useRef(null);
  const fileInputRefSave = useRef(null);

  const [isModelLoading, setIsModelLoading] = useState(false);
  const [isModelSaveLoading, setIsModelSaveLoading] = useState(false);

  const handleNewModel = async (e) => {
    setIsModelLoading(true);

    await handleInputManager.handleFileInput(e);

    setIsModelLoading(false);
    deleteModelsList();
    exitModelSettings();
    setForceRender((prev) => !prev); // Trigger re-render
  };

  const handleSaveModel = async (e) => {
    setIsModelSaveLoading(true);

    await handleInputManager.handleFileInputSave(e, modelId);

    setIsModelSaveLoading(false);
    deleteModelsList();
    exitModelSettings();
    setForceRender((prev) => !prev); // Trigger re-render
  };

  const handleModelSettings = (modelid, modelName, lastModified) => {
    // console.log(lastModified);
    setModelId(modelid);
    setModelName(modelName);
    setModelLastDescription(lastModified);

    setModelSettings((value) => !value);
  };

  const exitModelSettings = () => {
    setModelName('');
    setModelId('');
    setModelLastDescription('');
    setModelSettings(false);
  };

  const handleModelRemove = (modelid) => {
    setModelId(modelid);
    setConfirmRemove((value) => !value);
  };

  const exitModelRemove = () => {
    setModelId('');
    setConfirmRemove(false);
  };

  const handleChangeModelName = () => {
    setModelRename((value) => !value);
  };

  const changeModelName = async (name) => {
    try {
      const Bucket = 'exvirience-objects';

      const awsInformation = {
        accessKeyId: getSessionData.data.accessInformation.AccessKeyId,
        secretAccessKey: getSessionData.data.accessInformation.SecretAccessKey,
        sessionToken: getSessionData.data.accessInformation.SessionToken,
        bucketName: Bucket,
        accelerationStatus: true
      };

      const body = {
        companyName: CompanyName,
        models_Id: Models,
        modelId,
        newModelName: name,
        awsInformation
      };

      handleChangeModelName();
      exitModelSettings();
      setIsLoading(true);

      await modelsService.updateModel(body);

      deleteModelsList();
      setForceRender((prev) => !prev); // Trigger re-render
    } catch (error) {
      console.error('Error change model name:', error);
    }
  };

  const deleteModel = async () => {
    try {
      const Bucket = 'exvirience-objects';

      const awsInformation = {
        accessKeyId: getSessionData.data.accessInformation.AccessKeyId,
        secretAccessKey: getSessionData.data.accessInformation.SecretAccessKey,
        sessionToken: getSessionData.data.accessInformation.SessionToken,
        bucketName: Bucket,
        accelerationStatus: true
      };

      const body = {
        models_Id: Models,
        modelId,
        awsInformation
      };

      exitModelRemove();
      setIsLoading(true);

      await modelsService.deleteModel(body);

      deleteModelsList();
      setForceRender((prev) => !prev); // Trigger re-render
    } catch (error) {
      console.error('Error delete model:', error);
    }
  };

  const fetchModels = async () => {
    try {
      const body = {
        models_Id: Models
      };

      const response = await modelsService.listModel(body);
      setIsLoading(false);
      console.log('List Models Response:', response.data.models);
      setModels(response.data.models); // Set models directly
      setModelsList(response.data.models);
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  };

  useEffect(() => {
    if (getModels) {
      setModels(getModels);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      fetchModels();
    }
  }, [Models.Models, forceRender]);

  const iconSetting = <HiOutlineShare style={{ scale: '1.5', marginRight: '10px', transform: 'translateY(1.5px)' }} />;

  return (
    <div className="model-manager">
      <h1 className="Editor-Title"> Model Library </h1>
      <div className="editor-title-divider"></div>
      <button className="ui-button-new-model" onClick={() => fileInputRef.current.click()}>
        <input type="file" accept=".glb" ref={fileInputRef} style={{ display: 'none' }} onChange={handleNewModel} />
        <MdOutlineCloudUpload style={{ scale: '1.5', marginRight: '10px', transform: 'translateY(1.5px)' }} /> Upload
        Model
      </button>

      {isLoading && (
        <div className="user-Editor-Model-loader-container">
          <PageLoader type="original-l" isImporting={false} />
        </div>
      )}

      <div className="ModelContainer">
        {models.map((model, index) => (
          <div className="buttonRow" key={index}>
            <div
              className="topContainer"
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <button className="topview-button" onClick={() => handleModelRemove(model.modelId)}>
                <MdDeleteOutline style={{ scale: '1.5', transform: 'translateY(1.5px)' }} />
              </button>
              <div className="buttonText">{model.modelName}</div>
            </div>
            <div className="bottomContainer">
              <div className="rightContainer">
                <button
                  className="edit-viewbutton"
                  onClick={() => handleModelSettings(model.modelId, model.modelName, model.lastModified)}
                >
                  {iconSetting} {'Settings'}
                </button>
              </div>
            </div>
          </div>
        ))}
        {isModelLoading && (
          <div className="buttonRow" key="loading">
            <div
              className="topContainer"
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div className="buttonText">Uploading model...</div>
            </div>
            <div className="bottomContainer">
              <div className="rightContainer">{/* No Settings button here */}</div>
            </div>
          </div>
        )}
      </div>
      {modelRename && (
        <ConfirmRename
          renameObject={(value) => changeModelName(value)}
          cancelAction={handleChangeModelName}
          initialName={modelName}
        />
      )}
      {confirmRemove && (
        <ConfirmRemove
          deleteObject={deleteModel}
          deleteObjectContext={deleteModel}
          cancelAction={exitModelRemove}
          context={true}
          Title="Delete Model"
          Description="To delete the the model, type 'Confirm' and click the remove button."
        />
      )}
      {isModelSettings && (
        <div className="user-settings-container-model">
          <div className="user-settings-back-model" onClick={exitModelSettings}>
            <div className="user-settings-back-background-model"></div>
          </div>
          <div className="user-settings-sidebar-model">
            <div className="close-icon" onClick={exitModelSettings}>
              <MdOutlineClose />
            </div>
            <h2 className="user-settings-sidebar-model-h2">{modelName}</h2>

            <div className="editor-title-divider-model"></div>

            <div className="section">
              <h3>Model Details</h3>
              <div className="sharing-buttons">
                <button className="ui-button-new-model-single" onClick={handleChangeModelName}>
                  <LuFileEdit style={{ scale: '1.5', marginRight: '10px', transform: 'translateY(1.5px)' }} />
                  Change Name
                </button>
              </div>

              <h4>Last Modified:</h4>
              <p>{modelLastDescription}</p>
            </div>

            <div className="editor-title-divider-model"></div>

            <div className="section">
              <h3>Update Model Version</h3>
              <div className="sharing-buttons">
                <button
                  className="ui-button-new-model-single"
                  onClick={() => fileInputRefSave.current.click()}
                  disabled={isModelSaveLoading}
                >
                  <input
                    type="file"
                    accept=".glb"
                    ref={fileInputRefSave}
                    style={{ display: 'none' }}
                    onChange={handleSaveModel}
                  />
                  <LuFileEdit style={{ scale: '1.5', marginRight: '10px', transform: 'translateY(1.5px)' }} />
                  {isModelSaveLoading ? 'Updating ...' : 'Update Version'}
                </button>
              </div>

              <h3>Preview Model</h3>
              <div
                style={{ width: '100%', height: '400px', marginTop: '20px', borderRadius: '10px', overflow: 'hidden' }}
              >
                {/* Conditionally render the overlay div */}
                {percentage < 100 && (
                  <div
                    style={{
                      position: 'absolute',
                      // top: 0,
                      left: 0,
                      width: '100%',
                      height: '200px',
                      backgroundColor: 'white',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      zIndex: 10 // Make sure it covers the ScenePreviewer
                    }}
                  >
                    <h1 style={{ fontSize: '24px', color: 'black' }}>{percentage}%</h1>
                  </div>
                )}
                <ScenePreviewer modelId={modelId} setPercentage={setPercentage} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ModelManager.propTypes = {
  Models: PropTypes.any,
  CompanyName: PropTypes.string
};

export default ModelManager;
