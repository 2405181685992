import { GetObjectCommand, HeadObjectCommand } from '@aws-sdk/client-s3';
import axios from 'axios';

import AWSClient from '@services/api/AWS/hooks/AWSClient';
import AWSGetURL from '@services/api/AWS/hooks/AWSGetURL';

const AWSGet = async (awsBucket, awsKey, accelerated, usePercentage, setPercentage) => {
  const client = AWSClient();

  let fileSize;
  let command;

  if (accelerated) {
    command = new GetObjectCommand({
      Bucket: awsBucket,
      Key: awsKey,
      AccelerationStatus: 'Enabled'
    });
  } else {
    // eslint-disable-next-line no-unused-vars
    command = new GetObjectCommand({
      Bucket: awsBucket,
      Key: awsKey
    });
  }

  // See if object exists first
  try {
    const head = new HeadObjectCommand({
      Bucket: awsBucket,
      Key: awsKey
    });

    const response = await client.send(head);

    fileSize = response.ContentLength;

    console.log(`Object (${awsKey}) exists in Bucket (${awsBucket}) and has size ${fileSize}`);
  } catch (error) {
    console.error(`Error finding object ${awsKey}: `, error);

    return;
  }

  // Get the object
  if (usePercentage) {
    try {
      const objectURL = await AWSGetURL(awsBucket, awsKey, true);

      // Use Axios to download the file with progress tracking
      const response = await axios.get(objectURL, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          if (setPercentage) {
            const totalLength =
              progressEvent.total ||
              parseInt(progressEvent.currentTarget.getResponseHeader('content-length'), 10) ||
              parseInt(progressEvent.currentTarget.getResponseHeader('x-decompressed-content-length'), 10);
            // console.log('Total length: ', totalLength);

            if (totalLength) {
              // eslint-disable-next-line prefer-const
              let progress = Math.floor((progressEvent.loaded / totalLength) * 100);
              setPercentage(progress); // Update your progress tracking state or ref
              console.log(`Download Progress: ${progress}%`);
            }
          }
        }
      });

      return response.data;
    } catch (err) {
      console.error(err);
    }
  } else {
    try {
      const object = await client.send(command);

      return object;
    } catch (err) {
      console.error(err);
    }
  }
};

export default AWSGet;
