import { Vector3, Camera, Animation, CubicEase, EasingFunction, PointerEventTypes } from '@babylonjs/core'; // Assuming you're using BabylonJS

class HandleCameraState {
  constructor(cameraInstance, canvasInstance, sceneInstance, setIsOrthographic, isOrthographic) {
    this.cameraInstance = cameraInstance;
    this.canvasInstance = canvasInstance;
    this.sceneInstance = sceneInstance;
    this.setIsOrthographic = setIsOrthographic;
    this.isOrthographic = isOrthographic;
  }

  orthographicCamera = (axis) => {
    const newIsOrthographic = !this.isOrthographic;
    this.setIsOrthographic(newIsOrthographic);

    if (newIsOrthographic) {
      let endPosition = null;
      let desiredAlpha = null;
      const distance = this.cameraInstance.current.position.subtract(this.cameraInstance.current.target).length();

      switch (axis) {
        case 'z':
          endPosition = new Vector3(0, 0, -distance);
          break;
        case '-z':
          endPosition = new Vector3(0, 0, distance);
          break;
        case 'y':
          endPosition = new Vector3(0, distance, 0);
          desiredAlpha = 0;
          break;
        case '-y':
          endPosition = new Vector3(0, -distance, 0);
          desiredAlpha = 0;
          break;
        case 'x':
          endPosition = new Vector3(distance, 0, 0);
          break;
        case '-x':
          endPosition = new Vector3(-distance, 0, 0);
          break;
        case 'normal':
          // If axis is 'normal', set the position and target of orthographic camera to be the same as the perspective camera
          endPosition = this.cameraInstance.current.position.clone();
          break;
        // ... add other cases if necessary
        default:
        // Handle default case if needed
      }

      const endTarget = new Vector3(0, 0, 0);

      this.animateCameraTo(
        this.cameraInstance.current.position,
        endPosition,
        this.cameraInstance.current.target,
        endTarget,
        axis === 'y' || axis === '-y' ? this.cameraInstance.current.alpha : undefined,
        desiredAlpha
      );

      // Switch to orthographic mode
      // Calculate the distance from the camera to its target
      const distanceToTarget = this.cameraInstance.current.position
        .subtract(this.cameraInstance.current.target)
        .length();

      // Calculate the height and width of the frustum at the distance to the target
      const height = 2 * distanceToTarget * Math.tan(this.cameraInstance.current.fov / 2);
      const width = height * (this.canvasInstance.current.width / this.canvasInstance.current.height);

      // Set the orthographic camera's boundaries
      this.cameraInstance.current.mode = Camera.ORTHOGRAPHIC_CAMERA;
      this.cameraInstance.current.orthoTop = height / 2;
      this.cameraInstance.current.orthoBottom = -height / 2;
      this.cameraInstance.current.orthoLeft = -width / 2;
      this.cameraInstance.current.orthoRight = width / 2;

      this.cameraInstance.current.inputs.attached.mousewheel.detachControl(this.canvasInstance.current);

      this.sceneInstance.current.onPointerObservable.add((p) => {
        const event = p.event;

        let wheelDelta = 0;

        if (event.wheelDelta) {
          wheelDelta = event.wheelDelta;
        } else {
          wheelDelta = -(event.deltaY || event.detail) * 60;
        }

        // Assuming cameraInstance.current is your ArcRotateCamera when in perspective mode
        const currentRadius = this.cameraInstance.current.radius;

        // Adjust the zoom factor using the current radius to make it feel similar to perspective zooming
        const zoomFactor = 1 + wheelDelta / 1000;
        const newRadius = currentRadius / zoomFactor;

        // Compute the orthographic dimensions based on the new radius
        const distanceToTarget = newRadius;
        const height = 2 * distanceToTarget * Math.tan(this.cameraInstance.current.fov / 2);
        const width = height * (this.canvasInstance.current.width / this.canvasInstance.current.height);

        this.cameraInstance.current.orthoTop = height / 2;
        this.cameraInstance.current.orthoBottom = -height / 2;
        this.cameraInstance.current.orthoLeft = -width / 2;
        this.cameraInstance.current.orthoRight = width / 2;
      }, PointerEventTypes.POINTERWHEEL);
    } else {
      this.cameraInstance.current.mode = Camera.PERSPECTIVE_CAMERA;
      this.cameraInstance.current.inputs.attached.mousewheel.attachControl(this.canvasInstance.current);
      // Remove the zoom scale handler from onPointerObservable if needed
      // (Depending on your use case you might want to store the observer and remove it here)
    }
  };

  animateCameraTo(startPos, endPos, startTarget, endTarget, startAlpha, endAlpha) {
    const animCamPosition = new Animation(
      'animCam',
      'position',
      30,
      Animation.ANIMATIONTYPE_VECTOR3,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );
    const animCamTarget = new Animation(
      'animCamTarget',
      'target',
      30,
      Animation.ANIMATIONTYPE_VECTOR3,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    // Position keys
    const positionKeys = [];
    positionKeys.push({ frame: 0, value: startPos });
    positionKeys.push({ frame: 100, value: endPos });
    animCamPosition.setKeys(positionKeys);

    // Target keys
    const targetKeys = [];
    targetKeys.push({ frame: 0, value: startTarget });
    targetKeys.push({ frame: 100, value: endTarget });
    animCamTarget.setKeys(targetKeys);

    // Add easing function for smoother animation
    const easingFunction = new CubicEase();
    easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
    animCamPosition.setEasingFunction(easingFunction);
    animCamTarget.setEasingFunction(easingFunction);

    this.cameraInstance.current.animations = [];
    this.cameraInstance.current.animations.push(animCamPosition, animCamTarget);

    // Check if startAlpha and endAlpha are provided
    if (startAlpha !== undefined && endAlpha !== undefined) {
      const animCamAlpha = new Animation(
        'animCamAlpha',
        'alpha',
        30,
        Animation.ANIMATIONTYPE_FLOAT,
        Animation.ANIMATIONLOOPMODE_CONSTANT
      );

      const alphaKeys = [];
      alphaKeys.push({ frame: 0, value: startAlpha });
      alphaKeys.push({ frame: 100, value: endAlpha });
      animCamAlpha.setKeys(alphaKeys);

      animCamAlpha.setEasingFunction(easingFunction);

      this.cameraInstance.current.animations.push(animCamAlpha);
    }

    this.sceneInstance.current.beginAnimation(this.cameraInstance.current, 0, 100, false);
  }
}

export default HandleCameraState;
