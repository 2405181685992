/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '@pages/user/manager/editor/EditorManager.scss';

import { TbEdit, TbUserShare, TbWorldCancel } from 'react-icons/tb';
import { HiOutlineShare } from 'react-icons/hi2';
import { BiBadge } from 'react-icons/bi';
import { MdOutlineManageAccounts, MdOutlineClose, MdDeleteOutline } from 'react-icons/md';
import { LuFileEdit } from 'react-icons/lu';

import { scenesService } from '@services/api/scenes/scenes.service';
import PageLoader from '@components/page-loader/PageLoader'; // the page loader component
import { useSessionStorage } from '@hooks/General';
import { ConfirmRename, ConfirmRemove, ConfirmSceneDescription } from '@components/Modal windows';

import { SceneDetails } from '@hooks/Scene';

const EditorManager = ({ Scenes, CompanyName }) => {
  const [scenes, setScenes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSceneSettings, setSceneSettings] = useState(false);

  // get the file in input from the manager page
  const getScenes = useSessionStorage('scenesList', 'get');
  // console.log(getScenes);
  const [setScenesList] = useSessionStorage('scenesList', 'set');
  const [deleteScenesList] = useSessionStorage('scenesList', '');
  const [deleteMediasList] = useSessionStorage('mediasList', '');

  // modal window for sceneRename
  const [sceneRename, setSceneRename] = useState(false);
  const [sceneName, setSceneName] = useState('');
  const [sceneId, setSceneId] = useState('');
  const [sceneDescription, setSceneDescription] = useState('');
  const [sceneLastDescription, setSceneLastDescription] = useState('');

  // to rerender the component
  const [forceRender, setForceRender] = useState(false); // New state variable

  // Define a state variable for scene remove
  const [confirmRemove, setConfirmRemove] = useState(false);

  // Define a state variable for scene description
  const [confirmDescription, setConfirmDescription] = useState(false);

  // session data to get access keys
  const getSessionData = useSessionStorage('sessionData', 'get');
  const userDataStore = useSessionStorage('userData', 'get');

  // scene details
  const [sceneModels, setSceneModels] = useState([]);
  const [sceneImages, setSceneImages] = useState([]);
  const [sceneVideos, setSceneVideos] = useState([]);

  const sceneDetailsManager = new SceneDetails(
    getSessionData,
    userDataStore,
    setSceneModels,
    setSceneImages,
    setSceneVideos
  );

  const handleNewScene = () => {
    deleteScenesList();
    deleteMediasList();
    window.location.assign('/editor/new');
  };

  const handleSceneEdit = (sceneid) => {
    deleteScenesList();
    deleteMediasList();
    window.location.assign(`/editor/${sceneid}`);
  };

  const handleSceneSettings = (sceneid, sceneName, description, lastModified) => {
    // console.log(description);
    // setIsLoading(true);

    setSceneId(sceneid);
    setSceneName(sceneName);
    setSceneLastDescription(lastModified);

    if (description) {
      setSceneDescription(description);
    } else {
      const defaultDescription =
        'Update this description to better track and identify the scene. Include details such as purpose, key elements, and any important notes.';

      setSceneDescription(defaultDescription);

      (async () => {
        try {
          // let's update it in the db
          const descriptionBody = {
            scenes_Id: Scenes,
            sceneId: sceneid,
            description: defaultDescription
          };

          await scenesService.updateSceneDescription(descriptionBody);
        } catch (error) {
          console.error('Error updating scene description:', error);
        }
      })();
    }

    /* (async () => {
      try {
        await sceneDetailsManager.importScene(sceneid);

        setIsLoading(false);
        setSceneSettings((value) => !value);
      } catch (error) {
        setIsLoading(false);
        console.error('Error updating scene description:', error);
      }
    })(); */

    try {
      sceneDetailsManager.importScene(sceneid);

      setSceneSettings((value) => !value);
    } catch (error) {
      console.error('Error updating scene description:', error);
    }
  };

  const exitSceneSettings = () => {
    setSceneName('');
    setSceneId('');
    setSceneDescription('');
    setSceneLastDescription('');
    setSceneModels([]);
    setSceneImages([]);
    setSceneVideos([]);
    setSceneSettings(false);
  };

  const handleSceneRemove = (sceneid) => {
    setSceneId(sceneid);
    setConfirmRemove((value) => !value);
  };

  const exitSceneRemove = () => {
    setSceneId('');
    setConfirmRemove(false);
  };

  const handleChangeSceneName = () => {
    setSceneRename((value) => !value);
  };

  const changeSceneName = async (name) => {
    try {
      const Bucket = 'exvirience-scenes';

      const awsInformation = {
        accessKeyId: getSessionData.data.accessInformation.AccessKeyId,
        secretAccessKey: getSessionData.data.accessInformation.SecretAccessKey,
        sessionToken: getSessionData.data.accessInformation.SessionToken,
        bucketName: Bucket,
        accelerationStatus: true
      };

      const body = {
        companyName: CompanyName,
        scenes_Id: Scenes,
        sceneId,
        newSceneName: name,
        awsInformation
      };

      handleChangeSceneName();
      exitSceneSettings();
      setIsLoading(true);

      await scenesService.updateScene(body);

      deleteScenesList();
      setForceRender((prev) => !prev); // Trigger re-render
    } catch (error) {
      console.error('Error change scene name:', error);
    }
  };

  const handleChangeSceneDescription = () => {
    setConfirmDescription((value) => !value);
  };

  const changeSceneDescription = async (description) => {
    try {
      const body = {
        scenes_Id: Scenes,
        sceneId,
        description
      };

      handleChangeSceneDescription();
      exitSceneSettings();
      setIsLoading(true);

      await scenesService.updateSceneDescription(body);

      deleteScenesList();
      setForceRender((prev) => !prev); // Trigger re-render
    } catch (error) {
      console.error('Error change scene name:', error);
    }
  };

  const deleteScene = async () => {
    try {
      const Bucket = 'exvirience-scenes';

      const awsInformation = {
        accessKeyId: getSessionData.data.accessInformation.AccessKeyId,
        secretAccessKey: getSessionData.data.accessInformation.SecretAccessKey,
        sessionToken: getSessionData.data.accessInformation.SessionToken,
        bucketName: Bucket,
        accelerationStatus: true
      };

      const body = {
        scenes_Id: Scenes,
        sceneId,
        awsInformation
      };

      exitSceneRemove();
      setIsLoading(true);

      await scenesService.deleteScene(body);

      deleteScenesList();
      setForceRender((prev) => !prev); // Trigger re-render
    } catch (error) {
      console.error('Error delete scene:', error);
    }
  };

  const fetchScenes = async () => {
    try {
      const body = {
        scenes_Id: Scenes
      };

      const response = await scenesService.listScene(body);
      setIsLoading(false);
      console.log('List Scenes Response:', response.data.scenes);
      setScenes(response.data.scenes); // Set scenes directly
      setScenesList(response.data.scenes);
    } catch (error) {
      console.error('Error fetching scenes:', error);
    }
  };

  useEffect(() => {
    if (getScenes) {
      setScenes(getScenes);
      setIsLoading(false);
    } else {
      fetchScenes();
    }
  }, [Scenes.Scenes, forceRender]);

  const iconEdit = <TbEdit style={{ scale: '1.5', marginRight: '10px', transform: 'translateY(1.5px)' }} />;
  const iconSetting = <HiOutlineShare style={{ scale: '1.5', marginRight: '10px', transform: 'translateY(1.5px)' }} />;

  return (
    <div className="scene-manager">
      <h1 className="Editor-Title"> Saved Scenes </h1>
      <div className="editor-title-divider"></div>
      <button className="ui-button-new-scene" onClick={handleNewScene}>
        <BiBadge style={{ scale: '1.5', marginRight: '10px', transform: 'translateY(1.5px)' }} /> New Scene
      </button>

      {isLoading && (
        <div className="user-Editor-Scene-loader-container">
          <PageLoader type="original-l" isImporting={false} />
        </div>
      )}

      <div className="SceneContainer">
        {scenes.map((scene, index) => (
          <div className="buttonRow" key={index}>
            <div
              className="topContainer"
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <button className="topview-button" onClick={() => handleSceneRemove(scene.sceneId)}>
                <MdDeleteOutline style={{ scale: '1.5', transform: 'translateY(1.5px)' }} />
              </button>
              <div className="buttonText">{scene.sceneName}</div>
            </div>
            <div className="bottomContainer">
              <div className="leftContainer">
                <button className="edit-viewbutton" onClick={() => handleSceneEdit(scene.sceneId)}>
                  {iconEdit} {'Edit'}
                </button>
              </div>
              <div className="rightContainer">
                <button
                  className="edit-viewbutton"
                  onClick={() =>
                    handleSceneSettings(scene.sceneId, scene.sceneName, scene.description, scene.lastModified)
                  }
                >
                  {iconSetting} {'Settings'}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {sceneRename && (
        <ConfirmRename
          renameObject={(value) => changeSceneName(value)}
          cancelAction={handleChangeSceneName}
          initialName={sceneName}
        />
      )}
      {confirmRemove && (
        <ConfirmRemove
          deleteObject={deleteScene}
          deleteObjectContext={deleteScene}
          cancelAction={exitSceneRemove}
          context={true}
          Title="Delete Scene"
          Description="To delete the the scene, type 'Confirm' and click the remove button."
        />
      )}
      {confirmDescription && (
        <ConfirmSceneDescription renameObject={changeSceneDescription} cancelAction={handleChangeSceneDescription} />
      )}
      {isSceneSettings && (
        <div className="user-settings-container-scene">
          <div className="user-settings-back-scene" onClick={exitSceneSettings}>
            <div className="user-settings-back-background-scene"></div>
          </div>
          <div className="user-settings-sidebar-scene">
            <div className="close-icon" onClick={exitSceneSettings}>
              <MdOutlineClose />
            </div>
            <h2 className="user-settings-sidebar-scene-h2">{sceneName}</h2>
            <div className="editor-title-divider-scene"></div>
            <div className="section">
              <h3>Sharing</h3>
              <div className="sharing-buttons">
                <button className="ui-button-new-scene-single" onClick={handleNewScene}>
                  <TbUserShare style={{ scale: '1.5', marginRight: '10px', transform: 'translateY(1.5px)' }} /> Share
                  Link
                </button>
                <button className="ui-button-new-scene-single" onClick={handleNewScene}>
                  <TbWorldCancel style={{ scale: '1.5', marginRight: '10px', transform: 'translateY(1.5px)' }} /> Revoke
                  Link
                </button>
                <button className="ui-button-new-scene-single" onClick={handleNewScene}>
                  <MdOutlineManageAccounts
                    style={{ scale: '1.5', marginRight: '10px', transform: 'translateY(1.5px)' }}
                  />
                  Manage Access
                </button>
              </div>
            </div>

            <div className="editor-title-divider-scene"></div>

            <div className="section">
              <h3>Scene Details</h3>
              <div className="sharing-buttons">
                <button className="ui-button-new-scene-single" onClick={handleChangeSceneName}>
                  <LuFileEdit style={{ scale: '1.5', marginRight: '10px', transform: 'translateY(1.5px)' }} />
                  Change Name
                </button>
              </div>

              <h4>Last Modified:</h4>
              <p>{sceneLastDescription}</p>

              <div className="description">
                <h4>Description</h4>
                <p>{sceneDescription}</p>
                <button className="ui-button-new-scene-description" onClick={handleChangeSceneDescription}>
                  <LuFileEdit style={{ scale: '1.5', marginRight: '10px', transform: 'translateY(1.5px)' }} />
                  Modify Description
                </button>
              </div>

              <div className="models-used">
                <h4>Models Used</h4>
                <ul>
                  {sceneModels.length > 0 ? (
                    sceneModels.map((model, index) => <li key={index}>{model}</li>)
                  ) : (
                    <li>None</li>
                  )}
                </ul>

                <h4>Images Used</h4>
                <ul>
                  {sceneImages.length > 0 ? (
                    sceneImages.map((image, index) => <li key={index}>{image}</li>)
                  ) : (
                    <li>None</li>
                  )}
                </ul>

                <h4>Videos Used</h4>
                <ul>
                  {sceneVideos.length > 0 ? (
                    sceneVideos.map((video, index) => <li key={index}>{video}</li>)
                  ) : (
                    <li>None</li>
                  )}
                </ul>
                <div className="editor-title-divider-last"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

EditorManager.propTypes = {
  Scenes: PropTypes.any,
  CompanyName: PropTypes.string
};

export default EditorManager;
