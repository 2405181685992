import { useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

import './AuthTabs.scss';

import backgroundImage from '@assets/images/Banner_7.jpg';
import logoImage from '@assets/images/Logo_Exvirience_Original.png';
import Login from '@pages/auth/auth-tabs/login/Login.js';
import { Utils } from '@services/utils/utils.service';
import PageLoader from '@components/page-loader/PageLoader'; // the page loader component
import { defaultLoadingTime } from '@root/App';
import { useLocalStorage, CheckForMobile, useSessionStorage } from '@hooks/General';

const AuthTabs = () => {
  const isMobileDevice = CheckForMobile(); // This will automatically handle redirection and detection
  console.log(isMobileDevice);

  const keepLoggedIn = useLocalStorage('keepLoggedIn', 'get');
  const [environment, setEnvironment] = useState('');
  const navigate = useNavigate();

  const [deleteMediasList] = useSessionStorage('mediasList', '');
  const [deleteScenesList] = useSessionStorage('scenesList', '');
  const [deleteModelsList] = useSessionStorage('modelsList', '');

  deleteMediasList();
  deleteScenesList();
  deleteModelsList();

  useEffect(() => {
    const env = Utils.appEnvironment();
    setEnvironment(env);
    if (keepLoggedIn) navigate('/user');
  }, [keepLoggedIn, navigate]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let loadingTimer;
    let importingTimer;

    if (isMobileDevice) {
      navigate('/unauthorized');
    }

    // eslint-disable-next-line prefer-const
    loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, defaultLoadingTime);

    return () => {
      clearTimeout(loadingTimer);
      clearTimeout(importingTimer);
    };
  }, [isMobileDevice, navigate]);

  return (
    <div className="auth-container-wrapper" style={{ backgroundImage: `url(${backgroundImage})` }}>
      {isLoading && (
        <div className="auth-Editor-loader-container">
          <PageLoader type="original-l" isImporting={false} />
        </div>
      )}
      <div className="auth-environment">{environment}</div>
      <Link to={'https://www.exvirience.com/'}>
        <span className="auth-home">
          <FaArrowLeft className="auth-arrow-left" /> Back to Home
        </span>
      </Link>
      <div className="auth-container-wrapper-auth" style={{ backgroundColor: 'white', width: '400px' }}>
        <div className="auth-container-logo" style={{ backgroundImage: `url(${logoImage})`, height: 'auto' }}></div>
        <div className="auth-tabs">
          <div className="auth-tab-item">
            <Login />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthTabs;
