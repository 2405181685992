import {
  PickingInfo,
  PointerEventTypes,
  PointerInfo,
  Vector3,
  CubicEase,
  EasingFunction,
  Animation
} from '@babylonjs/core';

import { ConsoleLog } from '@hooks/General';

class MeshClickSimulator {
  constructor(sceneInstance, cameraInstance) {
    this.sceneInstance = sceneInstance;
    this.cameraInstance = cameraInstance;

    this._simulateClickMeshId = this._simulateClickMeshId.bind(this);
  }

  simulateClickAndTargetCamera(mesh) {
    this._simulateClick(mesh);
    this._newCameraTarget(mesh);
  }

  _simulateClick(mesh) {
    // eslint-disable-next-line prefer-const
    let pickInfo = new PickingInfo();
    pickInfo.hit = true;
    pickInfo.pickedMesh = mesh;

    // eslint-disable-next-line prefer-const
    let pointerInfo = new PointerInfo(PointerEventTypes.POINTERDOWN, new PointerEvent('pointerdown'), pickInfo);

    if (this.sceneInstance.current) {
      this.sceneInstance.current.onPointerObservable.notifyObservers(pointerInfo);
    }
  }

  _simulateClickMeshId(meshId) {
    if (!this.sceneInstance.current) {
      console.error('Scene instance is not available.');
      return;
    }

    // console.log('ID: ', meshId);
    /* console.log(
      'List: ',
      this.sceneInstance.current.meshes.map((mesh) => mesh.uniqueId)
    ); */

    // Find the mesh by its ID
    const mesh = this.sceneInstance.current.getMeshByUniqueID(meshId);
    if (!mesh) {
      console.error(`No mesh found with ID: ${meshId}`);
      return;
    }

    // eslint-disable-next-line prefer-const
    let pickInfo = new PickingInfo();
    pickInfo.hit = true;
    pickInfo.pickedMesh = mesh;

    // eslint-disable-next-line prefer-const
    let pointerInfo = new PointerInfo(PointerEventTypes.POINTERDOWN, new PointerEvent('pointerdown'), pickInfo);

    this.sceneInstance.current.onPointerObservable.notifyObservers(pointerInfo);
  }

  _newCameraTarget(currentPickedMesh) {
    let targetPosition;

    if (currentPickedMesh && currentPickedMesh.metadata && currentPickedMesh.metadata.canToggleFocus) {
      if (currentPickedMesh.metadata.targetPosition) {
        targetPosition = currentPickedMesh.metadata.targetPosition;
      } else {
        targetPosition = currentPickedMesh.position.clone();
      }
    } else {
      targetPosition = new Vector3(0, 0, 0);
    }

    // eslint-disable-next-line prefer-const
    let easingFunction = new CubicEase();
    easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

    // eslint-disable-next-line prefer-const
    let animCamTarget = new Animation(
      'animCam',
      'target',
      30,
      Animation.ANIMATIONTYPE_VECTOR3,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    // eslint-disable-next-line prefer-const
    let keys = [
      { frame: 0, value: this.cameraInstance.current.target },
      { frame: 30, value: targetPosition }
    ];

    animCamTarget.setKeys(keys);
    animCamTarget.setEasingFunction(easingFunction);

    this.sceneInstance.current.stopAnimation(this.cameraInstance.current);
    this.sceneInstance.current.beginDirectAnimation(this.cameraInstance.current, [animCamTarget], 0, 30, false);
  }

  _newCameraTargetDefault(currentPickedMesh) {
    let targetPosition;

    try {
      if (currentPickedMesh) {
        // eslint-disable-next-line prefer-const
        if (currentPickedMesh) {
          targetPosition = currentPickedMesh;
        }
      }
    } catch (error) {
      ConsoleLog.error(error);
      return;
    }

    // eslint-disable-next-line prefer-const
    let easingFunction = new CubicEase();
    easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

    // eslint-disable-next-line prefer-const
    let animCamTarget = new Animation(
      'animCam',
      'target',
      30,
      Animation.ANIMATIONTYPE_VECTOR3,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    // eslint-disable-next-line prefer-const
    let keys = [
      { frame: 0, value: this.cameraInstance.current.target },
      { frame: 30, value: targetPosition }
    ];

    animCamTarget.setKeys(keys);
    animCamTarget.setEasingFunction(easingFunction);

    this.sceneInstance.current.stopAnimation(this.cameraInstance.current);
    this.sceneInstance.current.beginDirectAnimation(this.cameraInstance.current, [animCamTarget], 0, 30, false);
  }
}

export default MeshClickSimulator;
