import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { MdOutlineCloudUpload, MdOutlineMenuBook, MdArrowBack } from 'react-icons/md';
import { HiSquare3Stack3D } from 'react-icons/hi2';

import '@pages/user/editor/Tool bars/Handle environment toolbar/EnvironmentToolbar.scss';

const EnvironmentToolbar = ({ headBack, /* library, */ plane, handleFileInput }) => {
  const fileInputRef = useRef(null);
  return (
    <div className="EnvironmentToolbar-container">
      {/* eslint-disable-next-line react/prop-types */}
      <div className="EnvironmentToolbar-element" onClick={() => fileInputRef.current.click()}>
        <input type="file" accept=".glb" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileInput} />
        <div className="EnvironmentToolbar-icon">
          <MdOutlineCloudUpload />
        </div>
        <div className="EnvironmentToolbar-text">Upload</div>
      </div>

      <div className="EnvironmentToolbar-element" onClick={plane}>
        <div className="EnvironmentToolbar-icon">
          <HiSquare3Stack3D />
        </div>
        <div className="EnvironmentToolbar-text">Default Plane</div>
      </div>

      <div className="EnvironmentToolbar-element" /* onClick={} */>
        <div className="EnvironmentToolbar-icon">
          <MdOutlineMenuBook />
        </div>
        <div className="EnvironmentToolbar-text">Environment Library</div>
      </div>

      <div className="EnvironmentToolbar-element" onClick={headBack}>
        <div className="EnvironmentToolbar-icon">
          <MdArrowBack />
        </div>
        <div className="EnvironmentToolbar-text">Back</div>
      </div>
    </div>
  );
};

EnvironmentToolbar.propTypes = {
  headBack: PropTypes.func.isRequired,
  /* library: PropTypes.func.isRequired, */
  plane: PropTypes.func.isRequired,
  handleFileInput: PropTypes.func.isRequired
};

export default EnvironmentToolbar;
