import PropTypes from 'prop-types';
import '@components/page-loader/spinner-loader/Spinner.scss';

const Spinner = ({ type, mode }) => {
  let spinnerClass;

  switch (mode) {
    case 'white':
      spinnerClass = 'Spinner-white';
      break;
    case 'black':
    default:
      spinnerClass = 'Spinner-black';
      break;
  }

  if (type === 'ring') {
    return (
      <div className={`Spinner-ring ${spinnerClass}`}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  } else if (type === 'ellipsis') {
    return (
      <div className={`Spinner-ellipsis${spinnerClass === 'Spinner-black' ? '-dark' : ''}`}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }
};

Spinner.propTypes = {
  type: PropTypes.string.isRequired,
  mode: PropTypes.string
};

export default Spinner;
