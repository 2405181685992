import axios from '@services/axios';

class AuthService {
  async logIn(body) {
    const response = await axios.post('/login', body);
    return response;
  }

  async forgotPassword(body) {
    const response = await axios.post('/request-password', body);
    return response;
  }

  async resetPassword(body) {
    const response = await axios.post('/set-new-password', body);
    return response;
  }

  async verifyUser(body) {
    const response = await axios.post('/auth/verify', body);
    return response;
  }
}

export const authService = new AuthService();
