import axios from '@services/axios';

class ScenesService {
  async addScene(body) {
    const response = await axios.post('/scenes/add', body);
    return response;
  }

  async saveScene(body) {
    const response = await axios.post('/scenes/save', body);
    return response;
  }

  async updateScene(body) {
    const response = await axios.post('/scenes/update', body);
    return response;
  }

  async deleteScene(body) {
    const response = await axios.post('/scenes/delete', body);
    return response;
  }

  async listScene(body) {
    const response = await axios.post('/scenes/list', body);
    return response;
  }

  async updateSceneDescription(body) {
    const response = await axios.post('/scenes/update-description', body);
    return response;
  }

  async updateSceneLastModified(body) {
    const response = await axios.post('/scenes/update-last-modified', body);
    return response;
  }

  async getScene(body) {
    const response = await axios.post('/scenes/get', body, {
      responseType: 'stream'
    });
    return response;
  }
}

export const scenesService = new ScenesService();
