import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { MdArrowBack, MdListAlt } from 'react-icons/md';

import '@pages/user/editor/Tool bars/Handle object list toolbar/ObjectListToolbar.scss';

const ObjectListToolbar = ({ sceneInstance, headBack }) => {
  const [rootParents, setRootParents] = useState(new Map());

  useEffect(() => {
    const getRootParentMeshes = () => {
      const familyRoots = new Map();

      // Iterate through all meshes
      sceneInstance.current.meshes.forEach((mesh) => {
        // Accessing familyID from metadata
        const familyID = mesh.metadata?.familyID;

        if (familyID && !familyRoots.has(familyID)) {
          let rootParent = mesh;
          while (rootParent.parent) {
            rootParent = rootParent.parent;
          }
          familyRoots.set(familyID, rootParent);
        }
      });

      return familyRoots;
    };

    if (sceneInstance && sceneInstance.current && sceneInstance.current.meshes) {
      setRootParents(getRootParentMeshes());
    }
  }, [sceneInstance]);

  return (
    <div className="ObjectListToolbar-container">
      <div className="ObjectListToolbar-first-element">
        <div className="ObjectListToolbar-icon">
          <MdListAlt />
        </div>
        <div className="ObjectListToolbar-text">Scene Object List</div>
      </div>
      <div className="ObjectListToolbar-wrapper">
        {rootParents.size > 0 ? (
          Array.from(rootParents.keys()).map((familyID) => (
            <div key={familyID} className="ObjectListToolbar-element">
              <div className="ObjectListToolbar-text">
                {`Family ID: ${familyID}, Root Parent Mesh Name: ${rootParents.get(familyID)?.name || 'Unknown'}`}
              </div>
            </div>
          ))
        ) : (
          <div className="ObjectListToolbar-element">
            <div className="ObjectListToolbar-text">No Objects in Scene</div>
          </div>
        )}
      </div>
      <div className="ObjectListToolbar-final-element" onClick={headBack}>
        <div className="ObjectListToolbar-icon">
          <MdArrowBack />
        </div>
        <div className="ObjectListToolbar-text">Back</div>
      </div>
    </div>
  );
};

ObjectListToolbar.propTypes = {
  sceneInstance: PropTypes.shape({
    current: PropTypes.object.isRequired
  }).isRequired,
  headBack: PropTypes.func
};

export default ObjectListToolbar;
