/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

import './VerifyTabs.scss';

import backgroundImage from '@assets/images/Banner_2.jpg';
import logoImage from '@assets/images/Logo_Exvirience_Original.png';
import VerifyUserAccount from '@pages/verify/verify-user/verify-user-account/VerifyUserAccount';
import { Utils } from '@services/utils/utils.service';
import PageLoader from '@components/page-loader/PageLoader'; // the page loader component
import { defaultLoadingTime } from '@root/App';

const VerifyTabs = () => {
  const [environment, setEnvironment] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const env = Utils.appEnvironment();
    setEnvironment(env);
  }, [navigate]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let loadingTimer;
    let importingTimer;

    // eslint-disable-next-line prefer-const
    loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, defaultLoadingTime);

    return () => {
      clearTimeout(loadingTimer);
      clearTimeout(importingTimer);
    };
  }, []);

  return (
    <div className="V-container-wrapper" style={{ backgroundImage: `url(${backgroundImage})` }}>
      {isLoading && (
        <div className="V-Editor-loader-container">
          <PageLoader type="original-l" isImporting={false} />
        </div>
      )}
      <div className="V-environment">{environment}</div>
      <Link to={'/auth'}>
        <span className="V-home">
          <FaArrowLeft className="V-arrow-left" /> Back to Login
        </span>
      </Link>
      <div className="V-container-wrapper-form" style={{ backgroundColor: 'white', width: '400px' }}>
        <div className="container-logo" style={{ backgroundImage: `url(${logoImage})`, height: 'auto' }}></div>
        <div className="V-tabs">
          <div className="V-tab-item">
            <VerifyUserAccount />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyTabs;
