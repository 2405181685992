const getClonedMeshFromFamilyId = (scene, familyID) => {
  for (const mesh of scene.meshes) {
    if (mesh.metadata && mesh.metadata.familyID === familyID && mesh.name === 'ClonedMesh') {
      console.log(mesh);
      return mesh;
    }
  }
};

const getClonedMeshBoxFromFamilyId = (scene, familyID) => {
  for (const mesh of scene.meshes) {
    if (mesh.metadata && mesh.metadata.familyID === familyID && mesh.name.startsWith('ClonedMeshBox')) {
      console.log(mesh);
      return mesh;
    }
  }
};

export { getClonedMeshFromFamilyId, getClonedMeshBoxFromFamilyId };
