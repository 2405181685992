import { CubicEase, EasingFunction, Animation } from '@babylonjs/core'; // Make sure to import the necessary BabylonJS modules.

class ChangeCameraTarget {
  constructor(camera, scene) {
    this.camera = camera;
    this.scene = scene;
  }

  changeTarget(currentPickedMesh) {
    if (currentPickedMesh && currentPickedMesh.metadata && currentPickedMesh.metadata.canToggleFocus) {
      // eslint-disable-next-line prefer-const
      let targetPosition = currentPickedMesh.metadata.targetPosition
        ? currentPickedMesh.metadata.targetPosition
        : currentPickedMesh.position.clone();

      const easingFunction = new CubicEase();
      easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

      const animCamTarget = new Animation(
        'animCam',
        'target',
        30,
        Animation.ANIMATIONTYPE_VECTOR3,
        Animation.ANIMATIONLOOPMODE_CONSTANT
      );

      const keys = [
        { frame: 0, value: this.camera.target },
        { frame: 30, value: targetPosition }
      ];

      animCamTarget.setKeys(keys);
      animCamTarget.setEasingFunction(easingFunction);

      this.scene.stopAnimation(this.camera);
      this.scene.beginDirectAnimation(this.camera, [animCamTarget], 0, 30, false);
    }
  }
}

export default ChangeCameraTarget;
