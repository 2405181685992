import { /* Routes, Route, */ BrowserRouter } from 'react-router-dom';
import { AppRouter } from '@root/routes';
// import { ErrorPage, ResetTabs, AuthTabs, RequestTabs, EditorDocumentation, User } from '@pages/index';
import '@root/App.scss';

export const defaultLoadingTime = 3000;

function App() {
  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  );
}

export default App;

/* <Route path="/auth" element={<AuthTabs />} />
        <Route path="/forgot-password" element={<RequestTabs />} />
        <Route path="/reset-password" element={<ResetTabs />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/documentation" element={<EditorDocumentation />} />
        <Route path="/user" element={<User />} />
      </Routes> */
