import { HighlightLayer, PointerDragBehavior, Vector3, Color3 } from '@babylonjs/core';
import { GizmoManager } from '@babylonjs/core/Gizmos/gizmoManager';
import { Quaternion } from 'babylonjs';

class HandleClickMesh {
  constructor(
    scene,
    camera,
    {
      setGizmoManager,
      setSelectedMesh,
      setToolbarState,
      disableAllGizmo,
      setOnlyDelete,
      enablePositionGizmo,
      enableRotationGizmo,
      enableScaleGizmo,
      nothingSelectedRef,
      handleMeshClickRef,
      removeHighlightRef,
      highlightedMesh,
      lastMeshWithBehaviors,
      sectionToolbarStateRef
    }
  ) {
    this.scene = scene;
    this.camera = camera;
    this.setGizmoManager = setGizmoManager;
    this.setSelectedMesh = setSelectedMesh;
    this.setToolbarState = setToolbarState;
    this.disableAllGizmo = disableAllGizmo;
    this.setOnlyDelete = setOnlyDelete;
    this.enablePositionGizmo = enablePositionGizmo;
    this.enableRotationGizmo = enableRotationGizmo;
    this.enableScaleGizmo = enableScaleGizmo;
    this.nothingSelectedRef = nothingSelectedRef;
    this.sectionToolbarStateRef = sectionToolbarStateRef;

    this.highlightedMesh = highlightedMesh;
    this.lastMeshWithBehaviors = lastMeshWithBehaviors;

    this.gizmoManagerLocal = new GizmoManager(scene, 2);
    this.gizmoManagerLocal.keepDepthUtilityLayer.setRenderCamera(camera);
    this.gizmoManagerLocal.utilityLayer.setRenderCamera(camera);

    this.setGizmoManager(this.gizmoManagerLocal);
    this.highlightLayer = new HighlightLayer('hl1', scene);

    this.dragBehaviorX = new PointerDragBehavior({ dragAxis: new Vector3(1, 0, 0) });
    this.dragBehaviorZ = new PointerDragBehavior({ dragAxis: new Vector3(0, 0, 1) });
    this.dragBehaviorX.useObjectOrienationForDragging = false;
    this.dragBehaviorZ.useObjectOrienationForDragging = false;

    // Saving the function to the ref
    handleMeshClickRef.current = this.handleMeshClick.bind(this);
    removeHighlightRef.current = this.removeHighlight.bind(this);
  }

  handleMeshClick(pickedMesh) {
    if (this.sectionToolbarStateRef.current) {
      this.removeHighlight();
      console.log('handleMeshClick returned as sectionToolbarState = ' + this.sectionToolbarStateRef.current);
      return;
    }
    // Logic of the function
    // If there's a mesh currently highlighted, remove it from the highlight layer
    if (this.highlightedMesh) {
      this.highlightLayer.removeMesh(this.highlightedMesh);

      // Highlight the children of the target mesh
      this.highlightedMesh.getChildMeshes().forEach((childMesh) => {
        this.highlightLayer.removeMesh(childMesh);
      });
    }

    // Set the selected mesh
    this.setSelectedMesh(pickedMesh);
    // console.log('setting the selected mesh: ' + selectedMesh);
    // console.log('current mesh: ' + currentPickedMesh);
    // console.log('picked mesh: ' + pickedMesh);

    if (pickedMesh && pickedMesh.metadata && pickedMesh.metadata.reference) {
      this.nothingSelectedRef.current = true;
      // Check for targetPosition metadata, otherwise use the mesh's current position
      let targetMesh;

      if (pickedMesh.metadata.clone) {
        // The mesh has a parent, we need to toggle that objects gizmo
        targetMesh = pickedMesh.metadata.clone;
      } else {
        // The mesh doesn't have a parent, use that mesh
        targetMesh = pickedMesh;
      }

      if (targetMesh) {
        targetMesh.addBehavior(this.dragBehaviorX);
        targetMesh.addBehavior(this.dragBehaviorZ);

        // Update the reference to the last mesh that had behaviors added
        this.lastMeshWithBehaviors.current = targetMesh;
      }
    } else {
      this.nothingSelectedRef.current = false;
    }

    /* debugger line
    if (pickedMesh && pickedMesh.metadata) {
      console.log('isIslated: ' + pickedMesh.metadata.isIsolated);
      console.log('canToggleGizmo: ' + pickedMesh.metadata.canToggleGizmo);
    } */

    if (pickedMesh && pickedMesh.metadata && (pickedMesh.metadata.canToggleGizmo || pickedMesh.metadata.isIsolated)) {
      if (pickedMesh.metadata.plane) {
        this.disableAllGizmo();
        this.setOnlyDelete(true);
        this.setToolbarState('objectSelection');
        if (this.highlightedMesh) {
          this.highlightLayer.removeMesh(this.highlightedMesh);
          // Highlight the children of the target mesh
          this.highlightedMesh.getChildMeshes().forEach((childMesh) => {
            this.highlightLayer.removeMesh(childMesh);
          });
        }
        return;
      } else {
        this.setOnlyDelete(false);
      }
      // Check for targetPosition metadata, otherwise use the mesh's current position
      let targetMesh;

      // check to disable remove highlight from child
      let hasChildren = false;

      if (pickedMesh.metadata.clone && !pickedMesh.metadata.isIsolated) {
        // The mesh has a parent, we need to toggle that objects gizmo
        targetMesh = pickedMesh.metadata.clone;
        hasChildren = true;
      } else {
        // The mesh doesn't have a parent, use that mesh
        targetMesh = pickedMesh;
        hasChildren = false;
      }

      // console.log('Target mesh: ' + targetMesh);

      if (targetMesh) {
        // Make sure targetMesh is not null
        this.highlightedMesh = targetMesh;
        this.highlightLayer.addMesh(this.highlightedMesh, new Color3(0, 0, 100 / 255));

        if (hasChildren) {
          // Highlight the children of the target mesh
          targetMesh.getChildMeshes().forEach((childMesh) => {
            try {
              this.highlightLayer.addMesh(childMesh, new Color3(0, 0, 100 / 255));
            } catch (error) {
              console.log(error);
            }
          });
        }

        this.highlightLayer.innerGlow = false;
      }

      if (this.gizmoManagerLocal && this.gizmoManagerLocal.gizmos) {
        this.nothingSelectedRef.current = false;

        // console.log('gizmo manager local: ' + gizmoManagerLocal);
        if (this.enablePositionGizmo && this.gizmoManagerLocal.gizmos.positionGizmo) {
          // console.log('position gizmo enabled: ' + enablePositionGizmo);
          this.gizmoManagerLocal.gizmos.positionGizmo.attachedMesh = targetMesh;
          // console.log('attached mesh: ' + gizmoManagerLocal.gizmos.positionGizmo.attachedMesh);
        }
        if (this.enableRotationGizmo && this.gizmoManagerLocal.gizmos.rotationGizmo) {
          this.gizmoManagerLocal.gizmos.rotationGizmo.attachedMesh = targetMesh;
        }
        if (this.enableScaleGizmo && this.gizmoManagerLocal.gizmos.scaleGizmo) {
          this.gizmoManagerLocal.gizmos.scaleGizmo.attachedMesh = targetMesh;
        }

        if (!pickedMesh.metadata.isIsolated) {
          this.setToolbarState('objectSelection');
        }
      }
    } else {
      if (this.highlightedMesh) {
        this.highlightLayer.removeMesh(this.highlightedMesh);

        // Highlight the children of the target mesh
        this.highlightedMesh.getChildMeshes().forEach((childMesh) => {
          this.highlightLayer.removeMesh(childMesh);
        });
      }
      this.nothingSelectedRef.current = true;

      if (this.gizmoManagerLocal.gizmos.positionGizmo) {
        this.gizmoManagerLocal.gizmos.positionGizmo.attachedMesh = null;
      }
      if (this.gizmoManagerLocal.gizmos.rotationGizmo) {
        this.gizmoManagerLocal.gizmos.rotationGizmo.attachedMesh = null;
      }
      if (this.gizmoManagerLocal.gizmos.scaleGizmo) {
        this.gizmoManagerLocal.gizmos.scaleGizmo.attachedMesh = null;
      }
    }
  }

  removeHighlight() {
    // If there's a mesh currently highlighted, remove it from the highlight layer
    if (this.highlightedMesh) {
      this.highlightLayer.removeMesh(this.highlightedMesh);
      // Highlight the children of the target mesh
      this.highlightedMesh.getChildMeshes().forEach((childMesh) => {
        this.highlightLayer.removeMesh(childMesh);
      });

      this.highlightedMesh = null;
    }
  }

  calculateQuaternionForWorldRotation(object1) {
    // Get the world rotation matrix of object1
    // eslint-disable-next-line prefer-const
    let worldMatrix1 = object1.getWorldMatrix();
    // eslint-disable-next-line prefer-const
    let worldRotationQuaternion1 = new Quaternion();
    worldMatrix1.getRotationMatrixToRef(worldRotationQuaternion1);

    // Calculate the quaternion that represents the rotation from object1's world rotation to the world identity rotation (0, 0, 0, 1)
    let worldRotationQuaternion2 = new Quaternion();
    worldRotationQuaternion2.copyFrom(Quaternion.Identity());
    worldRotationQuaternion2 = worldRotationQuaternion2.multiply(worldRotationQuaternion1.conjugate());

    // Apply the resulting quaternion to object2
    return worldRotationQuaternion2;
  }

  getHighlightMesh() {
    return this.nothingSelectedRef.current;
  }

  dragBehaviourX() {
    return this.dragBehaviorX;
  }

  dragBehaviourZ() {
    return this.dragBehaviorZ;
  }
}

export default HandleClickMesh;
