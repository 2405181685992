import axios from 'axios';

export let BASE_ENDPOINT = '';

// when developing locally, change this value to local
export const APP_ENVIRONMENT = process.env.APP_ENVIRONMENT;

if (APP_ENVIRONMENT === 'local') {
  BASE_ENDPOINT = 'http://localhost:8000';
} else if (APP_ENVIRONMENT === 'development') {
  BASE_ENDPOINT = 'https://app.dev.exvirience.io';
} else if (APP_ENVIRONMENT === 'staging') {
  BASE_ENDPOINT = 'https://stg.exvirience.io';
} else if (APP_ENVIRONMENT === 'production') {
  BASE_ENDPOINT = 'https://app.exvirience.io';
}

const BASE_URL = `${BASE_ENDPOINT}/api/v1`;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  withCredentials: true
});

// Interceptor to handle unauthorized errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // If 401 Unauthorized, redirect to the auth page
      window.location.href = '/auth'; // You can use history.push('/auth') if using react-router-dom
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
