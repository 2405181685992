import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { userService } from '@services/api/user/user.service';
import { useLocalStorage, useSessionStorage } from '@hooks/General';
import { Utils } from '@services/utils/utils.service';

const CheckForLogin = (route) => {
  // const [isLoggedIn, setIsLoggedIn] = useState(null);
  const getSessionData = useSessionStorage('sessionData', 'get');
  const [deleteStorageUsername] = useLocalStorage('username', 'delete');
  const [setLoggedIn] = useLocalStorage('keepLoggedIn', 'set');
  const [deleteSessionPageReload] = useSessionStorage('pageReload', 'delete');
  const [deleteSessionData] = useSessionStorage('sessionData', 'delete');
  const dispatch = useDispatch();

  console.log(getSessionData);

  const handleCurrentUser = async () => {
    try {
      if (getSessionData) {
        const authResult = await userService.checkCurrentUser();
        if (authResult.data.token === getSessionData.data.token) {
          console.log('Tokens are equal');
          if (route.equal) {
            window.location.assign(route.route);
          }
        } else {
          console.log('Tokens are not equal');
          if (!route.equal) {
            window.location.assign(route.route);
          }
        }
      } else {
        try {
          deleteSessionData();
          Utils.clearStore({ dispatch, deleteStorageUsername, deleteSessionPageReload, setLoggedIn });
          // eslint-disable-next-line no-unused-vars
          await userService.logoutUser();
          console.log('Logged out user');
        } catch (error) {
          console.error(error);
        }
        console.error('No token in session');
        return;
      }
    } catch (error) {
      console.error(error);
      if (!route.equal) {
        window.location.assign(route.route);
      }
    }
  };

  useEffect(() => {
    handleCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default CheckForLogin;
