import React from 'react';
import PropTypes from 'prop-types';

import { MdOutlineCloudUpload /* , MdOutlineMenuBook */, MdArrowBack, MdOutlineShapeLine } from 'react-icons/md';

import '@pages/user/editor/Tool bars/Handle model toolbar/ModelToolbar.scss';

const ModelToolbar = ({ headBack, shapes, library }) => {
  // const fileInputRef = useRef(null);
  return (
    <div className="ModelToolbar-container">
      {/* eslint-disable-next-line react/prop-types */}
      {/* <div className="ModelToolbar-element" onClick={() => fileInputRef.current.click()}>
        <input type="file" accept=".glb" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileInput} />
        <div className="ModelToolbar-icon">
          <MdOutlineCloudUpload />
        </div>
        <div className="ModelToolbar-text">Upload</div>
      </div> */}

      <div className="ModelToolbar-element" onClick={library}>
        <div className="ModelToolbar-icon">
          <MdOutlineCloudUpload />
        </div>
        <div className="ModelToolbar-text">Model Library</div>
      </div>

      <div className="ModelToolbar-element" onClick={shapes}>
        <div className="ModelToolbar-icon">
          <MdOutlineShapeLine />
        </div>
        <div className="ModelToolbar-text">Default Shapes</div>
      </div>

      <div className="ModelToolbar-element" onClick={headBack}>
        <div className="ModelToolbar-icon">
          <MdArrowBack />
        </div>
        <div className="ModelToolbar-text">Back</div>
      </div>
    </div>
  );
};

ModelToolbar.propTypes = {
  headBack: PropTypes.func.isRequired,
  shapes: PropTypes.func.isRequired,
  library: PropTypes.func.isRequired
};

export default ModelToolbar;
