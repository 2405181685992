import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '@components/Error logger/VisualLog.scss'; // Import the SCSS file for styles

const VisualLog = ({ message }) => {
  const [visible, setVisible] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (message) {
      setVisible(true);
      setFadeOut(false);
      const timer = setTimeout(() => {
        setFadeOut(true); // Start fade out
        setTimeout(() => setVisible(false), 4000); // Wait for fade out to finish, then remove from DOM
      }, 7000); // Start fade out 1 second before hiding
      return () => clearTimeout(timer);
    }
  }, [message]);

  if (!visible) {
    return null;
  }

  return (
    <div className="visualLogContainer">
      <div className={`visual-log ${fadeOut ? 'fade-out' : ''}`}>
        {'Error: '}
        {message}
      </div>
    </div>
  );
};

VisualLog.propTypes = {
  message: PropTypes.string.isRequired
};

export default VisualLog;
