import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { MdArrowBack, MdOutlineAccountTree, MdKeyboardArrowDown, MdKeyboardArrowUp, MdSearch } from 'react-icons/md';
import { HiMiniMinusSmall } from 'react-icons/hi2';
import { TbCube, TbEdit } from 'react-icons/tb';

import { CgRename } from 'react-icons/cg';
import { IoMdEyeOff, IoMdEye } from 'react-icons/io';

import '@pages/user/editor/Tool bars/Handle model toolbar/Mesh toolbar/Isolate toolbar/IsolationToolbar.scss';

import { MeshClickSimulator } from '@hooks/Mesh/index';
import { ConfirmRename, ConfirmDescription, ConfirmSeeDescription } from '@components/Modal windows/index';

// TreeNode component represents each node in the tree structure
const TreeNode = ({
  node,
  depth = 0,
  onNodeClick,
  sceneInstance,
  selectedMeshId,
  setSelectedMeshId,
  isHighlightLayerEmpty,
  pickedMeshIdRef
}) => {
  // State to manage visibility of child nodes
  const [areChildrenVisible, setAreChildrenVisible] = useState(node.children && node.children.length > 0);

  /* ----------------------------------------------------------------------- */

  // Function to toggle visibility of child nodes
  const toggleChildrenVisibility = () => {
    setAreChildrenVisible(!areChildrenVisible);
  };

  /* ----------------------------------------------------------------------- */

  // Component to render the toggle icon
  const ToggleIcon = () => {
    return node.children && node.children.length > 0 ? (
      areChildrenVisible ? (
        <MdKeyboardArrowUp />
      ) : (
        <MdKeyboardArrowDown />
      )
    ) : (
      <HiMiniMinusSmall />
    );
  };

  /* ----------------------------------------------------------------------- */

  // Component to render the mesh icon
  const MeshIcon = () => {
    if (sceneInstance && sceneInstance.current) {
      const meshExists = sceneInstance.current.getMeshByUniqueID(node.id);
      return meshExists ? (
        <span className="IsolationToolbar-mesh-icon">
          {' ('}
          <TbCube />
          {') '}
        </span>
      ) : null;
    }
    return null;
  };

  /* ----------------------------------------------------------------------- */

  // Handle click event on a node
  const handleNodeClick = (event) => {
    event.stopPropagation();
    onNodeClick && onNodeClick(node.id);
    setSelectedMeshId((prevSelectedMeshId) => (prevSelectedMeshId === node.id ? prevSelectedMeshId : node.id));
  };

  /* ----------------------------------------------------------------------- */

  // Determine if the node is selected
  // const isSelected = (selectedMeshId === node.id || pickedMeshIdRef === node.id) && isHighlightLayerEmpty();

  // Determine if the node is selected
  const isSelected =
    isHighlightLayerEmpty() && (pickedMeshIdRef === '00000' ? selectedMeshId === node.id : pickedMeshIdRef === node.id);

  /* ----------------------------------------------------------------------- */

  // Debugging functions
  /*
  useEffect(() => {
    console.log(
      `Node ID: ${node.id}, Selected Mesh ID: ${selectedMeshId}, Picked Mesh Unique ID: ${pickedMeshIdRef.current}, Is Selected: ${isSelected}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected]);

  // Debugging logs
  console.log(
    `Node ID: ${node.id}, Selected Mesh ID: ${selectedMeshId}, Picked Mesh ID: ${pickedMeshId}, Is Selected: ${isSelected}`
  ); */

  // console.log(`Picked Mesh ID: ${pickedMeshIdRef}`);

  /* ----------------------------------------------------------------------- */

  // Scroll into view when selected
  const nodeRef = useRef(null);
  useEffect(() => {
    if (isSelected && nodeRef.current) {
      nodeRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [isSelected]);

  /* ----------------------------------------------------------------------- */

  return (
    <>
      <div
        ref={nodeRef}
        className={`IsolationToolbar-element${isSelected ? '-selected' : ''}`}
        onClick={handleNodeClick}
      >
        <div
          className="IsolationToolbar-icon"
          onClick={toggleChildrenVisibility}
          style={{ marginLeft: `${depth * 5}px` }}
        >
          <ToggleIcon />
        </div>
        <MeshIcon />
        <div className="IsolationToolbar-text">{node.name}</div>
      </div>
      {areChildrenVisible &&
        node.children &&
        node.children.map((child) => (
          <TreeNode
            key={child.id}
            node={child}
            depth={depth + 1}
            onNodeClick={onNodeClick}
            sceneInstance={sceneInstance}
            selectedMeshId={selectedMeshId}
            setSelectedMeshId={setSelectedMeshId}
            isHighlightLayerEmpty={isHighlightLayerEmpty}
            pickedMeshIdRef={pickedMeshIdRef}
          />
        ))}
    </>
  );
};

TreeNode.propTypes = {
  node: PropTypes.object.isRequired,
  depth: PropTypes.number,
  onNodeClick: PropTypes.func.isRequired,
  sceneInstance: PropTypes.any,
  selectedMeshId: PropTypes.any,
  setSelectedMeshId: PropTypes.func.isRequired,
  isHighlightLayerEmpty: PropTypes.func,
  pickedMeshIdRef: PropTypes.any
};

/* ----------------------------------------------------------------------- */
/* ----------------------------------------------------------------------- */
/* ----------------------------------------------------------------------- */

// IsolationToolbar component provides the UI for the isolation feature
const IsolationToolbar = ({
  stopIsolation,
  meshParentTree,
  sceneInstance,
  cameraInstance,
  highlightedLayer,
  pickedMeshRef,
  descriptionObject
}) => {
  const meshClickManager = new MeshClickSimulator(sceneInstance, cameraInstance);
  const [selectedMeshId, setSelectedMeshId] = useState(null);

  /* ----------------------------------------------------------------------- */

  const [isRenameModalVisible, setIsRenameModalVisible] = useState(false);
  const [initialNodeName, setInitialNodeName] = useState('');

  /* ----------------------------------------------------------------------- */

  const [isDescriptionModalVisible, setIsDescriptionModalVisible] = useState(false);
  const [isDescriptionModalSeeVisible, setIsDescriptionModalSeeVisible] = useState(false);

  const [initialDescription, setInitialDescription] = useState('');

  /* ----------------------------------------------------------------------- */

  // State for the tree structure
  const [treeData, setTreeData] = useState(meshParentTree);
  const [isComponentVisible, setIsComponentVisible] = useState(true);
  const [selectedMeshVisibility, setSelectedMeshVisibility] = useState({});

  /* ----------------------------------------------------------------------- */

  // console.log(selectedMeshVisibility);

  // Function to check if the highlightLayer is empty
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isHighlightLayerEmpty = useCallback(() => !highlightedLayer);

  /* ----------------------------------------------------------------------- */

  // Reset selectedMeshId if highlight layer is empty
  useEffect(() => {
    if (!isHighlightLayerEmpty()) {
      setSelectedMeshId(null);
    }
  }, [isHighlightLayerEmpty]);

  /* ----------------------------------------------------------------------- */

  // Modified stopIsolation function to handle mesh deselection
  const handleStopIsolation = () => {
    unhideAllObjects();
    selectedMeshId && setSelectedMeshId(null);
    stopIsolation();
  };

  /* ----------------------------------------------------------------------- */

  // const pickedMeshIdRef = useRef(null);
  // Use state to track the current value of pickedMeshId
  const [pickedMeshId, setPickedMeshId] = useState(null);

  // pickedMeshIdRef.current = pickedMeshRef.current;

  /* ----------------------------------------------------------------------- */

  useEffect(() => {
    // Set up an interval to log the value every second
    const intervalId = setInterval(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setPickedMeshId(pickedMeshRef.current ? pickedMeshRef.current.uniqueId : '00000');
      // pickedMeshIdRef.current = pickedMeshRef.current ? pickedMeshRef.current.uniqueId : null; // using null doesn't work

      // console.log('Current value of pickedMeshRef:', pickedMeshId);
    }, 100); // 1000 milliseconds = 1 second

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run only on mount and unmount

  /* ----------------------------------------------------------------------- */

  const handleRename = (newName) => {
    // Check if there's a selected mesh
    if (!selectedMeshId && !pickedMeshRef.current) {
      console.log('No mesh selected for renaming.');
      return;
    }

    // Clone the tree structure for immutability
    // eslint-disable-next-line prefer-const
    let newTreeData = JSON.parse(JSON.stringify(treeData));

    // Update the name in the cloned tree structure
    const updateNodeName = (node) => {
      if (node.id === selectedMeshId || node.id === pickedMeshRef.current.uniqueId) {
        // console.log(`Old Node Name: ${node.name}`);
        node.name = newName;
        // console.log(`New Node Name: ${node.name}`);
      }
      node.children && node.children.forEach(updateNodeName);
    };

    updateNodeName(newTreeData);

    // Set the new tree data to trigger a re-render
    setTreeData(newTreeData);

    // Update the mesh's name in the 3D scene
    const mesh = sceneInstance.current.getMeshByUniqueID(selectedMeshId || pickedMeshRef.current.uniqueId);
    if (mesh) {
      // console.log(`Old Mesh Name: ${mesh.name}`);
      mesh.name = newName;
      // console.log(`New Mesh Name: ${mesh.name}`);
    }
  };

  /* ----------------------------------------------------------------------- */

  const handleDescription = (objectName, objectDescription) => {
    // Check if there's a selected mesh
    if (!selectedMeshId && !pickedMeshRef.current) {
      console.log('No mesh selected for renaming.');
      return;
    }

    // Method to modify values based on keyName
    function modifyValue(descriptionObject, keyName, newValue) {
      // If keyName exists in descriptionObject, update its value
      // eslint-disable-next-line no-prototype-builtins
      if (descriptionObject.hasOwnProperty(keyName)) {
        descriptionObject[keyName] = newValue;
      } else {
        console.log(`Key "${keyName}" does not exist in descriptionObject.`);
      }
    }

    modifyValue(descriptionObject.current, objectName, objectDescription);
  };

  /* ----------------------------------------------------------------------- */

  const openRenameModal = () => {
    if (selectedMeshId || pickedMeshRef.current) {
      // Find the node to get its current name
      const findNode = (node) => {
        if (node.id === selectedMeshId || node.id === pickedMeshRef.current.uniqueId) {
          // console.log('inside: ', node.name);
          return node.name;
        }
        if (node.children) {
          // eslint-disable-next-line prefer-const
          for (let child of node.children) {
            const foundName = findNode(child);
            if (foundName) return foundName;
          }
        }
      };

      const nodeName = findNode(treeData);
      // console.log('outside: ', nodeName);
      setInitialNodeName(nodeName);
      setIsRenameModalVisible(true);
    }
  };

  /* ----------------------------------------------------------------------- */

  function searchObjectAndReturn(descriptionObject, keyName) {
    let value;

    // Check if keyName exists in descriptionObject
    // eslint-disable-next-line no-prototype-builtins
    if (descriptionObject.hasOwnProperty(keyName)) {
      // If keyName is found, return its value
      value = descriptionObject[keyName];
    } else {
      // If keyName is not found, add it to descriptionObject with an empty string value
      descriptionObject[keyName] = '';
      value = '';
    }

    return value;
  }

  /* Method to search for a key in the descriptionObject
  function searchObjectAndReturn(descriptionObject, keyName) {
    let found = false;
    let value;

    // Loop through each object in descriptionObject
    if (descriptionObject.length > 0) {
      for (const obj of descriptionObject) {
        // If keyName is found, update its value
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(keyName)) {
          value = obj[keyName];
          found = true;
          break; // Stop searching after the first occurrence
        }
      }
    }

    // If keyName is not found, add a new object with keyName and newValue
    if (!found) {
      descriptionObject.push({ [keyName]: '' });
      value = '';
    }

    return value;
  } */

  const openDescriptionModal = () => {
    console.log(descriptionObject.current);

    if (selectedMeshId || pickedMeshRef.current) {
      // Find the node to get its current name
      const findNode = (node) => {
        if (node.id === selectedMeshId || node.id === pickedMeshRef.current.uniqueId) {
          // console.log('inside: ', node.name);
          return node.name;
        }
        if (node.children) {
          // eslint-disable-next-line prefer-const
          for (let child of node.children) {
            const foundName = findNode(child);
            if (foundName) return foundName;
          }
        }
      };

      const nodeName = findNode(treeData);
      const initialDescription = searchObjectAndReturn(descriptionObject.current, nodeName);

      // console.log('outside: ', nodeName);
      setInitialNodeName(nodeName);
      setInitialDescription(initialDescription);
      setIsDescriptionModalVisible(true);
    }
  };

  /* ----------------------------------------------------------------------- */

  const openSeeDescriptionModal = () => {
    if (selectedMeshId || pickedMeshRef.current) {
      // Find the node to get its current name
      const findNode = (node) => {
        if (node.id === selectedMeshId || node.id === pickedMeshRef.current.uniqueId) {
          // console.log('inside: ', node.name);
          return node.name;
        }
        if (node.children) {
          // eslint-disable-next-line prefer-const
          for (let child of node.children) {
            const foundName = findNode(child);
            if (foundName) return foundName;
          }
        }
      };

      const nodeName = findNode(treeData);
      const initialDescription = searchObjectAndReturn(descriptionObject.current, nodeName);

      // console.log('outside: ', nodeName);
      setInitialNodeName(nodeName);
      setInitialDescription(initialDescription);
      setIsDescriptionModalSeeVisible(true);
    }
  };

  /* ----------------------------------------------------------------------- */

  // Function to toggle component visibility
  const toggleComponentVisibility = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  /* ----------------------------------------------------------------------- */

  // Function to change the visibility of the actual mesh in the 3D scene
  const changeMeshVisibilityInScene = () => {
    // Ensure we have a selected mesh or a reference to a picked mesh
    if (!selectedMeshId && !pickedMeshRef.current) return;

    // Get the unique ID of the selected or picked mesh
    const meshUniqueId = selectedMeshId || pickedMeshRef.current.uniqueId;

    // Retrieve the mesh from the scene using its unique ID
    const mesh = sceneInstance.current.getMeshByUniqueID(meshUniqueId);

    // Check if the mesh exists in the scene
    if (mesh) {
      // Toggle the visibility of the mesh
      mesh.isVisible = !mesh.isVisible;
    }
  };

  /* ----------------------------------------------------------------------- */

  const updateHasHiddenObjects = (node) => {
    // Base case: if the node itself is hidden
    if (node.isHidden) {
      setHasHiddenObjects(true);
      return;
    }

    // Recursive case: check children
    if (node.children) {
      // eslint-disable-next-line prefer-const
      for (let child of node.children) {
        updateHasHiddenObjects(child);
      }
    }
  };

  /* ----------------------------------------------------------------------- */

  // Function to toggle mesh visibility
  const toggleMeshVisibility = () => {
    if (!selectedMeshId && !pickedMeshRef.current) return;

    // eslint-disable-next-line prefer-const
    let newTreeData = JSON.parse(JSON.stringify(treeData));
    const activeMeshId = selectedMeshId || (pickedMeshRef.current && pickedMeshRef.current.uniqueId);

    const updateVisibility = (node) => {
      if (node.id === activeMeshId) {
        console.log(node);
        node.isHidden = !node.isHidden;
        setSelectedMeshVisibility({ ...selectedMeshVisibility, [activeMeshId]: node.isHidden });
      }
      node.children && node.children.forEach(updateVisibility);
    };

    updateVisibility(newTreeData);
    setTreeData(newTreeData);
    updateHasHiddenObjects(newTreeData);

    // Change the visibility of the actual mesh in the 3D scene
    changeMeshVisibilityInScene();

    toggleComponentVisibility();
  };

  /* ----------------------------------------------------------------------- */

  const [hasHiddenObjects, setHasHiddenObjects] = useState(false);

  /* ----------------------------------------------------------------------- */

  // Function to unhide all objects in the tree and update their visibility in the 3D scene
  const unhideAllObjects = () => {
    // Clone the tree data for immutability
    // eslint-disable-next-line prefer-const
    let newTreeData = JSON.parse(JSON.stringify(treeData));

    // Recursive function to set isHidden to false for each node
    const setVisibility = (node) => {
      if (node.isHidden) {
        node.isHidden = false;
        // Update the visibility in the 3D scene
        const mesh = sceneInstance.current.getMeshByUniqueID(node.id);
        if (mesh) {
          mesh.isVisible = true;
        }
      }
      node.children && node.children.forEach(setVisibility);
    };

    // Start the recursive visibility update
    setVisibility(newTreeData);

    // Update the tree data to trigger a re-render
    setTreeData(newTreeData);

    updateHasHiddenObjects(newTreeData);

    // Reset the selected mesh visibility state
    setSelectedMeshVisibility({});
    // Reset the hasHiddenObjects state
    setHasHiddenObjects(false);

    // Reset the isComponentVisible state
    setIsComponentVisible(false);
  };

  /* ----------------------------------------------------------------------- */

  // Determine the active mesh ID (either selected or picked)
  const activeMeshId = selectedMeshId || (pickedMeshRef.current && pickedMeshRef.current.uniqueId);

  // Determine the state of the hide button
  const isHideButtonDisabled = !activeMeshId;
  const hideButtonText = selectedMeshVisibility[activeMeshId] ? 'Unhide' : 'Hide';

  /* ----------------------------------------------------------------------- */

  return (
    <>
      {isRenameModalVisible && (
        <ConfirmRename
          renameObject={(newName) => {
            handleRename(newName);
            setIsRenameModalVisible(false);
          }}
          cancelAction={() => setIsRenameModalVisible(false)}
          initialName={initialNodeName}
        />
      )}

      {isDescriptionModalVisible && (
        <ConfirmDescription
          renameObject={(name, value) => {
            handleDescription(name, value);
            setIsDescriptionModalVisible(false);
          }}
          cancelAction={() => setIsDescriptionModalVisible(false)}
          initialName={initialDescription}
          objectName={initialNodeName}
        />
      )}

      {isDescriptionModalSeeVisible && (
        <ConfirmSeeDescription
          cancelAction={() => setIsDescriptionModalSeeVisible(false)}
          initialName={initialDescription}
        />
      )}

      <div className="IsolationToolbar-container">
        <div className="IsolationToolbar-first-element">
          <div className="IsolationToolbar-icon">
            <MdOutlineAccountTree />
          </div>
          <div className="IsolationToolbar-text">Element Parent Tree</div>
        </div>

        <div className="IsolationToolbar-wrapper">
          <TreeNode
            sceneInstance={sceneInstance}
            node={treeData}
            onNodeClick={meshClickManager._simulateClickMeshId}
            selectedMeshId={selectedMeshId}
            setSelectedMeshId={setSelectedMeshId}
            isHighlightLayerEmpty={isHighlightLayerEmpty}
            pickedMeshIdRef={pickedMeshId}
          />
        </div>

        <div className="DefaultToolbar-divider"></div>

        <div className="IsolationToolbar-final-element" onClick={handleStopIsolation}>
          <div className="IsolationToolbar-icon">
            <MdArrowBack />
          </div>
          <div className="IsolationToolbar-text">Exit Isolation</div>
        </div>
      </div>

      <div className="ActionButtons-container">
        <div
          className={`ActionButton ${selectedMeshId || pickedMeshRef.current ? 'enabled' : 'disabled'}`}
          onClick={openRenameModal}
        >
          <CgRename className="ActionButton-icon" />
          <span className="ActionButton-text">Rename</span>
        </div>
        <div
          className={`ActionButton ${selectedMeshId || pickedMeshRef.current ? 'enabled' : 'disabled'}`}
          onClick={openSeeDescriptionModal}
        >
          <MdSearch className="ActionButton-icon" />
          <span className="ActionButton-text">See Description</span>
        </div>
        <div
          className={`ActionButton ${selectedMeshId || pickedMeshRef.current ? 'enabled' : 'disabled'}`}
          onClick={openDescriptionModal}
        >
          <TbEdit className="ActionButton-icon" />
          <span className="ActionButton-text">Modify Description</span>
        </div>
        <div
          className={`ActionButton ${selectedMeshId || pickedMeshRef.current ? 'enabled' : 'disabled'}`}
          onClick={toggleMeshVisibility}
          disabled={isHideButtonDisabled}
        >
          {isComponentVisible ? (
            <IoMdEyeOff className="ActionButton-icon" />
          ) : (
            <IoMdEye className="ActionButton-icon" />
          )}
          <span className="ActionButton-text">{hideButtonText}</span>
        </div>
        <div className={`ActionButton ${hasHiddenObjects ? 'enabled' : 'disabled'}`} onClick={unhideAllObjects}>
          <IoMdEye className="ActionButton-icon" />
          <span className="ActionButton-text">Unhide All</span>
        </div>
      </div>
    </>
  );
};

IsolationToolbar.propTypes = {
  stopIsolation: PropTypes.func.isRequired,
  meshParentTree: PropTypes.object.isRequired,
  sceneInstance: PropTypes.any,
  cameraInstance: PropTypes.any,
  highlightedLayer: PropTypes.any,
  pickedMeshRef: PropTypes.any,
  descriptionObject: PropTypes.object.isRequired
};

export default IsolationToolbar;
