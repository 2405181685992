/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import '@pages/user/manager/media library/MediaManager.scss';

import { TbEdit, TbUserShare, TbWorldCancel } from 'react-icons/tb';
import { HiOutlineShare } from 'react-icons/hi2';
import {
  MdOutlineManageAccounts,
  MdOutlineClose,
  MdDeleteOutline,
  MdOutlinePhotoSizeSelectActual,
  MdOutlineVideoCameraBack,
  MdOutlineCloudUpload
} from 'react-icons/md';
import { LuFileEdit } from 'react-icons/lu';

import { mediasService } from '@services/api/medias/medias.service';
import PageLoader from '@components/page-loader/PageLoader'; // the page loader component
import { useSessionStorage } from '@hooks/General';
import { ConfirmRename, ConfirmRemove, ConfirmSceneDescription } from '@components/Modal windows';

import MediaInputHandler from '@hooks/Mesh/hooks/MediaInutHandler';

const MediaManager = ({ Medias, CompanyName }) => {
  const [medias, setMedias] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isMediaSettings, setMediaSettings] = useState(false);

  // get the file in input from the manager page
  const getMedias = useSessionStorage('mediasList', 'get');
  // console.log(getMedias);
  const [setMediasList] = useSessionStorage('mediasList', 'set');
  const [deleteMediasList] = useSessionStorage('mediasList', '');

  // modal window for mediaRename
  const [mediaRename, setMediaRename] = useState(false);
  const [mediaName, setMediaName] = useState('');
  const [mediaId, setMediaId] = useState('');
  const [mediaType, setMediaType] = useState('');
  const [mediaLastDescription, setMediaLastDescription] = useState('');

  // to rerender the component
  const [forceRender, setForceRender] = useState(false); // New state variable

  // Define a state variable for media remove
  const [confirmRemove, setConfirmRemove] = useState(false);

  // session data to get access keys
  const getSessionData = useSessionStorage('sessionData', 'get');
  const userDataStore = useSessionStorage('userData', 'get');

  // Handles the input of the glb file
  const handleInputManager = new MediaInputHandler(userDataStore, getSessionData);

  const fileInputRef = useRef(null);
  const fileInputRefSave = useRef(null);

  const [isMediaLoading, setIsMediaLoading] = useState(false);
  const [isMediaSaveLoading, setIsMediaSaveLoading] = useState(false);

  const [mediaUrl, setMediaUrl] = useState(null);

  const fetchMedia = async (mediaId) => {
    const Bucket = 'exvirience-media';
    const awsInformation = {
      accessKeyId: getSessionData.data.accessInformation.AccessKeyId,
      secretAccessKey: getSessionData.data.accessInformation.SecretAccessKey,
      sessionToken: getSessionData.data.accessInformation.SessionToken,
      bucketName: Bucket
    };

    const body = {
      medias_Id: Medias,
      mediaId,
      awsInformation
    };

    try {
      const response = await mediasService.getMediaURL(body); // Adjust this based on your actual service method

      console.log(response);

      const url = response.data.media;
      setMediaUrl(url);
    } catch (error) {
      console.error('Error fetching media:', error);
    }
  };

  const handleNewMedia = async (e) => {
    setIsMediaLoading(true);

    await handleInputManager.handleFileInput(e);

    setIsMediaLoading(false);
    deleteMediasList();
    exitMediaSettings();
    setForceRender((prev) => !prev); // Trigger re-render
  };

  const handleSaveMedia = async (e) => {
    setIsMediaSaveLoading(true);

    await handleInputManager.handleFileInputSave(e, mediaId);

    setIsMediaSaveLoading(false);
    deleteMediasList();
    exitMediaSettings();
    setForceRender((prev) => !prev); // Trigger re-render
  };

  const handleMediaSettings = (mediaid, mediaName, mediaType, lastModified) => {
    // console.log(lastModified);
    setMediaId(mediaid);
    setMediaName(mediaName);
    setMediaType(mediaType);
    setMediaLastDescription(lastModified);

    fetchMedia(mediaid);

    setMediaSettings((value) => !value);
  };

  const exitMediaSettings = () => {
    setMediaName('');
    setMediaId('');
    setMediaType('');
    setMediaLastDescription('');
    setMediaSettings(false);
  };

  const handleMediaRemove = (mediaid) => {
    setMediaId(mediaid);
    setConfirmRemove((value) => !value);
  };

  const exitMediaRemove = () => {
    setMediaId('');
    setConfirmRemove(false);
  };

  const handleChangeMediaName = () => {
    setMediaRename((value) => !value);
  };

  const changeMediaName = async (name) => {
    try {
      const Bucket = 'exvirience-media';

      const awsInformation = {
        accessKeyId: getSessionData.data.accessInformation.AccessKeyId,
        secretAccessKey: getSessionData.data.accessInformation.SecretAccessKey,
        sessionToken: getSessionData.data.accessInformation.SessionToken,
        bucketName: Bucket,
        accelerationStatus: true
      };

      const body = {
        companyName: CompanyName,
        medias_Id: Medias,
        mediaId,
        newMediaName: name,
        awsInformation
      };

      handleChangeMediaName();
      exitMediaSettings();
      setIsLoading(true);

      await mediasService.updateMedia(body);

      deleteMediasList();
      setForceRender((prev) => !prev); // Trigger re-render
    } catch (error) {
      console.error('Error change media name:', error);
    }
  };

  const deleteMedia = async () => {
    try {
      const Bucket = 'exvirience-media';

      const awsInformation = {
        accessKeyId: getSessionData.data.accessInformation.AccessKeyId,
        secretAccessKey: getSessionData.data.accessInformation.SecretAccessKey,
        sessionToken: getSessionData.data.accessInformation.SessionToken,
        bucketName: Bucket,
        accelerationStatus: true
      };

      const body = {
        medias_Id: Medias,
        mediaId,
        awsInformation
      };

      exitMediaRemove();
      setIsLoading(true);

      await mediasService.deleteMedia(body);

      deleteMediasList();
      setForceRender((prev) => !prev); // Trigger re-render
    } catch (error) {
      console.error('Error delete media:', error);
    }
  };

  const fetchMedias = async () => {
    try {
      const body = {
        medias_Id: Medias
      };

      const response = await mediasService.listMedia(body);
      setIsLoading(false);
      console.log('List Medias Response:', response.data.medias);
      setMedias(response.data.medias); // Set medias directly
      setMediasList(response.data.medias);
    } catch (error) {
      console.error('Error fetching medias:', error);
    }
  };

  useEffect(() => {
    if (getMedias) {
      setMedias(getMedias);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      fetchMedias();
    }
  }, [Medias.Medias, forceRender]);

  const iconSetting = <HiOutlineShare style={{ scale: '1.5', marginRight: '10px', transform: 'translateY(1.5px)' }} />;

  return (
    <div className="model-manager">
      <h1 className="Editor-Title"> Media Library </h1>
      <div className="editor-title-divider"></div>
      <button className="ui-button-new-model" onClick={() => fileInputRef.current.click()}>
        <input
          type="file"
          accept=".jpg, .jpeg, .png, .gif, .bmp, .tiff, .webp, .mp4, .mov, .avi, .mkv, .wmv, .flv, .mpeg, .webm"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleNewMedia}
        />
        <MdOutlineCloudUpload style={{ scale: '1.5', marginRight: '10px', transform: 'translateY(1.5px)' }} /> Upload
        Media
      </button>

      {isLoading && (
        <div className="user-Editor-Model-loader-container">
          <PageLoader type="original-l" isImporting={false} />
        </div>
      )}

      <div className="MediaContainer">
        {medias.map((media, index) => (
          <div className="buttonRow" key={index}>
            <div
              className="topContainer"
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <button className="mediatype-icon">
                {media.mediaType === 'video' ? (
                  <MdOutlineVideoCameraBack style={{ scale: '1.5', transform: 'translateY(1.5px)' }} />
                ) : (
                  <MdOutlinePhotoSizeSelectActual style={{ scale: '1.5', transform: 'translateY(1.5px)' }} />
                )}
              </button>
              <button className="topview-button" onClick={() => handleMediaRemove(media.mediaId)}>
                <MdDeleteOutline style={{ scale: '1.5', transform: 'translateY(1.5px)' }} />
              </button>
              <div className="buttonText">{media.mediaName}</div>
            </div>
            <div className="bottomContainer">
              <div className="rightContainer">
                <button
                  className="edit-viewbutton-media"
                  onClick={() =>
                    handleMediaSettings(media.mediaId, media.mediaName, media.mediaType, media.lastModified)
                  }
                >
                  {iconSetting} {'Settings'}
                </button>
              </div>
            </div>
          </div>
        ))}
        {isMediaLoading && (
          <div className="buttonRow" key="loading">
            <div
              className="topContainer"
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div className="buttonText">Uploading media...</div>
            </div>
            <div className="bottomContainer">
              <div className="rightContainer">{/* No Settings button here */}</div>
            </div>
          </div>
        )}
      </div>
      {mediaRename && (
        <ConfirmRename
          renameObject={(value) => changeMediaName(value)}
          cancelAction={handleChangeMediaName}
          initialName={mediaName}
        />
      )}
      {confirmRemove && (
        <ConfirmRemove
          deleteObject={deleteMedia}
          deleteObjectContext={deleteMedia}
          cancelAction={exitMediaRemove}
          context={true}
          Title="Delete Media"
          Description="To delete the the media, type 'Confirm' and click the remove button."
        />
      )}
      {isMediaSettings && (
        <div className="user-settings-container-model">
          <div className="user-settings-back-model" onClick={exitMediaSettings}>
            <div className="user-settings-back-background-model"></div>
          </div>
          <div className="user-settings-sidebar-model">
            <div className="close-icon" onClick={exitMediaSettings}>
              <MdOutlineClose />
            </div>
            <h2 className="user-settings-sidebar-model-h2">{mediaName}</h2>

            <div className="editor-title-divider-model"></div>

            <div className="section">
              <h3>Media Details</h3>
              <div className="sharing-buttons">
                <button className="ui-button-new-model-single" onClick={handleChangeMediaName}>
                  <LuFileEdit style={{ scale: '1.5', marginRight: '10px', transform: 'translateY(1.5px)' }} />
                  Change Name
                </button>
              </div>

              <h4>Last Modified:</h4>
              <p>{mediaLastDescription}</p>

              <h4>Media Type:</h4>
              <p>{mediaType}</p>
            </div>

            <div className="editor-title-divider-model"></div>

            <div className="section">
              <h3>Update Media Version</h3>
              <div className="sharing-buttons">
                <button
                  className="ui-button-new-model-single"
                  onClick={() => fileInputRefSave.current.click()}
                  disabled={isMediaSaveLoading}
                >
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png, .gif, .bmp, .tiff, .webp, .mp4, .mov, .avi, .mkv, .wmv, .flv, .mpeg, .webm"
                    ref={fileInputRefSave}
                    style={{ display: 'none' }}
                    onChange={handleSaveMedia}
                  />
                  <LuFileEdit style={{ scale: '1.5', marginRight: '10px', transform: 'translateY(1.5px)' }} />
                  {isMediaSaveLoading ? 'Updating ...' : 'Update Version'}
                </button>
              </div>

              <h3>Preview Media</h3>
              {mediaType === 'image' &&
                (mediaUrl ? (
                  <img src={mediaUrl} alt={mediaName} style={{ width: '100%', height: 'auto', borderRadius: '10px' }} />
                ) : (
                  'Loading...'
                ))}
              {mediaType === 'video' &&
                (mediaUrl ? (
                  <video controls src={mediaUrl} style={{ width: '100%', height: 'auto', borderRadius: '10px' }}>
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  'Loading...'
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

MediaManager.propTypes = {
  Medias: PropTypes.any,
  CompanyName: PropTypes.string
};

export default MediaManager;
