import { StandardMaterial, Color3, Vector3, MeshBuilder, Color4 } from '@babylonjs/core'; // Ensure the necessary BabylonJS modules are imported.

class CreateLineAxese {
  constructor(scene) {
    this.scene = scene;
  }

  createLineAxes(C1M) {
    // Create paths for axes
    const myPathX = [new Vector3(1.5, 0, 0), new Vector3(-1.5, 0, 0)];
    const myPathY = [new Vector3(0, 1, 0), new Vector3(0, -1, 0)];
    const myPathZ = [new Vector3(0, 0, 1.5), new Vector3(0, 0, -1.5)];

    // Create tubes for axes
    const tubeX = MeshBuilder.CreateTube('tubeX', { path: myPathX, radius: 0.005 }, this.scene);
    const tubeY = MeshBuilder.CreateTube('tubeY', { path: myPathY, radius: 0.005 }, this.scene);
    const tubeZ = MeshBuilder.CreateTube('tubeZ', { path: myPathZ, radius: 0.005 }, this.scene);

    // Create materials for each axis
    const createAxisMaterial = (color) => {
      const material = new StandardMaterial('lineMaterial', this.scene);
      material.emissiveColor = color;
      material.diffuseColor = color;
      return material;
    };

    tubeX.material = createAxisMaterial(new Color4(1, 0, 0, 0.5));
    tubeY.material = createAxisMaterial(new Color4(0, 1, 0, 0.5));
    tubeZ.material = createAxisMaterial(new Color4(0, 0, 1, 0.5));

    [tubeX, tubeY, tubeZ].forEach((tube) => {
      tube.layerMask = C1M;
      tube.renderingGroupId = 1;

      tube.metadata = { editor: true };
    });

    // Create the center point
    const centerPointVisual = MeshBuilder.CreateSphere('centerPoint', { diameter: 0.1 }, this.scene);
    const black = new StandardMaterial('centerPointMaterial', this.scene);
    black.diffuseColor = new Color4(0, 0, 0, 0); // Set to black
    black.specularColor = new Color3(0, 0, 0); // No specular reflection
    black.ambientColor = new Color3(0, 0, 0); // No ambient light

    centerPointVisual.material = black;
    centerPointVisual.position = new Vector3(0, 0, 0);
    centerPointVisual.layerMask = C1M;
    centerPointVisual.renderingGroupId = 1;
    centerPointVisual.metadata = {
      canToggleGizmo: false,
      canToggleFocus: false,
      isolatable: false,
      editor: true
    };
  }
}

export default CreateLineAxese;
