import axios from '@services/axios';

class MediasService {
  async getMediaURL(body) {
    const response = await axios.post('/medias/get-url', body);
    return response;
  }

  async addMedia(formData) {
    const response = await axios.post('/medias/add', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  }

  async saveMedia(formData) {
    const response = await axios.post('/medias/save', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  }

  async updateMedia(body) {
    const response = await axios.post('/medias/update', body);
    return response;
  }

  async deleteMedia(body) {
    const response = await axios.post('/medias/delete', body);
    return response;
  }

  async listMedia(body) {
    const response = await axios.post('/medias/list', body);
    return response;
  }

  async updateMediaLastModified(body) {
    const response = await axios.post('/medias/update-last-modified', body);
    return response;
  }

  async getMedia(body, setPercentage) {
    const response = await axios.post('/medias/get', body, {
      responseType: 'arraybuffer',
      onDownloadProgress: (progressEvent) => {
        if (setPercentage) {
          const totalLength =
            progressEvent.total ||
            parseInt(progressEvent.currentTarget.getResponseHeader('content-length'), 10) ||
            parseInt(progressEvent.currentTarget.getResponseHeader('x-decompressed-content-length'), 10);
          // console.log('Total length: ', totalLength);

          if (totalLength) {
            // eslint-disable-next-line prefer-const
            let progress = Math.floor((progressEvent.loaded / totalLength) * 100);
            setPercentage(progress); // Update your progress tracking state or ref
            console.log(`Download Progress: ${progress}%`);
          }
        }
      }
    });
    return response;
  }

  async getMediaNoPercent(body) {
    const response = await axios.post('/medias/get', body, {
      responseType: 'arraybuffer'
    });
    return response;
  }
}

export const mediasService = new MediasService();
