import './ForgotPassword.scss';

import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

import Input from '@components/input/Input';
import Button from '@components/button/Button';
import { authService } from '@services/api/auth/auth.service';
import { Utils } from '@services/utils/utils.service';

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasResponse, setHasResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [canReset, setCanReset] = useState(false);

  const forgotPassword = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const params = {
        email
      };

      const response = await authService.forgotPassword(params);
      setLoading(false);
      setEmail('');
      setHasError(false);
      setHasResponse(true);
      setAlertType('alert-success');
      setResponseMessage(response?.data?.message);

      // console.log(response);

      setErrorMessage('');
      setCanReset(true);

      Utils.redirectToPageAfterDelay(navigate, '/reset-password', 3);
    } catch (error) {
      setHasError(true);
      setHasResponse(false);
      setLoading(false);
      setAlertType('alert-error');
      setResponseMessage('');
      setErrorMessage(error?.response?.data.message);

      // console.log(error);

      setCanReset(false);
    }
  };

  return (
    <div className="auth-inner-login" style={{ height: '550px' }}>
      <form className="auth-form-login" onSubmit={forgotPassword}>
        <div className="form-input-container-login">
          <Input
            id="email"
            name="email"
            type="email"
            value={email}
            labelText="Email"
            placeholder="Enter email"
            style={{ border: `${hasError ? '1px solid #fa9b8a' : ''}` }}
            handleChange={(event) => setEmail(event.target.value)}
          />
          {hasError && errorMessage && (
            <div className={`alerts ${alertType}`} role="alert">
              {errorMessage}
            </div>
          )}
          {hasResponse && responseMessage && (
            <div className={`alerts ${alertType}`} role="alert">
              {responseMessage}
            </div>
          )}
          <Button
            label={`${loading ? 'SENDING EMAIL...' : 'REQUEST RESET CODE'}`}
            className="auth-button button"
            disabled={!email}
          />
          <div className="FP-divider"></div>
          {canReset && (
            <Link to={'/reset-password'}>
              <span className="arrow-links" style={{ fontSize: '15px' }}>
                Reset password <FaArrowRight className="arrow-right" />
              </span>
            </Link>
          )}
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
