/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import '@pages/user/editor/Modal windows/Media library/VideoLibrary.scss';

import { MdOutlineCloudUpload, MdOutlineClose } from 'react-icons/md';

import { mediasService } from '@services/api/medias/medias.service';
import PageLoader from '@components/page-loader/PageLoader'; // the page loader component
import { useSessionStorage } from '@hooks/General';

const VideoLibrary = ({ headBack, inputMeshMethod, addMethod }) => {
  const [pictures, setPictures] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const userDataStore = useSessionStorage('userData', 'get');

  const Medias = userDataStore.mediasId;

  const fileInputRef = useRef(null);

  // to rerender the component
  const [forceRender, setForceRender] = useState(false); // New state variable

  // this should first upload the picture and then add it to the list
  // not add it directly into the scene
  const handleNewMedia = async (e) => {
    setIsLoading(true);

    await inputMeshMethod(e);

    setIsLoading(false);

    setPictures([]);

    setForceRender((prev) => !prev); // Trigger re-render
  };
  /* const handleModelInputScene = async (mediaId, mediaName) => {
    try {
      console.log('Starting download for media:', mediaId);
      handleDownloading(mediaId);

      // first let's call the input mesh method
      await inputMeshMethod(mediaId, mediaName, setLoadingPercent);
    } catch (error) {
      console.error('Error adding media to scene:', error);
    } finally {
      console.log('Finished download for media:', mediaId);
      headBack();
      setDownloadingModelId(null); // Reset the downloading media ID
    }
  }; */

  const handlePictureInputScene = (type, keyName, mediaName) => {
    addMethod(type, 'exvirience-media', keyName, Medias, mediaName);

    headBack();
  };

  const fetchPictures = async () => {
    try {
      const body = {
        medias_Id: Medias
      };

      const response = await mediasService.listMedia(body);
      setIsLoading(false);
      console.log('List Medias Response:', response.data.medias);
      setPictures(response.data.medias); // Set medias directly
    } catch (error) {
      console.error('Error fetching medias:', error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchPictures();
  }, [forceRender]);

  const iconAdd = <MdOutlineCloudUpload style={{ scale: '1.5', marginRight: '10px' }} />;

  return (
    <div className="model-manager-model-container">
      <div className="model-manager-modal">
        <div className="close-icon-modal" onClick={headBack}>
          <MdOutlineClose />
        </div>
        <h1 className="Editor-Title-modal"> Video Library </h1>
        <div className="editor-title-divider-modal"></div>

        {!isLoading && (
          <button className="ui-button-new-model-modal-vid" onClick={() => fileInputRef.current.click()}>
            <input
              type="file"
              accept=".mp4, .mov, .avi, .mkv, .wmv, .flv, .mpeg, .webm"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleNewMedia}
            />
            <MdOutlineCloudUpload style={{ scale: '1.5', marginRight: '10px', transform: 'translateY(1.5px)' }} />{' '}
            Upload Video
          </button>
        )}

        {isLoading && (
          <div className="user-Editor-Model-loader-container-modal">
            <PageLoader type="original-l" isImporting={false} height="100%" width="100%" />
          </div>
        )}

        <div className="ModelContainer-modal">
          {pictures
            .filter((media) => media.mediaType === 'video')
            .map((media, index) => (
              <div className="buttonRow-modal" key={index}>
                <div
                  className="topContainer-modal"
                  style={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                >
                  <div className="buttonText-modal">{media.mediaName}</div>
                </div>
                <div className="bottomContainer-modal">
                  <div className="rightContainer-modal">
                    <button
                      className="edit-viewbutton-modal"
                      onClick={() => handlePictureInputScene(media.mediaType, media.mediaId, media.mediaName)}
                      disabled={false} // Disable other buttons except the downloading one
                    >
                      <div className="button-content-modal">
                        {iconAdd} {'Add to scene'}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

VideoLibrary.propTypes = {
  headBack: PropTypes.func.isRequired,
  inputMeshMethod: PropTypes.func.isRequired,
  addMethod: PropTypes.func.isRequired
};

export default VideoLibrary;
