import { MeshBuilder, StandardMaterial, Color4, Color3, Vector3 } from '@babylonjs/core';

class LoadingSpheresCreator {
  constructor(
    sceneInstance,
    setLoadingMesh,
    setLoadingSpheres,
    C1M,
    loadingMeshRef,
    numSpheres,
    intervalID,
    loadingSpheres,
    spheres
  ) {
    this.sceneInstance = sceneInstance;
    this.setLoadingMesh = setLoadingMesh;
    this.setLoadingSpheres = setLoadingSpheres;
    this.C1M = C1M;
    this.loadingMeshRef = loadingMeshRef;
    this.numSpheres = numSpheres;
    this.intervalID = intervalID;
    this.loadingSpheres = loadingSpheres;
    this.spheres = spheres;
  }

  create(position) {
    this.setLoadingMesh(true);

    const PosX = position.x;
    const PosY = position.y;
    const PosZ = position.z;

    const radius = 0.35;
    const angleStep = (2 * Math.PI) / this.numSpheres;

    const spheres = [];

    for (let i = 0; i < this.numSpheres; i++) {
      const angle = i * angleStep;
      const x = PosX + radius * Math.cos(angle);
      const z = PosZ + radius * Math.sin(angle);

      const sphere = MeshBuilder.CreateSphere('loadingsphere' + i, { diameter: 0.15 }, this.sceneInstance.current);
      const loadingMaterial = new StandardMaterial('lineMaterial', this.sceneInstance.current);
      loadingMaterial.diffuseColor = new Color4(0, 0, 0, 1);
      loadingMaterial.specularColor = new Color3(0, 0, 0);
      loadingMaterial.ambientColor = new Color3(0, 0, 0);
      sphere.material = loadingMaterial;
      sphere.position = new Vector3(x, PosY, z);

      sphere.layerMask = this.C1M;

      spheres.push(sphere);
    }

    this.setLoadingSpheres(spheres);

    const totalSpheres = spheres.length;

    // Set initial scales for the spheres
    for (let i = 0; i < 20; i++) {
      spheres[i].scaling = new Vector3(0, 0, 0);
    }

    const initial = 1 - 0.03 * 30;
    let j = 0;
    for (let i = 20; i < 50; i++) {
      const addition = 0.03 * j;
      spheres[i].scaling = new Vector3(initial + addition, initial + addition, initial + addition);
      j += 1;
    }

    let z = 0;
    for (let i = 50; i < 60; i++) {
      const addition = 0.03 * z;
      spheres[i].scaling = new Vector3(1 + addition, 1 + addition, 1 + addition);
      z += 1;
    }

    if (this.loadingMeshRef) {
      this.intervalID = setInterval(this.updateScales.bind(this, spheres, totalSpheres), 50);
    }
  }

  updateScales(spheres, totalSpheres) {
    // Shift the scales of the spheres
    for (let i = totalSpheres - 1; i > 0; i--) {
      spheres[i].scaling = spheres[i - 1].scaling.clone();
    }

    // Set the scale of the first sphere to the scale of the last sphere
    spheres[0].scaling = spheres[totalSpheres - 1].scaling.clone();
  }

  disposeSpheres() {
    this.setLoadingMesh(false);

    // Clear the interval
    clearInterval(this.intervalID);

    this.spheres.length = 0;

    // Dispose of the spheres
    this.loadingSpheres.forEach((sphere) => sphere.dispose());

    for (let i = 0; i < this.numSpheres; i++) {
      const meshName = 'loadingsphere' + i; // replace with your mesh name
      // eslint-disable-next-line prefer-const
      let mesh = this.sceneInstance.current.getMeshByName(meshName);
      if (mesh) {
        mesh.dispose();
      }
    }

    // Clear the state
    this.setLoadingSpheres([]);
  }
}

export default LoadingSpheresCreator;
