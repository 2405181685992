/* eslint-disable no-unused-vars */
import { modelsService } from '@services/api/models/models.service';

class ModelInputHandler {
  constructor(userDataStore, sessionData) {
    this.userDataStore = userDataStore;
    this.sessionData = sessionData;
  }

  handleFileInput = async (e) => {
    const file = e.target.files[0];

    if (file && file.name.endsWith('.glb')) {
      const formData = new FormData();

      formData.append('file', file);
      formData.append('companyName', this.userDataStore.company);
      formData.append('models_Id', this.userDataStore.modelsId);
      formData.append('modelName', file.name);
      formData.append('accessKeyId', this.sessionData.data.accessInformation.AccessKeyId);
      formData.append('secretAccessKey', this.sessionData.data.accessInformation.SecretAccessKey);
      formData.append('sessionToken', this.sessionData.data.accessInformation.SessionToken);
      formData.append('bucketName', 'exvirience-objects');
      formData.append('accelerationStatus', 'true');

      try {
        const response = await modelsService.addModel(formData);
        console.log(response.data);
      } catch (error) {
        console.error('Error uploading model', error);
      }
    } else {
      alert('Please upload a .glb file');
    }
  };

  handleFileInputSave = async (e, modelId) => {
    const file = e.target.files[0];

    if (file && file.name.endsWith('.glb')) {
      const formData = new FormData();

      formData.append('file', file);
      formData.append('models_Id', this.userDataStore.modelsId);
      formData.append('modelId', modelId);
      formData.append('accessKeyId', this.sessionData.data.accessInformation.AccessKeyId);
      formData.append('secretAccessKey', this.sessionData.data.accessInformation.SecretAccessKey);
      formData.append('sessionToken', this.sessionData.data.accessInformation.SessionToken);
      formData.append('bucketName', 'exvirience-objects');
      formData.append('accelerationStatus', 'true');

      try {
        const response = await modelsService.saveModel(formData);
        console.log(response.data);
      } catch (error) {
        console.error('Error uploading model', error);
      }
    } else {
      alert('Please upload a .glb file');
    }
  };
}

export default ModelInputHandler;
