import PropTypes from 'prop-types';

import { Vector3, MeshBuilder, StandardMaterial, Color3, Mesh, Plane } from '@babylonjs/core';
// eslint-disable-next-line no-unused-vars
import { ConsoleLog } from '@hooks/General';

const CreateClippingPlaneFromBoundingbox = (mesh, scene) => {
  const thresholdMax = [1.1, 0, 1.05, 0, 1.05];
  const thresholdMin = [1.05, 0, 1.05, 0, 1.05];

  const createPlanesForCube = (cube, scene) => {
    // Ensure the world matrix is up to date
    cube.computeWorldMatrix(true);

    // Get the bounding info of the cube
    const boundingInfo = cube.getBoundingInfo();
    // console.log(boundingInfo);

    // Calculate the size of the cube along each local axis
    const size = boundingInfo.boundingBox.extendSize.scale(2);

    // Define the six face normals in local space
    const faceNormals = [
      new Vector3(1, 0, 0), // Right
      new Vector3(-1, 0, 0), // Left
      new Vector3(0, 1, 0), // Top
      new Vector3(0, -1, 0), // Bottom
      new Vector3(0, 0, 1), // Front
      new Vector3(0, 0, -1) // Back
    ];

    const planeCoefficients = [];

    faceNormals.forEach((localNormal, index) => {
      // Ensure the world matrix is up to date
      cube.computeWorldMatrix(true);

      // Create a plane at the face center
      const plane = MeshBuilder.CreatePlane(`plane_${index}`, { size: 2, sideOrientation: Mesh.DOUBLESIDE }, scene);
      // console.log('Plane before parent change', plane);
      // plane.parent = cubeParent;
      // console.log('Plane after parent change', plane);

      // Transform the normal to world space
      const worldNormal = Vector3.TransformNormal(localNormal, cube.getWorldMatrix());

      // Find the distance from the center of the cube to the face center along the normal
      const distanceToFace = size.multiply(localNormal).length() / 2;

      // Calculate the world position of the face center
      const faceCenterWorld = cube.position.add(worldNormal.scale(distanceToFace));

      plane.position = faceCenterWorld;
      plane.lookAt(faceCenterWorld.add(worldNormal));

      plane.isVisible = false;

      // const degreesToRadians = (degrees) => degrees * (Math.PI / 180);
      // plane.rotation.z = degreesToRadians(90 - 80);

      // Assign a semi-transparent material to the plane
      const planeMaterial = new StandardMaterial(`planeMat_${index}`, scene);
      planeMaterial.diffuseColor = new Color3(0.5, 0.5, 0.5); // Grey color
      planeMaterial.alpha = 0; // Semi-transparent
      plane.material = planeMaterial;

      // Calculate 'd' coefficient using the plane's position in world coordinates
      const d = Vector3.Dot(worldNormal, faceCenterWorld);

      // The coefficients 'a', 'b', and 'c' are the components of the worldNormal vector
      const a = worldNormal.x;
      const b = worldNormal.y;
      const c = worldNormal.z;

      // console.log(`Plane ${index} Equation Coefficients:`, { a, b, c, d });

      const coefficients = { a, b, c, d, faceCenterWorld };
      planeCoefficients.push(coefficients);
    });

    // eslint-disable-next-line no-unused-vars
    const limits = changePlaneEquation(planeCoefficients, scene);

    // cube.scaling.z *= -1;
    return limits;
  };

  const changePlaneEquation = (planeCoefficients, scene) => {
    const limits = [];

    for (let i = 0; i < Object.keys(planeCoefficients).length; i += 2) {
      // console.log(planeCoefficients);

      planeCoefficients[i].a = planeCoefficients[i + 1].a;
      planeCoefficients[i].b = planeCoefficients[i + 1].b;
      planeCoefficients[i].c = planeCoefficients[i + 1].c;

      planeCoefficients[i].d =
        (planeCoefficients[i].faceCenterWorld._x * planeCoefficients[i].a +
          planeCoefficients[i].faceCenterWorld._y * planeCoefficients[i].b +
          planeCoefficients[i].faceCenterWorld._z * planeCoefficients[i].c) *
        1;

      const dmin = planeCoefficients[i].d * thresholdMin[i];
      const dmax = planeCoefficients[i + 1].d * thresholdMax[i];
      const a = planeCoefficients[i].a;
      const b = planeCoefficients[i].b;
      const c = planeCoefficients[i].c;

      const values = { dmin, dmax, a, b, c };

      limits.push(values);

      // console.log(planeCoefficients[i].d);
      // console.log(planeCoefficients[i + 1].d);
    }

    limits.forEach((element) => {
      // Use the coefficients retrieved earlier to create a plane
      // eslint-disable-next-line no-unused-vars
      const customPlane1 = createPlaneFromEquation(element.a, element.b, element.c, element.dmin, scene);
      // eslint-disable-next-line no-unused-vars
      const customPlane2 = createPlaneFromEquation(element.a, element.b, element.c, element.dmax, scene);
    });

    return limits;
  };

  const createPlaneFromEquation = (a, b, c, d, scene) => {
    // Normalize the plane coefficients
    const length = Math.sqrt(a * a + b * b + c * c);
    a /= length;
    b /= length;
    c /= length;
    d /= length;

    // Create a plane using the normal and distance from origin
    const plane = new Plane(a, b, c, -d);

    /* / Accessing the values
    const planeA = plane.normal.x;
    const planeB = plane.normal.y;
    const planeC = plane.normal.z;
    const planeD = plane.d;

    console.log('planeA before: ', planeA);
    console.log('planeB before: ', planeB);
    console.log('planeC before: ', planeC);
    console.log('planeD before: ', planeD); */

    // Use MeshBuilder to create a plane mesh
    const planeMesh = MeshBuilder.CreatePlane(
      'plane',
      {
        sourcePlane: plane,
        size: 5 // Define the visible size of the plane
      },
      scene
    );

    // To visually see the plane in the scene, assign it a material
    const material = new StandardMaterial('material', scene);
    material.backFaceCulling = false;
    material.alpha = 0.5;
    material.diffuseColor = new Color3(1, 1, 0); // Yellow color
    planeMesh.material = material;

    planeMesh.isVisible = false;

    // planeMesh.parent = cubeParent;

    /* Accessing the values
    const planeA1 = plane.normal.x;
    const planeB1 = plane.normal.y;
    const planeC1 = plane.normal.z;
    const planeD1 = plane.d;

    console.log('planeA after: ', planeA1);
    console.log('planeB after: ', planeB1);
    console.log('planeC after: ', planeC1);
    console.log('planeD after: ', planeD1); */

    return planeMesh;
  };

  return createPlanesForCube(mesh, scene);
};

CreateClippingPlaneFromBoundingbox.propTypes = {
  mesh: PropTypes.any.isRequired,
  sceneInstance: PropTypes.any.isRequired
};

export default CreateClippingPlaneFromBoundingbox;
