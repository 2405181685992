import React, { useState, useEffect, useRef } from 'react';

import '@pages/user/editor/Editor.scss';

import PageLoader from '@components/page-loader/PageLoader'; // the page loader component

// Importing the rerndered object/components
import {
  ContextMenu,
  ReferenceToolbar,
  DefaultToolbar,
  SectionToolbar,
  ObjectListToolbar,
  IsolationToolbar,
  ObjectToolbar,
  ModelToolbar,
  DefaultShapeToolbar,
  EnvironmentToolbar,
  GeneralToolbar
} from '@pages/user/editor/Tool bars/index';

import { ConfirmRemove, ConfirmReturn, AddPlane } from '@components/Modal windows/index';

// Importing the babylon components
import { Vector3, Matrix } from '@babylonjs/core/Maths/math';
import { ArcRotateCamera, PointerEventTypes, Viewport } from '@babylonjs/core';
import SceneComponent from 'babylonjs-hook';
// Side-effects only imports allowing the standard material to be used as default.
import '@babylonjs/core/Materials/standardMaterial';
// Side-effects only imports allowing Mesh to create default shapes (to enhance tree shaking, the construction methods on mesh are not available if the meshbuilder has not been imported).
import '@babylonjs/core/Meshes/Builders/sphereBuilder';
import '@babylonjs/core/Meshes/Builders/boxBuilder';
import '@babylonjs/core/Meshes/Builders/groundBuilder';
// babylon imports to be able to load objects
import '@babylonjs/loaders/glTF/1.0/glTFLoader';
import '@babylonjs/loaders/glTF/2.0/glTFLoader';

// importing the different function classes
import {
  ChangeCameraTarget,
  CreatePlaneAndCS,
  CreateLineAxese,
  CreateGroundGrid,
  SetCameraToScene,
  SetSceneColor,
  AvatarReferenceManager
} from '@hooks/Scene setup/index';

import {
  HandleClickMesh,
  MeshClickSimulator,
  DeleteMeshConfirm,
  MeshCreator,
  ShadowPlaneCreator,
  LoadingSpheresCreator,
  MeshInputHandler,
  HandelIsolationAndEdit,
  MediaInputHandler
} from '@hooks/Mesh/index';

import { CheckForLogin } from '@hooks/General/index';

import { HandleCameraState, FileLoader, SceneDownloader } from '@hooks/Scene/index';

import VisualLog from '@components/Error logger/VisualLog';

import ConfirmName from '@components/Modal windows/Modal window - name scene/ConfirmName';

import { useParams } from 'react-router-dom';

import { useSessionStorage } from '@hooks/General';

import ModelLibrary from '@pages/user/editor/Modal windows/Model library/ModelLibrary';

import PictureLibrary from '@pages/user/editor/Modal windows/Media library/PictureLibrary';

import VideoLibrary from '@pages/user/editor/Modal windows/Media library/VideoLibrary';

/* ----------------------------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------------------------------------- */

const Editor = () => {
  // get the file in input from the manager page
  const { file } = useParams();
  // console.log(file);

  const handleSceneEdit = (sceneid) => {
    window.location.assign(`/editor/${sceneid}`);
  };

  useEffect(() => {
    const confirmExit = (event) => {
      // Cancel the event as returning a string will prompt a confirmation dialog
      event.preventDefault();
      // Chrome requires returnValue to be set
      event.returnValue = '';
      // Prompt user with a confirmation dialog
      const message = 'Are you sure you want to leave?'; // Your confirmation message
      event.returnValue = message; // For Chrome
      return message; // For other browsers
    };

    // Add event listener for beforeunload event
    // window.addEventListener('beforeunload', confirmExit);

    // Cleanup by removing the event listener when component unmounts
    return () => {
      window.removeEventListener('beforeunload', confirmExit);
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount

  /* true if there is a file sent from the manager
  let loadingNewScene;

  if (file) {
    loadingNewScene = true;
  } else {
    loadingNewScene = false;
  } */

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  // To prevent the methods to be called twice
  // eslint-disable-next-line no-unused-vars
  const MemoizedReferenceToolbar = React.memo(ReferenceToolbar);

  // sets the coordinate system path
  const Public_URL = 'src/assets/models/';

  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [importLoading, setImportLoading] = useState(false);

  const debug = false;
  if (debug) {
    // check at the beginning if the user was already logged in
    const route = { equal: false, route: 'http://localhost:3000/login' };
    CheckForLogin(route);
  }

  // setting a time out for 4 or 6 seconds to let the page load before being able to use it
  useEffect(() => {
    let loadingTimer;
    let importingTimer;

    if (file === 'new') {
      // If there's no file, just set setIsLoading to false after 4 seconds
      loadingTimer = setTimeout(() => {
        setImportLoading(false);
        setIsLoading(false);
      }, 4000);
    } else {
      console.log(file);
      // If there's a file, start loading right away setImportLoading to false after 2 seconds
      importingTimer = setTimeout(() => {
        setImportLoading(true);
      }, 1000);

      // If there's a file, set setIsLoading to false after 6 seconds
      loadingTimer = setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    }

    return () => {
      clearTimeout(loadingTimer);
      clearTimeout(importingTimer); // Clean up both timers on component unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // enables gizmo states
  const [gizmoManager, setGizmoManager] = useState(null);
  const [enablePositionGizmo, setEnablePositionGizmo] = useState(false);
  const [enableRotationGizmo, setEnableRotationGizmo] = useState(false);
  const [enableScaleGizmo, setEnableScaleGizmo] = useState(false);

  // State for toggling the Avatar
  const [enableAvatar, setEnableAvatar] = useState(false);

  // State for toggling the save
  const [enableSave, setEnableSave] = useState(false);

  // enables box generation
  const [boxes, setBoxes] = useState([]);
  // enables canvas generation
  const [canvases, setCanvases] = useState([]);
  // enables box generation
  const [spheres, setSpheres] = useState([]);
  // enables box generation
  const [cylinders, setCylinders] = useState([]);
  // enables mesh generation
  const [meshes, setMeshes] = useState([]);
  // Add a new state to keep track of the currently selected mesh
  const [selectedMesh, setSelectedMesh] = useState(null);
  // enables plane generation
  const [planes, setPlane] = useState([]);

  // shared gizmoState between the editor script and the objectToolbar
  // eslint-disable-next-line prefer-const
  const [gizmoState, setGizmoState] = useState('default');

  // Define a state letiable for scene
  // const [sceneInstance, setSceneInstance] = useState(null);
  const sceneInstance = useRef();

  // Define a state letiable for scene
  // const [canvasInstance, setCanvasInstance] = useState(null);
  const canvasInstance = useRef();

  // Define a state letiable for scene
  const [toolbarState, setToolbarState] = useState('default');
  // Define a state letiable for scene
  const [onlyDelete, setOnlyDelete] = useState(false);
  // Define a state letiable for scene for the previous toolbar
  const [previousToolbarState, setPreviousToolbarState] = useState('default');

  // Define a state letiable for scene confirmRemove
  const [confirmRemove, setConfirmRemove] = useState(false);
  // Define a state letiable for scene confirmPlane
  const [confirmPlane, setConfirmPlane] = useState(false);

  // to reference the clicked meshes
  const handleMeshClickRef = useRef();

  // To pass to the objectselection to disable the delete button
  const nothingSelectedRef = useRef();

  // To toggle the "are you sure to leave without saving?"
  const [leaveWithoutSave, setLeaveWithoutSave] = useState(false);

  // background color: either blue or baige or white
  const backgroundColor = 'white';
  const colorX = 246;
  const colorY = 251;
  const colorZ = 254;

  // for the new layer mask
  const C1M = 1;
  const C2M = 10;

  // The camera instance to call the change camera target outside the on scene ready
  // Define a state letiable for scene
  const cameraInstance = useRef(null);

  // Defines the state of the orthographic camera
  const [isOrthographic, setIsOrthographic] = useState(false);
  // To pass to the objectselection to disable the delete button
  const isOrthographicRef = useRef();

  // 10 is equal to 180cm
  // eslint-disable-next-line no-unused-vars
  // const manHeight = 10; // will need to use it later on to determine the scaled height

  const lastMeshWithBehaviors = useRef(null); // Outside the handleMeshClick function

  // To be able to call the right click menu only if we click a pickable mesh
  const pickedMeshRef = useRef(null);

  const descriptionObjectRef = useRef({});

  // To check when to remove the contextMenu
  const contextMenuRef = useRef(null);

  // Create a highlight layer
  // eslint-disable-next-line prefer-const
  let highlightedMesh = null;

  // Creating the remove highlight method reference
  const removeHighlightRef = useRef(null);

  // eslint-disable-next-line prefer-const, react-hooks/exhaustive-deps
  // to store original materials
  const [originalMaterialsState, setOriginalMaterialsState] = useState(new Map());

  // the familyId array to store the id of all the objects, this way we can pick the entire family's objects
  // eslint-disable-next-line prefer-const
  let familyIDs = [];

  // Handels the sectiontoolbar state
  const [sectionToolbarState, setSectionToolbarState] = useState(false);
  // Handels the sectionToolbarState ref
  const sectionToolbarStateRef = useRef(sectionToolbarState);

  // This is the ref of the Empty object that stores the mesh tree of the isolated object
  const emptyMeshTreeRef = useRef({
    id: null,
    name: null,
    children: []
  });

  const camera2instance = useRef(null);

  // cannot create a separate script otherwise it won't work
  // Function to update the viewport size based on fixed pixel dimensions
  function updateViewportSize(camera, pixelWidth, pixelHeight) {
    const engine = camera.getScene().getEngine();
    const canvas = engine.getRenderingCanvas();

    // Calculate the fractions of the canvas width and height
    const widthFraction = pixelWidth / canvas.width;
    const heightFraction = pixelHeight / canvas.height;

    // Calculate the top-left corner position based on the desired location
    // Here, we position the viewport in the top-right corner
    const xFraction = 1 - widthFraction;
    const yFraction = 1 - heightFraction;

    // Set the camera's viewport using the fractions
    camera.viewport = new Viewport(xFraction, yFraction, widthFraction, heightFraction);
  }

  // To import the meshes later on
  // eslint-disable-next-line prefer-const
  const importableMeshes = useRef([]);

  // highlight layer instance
  // const highlightLayerInstance = useRef(null);

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  const [logMessage, setLogMessage] = useState('');

  // eslint-disable-next-line no-unused-vars
  const triggerLog = (message) => {
    setLogMessage(message);
    setTimeout(() => setLogMessage(''), 10000); // Hide after 10 seconds
  };

  // triggerLog('This is a very very very very very very long log message!');

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  const getSessionData = useSessionStorage('sessionData', 'get');
  const userDataStore = useSessionStorage('userData', 'get');

  // setting the scene with all the necessary static tools
  const onSceneReady = async (scene) => {
    // To refocus the camera on the selected target if the user keeps the mouse clicked for more than 4 seconds on an object
    let mouseDownTimeout = null;
    let currentPickedMesh = null;
    // eslint-disable-next-line no-unused-vars
    let lastPickedMesh = null;

    /* ----------------------------------------------------------------------------------------------------------------------------------- */
    /* ----------------------------------------------------------------------------------------------------------------------------------- */

    const cameraManagerInstance = new SetCameraToScene(scene, C1M, canvasInstance, cameraInstance);
    const camera = cameraManagerInstance.getCamera();
    // cameraInstance.current = camera; // not needed as it already happens inside the class
    const canvas = cameraManagerInstance.getCanvas();

    // Method to call the camera new target
    const cameraTarget = new ChangeCameraTarget(camera, scene);
    cameraTarget.changeTarget(currentPickedMesh);

    /* ----------------------------------------------------------------------------------------------------------------------------------- */
    /* ----------------------------------------------------------------------------------------------------------------------------------- */

    scene.onPointerObservable.add((pointerInfo) => {
      // Check if the left mouse button is pressed
      if (pointerInfo.event.button !== 0) {
        return;
      }

      switch (pointerInfo.type) {
        case PointerEventTypes.POINTERDOWN:
          // Create a ray from the camera to the pointer position
          // eslint-disable-next-line no-case-declarations
          const ray = scene.createPickingRay(scene.pointerX, scene.pointerY, Matrix.Identity(), camera, false);

          // Perform a picking operation
          // eslint-disable-next-line no-case-declarations
          const hit = scene.pickWithRay(ray);

          // Record the mesh that was clicked
          lastPickedMesh = hit.pickedMesh;
          currentPickedMesh = hit.pickedMesh;

          // Start a timeout that will fire after 2 seconds
          mouseDownTimeout = setTimeout(function () {
            cameraTarget.changeTarget(currentPickedMesh);
          }, 1000);
          break;
        case PointerEventTypes.POINTERUP:
          // Clear the timeout if the mouse button was released
          clearTimeout(mouseDownTimeout);

          // Reset the mesh that was clicked
          lastPickedMesh = null;
          break;
        default:
          break;
      }
    });

    /* ----------------------------------------------------------------------------------------------------------------------------------- */
    /* ----------------------------------------------------------------------------------------------------------------------------------- */

    // sets the color of the scene
    // eslint-disable-next-line no-unused-vars
    const sceneConfig = new SetSceneColor(scene, backgroundColor, colorX, colorY, colorZ);

    /* ----------------------------------------------------------------------------------------------------------------------------------- */
    /* ----------------------------------------------------------------------------------------------------------------------------------- */

    // Create a new canvas element
    const camera2 = new ArcRotateCamera('CAM2', Math.PI / 2, Math.PI / 4, 5, new Vector3(0, 0, 0), scene);
    camera2.layerMask = C2M;
    // camera2.viewport = new Viewport(0.7, 0.7, 0.4, 0.3); // top right corner; lower left corner would be 0 0 0.3 0.3
    camera2.metadata = { editor: true };

    camera2instance.current = camera2;

    // Set the initial viewport size
    updateViewportSize(camera2, 340, 240);

    /* ----------------------------------------------------------------------------------------------------------------------------------- */
    /* ----------------------------------------------------------------------------------------------------------------------------------- */

    // Creating the top left corner orthographic cube and the reference to the coordinate system
    const planeAndCSManager = new CreatePlaneAndCS(
      scene,
      C2M,
      cameraStateManager.orthographicCamera,
      Public_URL,
      camera2,
      getSessionData
    );
    planeAndCSManager.initialize();

    /* ----------------------------------------------------------------------------------------------------------------------------------- */
    /* ----------------------------------------------------------------------------------------------------------------------------------- */

    scene.registerBeforeRender(function () {
      camera2.alpha = camera.alpha;
      camera2.beta = camera.beta;
    });

    // Changes the active camera in which i can click
    canvas.addEventListener('pointermove', function (event) {
      const thresholdWidth = canvas.width * 0.3; // 20% of the canvas's width
      const thresholdHeight = canvas.height * 0.3; // 30% of the canvas's height

      const startX = canvas.width; // Begin at the right edge minus 20% of the canvas's width
      const startY = 0; // Begin at the top edge

      const endX = startX + thresholdWidth; // End at the right edge
      const endY = startY + thresholdHeight; // Extend 30% downwards from the top

      if (event.clientX > startX && event.clientX < endX && event.clientY > startY && event.clientY < endY) {
        // console.log('camera2');
        scene.cameraToUseForPointers = camera2;
      } else {
        // console.log('camera');
        scene.cameraToUseForPointers = camera;
      }
    });

    scene.activeCameras.push(camera);
    scene.activeCameras.push(camera2);

    /* ----------------------------------------------------------------------------------------------------------------------------------- */
    /* ----------------------------------------------------------------------------------------------------------------------------------- */

    // Creating the origin axese in the editor
    const axeseManager = new CreateLineAxese(scene);
    axeseManager.createLineAxes(C1M);

    /* ----------------------------------------------------------------------------------------------------------------------------------- */
    /* ----------------------------------------------------------------------------------------------------------------------------------- */

    // Create grid mesh
    // eslint-disable-next-line no-unused-vars
    const gridManager = new CreateGroundGrid(scene, C1M, backgroundColor, colorX, colorY, colorZ);

    /* ----------------------------------------------------------------------------------------------------------------------------------- */
    /* ----------------------------------------------------------------------------------------------------------------------------------- */

    // Configuration for the gizmo handler
    const config = {
      setGizmoManager,
      setSelectedMesh,
      setToolbarState,
      disableAllGizmo,
      setOnlyDelete,
      enablePositionGizmo,
      enableRotationGizmo,
      enableScaleGizmo,
      nothingSelectedRef,
      handleMeshClickRef,
      removeHighlightRef,
      highlightedMesh,
      lastMeshWithBehaviors,
      sectionToolbarStateRef
    };

    // eslint-disable-next-line no-unused-vars
    const gizmoHandler = new HandleClickMesh(scene, camera, config);

    /* ----------------------------------------------------------------------------------------------------------------------------------- */
    /* ----------------------------------------------------------------------------------------------------------------------------------- */

    // Add a pointer down event listener to the scene's canvas
    scene.onPointerObservable.add((pointerInfo) => {
      switch (pointerInfo.type) {
        case PointerEventTypes.POINTERDOWN:
          // Create a ray from the camera to the pointer position
          // eslint-disable-next-line no-case-declarations
          const ray = scene.createPickingRay(scene.pointerX, scene.pointerY, Matrix.Identity(), camera, false);

          // Perform a picking operation
          // eslint-disable-next-line no-case-declarations
          const hit = scene.pickWithRay(ray);

          currentPickedMesh = hit.pickedMesh;

          // eslint-disable-next-line no-case-declarations
          const pickedMesh = pointerInfo.pickInfo.pickedMesh;

          // To be able to call the reight menu on the picked mesh only
          if (
            pickedMesh &&
            pickedMesh.metadata &&
            pickedMesh.metadata.canToggleGizmo &&
            isNotIsolationref &&
            isNotIsolationref.current // This makes sure that when i call the transparency method the only way to right click on another object is if i exit the edit
          ) {
            if (pickedMesh.metadata.clone) {
              // The mesh has a parent, we need to toggle that objects gizmo
              // console.log('called here');
              pickedMeshRef.current = pickedMesh.metadata.clone;
              // console.log('here');
            } else {
              // The mesh doesn't have a parent, use that mesh
              pickedMeshRef.current = pickedMesh;
              // console.log('called here 2');
              // console.log('here 2');
            }
          } else {
            if (!sectionToolbarStateRef.current) {
              pickedMeshRef.current = null;
              // console.log('called here 3');
            }
          }

          // Remove behaviors from the last mesh that had them
          if (lastMeshWithBehaviors.current) {
            lastMeshWithBehaviors.current.removeBehavior(gizmoHandler.dragBehaviorX);
            lastMeshWithBehaviors.current.removeBehavior(gizmoHandler.dragBehaviorZ);

            // Reset the reference
            lastMeshWithBehaviors.current = null;
          }

          // Ensure only left mouse button was clicked
          if (pointerInfo.event.button === 0) {
            // This is called only if the noIsolation method is not called
            // eslint-disable-next-line no-constant-condition
            if (true /* isNotIsolationref && isNotIsolationref.current */) {
              // Call the new function to handle the click
              gizmoHandler.handleMeshClick(pickedMesh);
            }
          }

          break;
        default:
          break;
      }
    });

    /* ----------------------------------------------------------------------------------------------------------------------------------- */
    /* ----------------------------------------------------------------------------------------------------------------------------------- */

    scene.registerBeforeRender(function () {
      // Check if gizmoManager is not null
      if (gizmoManager && gizmoManager.gizmos.positionGizmo) {
        // console.log('i can access this');
        // Check the mesh that the gizmo is attached to
        // eslint-disable-next-line prefer-const
        let mesh = gizmoManager.gizmos.positionGizmo.attachedMesh;

        // If there's a mesh and it has metadata
        if (mesh && mesh.metadata) {
          // Check if the position, rotation, or scale has changed
          if (
            !mesh.position.equals(mesh.metadata.position) ||
            !mesh.rotation.equals(mesh.metadata.rotation) ||
            !mesh.scaling.equals(mesh.metadata.scale)
          ) {
            // Update and log object's position, rotation, and scale
            mesh.metadata.position = mesh.position.clone();
            mesh.metadata.rotation = mesh.rotation.clone();
            mesh.metadata.scale = mesh.scaling.clone();

            console.log('Metadata Position: ', mesh.metadata.position);
            console.log('Metadata Rotation: ', mesh.metadata.rotation);
            console.log('Metadata Scale: ', mesh.metadata.scale);
          }
        }
      }
    });

    /* ----------------------------------------------------------------------------------------------------------------------------------- */
    /* ----------------------------------------------------------------------------------------------------------------------------------- */

    // In the onSceneReady function to cancel the shadow if an object goes below position.y = 0:
    scene.registerBeforeRender(function () {
      scene.meshes.forEach((mesh) => {
        // Check if the mesh has a shadow plane and if the shadow plane has a material
        if (mesh.metadata && mesh.metadata.shadowPlane && mesh.metadata.shadowPlane.material) {
          // Calculate the difference between the initial and current Y position
          // eslint-disable-next-line prefer-const
          let deltaY = mesh.position.y - mesh.metadata.shadowPlane.initialY;

          // Use this difference to calculate a new opacity
          // eslint-disable-next-line prefer-const
          let newOpacity = Math.max(0, 1 - deltaY / 10); // Decrease opacity as the object moves up

          // Apply the new opacity to the shadow plane's material
          mesh.metadata.shadowPlane.material.alpha = newOpacity * 0.05;

          // Hide shadow plane if mesh is below y=0
          if (mesh.position.y < 0) {
            mesh.metadata.shadowPlane.setEnabled(false);
          } else {
            mesh.metadata.shadowPlane.setEnabled(true);
          }

          // Update the position of the shadow plane to match the object's X and Z coordinates
          mesh.metadata.shadowPlane.position.x = mesh.position.x;
          mesh.metadata.shadowPlane.position.z = mesh.position.z;

          // Update the rotation of the shadow plane to match the object's rotation
          // maintain x rotation stable, only change y and z rotations
          mesh.metadata.shadowPlane.rotation.y = mesh.rotation.y;
          mesh.metadata.shadowPlane.rotation.z = mesh.rotation.z;
        }
      });
    });

    // Save the scene instance to state
    sceneInstance.current = scene;
  };

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  // Update the viewport size when the canvas is resized
  window.addEventListener('resize', () => {
    updateViewportSize(camera2instance.current, 340, 240);
  });

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  // Debug logs
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  useEffect(() => {
    if (pickedMeshRef.current) {
      // console.log('Mesh Rotation: ', pickedMeshRef.current.rotation);
      // console.log('Mesh Quaternion Rotation: ', pickedMeshRef.current.rotationQuaternion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickedMeshRef.current]);

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  // Also, you may want to call updateViewportSize in the engine's runRenderLoop
  /* to ensure the viewport is updated every frame (useful if the canvas size can change programmatically)
  engine.runRenderLoop(() => {
    updateViewportSize(camera2instance.current, 400, 300);
    sceneInstance.current.render();
  }); */

  /**
   * Will run on every frame render.  We are spinning the box on y-axis.
   */
  const onRender = (scene) => {};

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  // Update your useEffect where you're enabling the gizmos
  useEffect(() => {
    if (gizmoManager) {
      // Check if a mesh is selected and if it can toggle gizmo
      if (selectedMesh && selectedMesh.metadata && selectedMesh.metadata.canToggleGizmo) {
        gizmoManager.positionGizmoEnabled = enablePositionGizmo;
        gizmoManager.rotationGizmoEnabled = enableRotationGizmo;
        gizmoManager.scaleGizmoEnabled = enableScaleGizmo;
      } else {
        // No mesh is selected or the selected mesh cannot toggle gizmo, disable all gizmos
        gizmoManager.positionGizmoEnabled = false;
        gizmoManager.rotationGizmoEnabled = false;
        gizmoManager.scaleGizmoEnabled = false;
      }
    }
  }, [gizmoManager, selectedMesh, enablePositionGizmo, enableRotationGizmo, enableScaleGizmo]);

  const positionGizmo = () => {
    // console.log('position');
    // eslint-disable-next-line no-constant-condition
    if (true /* isNotIsolationref.current */) {
      setEnablePositionGizmo(!enablePositionGizmo);
      setEnableRotationGizmo(false);
      setEnableScaleGizmo(false);

      // disables the icon select
      if (enablePositionGizmo === false) {
        setGizmoState('move');
      } else {
        setGizmoState('default');
      }
    }
  };

  const rotationGizmo = () => {
    // console.log('rotation');

    setEnablePositionGizmo(false);
    setEnableRotationGizmo(!enableRotationGizmo);
    setEnableScaleGizmo(false);

    // disables the icon select
    if (enableRotationGizmo === false) {
      setGizmoState('rotate');
    } else {
      setGizmoState('default');
    }
  };

  const scaleGizmo = () => {
    // console.log('scale');

    setEnablePositionGizmo(false);
    setEnableRotationGizmo(false);
    setEnableScaleGizmo(!enableScaleGizmo);

    // disables the icon select
    if (enableScaleGizmo === false) {
      setGizmoState('scale');
    } else {
      setGizmoState('default');
    }
  };

  const disableAllGizmo = () => {
    // console.log('disable');

    setEnablePositionGizmo(false);
    setEnableRotationGizmo(false);
    setEnableScaleGizmo(false);

    // disables the icon select
    setGizmoState('default');
  };

  // Method to change the toolbar
  const changeToolbar = (name) => {
    setToolbarState(name);
    setPreviousToolbarState(name);
  };

  const previousToolbarStateRef = useRef(previousToolbarState);

  // now you can use previousToolbarStateRef.current to get the latest value of previousToolbarState
  useEffect(() => {
    // console.log(previousToolbarState);
    previousToolbarStateRef.current = previousToolbarState;
  }, [previousToolbarState]);

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  // toggle the context menu
  const hideContextMenu = () => {
    setShowMenu(false);
    document.removeEventListener('click', handleClick);
  };

  const handleClick = (event) => {
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
      hideContextMenu();
    }
  };

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  // useEffect to log the value of pickedMeshRef.current
  useEffect(() => {
    // console.log(sectionToolbarState);
    sectionToolbarStateRef.current = sectionToolbarState;

    if (!sectionToolbarStateRef.current) {
      pickedMeshRef.current = selectedMesh;
      // console.log('called here 4');
    } else {
      // console.log(pickedMeshRef.current);
    }
    // console.log('PickedMesh changed: ', pickedMeshRef.current ? pickedMeshRef.current.uniqueId : null);
    // console.log will only trigger when sectionToolbarState is false
    // console.log('PickedMesh changed: ', pickedMeshRef.current ? pickedMeshRef.current.uniqueId : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMesh, sectionToolbarState, sectionToolbarStateRef.current]); // Dependency on the state, not the ref directly

  const handelSectionToolbar = () => {
    setSectionToolbarState(!sectionToolbarState);
    disableAllGizmo();
    changeToolbar('Section');

    removeHighlightRef.current();
  };

  // Toggle if the delete is being called from the context menu
  const [context, setContext] = useState(false);

  // function to delete objects
  // Toggle the confirm remove modal window
  const DeleteConfirm = () => {
    if (selectedMesh) {
      setContext(false);
      setConfirmRemove(!confirmRemove);
      hideContextMenu();
    }
  };

  // toggle delete confirm remove modal window from the context menu
  const DeleteConfirmContext = () => {
    if (pickedMeshRef.current) {
      setContext(true);
      setConfirmRemove(!confirmRemove);
      hideContextMenu();
      setContext(false);
    }
  };

  // initiates the DeleteMeshConfirm class in order to call it in the rendered button
  const meshDelete = new DeleteMeshConfirm(
    setBoxes,
    setCanvases,
    setSpheres,
    setCylinders,
    setMeshes,
    setToolbarState,
    setSelectedMesh,
    disableAllGizmo,
    DeleteConfirm,
    previousToolbarStateRef,
    sceneInstance,
    importableMeshes.current
  );

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  // The load mesh method is below in the scale reference section
  const [loadingMesh, setLoadingMesh] = useState(false);
  const loadingMeshRef = useRef(loadingMesh);

  // create loading spheres
  let intervalID; // Save the interval ID here

  // Declare state letiable to hold the loading spheres
  const [loadingSpheres, setLoadingSpheres] = useState([]);

  // now you can use previousToolbarStateRef.current to get the latest value of previousToolbarState
  useEffect(() => {
    // console.log(previousToolbarState);
    loadingMeshRef.current = loadingMesh;
  }, [loadingMesh]);

  const numSpheres = 60; // Number of spheres

  const loadingSpheresCreator = new LoadingSpheresCreator(
    sceneInstance,
    setLoadingMesh,
    setLoadingSpheres,
    C1M,
    loadingMeshRef,
    numSpheres,
    intervalID,
    loadingSpheres,
    spheres
  );

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  // Create a Map to store the shadow planes
  // eslint-disable-next-line prefer-const
  let shadowPlanes = new Map();

  const shadowPlaneCreator = new ShadowPlaneCreator(sceneInstance, C1M, shadowPlanes);

  // Toggle the confirm remove modal window
  const planeConfirm = () => {
    setConfirmPlane(!confirmPlane);
  };

  // simulates a click in order to re-toggle the camera target
  const clickSimulator = new MeshClickSimulator(sceneInstance, cameraInstance);

  const meshCreator = new MeshCreator(
    sceneInstance,
    setBoxes,
    setCanvases,
    setSpheres,
    setCylinders,
    setPlane,
    clickSimulator,
    (mesh) => shadowPlaneCreator.create(mesh),
    C1M,
    planeConfirm,
    familyIDs,
    loadingSpheresCreator,
    setMeshes,
    importableMeshes.current,
    canvases
  );

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  // method to pass to the general toolbar
  const toggleReference = () => {
    if (!enableAvatar) {
      changeToolbar('reference');
    } else {
      setEnableAvatar((prevEnableAvatar) => {
        // The callback function ensures that the latest state is being used
        if (prevEnableAvatar) {
          disposeReference();
        }
        return !prevEnableAvatar;
      });
    }
  };

  // Define the asynchronous function to change the state and then execute the callback
  // To prevent the method to be called twice
  // eslint-disable-next-line no-unused-vars
  let isMethodExecuting = false;
  /* Function to toggle the state of enableAvatar
  const [avatarName, setAvatarName] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggleAvatar = (name) => {
    if (isMethodExecuting) {
      return;
    }
    setEnableAvatar((prevEnableAvatar) => !prevEnableAvatar);
    setAvatarName(name);
  }; */

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const avatarReference = new AvatarReferenceManager(
    sceneInstance,
    C1M,
    loadingSpheresCreator,
    clickSimulator,
    Public_URL,
    cameraInstance,
    getSessionData
  );

  /* useEffect(() => {
    async function fetchData(enableAvatar, avatarName) {
      if (enableAvatar && avatarName) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        isMethodExecuting = true;
        // console.log('here');
        // This was with the toggle reference button
        await avatarReference.scaleReferences(avatarName);
        changeToolbar('default');
        isMethodExecuting = false;
      }
    }

    fetchData(enableAvatar, avatarName);
  }, [enableAvatar, avatarName]); */

  useEffect(() => {
    async function fetchData() {
      if (avatarReference) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        isMethodExecuting = true;
        // console.log('here');
        // This was with the toggle reference button
        await avatarReference.humanReferences();

        console.log('Initiated avatar');

        isMethodExecuting = false;
      }
    }

    fetchData();
  }, []);

  // Dispose the scale reference
  const disposeReference = () => {
    // eslint-disable-next-line prefer-const
    let mesh = sceneInstance.current.getMeshByName('referenceBox');
    if (mesh) {
      mesh.dispose();
      setSelectedMesh(null);
      disableAllGizmo();
    }
  };

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  // Method to return to the manager page
  const headBackToManager = () => {
    // eslint-disable-next-line no-constant-condition
    if (enableSave) {
      setIsLoading(true);
      window.location.assign('/user/');
    } else {
      setLeaveWithoutSave(!leaveWithoutSave);
    }
  };

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  // Handels the input of media files
  const handleMediaManager = new MediaInputHandler(userDataStore, getSessionData);

  // Handles the input of the glb file
  const handleInputManager = new MeshInputHandler(
    sceneInstance,
    loadingSpheresCreator,
    shadowPlaneCreator,
    clickSimulator,
    C1M,
    setMeshes,
    familyIDs,
    meshes,
    importableMeshes.current,
    userDataStore,
    getSessionData
  );

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  // calling the HandleCamerState in order to be able to toggle the orthographic camera
  const cameraStateManager = new HandleCameraState(
    cameraInstance,
    canvasInstance,
    sceneInstance,
    setIsOrthographic,
    isOrthographic
  );

  // now you can use previousToolbarStateRef.current to get the latest value of previousToolbarState
  useEffect(() => {
    // console.log(previousToolbarState);
    isOrthographicRef.current = isOrthographic;
  }, [isOrthographic]);

  // method to change back the target to the origin
  const backToOrigin = () => {
    if (sceneInstance.current) {
      clickSimulator._newCameraTarget();
    }
  };

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  // Sets the right click menu
  const [menuPosition, setMenuPosition] = useState({ x: '0px', y: '0px' });

  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();

      if (pickedMeshRef.current && !sectionToolbarStateRef.current) {
        setMenuPosition({
          x: `${event.pageX}px`,
          y: `${event.pageY}px`
        });
        setShowMenu(true);
        // Add the click listener when context menu is shown
        document.addEventListener('click', handleClick);
      } else {
        setShowMenu(false);
        document.removeEventListener('click', handleClick);
      }
    };

    // Attach the right-click event to the Babylon canvas
    canvasInstance.current.addEventListener('contextmenu', handleContextMenu);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      canvasInstance.current.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('click', handleClick);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  const [isTransparent, setIsTransparent] = useState(false); // flag to track transparency state
  const isTransparentRef = useRef(isTransparent);

  // now you can use previousToolbarStateRef.current to get the latest value of previousToolbarState
  useEffect(() => {
    // console.log(previousToolbarState);
    isTransparentRef.current = isTransparent;
  }, [isTransparent]);

  // To be able to disable all of the gizmos and isolate the object
  const [noIsolation, setIsolation] = useState(true);
  const isNotIsolationref = useRef(noIsolation);

  // now you can use previousToolbarStateRef.current to get the latest value of previousToolbarState
  useEffect(() => {
    // console.log(previousToolbarState);
    isNotIsolationref.current = noIsolation;
  }, [noIsolation]);

  const isolationManager = new HandelIsolationAndEdit(
    sceneInstance,
    setIsTransparent,
    setIsolation,
    disableAllGizmo,
    changeToolbar,
    hideContextMenu,
    removeHighlightRef,
    originalMaterialsState,
    setOriginalMaterialsState,
    isNotIsolationref,
    isTransparent,
    pickedMeshRef,
    emptyMeshTreeRef
  );

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  const [isNameModalVisible, setIsNameModalVisible] = useState(false);
  const [isVRCommand, setIsVRCommand] = useState(false);

  const downloader = new SceneDownloader(
    importableMeshes.current,
    sceneInstance.current,
    userDataStore,
    getSessionData,
    handleSceneEdit,
    file,
    setIsLoading
  );
  // Method to toggle the Save state
  const toggleSave = () => {
    setIsNameModalVisible(!isNameModalVisible);
  };

  const toggleCommands = () => {
    setIsVRCommand(!isVRCommand);
  };

  const confirmSave = (name) => {
    downloader.sceneJson(name, descriptionObjectRef.current);
    setIsNameModalVisible(false);
    setEnableSave(true);
  };

  // setting a time out for 4 or 6 seconds to let the page load before being able to use it
  useEffect(() => {
    let loadingTimer;

    if (enableSave) {
      // If there's a file, start loading right away setImportLoading to false after 2 seconds
      loadingTimer = setTimeout(() => {
        setEnableSave(false);
      }, 20000);
    }

    return () => {
      clearTimeout(loadingTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableSave]);

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  const fileLoaderManager = new FileLoader(
    sceneInstance,
    C1M,
    familyIDs,
    shadowPlanes,
    loadingSpheresCreator,
    setBoxes,
    setCanvases,
    setSpheres,
    setCylinders,
    (mesh) => shadowPlaneCreator.create(mesh),
    boxes,
    canvases,
    spheres,
    cylinders,
    meshes,
    importableMeshes.current,
    setMeshes,
    descriptionObjectRef,
    userDataStore,
    getSessionData
  );

  useEffect(() => {
    async function instantiateScene() {
      if (file !== 'new') {
        if (importLoading) {
          if (sceneInstance.current) {
            sceneInstance.current.onReadyObservable.addOnce(async () => {
              await fileLoaderManager.importScene(file);
            });
          }
        }
      }
    }

    instantiateScene();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importLoading]);

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  // Assuming `isLoading` is a state or prop that gets passed to this component
  // eslint-disable-next-line no-unused-vars
  function VoiceflowWidget() {
    useEffect(() => {
      // Only run the code when `isLoading` is false
      if (!isLoading) {
        const script = document.createElement('script');
        script.src = 'https://cdn.voiceflow.com/widget/bundle.mjs';
        script.type = 'module'; // Assuming the script is an ES module
        script.async = true; // To load it asynchronously
        script.onload = () => {
          window.voiceflow.chat.load({
            verify: { projectID: '65bb71ee8867b45c58aa1452' },
            url: 'https://general-runtime.voiceflow.com',
            versionID: 'production'
          });
        };

        document.body.appendChild(script);

        // Cleanup function to remove the script when the component unmounts or `isLoading` changes
        return () => {
          document.body.removeChild(script);
        };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]); // Only re-run the effect if `isLoading` changes

    return null; // This component doesn't render anything itself
  }

  VoiceflowWidget();

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  const [modelLibrary, setModelLibrary] = useState(false);
  const handleModelLibrary = () => {
    setModelLibrary((prev) => !prev);
  };

  const [videoLibrary, setVideoLibrary] = useState(false);
  const handleVideoLibrary = () => {
    setVideoLibrary((prev) => !prev);
  };

  const [pictureLibrary, setPictureLibrary] = useState(false);
  const handlePictureLibrary = () => {
    setPictureLibrary((prev) => !prev);
  };

  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------- */

  return (
    <section className="Editor-page">
      {/* ------------------------------------------------------------------------------------------------------------------- */}
      {isLoading && (
        <div className="Editor-loader-container">
          <PageLoader type="original-l" isImporting={false} />
        </div>
      )}
      {/* ------------------------------------------------------------------------------------------------------------------- */}
      <div>
        <div className="Editor-component-container"></div>
        <SceneComponent
          className="SceneComponent"
          antialias
          onSceneReady={onSceneReady}
          onRender={onRender}
          id="my-canvas"
        />
      </div>
      {/* ------------------------------------------------------------------------------------------------------------------- */}
      {logMessage && !isLoading && <VisualLog message={logMessage} />}
      {/* ------------------------------------------------------------------------------------------------------------------- */}
      {!isLoading && toolbarState === 'objectSelection' && !confirmPlane && (
        <ObjectToolbar
          isMovable={positionGizmo}
          isRotatable={rotationGizmo}
          isScalable={scaleGizmo}
          deleteObject={DeleteConfirm}
          gizmoState={gizmoState}
          addBehaviour={() => changeToolbar('default')}
          sectionToolbar={() => handelSectionToolbar()}
          headBack={() => changeToolbar(previousToolbarStateRef.current)}
          isUnSelected={!nothingSelectedRef.current}
          onlyDelete={onlyDelete}
          isolate={isolationManager.toggleMeshTransparency}
        />
      )}
      {/* ------------------------------------------------------------------------------------------------------------------- */}
      {!isLoading && confirmRemove && !confirmPlane && (
        <ConfirmRemove
          deleteObject={() => meshDelete.deleteSelectedObject(selectedMesh, boxes, spheres, cylinders, meshes)}
          deleteObjectContext={() => meshDelete.deleteSelectedObject(pickedMeshRef, boxes, spheres, cylinders, meshes)}
          cancelAction={DeleteConfirm}
          context={context}
        />
      )}
      {/* ------------------------------------------------------------------------------------------------------------------- */}
      {!isLoading && toolbarState === 'default' && !confirmPlane && (
        <GeneralToolbar
          addModel={() => changeToolbar('model')}
          addEnvironment={() => changeToolbar('environment')}
          addImage={handlePictureLibrary}
          viewList={() => changeToolbar('objectList')}
          addVideo={handleVideoLibrary}
          viewAvatar={toggleReference}
          saveScene={toggleSave}
          isHidden={enableAvatar}
          isSaved={enableSave}
          addCommands={toggleCommands}
          isVRCommand={isVRCommand}
        />
      )}
      {/* ------------------------------------------------------------------------------------------------------------------- */}
      {isNameModalVisible && (
        <ConfirmName
          nameObject={(newName) => {
            confirmSave(newName);
          }}
          cancelAction={() => {
            setIsNameModalVisible(false);
            setEnableSave(false);
          }}
          initialName={'New scene'}
          file={file}
        />
      )}
      {/* ------------------------------------------------------------------------------------------------------------------- */}
      {!isLoading && (
        <DefaultToolbar
          headBack={headBackToManager}
          backToOrigin={backToOrigin}
          orthographicCamera={() => cameraStateManager.orthographicCamera('normal')}
          isOrthographic={isOrthographic}
        />
      )}
      {/* ------------------------------------------------------------------------------------------------------------------- */}
      {!isLoading && toolbarState === 'model' && !confirmPlane && (
        <ModelToolbar
          headBack={() => changeToolbar('default')}
          shapes={() => changeToolbar('defaultShapes')}
          library={handleModelLibrary}
          /* handleFileInput={(e) => handleInputManager.handleFileInput(e)} */
        />
      )}
      {/* ------------------------------------------------------------------------------------------------------------------- */}
      {!isLoading && toolbarState === 'environment' && (
        <EnvironmentToolbar
          headBack={() => changeToolbar('default')}
          /* handleFileInput={(e) => handleInputManager.handleFileInput(e)} */
          /* library={} */
          plane={planeConfirm}
        />
      )}
      {/* ------------------------------------------------------------------------------------------------------------------- */}
      {!isLoading && toolbarState === 'defaultShapes' && !confirmPlane && (
        <DefaultShapeToolbar
          headBack={() => changeToolbar('model')}
          cube={() => meshCreator.createBox(boxes)}
          sphere={() => meshCreator.createSphere(spheres)}
          cylinder={() => meshCreator.createCylinder(cylinders)}
        />
      )}
      {/* ------------------------------------------------------------------------------------------------------------------- }
      {!isLoading && toolbarState === 'reference' && !confirmPlane && (
        <MemoizedReferenceToolbar
          human={() => toggleAvatar('human')}
          hand={() => toggleAvatar('hand')}
          coin={() => toggleAvatar('coin')}
          headBack={() => changeToolbar('default')}
        />
      )}
      { ------------------------------------------------------------------------------------------------------------------- */}
      {!isLoading && showMenu && !confirmRemove && (
        <ContextMenu
          showMenu={showMenu}
          position={menuPosition}
          isolate={isolationManager.toggleMeshTransparency}
          ref={contextMenuRef}
          delete={DeleteConfirmContext}
        />
      )}
      {/* ------------------------------------------------------------------------------------------------------------------- */}
      {!isLoading && !confirmRemove && confirmPlane && (
        <AddPlane addPlane={(x, y) => meshCreator.createPlane(x, y, planes)} cancelAction={planeConfirm} />
      )}
      {/* ------------------------------------------------------------------------------------------------------------------- */}
      {!isLoading && toolbarState === 'objectList' && !confirmPlane && (
        <ObjectListToolbar
          familyIds={familyIDs}
          sceneInstance={sceneInstance}
          headBack={() => changeToolbar('default')}
        />
      )}
      {/* ------------------------------------------------------------------------------------------------------------------- */}
      {!isLoading && toolbarState === 'Isolation' && !confirmPlane && (
        <IsolationToolbar
          stopIsolation={isolationManager.toggleMeshTransparency}
          meshParentTree={emptyMeshTreeRef.current}
          sceneInstance={sceneInstance}
          cameraInstance={cameraInstance}
          highlightedLayer={nothingSelectedRef.current}
          pickedMeshRef={pickedMeshRef}
          descriptionObject={descriptionObjectRef}
        />
      )}
      {/* ------------------------------------------------------------------------------------------------------------------- */}
      {!isLoading && toolbarState === 'Section' && !confirmPlane && (
        <SectionToolbar
          sceneInstance={sceneInstance}
          headBack={() => {
            changeToolbar('default');
            setSectionToolbarState(!sectionToolbarState);
          }}
          pickedMeshRef={pickedMeshRef}
          C1M={C1M}
          CameraTarget={(e) => clickSimulator._newCameraTargetDefault(e)}
        />
      )}
      {/* ------------------------------------------------------------------------------------------------------------------- */}
      {leaveWithoutSave && (
        <ConfirmReturn
          cancelAction={() => setLeaveWithoutSave(!leaveWithoutSave)}
          setLoading={() => setIsLoading(true)}
        />
      )}
      {/* ------------------------------------------------------------------------------------------------------------------- */}
      {!isLoading && !confirmPlane && modelLibrary && (
        <ModelLibrary headBack={handleModelLibrary} inputMeshMethod={handleInputManager.handleFileDownload} />
      )}
      {!isLoading && !confirmPlane && videoLibrary && (
        <VideoLibrary
          headBack={handleVideoLibrary}
          inputMeshMethod={handleMediaManager.handleFileInput}
          addMethod={(type, bucket, key, mediasId, mediaName) =>
            meshCreator.createCanvasBox(type, bucket, key, mediasId, mediaName)
          }
        />
      )}
      {!isLoading && !confirmPlane && pictureLibrary && (
        <PictureLibrary
          headBack={handlePictureLibrary}
          inputMeshMethod={handleMediaManager.handleFileInput}
          addMethod={(type, bucket, key, mediasId, mediaName) =>
            meshCreator.createCanvasBox(type, bucket, key, mediasId, mediaName)
          }
        />
      )}
    </section>
  );
};

export default React.memo(Editor);
