import axios from '@services/axios';

class UserService {
  async logoutUser() {
    const response = await axios.get('/logout');
    return response;
  }

  async checkCurrentUser() {
    const response = await axios.get('/currentuser');
    return response;
  }
}

export const userService = new UserService();
