import { Color4, Color3, HemisphericLight, Vector3 } from '@babylonjs/core';

class SetSceneColor {
  constructor(scene, backgroundColor, colorX, colorY, colorZ) {
    this.scene = scene;
    this.backgroundColor = backgroundColor;
    this.colorX = colorX;
    this.colorY = colorY;
    this.colorZ = colorZ;

    this.configureScene();
  }

  configureScene() {
    this.setSceneColor();
    this.enableFog();
    this.createLight();
  }

  setSceneColor() {
    this.scene.clearColor =
      this.backgroundColor === 'blue'
        ? new Color4(this.colorX / 255, this.colorY / 255, this.colorZ / 255)
        : this.backgroundColor === 'white'
        ? new Color4(1, 1, 1, 1)
        : new Color4(0.87, 0.85, 0.8, 1);
  }

  enableFog() {
    this.scene.fogEnabled = false;
    this.scene.fogMode = 2;
    this.scene.fogColor =
      this.backgroundColor === 'blue'
        ? new Color3(this.colorX / 255, this.colorY / 255, this.colorZ / 255)
        : this.backgroundColor === 'white'
        ? new Color3(255, 255, 255)
        : new Color3(0.87, 0.85, 0.8);
    this.scene.fogDensity = 0.025;
  }

  createLight() {
    const light = new HemisphericLight('light', new Vector3(0, 1, 0), this.scene);
    light.intensity = 1;
  }
}

export default SetSceneColor;
