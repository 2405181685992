import { Color3, Vector3, MeshBuilder } from '@babylonjs/core'; // Ensure the necessary BabylonJS modules are imported.
import { GridMaterial } from '@babylonjs/materials';

class CreateGroundGrid {
  constructor(scene, C1M, backgroundColor, colorX, colorY, colorZ) {
    this.scene = scene;
    this.C1M = C1M;
    this.backgroundColor = backgroundColor;
    this.colorX = colorX;
    this.colorY = colorY;
    this.colorZ = colorZ;

    this.createGround();
  }

  createGround() {
    // Create 'ground' shape
    const gridUp = MeshBuilder.CreateGround('ground', { width: 10000, height: 10000 }, this.scene);
    const gridDown = MeshBuilder.CreateGround('ground', { width: 10000, height: 10000 }, this.scene);

    // Properties
    [gridUp, gridDown].forEach((grid) => {
      grid.isPickable = false;
      grid.layerMask = this.C1M;
      grid.renderingGroupId = 0;
    });

    gridDown.rotate(new Vector3(0, 0, 1), Math.PI);

    // Create Grid Material
    const gridMaterial = this.createGridMaterial();

    // Assign Material to grids
    gridUp.material = gridMaterial;
    gridDown.material = gridMaterial;

    gridUp.metadata = { editor: true };
    gridDown.metadata = { editor: true };
  }

  createGridMaterial() {
    const gridMaterial = new GridMaterial('groundMaterial', this.scene);

    gridMaterial.mainColor = this.getMainColor();
    gridMaterial.lineColor = this.getLineColor();
    gridMaterial.gridRatio = 1;
    gridMaterial.opacity = 0.99;
    gridMaterial.majorUnitFrequency = 10;
    gridMaterial.minorUnitVisibility = 0.55;

    return gridMaterial;
  }

  getMainColor() {
    if (this.backgroundColor === 'blue') {
      return new Color3(this.colorX / 255, this.colorY / 255, this.colorZ / 255);
    } else if (this.backgroundColor === 'white') {
      return new Color3(1, 1, 1);
    } else {
      return new Color3(0.87, 0.85, 0.8);
    }
  }

  getLineColor() {
    if (this.backgroundColor === 'blue' || this.backgroundColor === 'white') {
      return new Color3(150 / 255, 150 / 255, 150 / 255);
    } else {
      return new Color3(1, 1, 1);
    }
  }
}

export default CreateGroundGrid;
