import { useLocalStorage, useSessionStorage, useEffectOnce, CheckForMobile } from '@hooks/General';
import { addUser } from '@redux/reducers/user/user.reducer';
import { userService } from '@services/api/user/user.service';
import { Utils } from '@services/utils/utils.service';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ children }) => {
  const { profile, token } = useSelector((state) => state.user);
  const [userData, setUserData] = useState(null);
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const keepLoggedIn = useLocalStorage('keepLoggedIn', 'get');
  const pageReload = useSessionStorage('pageReload', 'get');
  const [deleteStorageUsername] = useLocalStorage('username', 'delete');
  const [setLoggedIn] = useLocalStorage('keepLoggedIn', 'set');
  const [deleteSessionPageReload] = useSessionStorage('pageReload', 'delete');
  const [deleteSessionData] = useSessionStorage('sessionData', 'delete');
  const [userDataStore] = useSessionStorage('userData', 'set');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobileDevice = CheckForMobile(); // This will automatically handle redirection and detection

  if (isMobileDevice) {
    navigate('/unauthorized');
  }

  const checkUser = useCallback(async () => {
    try {
      const response = await userService.checkCurrentUser();
      console.log(response);
      setUserData(response.data.user);
      userDataStore(response.data.user);
      console.log(response.data.user);
      setTokenIsValid(true);
      dispatch(
        addUser({
          token: response.data.token,
          profile: response.data.user,
          userTokens: response.data.userTokens
        })
      );
    } catch (error) {
      console.error(error);
      setTokenIsValid(false);
      setTimeout(async () => {
        deleteSessionData();
        Utils.clearStore({ dispatch, deleteStorageUsername, deleteSessionPageReload, setLoggedIn });
        try {
          const response = await userService.logoutUser();
          console.log(response);
          navigate('/auth');
        } catch (error) {
          console.error(error);
          navigate('/auth');
        }
      }, 1000);
    }
  }, [
    userDataStore,
    dispatch,
    deleteSessionData,
    deleteStorageUsername,
    deleteSessionPageReload,
    setLoggedIn,
    navigate
  ]);

  useEffectOnce(() => {
    checkUser();
  });

  if (keepLoggedIn || (!keepLoggedIn && userData) || (profile && token) || pageReload) {
    if (!tokenIsValid) {
      return <></>;
    } else {
      return <>{children}</>;
    }
  } else {
    return navigate('/auth');
  }
};
ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired
};

export default ProtectedRoute;
