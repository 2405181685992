import { GetObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import AWSClient from '@services/api/AWS/hooks/AWSClient';

const AWSGetURL = async (awsBucket, awsKey, accelerated) => {
  const client = AWSClient();

  let command;

  if (accelerated) {
    command = new GetObjectCommand({
      Bucket: awsBucket,
      Key: awsKey,
      AccelerationStatus: 'Enabled'
    });
  } else {
    command = new GetObjectCommand({
      Bucket: awsBucket,
      Key: awsKey
    });
  }

  try {
    const objectURL = await getSignedUrl(client, command, { expiresIn: 3600 });

    return objectURL;
  } catch (err) {
    console.error(err);
  }
};

export default AWSGetURL;
