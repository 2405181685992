// ContextMenu.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { MdInfoOutline, MdDeleteOutline } from 'react-icons/md';
import { TbEdit } from 'react-icons/tb';
import { LuCopySlash, LuCopyPlus } from 'react-icons/lu';

import '@pages/user/editor/Tool bars/Right click context menu/ContextMenu.scss';

const ContextMenu = React.forwardRef((props, ref) => {
  // Temporary group and ungroup funciton
  const [group, setGroup] = useState(false);

  const changeGroup = (e) => {
    e.preventDefault();
    setGroup(!group);
  };

  if (!props.showMenu) return null;

  // console.log(position.x + ' ' + position.y);

  return (
    <div
      className={`context-menu ${props.showMenu ? 'active' : ''}`}
      style={{ left: props.position.x, top: props.position.y }}
      ref={ref}
    >
      <ul>
        <li onClick={changeGroup}>
          <div className="ContextMenu-icon">{group ? <LuCopyPlus /> : <LuCopySlash />}</div>
          <div className="ContextMenu-text">{group ? 'Regroup' : 'Ungroup'}</div>
        </li>
        <li
          onClick={() => {
            props.isolate();
          }}
        >
          <div className="ContextMenu-icon">
            <TbEdit />
          </div>
          <div className="ContextMenu-text">Edit</div>
        </li>
        <li>
          <div className="ContextMenu-icon">
            <MdInfoOutline />
          </div>
          <div className="ContextMenu-text">Properties</div>
        </li>
        <li
          onClick={() => {
            props.delete();
          }}
        >
          <div className="ContextMenu-icon">
            <MdDeleteOutline />
          </div>
          <div className="ContextMenu-text">Delete</div>
        </li>
        <li className="inactive"></li>
      </ul>
    </div>
  );
});

ContextMenu.propTypes = {
  showMenu: PropTypes.bool.isRequired,
  position: PropTypes.shape({
    x: PropTypes.string.isRequired,
    y: PropTypes.string.isRequired
  }).isRequired,
  isolate: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired
};

export default ContextMenu;
