import MobileDetect from 'mobile-detect';

import { useSessionStorage } from '@hooks/General';

const CheckForMobile = () => {
  // const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [getDeviceData] = useSessionStorage('deviceData', 'get');
  // eslint-disable-next-line no-unused-vars
  const [setDeviceData] = useSessionStorage('deviceData', 'set');

  if (getDeviceData) {
    if (getDeviceData === 'mobile') {
      console.log('Mobile device');
      return true;
    } else {
      console.log('Desktop device');
      return false;
    }
  } else {
    const userAgent = window.navigator.userAgent;
    console.log(userAgent);
    const md = new MobileDetect(userAgent);
    const isMobileDevice = !!md.mobile(); // Returns 'null' if not a mobile device, a truthy value otherwise

    if (isMobileDevice) {
      console.log('Mobile device');
      setDeviceData('mobile');
    } else {
      console.log('Desktop device');
      setDeviceData('desktop');
    }

    return isMobileDevice;
  }
};

export default CheckForMobile;
