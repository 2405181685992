class MeshHierarchyBuilder {
  // eslint-disable-next-line no-useless-constructor
  constructor() {}

  buildHierarchyTree(mesh) {
    // Create the current node object
    const node = {
      id: mesh.uniqueId,
      name: mesh.name,
      children: []
    };

    // console.log('Node: ', node);

    // If the mesh has children, recursively call buildHierarchyTree for each child
    if (mesh.getChildren && mesh.getChildren().length > 0) {
      mesh.getChildren().forEach((childMesh) => {
        const childNode = this.buildHierarchyTree(childMesh);
        if (childNode) {
          node.children.push(childNode);
        }
      });
    }

    return node;
  }
}

export default MeshHierarchyBuilder;
