import React from 'react';
import PropTypes from 'prop-types';

import { RiApps2Line } from 'react-icons/ri';
import { MdOutlineTripOrigin, MdCamera } from 'react-icons/md';

import '@pages/user/editor/Tool bars/Persistent fixed toolbar/DefaultToolbar.scss';

const DefaultToolbar = ({ headBack, backToOrigin, orthographicCamera, isOrthographic }) => {
  return (
    <>
      <div className="DefaultToolbar-container-left">
        <div className="DefaultToolbar-element" onClick={headBack}>
          <div className="DefaultToolbar-icon">
            <RiApps2Line />
          </div>
          <div className="DefaultToolbar-text">Back to Manager</div>
        </div>

        <div className="DefaultToolbar-divider"></div>
      </div>

      <div className="DefaultToolbar-container-right">
        <div className="DefaultToolbar-element" onClick={backToOrigin}>
          <div className="DefaultToolbar-icon">
            <MdOutlineTripOrigin />
          </div>
          <div className="DefaultToolbar-text">Origin</div>
        </div>
      </div>

      <div className="DefaultToolbar-container-right">
        <div className="DefaultToolbar-element" onClick={orthographicCamera} style={{ marginTop: '200px' }}>
          <div className="DefaultToolbar-icon">
            <MdCamera />
          </div>
          <div className="DefaultToolbar-text">{isOrthographic ? 'Ortho.' : 'Persp.'}</div>
        </div>
      </div>
    </>
  );
};

DefaultToolbar.propTypes = {
  headBack: PropTypes.func.isRequired,
  backToOrigin: PropTypes.func.isRequired,
  orthographicCamera: PropTypes.func.isRequired,
  isOrthographic: PropTypes.bool.isRequired
};

export default DefaultToolbar;
