/* import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Plane } from '@babylonjs/core';

import '@components/Tool bars/Handle section toolbar/SliderToolbar.scss';

const SliderToolbar = ({ limits, cloneMeshRef, newMaterial, sceneInstance, setLimitsRef }) => {
  // console.log('re-rendered in the SliderToolbar');

  const [triggerRerender, setTriggerRerender] = useState(false);

  const [clippingValues, setClippingValues] = useState({
    x: limits.current.xLimitMax,
    y: limits.current.yLimitMax,
    z: limits.current.zLimitMax
  });

  // check to dispose of double created clones
  useEffect(() => {
    const timer = setTimeout(() => {
      setTriggerRerender(true);
      setTriggerRerender(true);

      setClippingValues({
        x: limits.current.xLimitMax,
        y: limits.current.yLimitMax,
        z: limits.current.zLimitMax
      });
    }, 300); // 300 milliseconds delay

    // Cleanup function to clear the timer
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Dependency array

  // console.log(limits);

  // console.log(limits.current);

  /*
  const onXChange = (value) => {
    setClippingValues((prevValues) => ({ ...prevValues, x: value }));

    if (cloneMeshRef.current) {
      const newClippingValues = { ...clippingValues, x: value };

      newMaterial.clipPlane = new Plane(1, 0, 0, -newClippingValues.x);

      cloneMeshRef.current.material = newMaterial;
    }
  };

  const onYChange = (value) => {
    setClippingValues((prevValues) => ({ ...prevValues, y: value }));

    if (cloneMeshRef.current) {
      const newClippingValues = { ...clippingValues, y: value };

      newMaterial.clipPlane2 = new Plane(0, 1, 0, -newClippingValues.y);

      cloneMeshRef.current.material = newMaterial;
    }
  };

  const onZChange = (value) => {
    setClippingValues((prevValues) => ({ ...prevValues, z: value }));

    if (cloneMeshRef.current) {
      const newClippingValues = { ...clippingValues, z: value };

      newMaterial.clipPlane3 = new Plane(0, 0, 1, -newClippingValues.z);

      cloneMeshRef.current.material = newMaterial;
    }
  }; */

/* const updateClippingPlane = useCallback(
    (axis, value) => {
      setClippingValues((prevValues) => ({ ...prevValues, [axis]: value }));
      // console.log(value);

      if (cloneMeshRef.current) {
        switch (axis) {
          case 'x':
            cloneMeshRef.current.onBeforeRenderObservable.add(function () {
              sceneInstance.current.clipPlane = new Plane(
                limits.current.xAxisX,
                limits.current.yAxisX,
                limits.current.zAxisX,
                -value
              );
            });

            cloneMeshRef.current.onAfterRenderObservable.add(function () {
              sceneInstance.current.clipPlane = null;
            });
            break;
          case 'y':
            cloneMeshRef.current.onBeforeRenderObservable.add(function () {
              sceneInstance.current.clipPlane2 = new Plane(
                limits.current.xAxisY,
                limits.current.yAxisY,
                limits.current.zAxisY,
                -value
              );
            });

            cloneMeshRef.current.onAfterRenderObservable.add(function () {
              sceneInstance.current.clipPlane2 = null;
            });
            break;
          case 'z':
            cloneMeshRef.current.onBeforeRenderObservable.add(function () {
              sceneInstance.current.clipPlane3 = new Plane(
                limits.current.xAxisZ,
                limits.current.yAxisZ,
                limits.current.zAxisZ,
                -value
              );
            });

            cloneMeshRef.current.onAfterRenderObservable.add(function () {
              sceneInstance.current.clipPlane3 = null;
            });
            break;
          default:
            break;
        }
        cloneMeshRef.current.material = newMaterial;
      }
    },
    [cloneMeshRef, newMaterial, sceneInstance, limits]
  );

  return (
    <div className="ShortcutsToolbar-slider-container">
      <div className="slider-label">X</div>
      {triggerRerender && (
        <input
          className="slider x-slider"
          type="range"
          min={limits.current.xLimitMin}
          max={limits.current.xLimitMax}
          step={'0.01'}
          value={clippingValues.x}
          onChange={(e) => updateClippingPlane('x', parseFloat(e.target.value))}
        />
      )}

      <div className="slider-label">Y</div>
      {triggerRerender && (
        <input
          className="slider y-slider"
          type="range"
          min={limits.current.yLimitMin}
          max={limits.current.yLimitMax}
          step={'0.01'}
          value={clippingValues.y}
          onChange={(e) => updateClippingPlane('y', parseFloat(e.target.value))}
        />
      )}

      <div className="slider-label">Z</div>
      {triggerRerender && (
        <input
          className="slider z-slider"
          type="range"
          min={limits.current.zLimitMin}
          max={limits.current.zLimitMax}
          step={'0.01'}
          value={clippingValues.z}
          onChange={(e) => updateClippingPlane('z', parseFloat(e.target.value))}
        />
      )}
    </div>
  );
};

SliderToolbar.propTypes = {
  limits: PropTypes.any.isRequired,
  cloneMeshRef: PropTypes.any.isRequired,
  newMaterial: PropTypes.any.isRequired,
  sceneInstance: PropTypes.any.isRequired,
  setLimitsRef: PropTypes.any.isRequired
};

export default SliderToolbar; */

import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import { Plane, StandardMaterial, Color3, MeshBuilder } from '@babylonjs/core';
import '@pages/user/editor/Tool bars/Handle section toolbar/SliderToolbar.scss';

const SliderToolbar = ({ limits, sceneInstance, mesh, root }) => {
  // const meshParent = mesh.parent;

  // eslint-disable-next-line no-unused-vars
  const calculateValues = () => {
    limits.forEach((element) => {
      const length = Math.sqrt(element.a * element.a + element.b * element.b + element.c * element.c);

      // Normalize the plane coefficients
      element.a /= length;
      element.b /= length;
      element.c /= length;
      element.dmax /= length;
      element.dmin /= length;
    });
  };

  if (!root) {
    calculateValues();
  }

  const [clippingValues, setClippingValues] = useState({
    x: limits[0].dmax,
    y: limits[1].dmax,
    z: limits[2].dmax
  });

  const XclipPlaneValueRef = useRef(limits[0].dmax);
  const YclipPlaneValueRef = useRef(limits[1].dmax);
  const ZclipPlaneValueRef = useRef(limits[2].dmax);

  // To visually see the plane in the scene, assign it a material
  const material = new StandardMaterial('material', sceneInstance.current);
  material.backFaceCulling = false;
  material.diffuseColor = new Color3(1, 1, 0); // Yellow color

  const ClipPlaneXRef = useRef(null);
  const ClipPlaneYRef = useRef(null);
  const ClipPlaneZRef = useRef(null);

  if (mesh) {
    const clippingPlaneMaterial = new StandardMaterial('clippingPlaneMaterial', sceneInstance.current);
    clippingPlaneMaterial.diffuseColor = new Color3(1, 0, 0); // Red
    clippingPlaneMaterial.alpha = 0.5; // Transparency

    mesh.onBeforeRenderObservable.add(function () {
      // Create and assign the material to the clipping planes
      sceneInstance.current.clipPlane = new Plane(limits[0].a, limits[0].b, limits[0].c, -XclipPlaneValueRef.current);
      sceneInstance.current.clipPlane.name = 'ClipPlaneX'; // Give it a name

      sceneInstance.current.clipPlane2 = new Plane(limits[1].a, limits[1].b, limits[1].c, -YclipPlaneValueRef.current);
      sceneInstance.current.clipPlane.name = 'ClipPlaneY'; // Give it a name

      sceneInstance.current.clipPlane3 = new Plane(limits[2].a, limits[2].b, limits[2].c, -ZclipPlaneValueRef.current);
      sceneInstance.current.clipPlane.name = 'ClipPlaneZ'; // Give it a name

      // sceneInstance.current.clipPlane.parent = meshParent;

      ClipPlaneXRef.current = sceneInstance.current.clipPlane;
      ClipPlaneYRef.current = sceneInstance.current.clipPlane2;
      ClipPlaneZRef.current = sceneInstance.current.clipPlane3;
    });

    mesh.onAfterRenderObservable.add(function () {
      // Reset clipping planes
      sceneInstance.current.clipPlane = null;
      sceneInstance.current.clipPlane2 = null;
      sceneInstance.current.clipPlane3 = null;

      ClipPlaneXRef.current = null;
      ClipPlaneYRef.current = null;
      ClipPlaneZRef.current = null;
    });

    /* Use MeshBuilder to create a plane mesh
    const planeMeshX = MeshBuilder.CreatePlane(
      'plane',
      {
        sourcePlane: ClipPlaneXRef.current,
        size: 5 // Define the visible size of the plane
      },
      sceneInstance.current
    );
    planeMeshX.material = material;

    // Use MeshBuilder to create a plane mesh
    const planeMeshY = MeshBuilder.CreatePlane(
      'plane',
      {
        sourcePlane: ClipPlaneYRef.current,
        size: 5 // Define the visible size of the plane
      },
      sceneInstance.current
    );
    planeMeshY.material = material;

    // Use MeshBuilder to create a plane mesh
    const planeMeshZ = MeshBuilder.CreatePlane(
      'plane',
      {
        sourcePlane: ClipPlaneZRef.current,
        size: 5 // Define the visible size of the plane
      },
      sceneInstance.current
    );
    planeMeshZ.material = material; // */
  }

  const updateClippingPlane = useCallback((axis, value) => {
    setClippingValues((prevValues) => ({ ...prevValues, [axis]: value }));

    switch (axis) {
      case 'x':
        XclipPlaneValueRef.current = value;
        break;
      case 'y':
        YclipPlaneValueRef.current = value;
        break;
      case 'z':
        ZclipPlaneValueRef.current = value;
        break;
      default:
        break;
    }
  }, []);

  return (
    <div className="ShortcutsToolbar-slider-container">
      <div className="slider-label">X</div>
      <input
        className="slider x-slider"
        type="range"
        min={limits[0].dmin}
        max={limits[0].dmax}
        step={'0.01'}
        value={clippingValues.x}
        onChange={(e) => updateClippingPlane('x', parseFloat(e.target.value))}
      />
      <div className="slider-label">Y</div>
      <input
        className="slider y-slider"
        type="range"
        min={limits[1].dmin}
        max={limits[1].dmax}
        step={'0.01'}
        value={clippingValues.y}
        onChange={(e) => updateClippingPlane('y', parseFloat(e.target.value))}
      />

      <div className="slider-label">Z</div>
      <input
        className="slider z-slider"
        type="range"
        min={limits[2].dmin}
        max={limits[2].dmax}
        step={'0.01'}
        value={clippingValues.z}
        onChange={(e) => updateClippingPlane('z', parseFloat(e.target.value))}
      />
    </div>
  );
};

SliderToolbar.propTypes = {
  limits: PropTypes.array.isRequired,
  sceneInstance: PropTypes.object.isRequired,
  mesh: PropTypes.any.isRequired,
  root: PropTypes.bool.isRequired
};

export default SliderToolbar;
