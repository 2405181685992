import PropTypes from 'prop-types';
import '@components/input/input new/InputNew.scss';
import { forwardRef } from 'react';

const InputNew = forwardRef((props, ref) => (
  <div className="form-row-new" style={props.labelText && { display: 'flex', alignItems: 'center' }}>
    <input
      ref={ref}
      id={props.id}
      name={props.name}
      type={props.type}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      onClick={props.onClick}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      className={`form-input ${props.className}`}
      style={props.style}
      autoComplete="off"
    />
    {props.labelText && <span style={{ marginLeft: '5px' }}>{props.labelText}</span>}
  </div>
));

InputNew.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  labelText: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  style: PropTypes.object
};

export default InputNew;
