import React, { useState } from 'react';
import '@components/Modal windows/Modal window - description/ConfirmDescription.scss';
// import InputNew from '@components/input/input new/InputNew';
import TextArea from '@components/text-area/textArea';
import PropTypes from 'prop-types';

const ConfirmDescription = ({ renameObject, cancelAction, initialName = '', objectName }) => {
  const [inputValue, setInputValue] = useState(initialName); // initialize with initialName

  const handleInput = (event) => {
    setInputValue(event.target.value);
  };

  const handleConfirm = () => {
    renameObject(objectName, inputValue);
  };

  return (
    <div className="modal-wrapper-description" onClick={() => cancelAction()}>
      <div
        className="confirm-rename-container-description"
        onClick={(e) => e.stopPropagation()}
        style={{ backgroundColor: 'white' }}
      >
        <h2>Add/Modify Object Description</h2>
        <p>{"Enter a description to the object/part in order to visualize it in VR and click 'Add/Modify'."}</p>
        <p>{`Current description: ${initialName || 'No current description set on object.'}`}</p>
        <TextArea
          name="rename"
          type="text"
          value={inputValue}
          onChange={handleInput}
          placeholder={'New name'}
          autoComplete="off"
          style={{ marginTop: '10px', marginBottom: '0px', width: '430px', height: '80px' }}
        />
        {/* <InputNew
          name="rename"
          type="text"
          value={inputValue}
          onChange={handleInput}
          placeholder={'New name'}
          autoComplete="off"
          style={{ marginTop: '10px', marginBottom: '0px' }}
        /> */}
        <div className="buttons-container-description" style={{ backgroundColor: 'transparent', marginTop: '30px' }}>
          <button
            className="button-new-description"
            onClick={() => cancelAction()}
            style={{ cursor: 'pointer', color: 'rgba(255, 0, 0, 1)' }}
          >
            Cancel
          </button>
          <div className="divider"></div>
          <button
            className="button-new-description"
            onClick={handleConfirm}
            disabled={!inputValue}
            style={!inputValue ? { color: 'var(--white-17)', cursor: 'not-allowed' } : { cursor: 'pointer' }}
          >
            {'Add/Modify'}
          </button>
        </div>
      </div>
    </div>
  );
};

ConfirmDescription.propTypes = {
  renameObject: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  initialName: PropTypes.string,
  objectName: PropTypes.string
};

export default ConfirmDescription;
