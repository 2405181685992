import {
  StandardMaterial,
  Color3,
  Vector3,
  MeshBuilder,
  Animation,
  ActionManager,
  ExecuteCodeAction,
  SceneLoader,
  TransformNode
} from '@babylonjs/core'; // Ensure the necessary BabylonJS modules are imported.

import { modelsService } from '@services/api/models/models.service';

class CreatePlaneAndCS {
  constructor(scene, C2M, orthographicCamera, Public_URL, camera2, sessionData) {
    this.scene = scene;
    this.C2M = C2M;
    this.orthographicCamera = orthographicCamera.bind(this);
    this.Public_URL = Public_URL;
    this.camera2 = camera2;
    this.sessionData = sessionData;
  }

  createPlanes() {
    const planeSize = 0.5;
    const planeOptions = {
      height: planeSize,
      width: planeSize,
      updatable: true
    };

    const positions = [
      { x: -0.28, y: 0, z: 0 },
      { x: 0.28, y: 0, z: 0 },
      { x: 0, y: 0.28, z: 0 },
      { x: 0, y: -0.28, z: 0 },
      { x: 0, y: 0, z: -0.28 },
      { x: 0, y: 0, z: 0.28 }
    ];

    const rotations = [
      { x: 0, y: Math.PI / 2, z: 0 },
      { x: 0, y: -Math.PI / 2, z: 0 },
      { x: Math.PI / 2, y: 0, z: 0 },
      { x: -Math.PI / 2, y: 0, z: 0 },
      { x: 0, y: 0, z: 0 },
      { x: 0, y: Math.PI, z: 0 }
    ];

    // Create default material
    const defaultMaterialGrey = new StandardMaterial('defaultMaterial', this.scene);
    defaultMaterialGrey.diffuseColor = new Color3(100 / 255, 100 / 255, 100 / 255); // Grey color
    defaultMaterialGrey.disableLighting = true;
    defaultMaterialGrey.alpha = 0.5;

    // Create hover material
    const hoverMaterial = new StandardMaterial('hoverMaterial', this.scene);
    hoverMaterial.diffuseColor = new Color3(0, 0, 100 / 255); // Highlight blue color
    hoverMaterial.alpha = 0.7;
    // hoverMaterial.disableLighting = true;

    // Define positions and rotations as before...
    positions.forEach((position, index) => {
      // eslint-disable-next-line prefer-const
      let plane = MeshBuilder.CreatePlane(`cubePlane_${index}`, planeOptions, this.scene);
      plane.scaling = new Vector3(0, 0, 0);
      plane.position = new Vector3(position.x, position.y, position.z);
      plane.rotation = new Vector3(rotations[index].x, rotations[index].y, rotations[index].z);
      plane.material = defaultMaterialGrey;

      plane.isPickable = true;
      plane.layerMask = this.C2M;

      plane.metadata = { editor: true };

      // Wait for 1 second before starting the animation
      setTimeout(() => {
        // Create the animation
        // eslint-disable-next-line prefer-const
        let animation = new Animation(
          'scaleAnimation',
          'scaling',
          80,
          Animation.ANIMATIONTYPE_VECTOR3,
          Animation.ANIMATIONLOOPMODE_CONSTANT
        );

        // Create animation keys
        // eslint-disable-next-line prefer-const
        let keys = [];
        keys.push({ frame: 0, value: new Vector3(0, 0, 0) });
        keys.push({ frame: 80, value: new Vector3(1, 1, 1) });
        animation.setKeys(keys);

        // Attach the animation to the mesh
        plane.animations.push(animation);

        // Run the animation
        this.scene.beginAnimation(plane, 0, 80, false);
      }, 3500);

      // Ensure the plane has an action manager
      if (!plane.actionManager) {
        // console.log('Setting up ActionManager for plane');
        plane.actionManager = new ActionManager(this.scene);
        // console.log('ActionManager for plane set');
      } else {
        // console.log('Plane already has an ActionManager');
      }

      const directions = ['-x', 'x', 'y', '-y', 'z', '-z'];
      // eslint-disable-next-line prefer-const
      let i = directions[index] || '';

      // Register a pointer down event
      plane.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPickDownTrigger, () => {
          // console.log('yes');
          this.orthographicCamera(i); // Call the orthographic camera method when the plane is clicked
        })
      );

      // Register a mouse over event to change the material
      plane.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, () => {
          plane.material = hoverMaterial;
        })
      );

      // Register a mouse out event to revert the material change
      plane.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, () => {
          plane.material = defaultMaterialGrey;
        })
      );
    });
  }

  createCoordinateSystemMesh() {
    // When you move the function to a class, the meaning of this changes based on the calling context, which can make it not point to what you expect.
    const self = this; // Capture the class context. This ensures that you have the correct context throughout the method.

    console.log(self.sessionData);

    (async () => {
      const Bucket = 'exvirience-shared';
      const Key = 'Coordinate_System.glb';
      const models_Id = 'I'; // this is required

      const awsInformation = {
        accessKeyId: self.sessionData.data.accessInformation.AccessKeyId,
        secretAccessKey: self.sessionData.data.accessInformation.SecretAccessKey,
        sessionToken: self.sessionData.data.accessInformation.SessionToken,
        bucketName: Bucket
      };

      const modelsBody = {
        models_Id,
        modelId: Key,
        awsInformation
      };

      const objectURL = await modelsService.getModelURL(modelsBody);

      // console.log(objectURL);

      // console.log(self.Public_URL);
      // Coordinate system mesh upper right corner
      // eslint-disable-next-line prefer-const
      SceneLoader.ImportMesh('', '', objectURL.data.model, self.scene, function (newMeshes) {
        if (newMeshes && newMeshes[0]) {
          // Create an empty parent for all the imported meshes
          // eslint-disable-next-line prefer-const
          let coordinateSystem = new TransformNode('CoordinateSystem', self.scene);
          coordinateSystem.rotation = new Vector3(0, Math.PI, 0);

          newMeshes.forEach((mesh) => {
            mesh.parent = coordinateSystem; // Set the parent for each mesh
          });

          coordinateSystem.position = new Vector3(0, 0, 0);
          coordinateSystem.scaling = new Vector3(0, 0, 0); // Start with scale 0
          coordinateSystem.rotate(new Vector3(1, 0, 0), Math.PI / 2);

          self.camera2.setTarget(coordinateSystem.position);

          newMeshes.forEach(function (value) {
            value.metadata = {
              canToggleGizmo: false,
              canToggleFocus: false,
              clone: coordinateSystem, // Set the clone metadata to the new parent
              isolatable: false,
              editor: true
            };

            value.layerMask = self.C2M;
          });

          // Wait for 1 second before starting the animation
          setTimeout(() => {
            // Create the animation
            // eslint-disable-next-line prefer-const
            let animation = new Animation(
              'scaleAnimation',
              'scaling',
              80,
              Animation.ANIMATIONTYPE_VECTOR3,
              Animation.ANIMATIONLOOPMODE_CONSTANT
            );

            // Create animation keys
            // eslint-disable-next-line prefer-const
            let keys = [];
            keys.push({ frame: 0, value: new Vector3(0, 0, 0) });
            keys.push({ frame: 80, value: new Vector3(0.35, 0.35, 0.35) });
            animation.setKeys(keys);

            // Attach the animation to the coordinateSystem
            coordinateSystem.animations.push(animation);

            // Run the animation
            self.scene.beginAnimation(coordinateSystem, 0, 80, false);
          }, 3500);
        }
      });
    })();
  }

  initialize() {
    this.createPlanes();
    this.createCoordinateSystemMesh();
  }
}

export default CreatePlaneAndCS;
