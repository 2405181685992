import {
  SceneLoader,
  Vector3,
  Mesh,
  MeshBuilder,
  StandardMaterial,
  Color3,
  Quaternion,
  Axis,
  Space,
  // MeshExploder,
  // eslint-disable-next-line no-unused-vars
  TransformNode
} from '@babylonjs/core'; // Ensure the necessary BabylonJS modules are imported.
import { UniqueIDGenerator } from '@hooks/Mesh/index';

// eslint-disable-next-line no-unused-vars
import { ConsoleLog } from '@hooks/General';

import { modelsService } from '@services/api/models/models.service';

class MeshInputHandler {
  constructor(
    sceneInstance,
    loadingSpheresCreator,
    shadowPlaneCreator,
    clickSimulator,
    C1M,
    setMeshesCallback,
    familyIDs,
    meshes,
    importableMeshes,
    userDataStore,
    sessionData
  ) {
    this.sceneInstance = sceneInstance;
    this.loadingSpheresCreator = loadingSpheresCreator;
    this.shadowPlaneCreator = shadowPlaneCreator;
    this.clickSimulator = clickSimulator;
    this.C1M = C1M;
    this.setMeshesCallback = setMeshesCallback;
    this.meshes = meshes;
    this.familyIDs = familyIDs;
    this.name = '';
    this.newID = '';
    this.importableMeshes = importableMeshes;
    this.userDataStore = userDataStore;
    this.sessionData = sessionData;
  }

  handleFileDownload = async (modelId, modelName, setPercentage) => {
    const idGenerator = new UniqueIDGenerator(this.familyIDs);
    const newId = idGenerator.generateUniqueId();
    this.newID = newId;

    // console.log(modelId, modelName);

    try {
      this.loadingSpheresCreator.create(new Vector3(0, 0, 0));

      const Bucket = 'exvirience-objects';
      const Key = modelId;
      const models_Id = this.userDataStore.modelsId; // this has to be the users models id

      const awsInformation = {
        accessKeyId: this.sessionData.data.accessInformation.AccessKeyId,
        secretAccessKey: this.sessionData.data.accessInformation.SecretAccessKey,
        sessionToken: this.sessionData.data.accessInformation.SessionToken,
        bucketName: Bucket
      };

      const modelsBody = {
        models_Id,
        modelId: Key,
        awsInformation
      };

      const response = await modelsService.getModel(modelsBody, setPercentage);

      console.log(response.data);

      const dataURL = await this.readFileAndLoadMesh(response.data, response.headers['content-type']);

      console.log(dataURL);

      const result = await SceneLoader.ImportMeshAsync(null, '', dataURL, this.sceneInstance.current);
      const newMeshes = result.meshes;

      console.log(newMeshes);
      // console.log(newMeshes[0]);

      newMeshes.showBoundingBox = false;

      this.importableMeshes.push(newMeshes[0].uniqueId);

      newMeshes[0].scaling = new Vector3(1, 1, 1);
      // GENERATE A NAME FOR THE ROOT OBJECT FOR IT TO NOT ONLY BE CALLED __root__
      // ConsoleLog.log(this.meshes.length);
      this.name = this.changeRootName(newMeshes[0], this.meshes);

      // CREATE THE CLONED MESH AND STORE IT AS A CHILD OF THE ROOT
      // eslint-disable-next-line no-unused-vars
      let clonedMesh;
      try {
        clonedMesh = this.createClone(newMeshes, newId, this.sceneInstance.current);
      } catch (error) {
        console.error(error);
      }

      // ConsoleLog.log(clonedMesh);

      newMeshes[0].position = new Vector3(0, 0, 0);

      if (clonedMesh) {
        clonedMesh[1].parent = newMeshes[0];
      }
      // clonedMesh.position = new Vector3(4, 0, 1);
      // this.hideAllExceptClonedMeshes(this.sceneInstance.current);

      newMeshes[0].scaling = new Vector3(0, 0, 0);

      setTimeout(() => {
        this.loadingSpheresCreator.disposeSpheres();
        newMeshes[0].scaling = new Vector3(-1, 1, 1);
        if (clonedMesh) {
          clonedMesh[0].scaling = new Vector3(-1, 1, -1);
        }

        newMeshes.forEach((value) => {
          value.metadata = {
            canToggleGizmo: true,
            canToggleFocus: true,
            targetPosition: newMeshes[0].position,
            position: value.position.clone(),
            rotation: value.rotation.clone(),
            scale: value.scaling.clone(),
            clone: newMeshes[0],
            isolatable: true,
            familyID: newId,
            isIsolated: false,
            importable: true,
            clippable: true,
            importData: null
          };

          value.layerMask = this.C1M;
          value.renderingGroupId = 1;
        });

        const Bucket = 'exvirience-objects';

        // const Body = file;
        const ModelsId = this.userDataStore.modelsId; // this has to be the users models id

        // this we get as the response from the object put
        const Key = modelId; // this has to be the oject model id

        // console.log('put confirmed');
        newMeshes[0].metadata.importData = {
          familyID: newId,
          type: 'mesh',
          name: modelName,
          url: {
            bucket: Bucket,
            key: Key,
            modelsId: ModelsId
          },
          position: null,
          rotation: null,
          scaling: null,
          quaternion: null,
          description: {}
        };

        if (clonedMesh) {
          this.importableMeshes.push(clonedMesh[1].uniqueId);

          clonedMesh[1].metadata.importData = {
            familyID: newId,
            type: 'cloneBox',
            name: modelName + ' Box',
            url: {
              bucket: null,
              key: null,
              modelsId: null
            },
            position: null,
            rotation: null,
            scaling: null,
            quaternion: null
          };
        }

        console.log(newMeshes[0].metadata);

        const shadowPlane = this.shadowPlaneCreator.create(newMeshes[0]);
        newMeshes[0].metadata.shadowPlane = shadowPlane;
        newMeshes[0].renderingGroupId = 1;

        this.clickSimulator.simulateClickAndTargetCamera(newMeshes[1]);
      }, 1500);

      this.setMeshesCallback([...this.meshes, ...newMeshes]);
    } catch (error) {
      console.error('Error loading mesh', error);
    }
  };

  // won't be using this anymore as we moved it outside (in the ModelInputHandler)
  handleFileInput(e, modelId) {
    const file = e.target.files[0];

    const idGenerator = new UniqueIDGenerator(this.familyIDs);
    const newId = idGenerator.generateUniqueId();
    this.newID = newId;

    if (file && file.name.endsWith('.glb')) {
      // console.log(file);

      const reader = new FileReader();
      reader.onload = async (event) => {
        const dataUrl = event.target.result;
        // console.log(dataUrl);
        try {
          this.loadingSpheresCreator.create(new Vector3(0, 0, 0));

          const result = await SceneLoader.ImportMeshAsync(null, '', dataUrl, this.sceneInstance.current);
          const newMeshes = result.meshes;

          console.log(newMeshes);
          // console.log(newMeshes[0]);

          newMeshes.showBoundingBox = false;

          this.importableMeshes.push(newMeshes[0].uniqueId);

          newMeshes[0].scaling = new Vector3(1, 1, 1);
          // GENERATE A NAME FOR THE ROOT OBJECT FOR IT TO NOT ONLY BE CALLED __root__
          // ConsoleLog.log(this.meshes.length);
          this.name = this.changeRootName(newMeshes[0], this.meshes);

          // CREATE THE CLONED MESH AND STORE IT AS A CHILD OF THE ROOT
          // eslint-disable-next-line no-unused-vars
          let clonedMesh;
          try {
            clonedMesh = this.createClone(newMeshes, newId, this.sceneInstance.current);
          } catch (error) {
            console.error(error);
          }

          // ConsoleLog.log(clonedMesh);

          newMeshes[0].position = new Vector3(0, 0, 0);

          if (clonedMesh) {
            clonedMesh[1].parent = newMeshes[0];
          }
          // clonedMesh.position = new Vector3(4, 0, 1);
          // this.hideAllExceptClonedMeshes(this.sceneInstance.current);

          newMeshes[0].scaling = new Vector3(0, 0, 0);

          setTimeout(() => {
            this.loadingSpheresCreator.disposeSpheres();
            newMeshes[0].scaling = new Vector3(-1, 1, 1);
            if (clonedMesh) {
              clonedMesh[0].scaling = new Vector3(-1, 1, -1);
            }

            newMeshes.forEach((value) => {
              value.metadata = {
                canToggleGizmo: true,
                canToggleFocus: true,
                targetPosition: newMeshes[0].position,
                position: value.position.clone(),
                rotation: value.rotation.clone(),
                scale: value.scaling.clone(),
                clone: newMeshes[0],
                isolatable: true,
                familyID: newId,
                isIsolated: false,
                importable: true,
                clippable: true,
                importData: null
              };

              value.layerMask = this.C1M;
              value.renderingGroupId = 1;
            });

            (async () => {
              const Bucket = 'exvirience-objects';

              // const Body = file;
              const ModelsId = this.userDataStore.modelsId; // this has to be the users models id

              // this we get as the response from the object put
              const Key = modelId; // this has to be the oject model id

              // console.log('put confirmed');
              newMeshes[0].metadata.importData = {
                familyID: newId,
                type: 'mesh',
                name: file.name,
                url: {
                  bucket: Bucket,
                  key: Key,
                  modelsId: ModelsId
                },
                position: null,
                rotation: null,
                scaling: null,
                quaternion: null,
                description: {}
              };

              if (clonedMesh) {
                this.importableMeshes.push(clonedMesh[1].uniqueId);

                clonedMesh[1].metadata.importData = {
                  familyID: newId,
                  type: 'cloneBox',
                  name: file.name + ' Box',
                  url: {
                    bucket: null,
                    key: null,
                    modelsId: null
                  },
                  position: null,
                  rotation: null,
                  scaling: null,
                  quaternion: null
                };
              }

              console.log(newMeshes[0].metadata);
            })();

            const shadowPlane = this.shadowPlaneCreator.create(newMeshes[0]);
            newMeshes[0].metadata.shadowPlane = shadowPlane;
            newMeshes[0].renderingGroupId = 1;

            this.clickSimulator.simulateClickAndTargetCamera(newMeshes[1]);
          }, 1500);

          this.setMeshesCallback([...this.meshes, ...newMeshes]);
        } catch (error) {
          console.error('Error loading mesh', error);
        }
      };
      reader.readAsDataURL(file);
    } else {
      alert('Please upload a .glb file');
    }
  }

  hideAllExceptClonedMeshes(scene) {
    scene.meshes.forEach((mesh) => {
      // Hide all meshes except those named 'ClonedMesh'
      mesh.isVisible = mesh.name === 'ClonedMesh' || mesh.name === '__root_0__';
    });
  }

  // ADD THIS LINE
  changeRootName(mesh, meshesArray) {
    // To get the length of the array
    const arrayLength = meshesArray.length;

    mesh.name = `__root_${arrayLength}__`;

    return `__root_${arrayLength}__`;
  }

  createClone(newMeshes, newId, scene) {
    // eslint-disable-next-line prefer-const
    // set the meshes to merge into a single variable
    let meshesToMerge = newMeshes.slice(1); // This will create a new array without the first element

    meshesToMerge = meshesToMerge.filter((element) => element instanceof Mesh); // Now filter only Mesh instances

    let clonedMesh;

    // ConsoleLog.log(newMeshes);

    // merge the meshes
    try {
      if (meshesToMerge.length > 0 && meshesToMerge.every((mesh) => mesh instanceof Mesh)) {
        clonedMesh = Mesh.MergeMeshes(meshesToMerge, false, true, undefined, false, true);
        // ConsoleLog.log(clonedMesh);

        clonedMesh.name = 'ClonedMesh';
        clonedMesh.isVisible = false;

        clonedMesh.showBoundingBox = false;
      } else {
        clonedMesh = newMeshes[0].clone('clonedMesh');
        console.error('No cloned mesh created');
      }
    } catch (error) {
      ConsoleLog.error(error);
      this.loadingSpheresCreator.disposeSpheres();

      /*
      newMeshes.forEach((value) => {
        value.dispose();
      }); */

      // clonedMesh.dispose();
      return;
    }

    clonedMesh.metadata = {
      canToggleGizmo: false,
      canToggleFocus: false,
      targetPosition: newMeshes[0].position,
      position: clonedMesh.position.clone(),
      rotation: clonedMesh.rotation.clone(),
      scale: clonedMesh.scaling.clone(),
      clone: newMeshes[0],
      isolatable: false,
      familyID: newId,
      isIsolated: false,
      importable: true,
      clippable: true
    };

    clonedMesh.layerMask = this.C1M;
    clonedMesh.renderingGroupId = 1;

    clonedMesh.scaling = new Vector3(1, 1, -1);

    clonedMesh.parent = newMeshes[0];

    const cloneBox = this.createBoxFromBoundingBox(newMeshes, clonedMesh, scene, newId);

    return [clonedMesh, cloneBox];
  }

  createBoxFromBoundingBox(newMeshes, mesh, scene, newId) {
    // mesh.parent = null;

    mesh.computeWorldMatrix(true);
    const boundingInfo = mesh.getBoundingInfo();

    newMeshes[0].computeWorldMatrix(true);
    // eslint-disable-next-line no-unused-vars
    const boundingInfoRoot = mesh.getBoundingInfo();
    // console.log(boundingInfo);

    // Create a box with the same dimensions
    const box = MeshBuilder.CreateBox(
      'BoundingBoxEquivalent',
      { size: 1 }, // Start with unit size
      scene
    );

    box.name = 'ClonedMeshBox' + this.name;

    // box.parent = newMeshes[0];

    // Scale the box to match the bounding box size
    box.scaling = boundingInfo.boundingBox.extendSize.scale(2);
    const initialBoxScaling = box.scaling;
    // box.scaling.z *= -1;

    // Set the position of the new box to match the center of the bounding box in world space
    box.position = boundingInfo.boundingBox.centerWorld.clone();

    // box.position.y = boundingBoxSize.y / 4;

    box.metadata = {
      canToggleGizmo: false,
      canToggleFocus: false,
      targetPosition: box.position,
      position: box.position.clone(),
      rotation: box.rotation.clone(),
      scale: box.scaling.clone(),
      clone: newMeshes[0],
      isolatable: true,
      familyID: newId,
      isIsolated: false,
      importable: false,
      clippable: true
    };

    box.layerMask = this.C1M;
    box.renderingGroupId = 1;

    // Assign a semi-transparent material to the plane
    const boxMaterial = new StandardMaterial(`CloneBoxMaterial`, scene);
    boxMaterial.diffuseColor = new Color3(0.5, 0.5, 0.5); // Grey color
    boxMaterial.alpha = 0.5; // Semi-transparent
    box.material = boxMaterial;

    box.isVisible = false;

    // Calculate the initial relative position
    // eslint-disable-next-line no-unused-vars
    const initialRelativePosition = box.position.subtract(newMeshes[0].position);

    if (!box.rotationQuaternion) {
      box.rotationQuaternion = Quaternion.RotationYawPitchRoll(box.rotation.y, box.rotation.x, box.rotation.z);
    }
    if (!newMeshes[0].rotationQuaternion) {
      newMeshes[0].rotationQuaternion = Quaternion.RotationYawPitchRoll(
        newMeshes[0].rotation.y,
        newMeshes[0].rotation.x,
        newMeshes[0].rotation.z
      );
    }

    // Calculate initial scales and ratio
    const initialNewMeshesScale = newMeshes[0].scaling.clone();
    initialNewMeshesScale.z *= -1;
    // eslint-disable-next-line no-unused-vars
    const scaleRatio = initialBoxScaling.divide(initialNewMeshesScale);

    // Calculate the initial relative rotation
    // eslint-disable-next-line no-unused-vars
    const initialRelativeRotation = box.rotationQuaternion.multiply(newMeshes[0].rotationQuaternion.invert());

    // eslint-disable-next-line no-unused-vars
    const node = this.createEmptyNode(newMeshes, box, box.name);
    // box.parent = node;

    // Calculate the rotation difference between object1 and object2
    const rotationDifference = box.rotation.subtract(newMeshes[0].rotation);

    // Apply the rotation difference to object2
    box.rotate(Axis.Y, rotationDifference.y, Space.WORLD); // Adjust the axis as needed

    this.sceneInstance.current.onBeforeRenderObservable.add(() => {
      mesh.computeWorldMatrix(true);
      const boundingInfo = mesh.getBoundingInfo();
      // Update the box's position to maintain the same relative position to newMeshes[0]
      // Set the position of the new box to match the center of the bounding box in world space
      box.position = boundingInfo.boundingBox.centerWorld.clone();

      // Update the box's rotation to maintain the same relative rotation to newMeshes[0]
      // Calculate the rotation difference between object1 and its previous rotation
      // Calculate the updated rotation difference on each frame
      const newRotationDifference = newMeshes[0].rotation.subtract(box.rotation);

      // Apply the difference to object2 to keep it aligned with object1
      box.rotate(Axis.Y, newRotationDifference.y, Space.WORLD); // Adjust the axis as needed
      // box.rotationQuaternion = newMeshes[0].rotationQuaternion.multiply(initialRelativeRotation);

      // Apply the scaling ratio to the box
      // box.scaling = newMeshes[0].scaling.multiply(scaleRatio);
    });

    // Calculate the initial relative position
    // const initialRelativePosition = box.position.subtract(newMeshes[0].position);

    // this.createEmptyNode(newMeshes, box, box.name);

    return box;
  }

  createEmptyNode(rootMesh, name) {
    const emptyNode = new TransformNode(name, this.sceneInstance.current);
    emptyNode.name = name + 'Root';

    emptyNode.position = rootMesh[0].position.clone();
    // emptyNode.rotation = rootMesh[0].rotation.clone();
    emptyNode.scaling = rootMesh[0].scaling.clone();

    if (!emptyNode) {
      console.error('Cloning failed');
      return null;
    }

    // Dispose of all children
    if (emptyNode.getChildren()) {
      emptyNode.getChildren().forEach((child) => {
        if (child.dispose) {
          child.dispose();
        }
      });
    }

    // Delete all metadata
    emptyNode.metadata = null;
    emptyNode.isPickable = false; // Ensure the node is not pickable
    emptyNode.isVisible = false;

    console.log(emptyNode);

    if (!emptyNode.rotationQuaternion) {
      emptyNode.rotationQuaternion = Quaternion.RotationYawPitchRoll(
        emptyNode.rotation.y,
        emptyNode.rotation.x,
        emptyNode.rotation.z
      );
    }

    /*
    this.sceneInstance.current.onBeforeRenderObservable.add(() => {
      // Update the box's position to maintain the same relative position to newMeshes[0]
      emptyNode.position = rootMesh[0].position;

      // Update the box's rotation to maintain the same relative rotation to newMeshes[0]
      emptyNode.rotationQuaternion = rootMesh[0].rotationQuaternion;

      // Apply the scaling ratio to the box
      emptyNode.scaling = rootMesh[0].scaling;
      // Assuming 'box' is your mesh
      // box.scaling.y *= -1; // Invert the scale along the X-axis
    }); */

    return emptyNode;
  }

  readFileAndLoadMesh = (arrayBuffer, contentType) => {
    return new Promise((resolve, reject) => {
      try {
        const byteArray = new Uint8Array(arrayBuffer);
        const base64String = btoa(byteArray.reduce((data, byte) => data + String.fromCharCode(byte), ''));
        const dataUrl = `data:${contentType};base64,${base64String}`;
        resolve(dataUrl);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default MeshInputHandler;
