import '@pages/documentation/editorDocumentation.scss';

import React, { useState } from 'react';

import originalLogo from '@assets/images/Logo_Exvirience_Original.png';

import { EditorOverview, EditorGetStarted } from '@pages/documentation/pages/index';

const EditorDocumentation = () => {
  const [activeSection, setActiveSection] = useState('overview');

  return (
    <div className="documentation-page">
      <header className="header">
        <img src={originalLogo} alt="Logo" className="logo" />
      </header>
      <div className="main-content">
        <nav className="link-tree">
          <ul>
            <li onClick={() => setActiveSection('overview')}>Overview</li>
            <li onClick={() => setActiveSection('getStarted')}>{"Let's get started"}</li>
            {/* Add more items as needed */}
          </ul>
        </nav>
        {activeSection === 'overview' && <EditorOverview />}
        {activeSection === 'getStarted' && <EditorGetStarted />}
        {/* Render the component based on the activeSection state */}
      </div>
    </div>
  );
};

export default EditorDocumentation;
