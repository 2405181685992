import {
  ErrorPage,
  ResetTabs,
  AuthTabs,
  RequestTabs,
  EditorDocumentation,
  User,
  ProtectedRoute,
  Test,
  UnauthorizedPage,
  Editor,
  VR,
  VerifyTabs
} from '@pages/index';

import { useRoutes } from 'react-router-dom';

export const AppRouter = () => {
  const elements = useRoutes([
    {
      path: '/auth',
      element: <AuthTabs />
    },
    {
      path: '/forgot-password',
      element: <RequestTabs />
    },
    {
      path: '/reset-password',
      element: <ResetTabs />
    },
    {
      path: '*',
      element: <ErrorPage />
    },
    {
      path: '/documentation',
      element: <EditorDocumentation />
    },
    {
      path: '/verify',
      element: <VerifyTabs />
    },
    {
      path: '/testing',
      element: <Test />
    },
    {
      path: '/unauthorized',
      element: <UnauthorizedPage />
    },
    {
      path: '/user/',
      element: (
        <ProtectedRoute>
          <User />
        </ProtectedRoute>
      ),
      children: []
    },
    {
      path: '/editor/:file',
      element: (
        <ProtectedRoute>
          <Editor />
        </ProtectedRoute>
      ),
      children: []
    },
    {
      path: '/vr/:name',
      element: (
        <ProtectedRoute>
          <VR />
        </ProtectedRoute>
      ),
      children: []
    }
  ]);

  return elements;
};
