import './Login.scss';

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

import Input from '@components/input/Input';
import Button from '@components/button/Button';
import { authService } from '@services/api/auth/auth.service';
import { useLocalStorage, useSessionStorage /* , CheckForLogin */ } from '@hooks/General/index.js';
import { Utils } from '@services/utils/utils.service';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [user, setUser] = useState();
  const [setStoredEmail] = useLocalStorage('email', 'set');
  const [setLoggedIn] = useLocalStorage('keepLoggedIn', 'set');
  const [pageReload] = useSessionStorage('pageReload', 'set');
  // eslint-disable-next-line no-unused-vars
  const [setSessionData] = useSessionStorage('sessionData', 'set');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const route = { equal: true, route: '/user' };
  // CheckForLogin(route);

  const loginUser = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const result = await authService.logIn({
        email,
        password
      });
      console.log(result);
      setSessionData(result);
      setLoggedIn(true);
      setStoredEmail(email);
      setHasError(false);
      setAlertType('alert-success');
      Utils.dispatchUser(result, pageReload, dispatch, setUser);
    } catch (error) {
      setLoading(false);
      setHasError(true);
      setAlertType('alert-error');
      console.log(error?.response?.data);
      setErrorMessage(error?.response?.data.message);
    }
  };

  useEffect(() => {
    if (loading && !user) return;
    if (user) {
      navigate('/user');
    }
  }, [loading, user, navigate]);

  return (
    <div className="auth-inner-login" style={{ height: '550px' }}>
      <form className="auth-form-login" onSubmit={loginUser}>
        <div className="form-input-container-login">
          <Input
            id="email"
            name="email"
            type="email"
            value={email}
            labelText="Email"
            placeholder="Enter email"
            style={{ border: `${hasError ? '1px solid #fa9b8a' : ''}` }}
            handleChange={(event) => setEmail(event.target.value)}
          />
          <Input
            id="password"
            name="password"
            type="password"
            value={password}
            labelText="Password"
            placeholder="Enter Password"
            style={{ border: `${hasError ? '1px solid #fa9b8a' : ''}` }}
            handleChange={(event) => setPassword(event.target.value)}
          />
          {hasError && errorMessage && (
            <div className={`alerts ${alertType}`} role="alert">
              {errorMessage}
            </div>
          )}
          <label className="checkmark-container-login" htmlFor="checkbox">
            <Input
              id="checkbox"
              name="checkbox"
              type="checkbox"
              value={keepLoggedIn}
              handleChange={() => setKeepLoggedIn(!keepLoggedIn)}
              style={{ marginBottom: 0 }}
            />
            Keep me signed in
          </label>
          <Link to={'/forgot-password'}>
            <span className="arrow-links">
              Forgot password? <FaArrowRight className="arrow-right" />
            </span>
          </Link>
          <Link to={'/reset-password'}>
            <span className="arrow-links">
              Reset password <FaArrowRight className="arrow-right" />
            </span>
          </Link>
          <Link to={'/verify'}>
            <span className="arrow-links">
              Verify email <FaArrowRight className="arrow-right" />
            </span>
          </Link>
          <Button
            label={`${loading ? 'LOGIN IN PROGRESS...' : 'LOGIN'}`}
            className="auth-button button"
            disabled={!email || !password}
          />
        </div>
      </form>
    </div>
  );
};

export default Login;
