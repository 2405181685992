/* eslint-disable no-unused-vars */
import '@pages/testing/test.scss';

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import originalLogo from '@assets/images/Logo_Exvirience_Original.png';
import { defaultLoadingTime } from '@root/App';
import { CheckForMobile } from '@hooks/General';

import CubeLoaderContainer from '@components/cube-spinner/Cube-loader-container';
// import MobileDetect from 'mobile-detect';

const Test = () => {
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const isMobileDevice = CheckForMobile(); // This will automatically handle redirection and detection
  console.log(isMobileDevice);

  useEffect(() => {
    let loadingTimer;
    let importingTimer;

    if (isMobileDevice) {
      navigate('/unauthorized');
    }

    // eslint-disable-next-line prefer-const
    loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, defaultLoadingTime);

    return () => {
      clearTimeout(loadingTimer);
      clearTimeout(importingTimer);
    };
  }, [isMobileDevice, navigate]);

  return (
    <div className="documentation-page">
      {isLoading && (
        <div className="auth-Editor-loader-container">
          <CubeLoaderContainer type="original-l" isImporting={false} />
        </div>
      )}
      <header className="header">
        <img src={originalLogo} alt="Logo" className="logo" />
      </header>
      <div className="main-content">
        <nav className="link-tree">
          <ul>
            <li>{`${isMobileDevice}`}</li>
            <li>{'userAgent'}</li>
            {/* Add more items as needed */}
          </ul>
        </nav>
        {/* Render the component based on the activeSection state */}
      </div>
    </div>
  );
};

export default Test;
