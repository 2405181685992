/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from 'react';
import SceneComponent from 'babylonjs-hook';
import {
  Vector3,
  HemisphericLight,
  ArcRotateCamera,
  WebXRFeatureName,
  MeshBuilder,
  Color4,
  StandardMaterial,
  Color3,
  ArcRotateCameraPointersInput,
  Mesh,
  SceneLoader,
  Quaternion,
  AnimationGroup,
  MeshExploder,
  VideoTexture,
  Texture,
  Sound,
  Vector2,
  Engine
} from '@babylonjs/core';
import '@babylonjs/loaders';
import '@babylonjs/core/XR';

import '@pages/user/vr/VR.scss';

import { useParams } from 'react-router-dom';
import { AWSGet, AWSGetURL, AWSGetImage } from '@services/api/AWS';
import { useSessionStorage } from '@hooks/General';

import SceneLoaderCSS from '@components/page-loader/SceneLoader';
import logoImage from '@assets/images/Logo_Exvirience_Original.png';

import { AdvancedDynamicTexture, TextBlock, Control, HandMenu, Button } from '@babylonjs/gui';

const VR = () => {
  const device = useSessionStorage('savedDevice', 'get');
  console.log('Is the device a tablet? ', device);

  // Create a ref for the audio element
  const clickSoundRef = useRef(null);

  let baseMesh;
  let isExploded = false;

  const toggleExplosionRef = useRef(null);

  // let isCanvasVisible = false;
  const toggleCanvasVisibilityRef = useRef(null);

  let isCanvasVisibleImage = false;
  const toggleCanvasVisibilityImageRef = useRef(null);

  const toggleSoundRef = useRef(null);
  const toggleGrabSoundRef = useRef(null);

  const textBlockRef = useRef(null);

  const buttonArray = [];
  let canvasArray = [];
  let imgvidArray = [];
  let textureArray = [];
  let isCanvasVisible = [false, false];

  useEffect(() => {
    const confirmExit = (event) => {
      // Cancel the event as returning a string will prompt a confirmation dialog
      event.preventDefault();
      // Chrome requires returnValue to be set
      event.returnValue = '';
      // Prompt user with a confirmation dialog
      const message = 'Are you sure you want to leave?'; // Your confirmation message
      event.returnValue = message; // For Chrome
      return message; // For other browsers
    };

    // Add event listener for beforeunload event
    window.addEventListener('beforeunload', confirmExit);

    // Cleanup by removing the event listener when component unmounts
    return () => {
      window.removeEventListener('beforeunload', confirmExit);
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount

  /* start WHAT SCENE TO INTIALIZE */
  // Access the dynamic `id` parameter from the URL
  const { name } = useParams();
  // Use the `id` to fetch data, initialize values, etc.
  console.log(name); // For demonstration purposes
  /* end WHAT SCENE TO INTIALIZE */

  const Bucket = 'exvirience-scenes';
  const Key = `${name}`;

  const downloadPercentage = useRef(0);
  const [loadingPrecent, setLoadingPercent] = useState(0);

  useEffect(() => {
    // Update the loadingArray state with the SceneLoaderCSS component
    downloadPercentage.current = loadingPrecent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPrecent]);

  const [isLoading, setIsLoading] = useState(true);

  // const [chunkNumberSet] = useSessionStorage(`${name}_chunkNumber`, 'set');

  /* start XR HANDS SETUP */
  const leftIndex = useRef(null);
  const rightIndex = useRef(null);

  const leftHandGrabbedObj = useRef(null);
  const rightHandGrabbedObj = useRef(null);

  const initialDistanceRef = useRef(null);
  const initialScalingRef = useRef(null);
  /* end XR HANDS SETUP */

  /* start Session DATABASE */
  // To instantiate the database and have faster loading
  // const { get, set, delete: deleteItem, clear } = useIndexedDB('localDB', 'sessionStore');
  // eslint-disable-next-line prefer-const
  let file = null;
  /* end Session DATABASE */

  // eslint-disable-next-line prefer-const
  let nonHandMeshes = [];
  const initialParent = useRef(null);
  const sceneInstance = useRef(null);

  const previousLeftIndexTipPosition = useRef(null);
  const previousRightIndexTipPosition = useRef(null);

  /* start Scene Loading Sphere */
  // The load mesh method is below in the scale reference section
  const [loadingMesh, setLoadingMesh] = useState(false);
  const loadingMeshRef = useRef(loadingMesh);
  // Declare state letiable to hold the loading spheres
  // now you can use previousToolbarStateRef.current to get the latest value of previousToolbarState
  useEffect(() => {
    // console.log(previousToolbarState);
    loadingMeshRef.current = loadingMesh;
  }, [loadingMesh]);

  // Reads the blob and returns a data URL
  const readFileAndLoadMesh = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        // Create a data URL with the correct MIME type for GLB files
        const dataUrl =
          'data:model/gltf-binary;base64,' +
          btoa(new Uint8Array(event.target.result).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        resolve(dataUrl);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob); // Read the blob as an ArrayBuffer
    });
  };

  const initialValues = {};

  let realScale;
  let initialScale;
  let initialPosition;
  let isRealScale = false;
  let settingRealScale = false;
  const toggleRealScaleRef = useRef(null);
  let initialCubePos;

  const textBlockRefHandMenu = useRef(null);
  const leftRefHandMenu = useRef(null);
  const leftWristRefHandMenu = useRef(null);
  const leftWristPlaneRefHandMenu = useRef(null);
  const leftWristPlaneRefHandMenuBackground = useRef(null);

  // const toggleVRARRef = useRef(null);

  const onSceneReady = async (scene) => {
    sceneInstance.current = scene;

    /* start BASIC SCENE SETUP */
    const camera = new ArcRotateCamera('camera', Math.PI / 2, Math.PI / 4, 1, new Vector3(0, 0, 0), scene);
    camera.position = new Vector3(6, 5, -6);

    // eslint-disable-next-line prefer-const
    let cameraInputManager = new ArcRotateCameraPointersInput();
    cameraInputManager.buttons = [0, 1, 2];

    // Adjust camera button inputs
    camera.inputs.attached.pointers.buttons[0] = undefined;
    camera.inputs.attached.pointers.buttons[2] = undefined;
    camera._panningMouseButton = cameraInputManager.buttons[2];

    camera.wheelPrecision = 10;
    camera.inputs.attached.pointers._useCtrlForPanning = true;
    camera.attachControl(scene.getEngine().getRenderingCanvas(), true);
    scene.clearColor = new Color4(0.85, 0.85, 0.85, 1);
    const light = new HemisphericLight('light', new Vector3(0, 1, 0), scene);

    const clickSound = await AWSGet('exvirience-shared', 'clickSound.mp3', false, true, false);
    function blobToUrl(blob) {
      return URL.createObjectURL(blob);
    }

    const soundArray = blobToUrl(clickSound);
    const clickPlay = new Sound('clickSound', soundArray, scene, playSound, { loop: false, volume: 0.2 });

    // Disable the default audio unlock button
    // Engine.audioEngine.useCustomUnlockedButton = false;
    // unmutes the audio automatically
    // Engine.audioEngine.unlock();
    // Engine.audioEngine.unlock();
    // Function to play the sound when sound is ready
    function playSound() {
      clickPlay.play();
    }

    const grabSound = await AWSGet('exvirience-shared', 'objectGrab.mp3', false, true, false);
    const grabSoundArray = blobToUrl(grabSound);
    const grabPlay = new Sound('grabSound', grabSoundArray, scene, grabPlaySound, { loop: false, volume: 0.05 });

    // Disable the default audio unlock button
    // Engine.audioEngine.useCustomUnlockedButton = false;
    // unmutes the audio automatically
    // Engine.audioEngine.unlock();
    // Engine.audioEngine.unlock();
    // Function to play the sound when sound is ready
    let lastAnimationTime = 0;

    function grabPlaySound() {
      const currentTime = Date.now(); // Get the current time
      const cooldown = 1000; // Cooldown period in milliseconds (1 second)

      // Check if the cooldown period has elapsed since the last animation
      if (currentTime - lastAnimationTime < cooldown) {
        // Cooldown period hasn't elapsed, exit the function
        return;
      }

      // Update the last animation time to the current time
      lastAnimationTime = currentTime;

      grabPlay.play();
    }

    toggleGrabSoundRef.current = grabPlaySound;
    // playSound();

    /* end BASIC SCENE SETUP */

    const stream = await AWSGet(Bucket, Key);

    const InstantiateInScene = async (value) => {
      const saveScaling = async (value) => {
        realScale = new Vector3(value.scaling._x, -value.scaling._y, value.scaling._z);

        console.log(realScale);
      };

      const createMesh = async (value) => {
        /* start FILE INITIALIZER AND STORE IN DB */

        // method to get the file RUL and instantiate it in the scene
        const getFileURL = async () => {
          console.warn('Downloading object from AWS S3');
          const object = await AWSGet('exvirience-objects', `${value.name}`, true, true, setLoadingPercent);
          console.log('Object stream: ', object);
          // const objBlob = await convertStreamToBlob(object);

          const objectBlob = object;
          console.log('Object blob: ', objectBlob);

          if (!objectBlob) {
            console.error('File not found in local storage');
            return;
          }

          try {
            const dataURL = await readFileAndLoadMesh(objectBlob);

            return dataURL;
          } catch (error) {
            console.error('Error reading file:', error);
          }
        };

        // instantiation starts
        try {
          const objURL = await getFileURL();

          // const objURL = await AWSGetURL('exvirience-objects', value.name, true);

          const result = await SceneLoader.ImportMeshAsync('', '', objURL, scene);
          // setIsLoading(false);
          const newMeshes = result.meshes;

          // console.log(newMeshes);

          newMeshes.forEach((mesh) => {
            if (mesh !== newMeshes[0] && mesh !== capsuleHandle) {
              mesh.setParent(null);
              mesh.metadata.type = 'free';
              nonHandMeshes.push(mesh);
              // console.log(mesh.position);
              // explodingMesh.push(mesh);
            }
          });

          newMeshes[0].scaling = new Vector3(-1, 1, -1);

          nonHandMeshes.forEach((mesh) => {
            if (
              mesh !== capsuleHandle &&
              mesh.metadata.type !== 'button' &&
              mesh.metadata.type !== 'handler' &&
              mesh.metadata.type !== 'xz'
            ) {
              mesh.setParent(newMeshes[0]);
            }
          });

          initialParent.current = newMeshes[0];

          newMeshes[0].scaling = new Vector3(value.scaling._x, value.scaling._y, value.scaling._z);
          initialScale = newMeshes[0].scaling;
          newMeshes[0].position = new Vector3(value.position._x, value.position._y + 1, value.position._z);
          initialPosition = newMeshes[0].position;
          newMeshes[0].rotationQuaternion = new Quaternion(
            value.quaternion._x,
            value.quaternion._y,
            value.quaternion._z,
            value.quaternion._w
          );

          let ypositionMesh;
          newMeshes.forEach((mesh) => {
            if (mesh !== newMeshes[0]) {
              initialValues[mesh.name] = {
                scaling: mesh.scaling.clone(),
                quaternion: !!mesh.rotationQuaternion,
                rotation: mesh.rotationQuaternion ? mesh.rotationQuaternion.clone() : mesh.rotation.clone(),
                position: mesh.position.clone()
              };

              // console.log(mesh.name);
              if (ypositionMesh) {
                if (ypositionMesh.position.y > mesh.position.y) {
                  ypositionMesh = mesh;
                }
              } else {
                ypositionMesh = mesh;
              }
              // console.log(initialValues[mesh.name]);
            }
          });

          /* nonHandMeshes.forEach((mesh) => {
            if (mesh.metadata && mesh.metadata.type === 'free') {
              explodingMesh.push(mesh);
              // console.log(mesh);
            }
          }); */

          if (ypositionMesh) {
            baseMesh = ypositionMesh;
            console.log(baseMesh.name);
            /* const newExplosion = new MeshExploder(explodingMesh, baseMesh);
            newExplosion.explode(1); */
          }

          const objDescription = value.description;

          newMeshes.forEach((mesh) => {
            // eslint-disable-next-line no-prototype-builtins
            if (objDescription.hasOwnProperty(mesh.name)) {
              // If keyName is found, return its value
              mesh.metadata.dsc = objDescription[mesh.name];
              console.log(mesh.metadata.dsc);
            }
          });
        } catch (error) {
          console.error(error);
        }
        /* end FILE INITIALIZER AND STORE IN DB */
      };

      if (value.type === 'mesh') {
        await createMesh(value);
      } else if (value.type === 'cloneBox') {
        await saveScaling(value);
      } else if (value.type === 'image' || value.type === 'video') {
        await createCanvasBox(value);
      }
    };

    async function createCanvasBox(value) {
      if (sceneInstance.current) {
        const material = new StandardMaterial('canvasMaterial', sceneInstance.current);
        material.diffuseColor = new Color3(0, 0, 212 / 255); // Blue color
        material.emissiveColor = new Color3(0, 0, 212 / 255);
        material.alpha = 0.2; // Transparency

        const box = MeshBuilder.CreateBox(
          `${value.name}`,
          { depth: 0.05, width: 1, height: 1 * (9 / 16) },
          sceneInstance.current
        );
        box.position = new Vector3(0, 0, 0);
        box.material = material;

        // Move the box upward 1/2 its height
        box.position.y = 1;

        /* ------------------------------------- */

        // Create a plane mesh
        const canvasObject = await AWSGet('exvirience-shared', 'Resizable Canvas.glb', true, true, null);
        const readFileAndLoadMesh = (blob) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (event) {
              // Create a data URL with the correct MIME type for GLB files
              const dataUrl =
                'data:model/gltf-binary;base64,' +
                btoa(new Uint8Array(event.target.result).reduce((data, byte) => data + String.fromCharCode(byte), ''));
              resolve(dataUrl);
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(blob); // Read the blob as an ArrayBuffer
          });
        };
        const dataURL = await readFileAndLoadMesh(canvasObject);
        const resultImage = await SceneLoader.ImportMeshAsync('', '', dataURL, sceneInstance.current);
        const resizableCanvasImage = resultImage.meshes;
        resizableCanvasImage[0].position = new Vector3(0, 0, 0); // Adjust position as needed
        resizableCanvasImage[0].scaling = new Vector3(2, -2, 2);
        resizableCanvasImage[0].rotate(new Vector3(0, 1, 0), Math.PI / 2);
        // console.log(resizableCanvas);

        resizableCanvasImage.forEach((mesh) => {
          mesh.material = material;
          mesh.setParent(null);
        });

        const canvasResizeImage = resizableCanvasImage[1];
        resizableCanvasImage[0].dispose();
        /* resizableCanvasImage[2].dispose();
        resizableCanvasImage[3].dispose();
        resizableCanvasImage[4].dispose(); */

        canvasResizeImage.metadata.type = 'handler';
        nonHandMeshes.push(canvasResizeImage);

        const videoPlaneImage = MeshBuilder.CreatePlane(
          'imagePlane',
          { width: 1, height: 1 * (9 / 16) },
          sceneInstance.current
        );

        // Position the plane where you want it
        videoPlaneImage.position = new Vector3(0, 0, 0); // Adjust position as needed

        if (value.type === 'image') {
          videoPlaneImage.scaling = new Vector3(1, 1, 1);
        } else if (value.type === 'video') {
          videoPlaneImage.scaling = new Vector3(1, -1, 1);
        }

        // Create a video texture
        const objectURL = await AWSGetURL(value.url.bucket, value.url.key);
        let objTexture;

        if (value.type === 'image') {
          objTexture = new Texture(objectURL, sceneInstance.current);
        } else if (value.type === 'video') {
          objTexture = new VideoTexture('videoTexture', objectURL, sceneInstance.current, true, true);
        }

        if (objTexture) {
          // Create a material and apply the video texture to it
          const videoMaterialImage = new StandardMaterial('imageMaterial', sceneInstance.current);
          videoMaterialImage.diffuseTexture = objTexture;
          // Increase the emissive color to brighten the video
          videoMaterialImage.emissiveColor = new Color3(1, 1, 1);

          // Apply the material to the plane
          videoPlaneImage.material = videoMaterialImage;

          videoPlaneImage.setParent(canvasResizeImage);
          console.log('Video plane parent: ', videoPlaneImage.parent);

          canvasResizeImage.setParent(box);
          canvasResizeImage.position = new Vector3(-0.5, 0.5 * (9 / 16), 0.05);
          // canvasResizeImage.scaling = new Vector3(2, -2, 2);

          canvasResizeImage.isVisible = false;
          videoPlaneImage.isVisible = false;

          box.position = new Vector3(value.position._x, value.position._y + 1, value.position._z);
          box.scaling = new Vector3(value.scaling._x, value.scaling._y, value.scaling._z);
          box.rotation = new Vector3(value.rotation._x, value.rotation._y, value.rotation._z);

          if (capsuleHandle) {
            canvasResizeImage.setParent(null);
            box.dispose();
            canvasResizeImage.setParent(capsuleHandle);
          }

          canvasArray.push(canvasResizeImage);
          imgvidArray.push(videoPlaneImage);

          if (value.type === 'video') {
            objTexture.video.pause();
            textureArray.push(objTexture);
          } else {
            textureArray.push(null);
          }
        }
      }
    }

    function restartVideo(num) {
      if (textureArray && textureArray[num]) {
        console.log(textureArray[num]);
        textureArray[num].video.currentTime = 0;
        textureArray[num].video.play();
      }
    }

    function pauseVideo(num) {
      if (textureArray && textureArray[num]) {
        textureArray[num].video.pause();
      }
    }

    function toggleCanvasVisible(num) {
      if (canvasArray && imgvidArray) {
        const currentTime = Date.now(); // Get the current time
        const cooldown = 1000; // Cooldown period in milliseconds (1 second)

        // Check if the cooldown period has elapsed since the last animation
        if (currentTime - lastAnimationTime < cooldown) {
          // Cooldown period hasn't elapsed, exit the function
          return;
        }

        // Update the last animation time to the current time
        lastAnimationTime = currentTime;

        playSound();
        if (isCanvasVisible[num]) {
          console.log('Toggling off');
          canvasArray[num].isVisible = false;
          imgvidArray[num].isVisible = false;

          pauseVideo(num);

          isCanvasVisible[num] = false;
        } else {
          canvasArray[num].isVisible = false;
          imgvidArray[num].isVisible = false;
          isCanvasVisibleImage = false;
          console.log('Toggling on');

          restartVideo(num);

          canvasArray[num].isVisible = true;
          imgvidArray[num].isVisible = true;

          isCanvasVisible[num] = true;
        }
      }
    }

    toggleCanvasVisibilityImageRef.current = toggleCanvasVisible;

    let capsuleHandle;

    // Function to animate objects back to their initial values using lerp
    function animateToInitialValues() {
      const currentTime = Date.now(); // Get the current time
      const cooldown = 1000; // Cooldown period in milliseconds (1 second)

      // Check if the cooldown period has elapsed since the last animation
      if (currentTime - lastAnimationTime < cooldown) {
        // Cooldown period hasn't elapsed, exit the function
        return;
      }

      // Update the last animation time to the current time
      lastAnimationTime = currentTime;

      playSound();
      nonHandMeshes.forEach((mesh) => {
        if (mesh.metadata && mesh.metadata.type === 'free') {
          const initialValue = initialValues[mesh.name];
          console.log(initialValue);

          // Interpolate scaling
          mesh.scaling.copyFrom(initialValue.scaling);

          // Set rotation to initial value
          // eslint-disable-next-line no-constant-condition
          if (initialValue.rotation instanceof Quaternion) {
            // If initialValue.rotation is a Quaternion, apply it to rotationQuaternion
            mesh.rotationQuaternion.copyFrom(initialValue.rotation);
          } else if (initialValue.rotation instanceof Vector3) {
            // If initialValue.rotation is a Vector3, apply it to rotation
            mesh.rotation.copyFrom(initialValue.rotation);
          }

          // Set position to initial value
          mesh.position.copyFrom(initialValue.position);
        }
      });

      isExploded = false;
    }

    function toggleExplode() {
      if (isExploded) {
        animateToInitialValues();
      } else {
        const currentTime = Date.now(); // Get the current time
        const cooldown = 1000; // Cooldown period in milliseconds (1 second)

        // Check if the cooldown period has elapsed since the last animation
        if (currentTime - lastAnimationTime < cooldown) {
          // Cooldown period hasn't elapsed, exit the function
          return;
        }

        // Update the last animation time to the current time
        lastAnimationTime = currentTime;

        playSound();

        const explodeMesh = [];
        nonHandMeshes.forEach((mesh) => {
          if (mesh.metadata && mesh.metadata.type === 'free') {
            explodeMesh.push(mesh);
            console.log(mesh);
          }
        });

        if (explodeMesh) {
          console.log('here');
          const newExplosion = new MeshExploder(explodeMesh, baseMesh);
          newExplosion.explode(1);

          isExploded = true;
        }
      }
    }

    toggleExplosionRef.current = toggleExplode;

    // eslint-disable-next-line no-constant-condition
    if (true) {
      /* start CAPSULE HANDLE SETUP */
      capsuleHandle = MeshBuilder.CreateCapsule(
        'capsule',
        { orientation: new Vector3(1, 0, 0), height: 0.25, radius: 0.01 },
        scene
      );

      capsuleHandle.position = new Vector3(0, 1, 0);
      const material = new StandardMaterial('material', scene);
      material.diffuseColor = new Color3(0, 0, 212 / 255); // Blue color
      material.emissiveColor = new Color3(0, 0, 212 / 255);
      material.alpha = 0.6; // Transparency
      // Assign materials to the capsule
      capsuleHandle.material = material;
      capsuleHandle.scaling = new Vector3(1, 1, 1);
      capsuleHandle.metadata = {
        type: 'handler',
        name: 'Drag all',
        onClick: {}
      };

      // Assuming the capsule handle's position is already set
      const planeHeight = 1;
      const planeWidth = 1;
      const plane0 = MeshBuilder.CreatePlane('textPlane0', { height: planeHeight, width: planeWidth }, scene);

      // Position the plane above the capsule handle
      plane0.position = new Vector3(
        capsuleHandle.position.x + 0.25 / 2 + 0.1,
        capsuleHandle.position.y + 0.05,
        capsuleHandle.position.z + 0.05
      );

      // Tilt the plane by 30 degrees along the X-axis
      plane0.rotation.x = Math.PI / 4; // 30 degrees in radians

      // Create a dynamic texture for displaying text
      const dynamicTexture0 = new AdvancedDynamicTexture(scene);

      // Create and configure the text block
      const textBlock0 = new TextBlock();
      textBlock0.text = 'Reset components';
      textBlock0.fontSize = 25;
      textBlock0.color = 'blue';
      dynamicTexture0.addControl(textBlock0);

      // Apply the dynamic texture to the plane's material
      plane0.material = new StandardMaterial('planeMaterial0', scene);
      plane0.material.diffuseTexture = dynamicTexture0;

      plane0.setParent(capsuleHandle);

      /* ------------------ */

      const plane1 = MeshBuilder.CreatePlane('textPlane1', { height: planeHeight, width: planeWidth }, scene);

      // Position the plane above the capsule handle
      plane1.position = new Vector3(
        capsuleHandle.position.x + 0.25 / 2 + 0.2,
        capsuleHandle.position.y - 0.05,
        capsuleHandle.position.z - 0.05
      );

      // Tilt the plane by 30 degrees along the X-axis
      plane1.rotation.x = Math.PI / 4; // 30 degrees in radians

      // Create a dynamic texture for displaying text
      const dynamicTexture1 = new AdvancedDynamicTexture(scene);

      // Create and configure the text block
      const textBlock1 = new TextBlock();
      textBlock1.text = 'Toggle explode';
      textBlock1.fontSize = 25;
      textBlock1.color = 'blue';
      dynamicTexture1.addControl(textBlock1);

      // Apply the dynamic texture to the plane's material
      plane1.material = new StandardMaterial('planeMaterial1', scene);
      plane1.material.diffuseTexture = dynamicTexture1;

      plane1.setParent(capsuleHandle);

      /* ------------------ */

      const plane2 = MeshBuilder.CreatePlane('textPlane2', { height: planeHeight, width: planeWidth }, scene);

      // Position the plane above the capsule handle
      plane2.position = new Vector3(
        capsuleHandle.position.x + 0.25 / 2 + 0.3,
        capsuleHandle.position.y + 0.05,
        capsuleHandle.position.z + 0.05
      );

      // Tilt the plane by 30 degrees along the X-axis
      plane2.rotation.x = Math.PI / 4; // 30 degrees in radians

      // Create a dynamic texture for displaying text
      const dynamicTexture2 = new AdvancedDynamicTexture(scene);

      // Create and configure the text block
      const textBlock2 = new TextBlock();
      textBlock2.text = 'Image / Video';
      textBlock2.fontSize = 25;
      textBlock2.color = 'blue';
      dynamicTexture2.addControl(textBlock2);

      // Apply the dynamic texture to the plane's material
      plane2.material = new StandardMaterial('planeMaterial2', scene);
      plane2.material.diffuseTexture = dynamicTexture2;

      plane2.setParent(capsuleHandle);

      /* ------------------ */

      const plane3 = MeshBuilder.CreatePlane('textPlane3', { height: planeHeight, width: planeWidth }, scene);

      // Position the plane above the capsule handle
      plane3.position = new Vector3(
        capsuleHandle.position.x + 0.25 / 2 + 0.4,
        capsuleHandle.position.y - 0.05,
        capsuleHandle.position.z - 0.05
      );

      // Tilt the plane by 30 degrees along the X-axis
      plane3.rotation.x = Math.PI / 4; // 30 degrees in radians

      // Create a dynamic texture for displaying text
      const dynamicTexture3 = new AdvancedDynamicTexture(scene);

      // Create and configure the text block
      const textBlock3 = new TextBlock();
      textBlock3.text = 'Image / Video';
      textBlock3.fontSize = 25;
      textBlock3.color = 'blue';
      dynamicTexture3.addControl(textBlock3);

      // Apply the dynamic texture to the plane's material
      plane3.material = new StandardMaterial('planeMaterial3', scene);
      plane3.material.diffuseTexture = dynamicTexture3;

      plane3.setParent(capsuleHandle);

      /* ------------------ */

      const plane4 = MeshBuilder.CreatePlane('textPlane4', { height: planeHeight, width: planeWidth }, scene);

      // Position the plane above the capsule handle
      plane4.position = new Vector3(
        capsuleHandle.position.x + 0.25 / 2 + 0.5,
        capsuleHandle.position.y + 0.05,
        capsuleHandle.position.z + 0.05
      );

      // Tilt the plane by 30 degrees along the X-axis
      plane4.rotation.x = Math.PI / 4; // 30 degrees in radians

      // Create a dynamic texture for displaying text
      const dynamicTexture4 = new AdvancedDynamicTexture(scene);

      // Create and configure the text block
      const textBlock4 = new TextBlock();
      textBlock4.text = 'View real scale';
      textBlock4.fontSize = 25;
      textBlock4.color = 'blue';
      dynamicTexture4.addControl(textBlock4);

      // Apply the dynamic texture to the plane's material
      plane4.material = new StandardMaterial('planeMaterial4', scene);
      plane4.material.diffuseTexture = dynamicTexture4;

      plane4.setParent(capsuleHandle);

      /* VIDEO CANVAS /
      // Create a plane mesh
      const object = await AWSGet('exvirience-shared', 'Resizable Canvas.glb', true, true, null);
      const readFileAndLoadMesh = (blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = function (event) {
            // Create a data URL with the correct MIME type for GLB files
            const dataUrl =
              'data:model/gltf-binary;base64,' +
              btoa(new Uint8Array(event.target.result).reduce((data, byte) => data + String.fromCharCode(byte), ''));
            resolve(dataUrl);
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(blob); // Read the blob as an ArrayBuffer
        });
      };
      const dataURL = await readFileAndLoadMesh(object);
      const result = await SceneLoader.ImportMeshAsync('', '', dataURL, scene);
      const resizableCanvas = result.meshes;
      resizableCanvas[0].position = new Vector3(0, 0, 0); // Adjust position as needed
      resizableCanvas[0].scaling = new Vector3(2, -2, 2);
      resizableCanvas[0].rotate(new Vector3(0, 1, 0), Math.PI / 2);
      // console.log(resizableCanvas);

      resizableCanvas.forEach((mesh) => {
        mesh.material = material;
        mesh.setParent(null);
      });

      const canvasResize = resizableCanvas[1];
      resizableCanvas[0].dispose();
      canvasResize.metadata.type = 'handler';

      const videoPlane = MeshBuilder.CreatePlane('videoPlane', { width: 1, height: 1 * (9 / 16) }, scene);

      // Position the plane where you want it
      videoPlane.position = new Vector3(0, 0, 0); // Adjust position as needed
      videoPlane.scaling = new Vector3(1, -1, 1);

      // Create a video texture
      const videoURL = await AWSGetURL('exvirience-media', 'TRAILER multiplayer clip.mp4', true);
      const videoTexture = new VideoTexture('videoTexture', videoURL, scene, true, true);

      // Create a material and apply the video texture to it
      const videoMaterial = new StandardMaterial('videoMaterial', scene);
      videoMaterial.diffuseTexture = videoTexture;
      // Increase the emissive color to brighten the video
      videoMaterial.emissiveColor = new Color3(1, 1, 1);

      // Apply the material to the plane
      videoPlane.material = videoMaterial;

      nonHandMeshes.push(canvasResize);

      videoPlane.setParent(canvasResize);
      console.log('Video plane parent: ', videoPlane.parent);

      canvasResize.setParent(capsuleHandle);
      canvasResize.position = new Vector3(0, 1, 0);
      // canvasResize.scaling = new Vector3(2, -2, 2);

      // Function to restart the video
      function restartVideo() {
        if (videoTexture) {
          videoTexture.video.currentTime = 0;
          videoTexture.video.play();
        }
      }

      canvasResize.isVisible = false;
      videoPlane.isVisible = false;

      function toggleCanvasVisible() {
        const currentTime = Date.now(); // Get the current time
        const cooldown = 1000; // Cooldown period in milliseconds (1 second)

        // Check if the cooldown period has elapsed since the last animation
        if (currentTime - lastAnimationTime < cooldown) {
          // Cooldown period hasn't elapsed, exit the function
          return;
        }

        // Update the last animation time to the current time
        lastAnimationTime = currentTime;

        playSound();
        if (isCanvasVisible) {
          console.log('Toggling off');
          canvasResize.isVisible = false;
          videoPlane.isVisible = false;

          isCanvasVisible = false;
        } else {
          canvasResizeImage.isVisible = false;
          videoPlaneImage.isVisible = false;
          isCanvasVisibleImage = false;
          console.log('Toggling on');

          restartVideo();

          canvasResize.isVisible = true;
          videoPlane.isVisible = true;

          isCanvasVisible = true;
        }
      }

      toggleCanvasVisibilityRef.current = toggleCanvasVisible;
      / VIDEO CANVAS */

      /* IMAGE CANVAS /
      // Create a plane mesh
      const imageObject = await AWSGet('exvirience-shared', 'Resizable Canvas.glb', true, true, null);
      const dataURLImage = await readFileAndLoadMesh(imageObject);
      const resultImage = await SceneLoader.ImportMeshAsync('', '', dataURLImage, scene);
      const resizableCanvasImage = resultImage.meshes;
      resizableCanvasImage[0].position = new Vector3(0, 0, 0); // Adjust position as needed
      resizableCanvasImage[0].scaling = new Vector3(2, -2, 2);
      resizableCanvasImage[0].rotate(new Vector3(0, 1, 0), Math.PI / 2);
      // console.log(resizableCanvas);

      resizableCanvasImage.forEach((mesh) => {
        mesh.material = material;
        mesh.setParent(null);
      });

      const canvasResizeImage = resizableCanvasImage[1];
      resizableCanvasImage[0].dispose();
      canvasResizeImage.metadata.type = 'handler';

      const videoPlaneImage = MeshBuilder.CreatePlane('imagePlane', { width: 1, height: 1 * (9 / 16) }, scene);

      // Position the plane where you want it
      videoPlaneImage.position = new Vector3(0, 0, 0); // Adjust position as needed
      videoPlaneImage.scaling = new Vector3(1, 1, 1);

      // Create a video texture
      const videoURLImage = await AWSGetURL('exvirience-media', 'scheda tecnica prova.png', true);
      const videoTextureImage = new Texture(videoURLImage, scene);

      // Create a material and apply the video texture to it
      const videoMaterialImage = new StandardMaterial('imageMaterial', scene);
      videoMaterialImage.diffuseTexture = videoTextureImage;
      // Increase the emissive color to brighten the video
      videoMaterialImage.emissiveColor = new Color3(1, 1, 1);

      // Apply the material to the plane
      videoPlaneImage.material = videoMaterialImage;

      nonHandMeshes.push(canvasResizeImage);

      videoPlaneImage.setParent(canvasResizeImage);
      console.log('Video plane parent: ', videoPlaneImage.parent);

      canvasResizeImage.setParent(capsuleHandle);
      canvasResizeImage.position = new Vector3(0, 1, 0);
      // canvasResizeImage.scaling = new Vector3(2, -2, 2);

      canvasResizeImage.isVisible = false;
      videoPlaneImage.isVisible = false;

      function toggleCanvasVisibleImage() {
        const currentTime = Date.now(); // Get the current time
        const cooldown = 1000; // Cooldown period in milliseconds (1 second)

        // Check if the cooldown period has elapsed since the last animation
        if (currentTime - lastAnimationTime < cooldown) {
          // Cooldown period hasn't elapsed, exit the function
          return;
        }

        // Update the last animation time to the current time
        lastAnimationTime = currentTime;
        playSound();
        if (isCanvasVisibleImage) {
          console.log('Toggling off');
          canvasResizeImage.isVisible = false;
          videoPlaneImage.isVisible = false;

          isCanvasVisibleImage = false;
        } else {
          canvasResize.isVisible = false;
          videoPlane.isVisible = false;
          isCanvasVisible = false;
          console.log('Toggling on');

          restartVideo();

          canvasResizeImage.isVisible = true;
          videoPlaneImage.isVisible = true;

          isCanvasVisibleImage = true;
        }
      }

      toggleCanvasVisibilityImageRef.current = toggleCanvasVisibleImage;
      / IMAGE CANVAS */

      /* Real scale object */
      // Create a cube
      const cubeRealScale = MeshBuilder.CreateBox('cubeRealScale', { size: 0.15 }, scene);
      cubeRealScale.position = new Vector3(0, 1.2, 0.5); // Adjust the position of the cube
      // cubeRealScale.metadata = { type: 'xz' }; // Set metadata type
      const cubeMaterial = new StandardMaterial('material', scene);
      cubeMaterial.diffuseColor = new Color3(0, 0, 212 / 255); // Blue color
      cubeMaterial.emissiveColor = new Color3(0, 0, 212 / 255);
      cubeMaterial.alpha = 1;
      cubeRealScale.material = cubeMaterial;
      initialCubePos = cubeRealScale.position;

      // Create a plane
      const planeRealScale = MeshBuilder.CreatePlane('planeRealScale', { size: 0.5 }, scene);
      planeRealScale.position = new Vector3(0, 1, 0.5); // Adjust the position of the plane
      planeRealScale.rotate(new Vector3(1, 0, 0), Math.PI / 2);
      const planeMaterialCube = new StandardMaterial('material', scene);
      planeMaterialCube.diffuseColor = new Color3(0, 0, 212 / 255); // Blue color
      planeMaterialCube.emissiveColor = new Color3(0, 0, 212 / 255);
      planeMaterialCube.alpha = 0.3;

      planeRealScale.material = planeMaterialCube;

      planeRealScale.isVisible = false;
      cubeRealScale.isVisible = false;

      planeRealScale.setParent(capsuleHandle);
      cubeRealScale.setParent(capsuleHandle);

      nonHandMeshes.push(cubeRealScale);

      cubeRealScale.metadata = {};
      cubeRealScale.metadata.type = 'xz';

      // console.error(cubeRealScale.metadata);

      // Assuming you have access to the initial positions of both objects
      const initialPositionCube = cubeRealScale.position; // Initial position of the cube (object A)

      // Method to calculate proportional movement of the other object based on the movement of the cube
      function moveOtherObject(cubePosition, initialPositionOtherObject, otherObject) {
        // Calculate the difference between the current position of the cube and its initial position
        const movementDelta = cubePosition.subtract(initialPositionCube);

        // Apply the movement ratio to the movement delta
        const scaledMovementDelta = movementDelta.scale(0.3);

        // Set the position of the other object proportionally
        const newPositionOtherObject = initialPositionOtherObject.add(scaledMovementDelta);

        // Update the position of the other object
        otherObject.position = newPositionOtherObject;
      }

      scene.onBeforeRenderObservable.add(() => {
        if (settingRealScale) {
          planeRealScale.isVisible = true;
          cubeRealScale.isVisible = true;

          if (cubeRealScale !== initialPositionCube) {
            moveOtherObject(cubeRealScale.position, initialParent.current.position, initialParent.current);
          }
        } else {
          planeRealScale.isVisible = false;
          cubeRealScale.isVisible = false;
        }
      });

      function toggleToRealScale() {
        const currentTime = Date.now(); // Get the current time
        const cooldown = 1000; // Cooldown period in milliseconds (1 second)

        // Check if the cooldown period has elapsed since the last animation
        if (currentTime - lastAnimationTime < cooldown) {
          // Cooldown period hasn't elapsed, exit the function
          return;
        }

        // Update the last animation time to the current time
        lastAnimationTime = currentTime;

        playSound();
        if (initialParent && realScale && initialScale) {
          console.log(initialParent.current.position);
          console.log(realScale);
          console.log(initialScale);
        }

        if (isRealScale) {
          if (initialParent && initialScale && realScale && initialPosition) {
            console.log('setting initial scale');
            initialParent.current.scaling = new Vector3(initialScale.x, initialScale.y, initialScale.z);
            initialParent.current.position = new Vector3(initialPosition.x, initialPosition.y, initialPosition.z);

            isRealScale = false;
            settingRealScale = false;
          }
        } else {
          if (initialParent && initialScale && realScale && initialPosition) {
            settingRealScale = true;

            console.log('setting real scale');
            console.log('mesh 0 children: ', initialParent.current);

            initialParent.current.scaling = new Vector3(realScale.x, realScale.y, realScale.z);

            initialParent.current.position = initialParent.current.position.add(new Vector3(0, 0, 5));
            initialParent.current.position.y = 0;
            /* Calculate the new position with the initial offset
            let newPositionReal = capsuleHandle.position.add(initialOffsetHandleRef.current);
            // Add the extra movement vector
            newPositionReal = newPositionReal.add(new Vector3(0, 0, 5));
            newPositionReal.y = 0;

            initialParent.current.position = newPositionReal; */

            console.log('real scale set: ', initialParent.current.position);

            isRealScale = true;
          }
        }
      }

      toggleRealScaleRef.current = toggleToRealScale;
      /* Real scale object */

      // Positioning and creation parameters
      const numberOfSpheres = 5;
      const sphereRadius = 0.02;
      const distanceBetweenSpheres = 0.1; // 10 cm = 0.1 units
      const startPositionX = capsuleHandle.position.x + 0.25 / 2 + distanceBetweenSpheres; // Start 10cm to the left of the capsule

      // Create spheres in a loop
      for (let i = 0; i < numberOfSpheres; i++) {
        const sphere = MeshBuilder.CreateSphere(`Sphere_${i}`, { diameter: sphereRadius * 2 }, scene);

        // Position each sphere
        sphere.position.x = startPositionX + i * distanceBetweenSpheres; // Move each sphere 10cm further left
        sphere.position.y = capsuleHandle.position.y; // Align with the capsule's Y position
        sphere.position.z = capsuleHandle.position.z; // Align with the capsule's Z position

        if (i === 0) {
          sphere.metadata = {
            type: 'button',
            name: 'Reset components',
            onClick: { method: animateToInitialValues }
          };
        } else if (i === 1) {
          sphere.metadata = {
            type: 'button',
            name: 'Toggle explode',
            onClick: { method: toggleExplode }
          };
        } else if (i === 2) {
          sphere.metadata = {
            type: 'button',
            name: 'Image / Video',
            onClick: { method: () => toggleCanvasVisible(0) }
          };
        } else if (i === 3) {
          sphere.metadata = {
            type: 'button',
            name: 'Image / Video',
            onClick: { method: () => toggleCanvasVisible(1) }
          };
        } else {
          sphere.metadata = {
            type: 'button',
            name: 'Toggle real scale',
            onClick: { method: toggleToRealScale }
          };
        }

        // Apply a basic material to each sphere (optional)
        const sphereMaterial = new StandardMaterial(`sphereMaterial${i}`, scene);
        sphereMaterial.diffuseColor = new Color3(0, 0, 212 / 255); // Blue color
        sphereMaterial.emissiveColor = new Color3(0, 0, 212 / 255);
        sphereMaterial.alpha = 0.6; // Transparency
        sphere.material = sphereMaterial;

        nonHandMeshes.push(sphere);
        // buttonArray.push(sphere);

        sphere.setParent(capsuleHandle);

        console.log(sphere.metadata.name);
      }

      // console.error(buttonArray);

      // Create ground plane (floor of the box)
      const groundPlane = MeshBuilder.CreatePlane('groundPlane', { width: 50, height: 50 }, scene);
      groundPlane.position = new Vector3(0, 0, 0);
      groundPlane.rotation.x = Math.PI / 2; // Rotate to lie flat
      groundPlane.lookAt(new Vector3(0, 25, 0)); // Automatically orient plane to face the center

      // Create ceiling plane
      const ceilingPlane = MeshBuilder.CreatePlane('ceilingPlane', { width: 50, height: 50 }, scene);
      ceilingPlane.position = new Vector3(0, 50, 0); // Positioned 50 units up
      ceilingPlane.rotation.x = -Math.PI / 2; // Rotate to face inward
      ceilingPlane.lookAt(new Vector3(0, 25, 0)); // Automatically orient plane to face the center

      // Create four side walls
      const sidePlanes = [];
      for (let i = 0; i < 4; i++) {
        const plane = MeshBuilder.CreatePlane(`sidePlane${i}`, { width: 50, height: 50 }, scene);
        plane.position = new Vector3(Math.sin((Math.PI / 2) * i) * 25, 25, Math.cos((Math.PI / 2) * i) * 25);
        plane.lookAt(new Vector3(0, 25, 0)); // Automatically orient plane to face the center
        sidePlanes.push(plane);
      }

      // Optional: Create a standard material and assign it to all planes
      const planeMaterial = new StandardMaterial('planeMaterial', scene);
      planeMaterial.diffuseColor = new Color3(1, 1, 1); // Set color to white
      planeMaterial.emissiveColor = new Color3(1, 1, 1);
      planeMaterial.disableLighting = true; // Ensure color is not influenced by lighting
      planeMaterial.backFaceCulling = false; // Enable back face culling (this is the default)

      groundPlane.material = planeMaterial;
      ceilingPlane.material = planeMaterial;
      sidePlanes.forEach((plane) => (plane.material = planeMaterial));

      groundPlane.isVisible = false;
      ceilingPlane.isVisible = false;

      // Toggle side planes
      sidePlanes.forEach((plane) => {
        plane.isVisible = false;
      });

      // Function to toggle the visibility of all planes
      function toggleXRPlanes() {
        const currentTime = Date.now(); // Get the current time
        const cooldown = 1000; // Cooldown period in milliseconds (1 second)

        // Check if the cooldown period has elapsed since the last animation
        if (currentTime - lastAnimationTime < cooldown) {
          // Cooldown period hasn't elapsed, exit the function
          return;
        }

        // Update the last animation time to the current time
        lastAnimationTime = currentTime;
        // Toggle ground and ceiling planes
        groundPlane.isVisible = !groundPlane.isVisible;
        ceilingPlane.isVisible = !ceilingPlane.isVisible;

        // Toggle side planes
        sidePlanes.forEach((plane) => {
          plane.isVisible = !plane.isVisible;
        });
      }

      // toggleXRPlanes();

      /* ------------------ */

      const plane5 = MeshBuilder.CreatePlane('textPlane5', { height: planeHeight, width: planeWidth }, scene);

      // Position the plane above the capsule handle
      plane5.position = new Vector3(
        capsuleHandle.position.x - 0.25 / 2 - 0.1,
        capsuleHandle.position.y + 0.05,
        capsuleHandle.position.z + 0.05
      );

      // Tilt the plane by 30 degrees along the X-axis
      plane5.rotation.x = Math.PI / 4; // 30 degrees in radians

      // Create a dynamic texture for displaying text
      const dynamicTexture5 = new AdvancedDynamicTexture(scene);

      // Create and configure the text block
      const textBlock5 = new TextBlock();
      textBlock5.text = 'Toggle VR';
      textBlock5.fontSize = 25;
      textBlock5.color = 'blue';
      dynamicTexture5.addControl(textBlock5);

      // Apply the dynamic texture to the plane's material
      plane5.material = new StandardMaterial('planeMaterial5', scene);
      plane5.material.diffuseTexture = dynamicTexture5;

      plane5.setParent(capsuleHandle);

      const sphere5 = MeshBuilder.CreateSphere(`Sphere_5`, { diameter: sphereRadius * 2 }, scene);
      // Position each sphere
      sphere5.position.x = capsuleHandle.position.x - 0.25 / 2 - distanceBetweenSpheres; // Move each sphere 10cm further left
      sphere5.position.y = capsuleHandle.position.y; // Align with the capsule's Y position
      sphere5.position.z = capsuleHandle.position.z; // Align with the capsule's Z position
      sphere5.metadata = {
        type: 'button',
        name: 'Toggle XR',
        onClick: { method: toggleXRPlanes }
      };

      // Apply a basic material to each sphere (optional)
      const sphereMaterial = new StandardMaterial(`sphereMaterial5`, scene);
      sphereMaterial.diffuseColor = new Color3(0, 0, 212 / 255); // Blue color
      sphereMaterial.emissiveColor = new Color3(0, 0, 212 / 255);
      sphereMaterial.alpha = 0.6; // Transparency
      sphere5.material = sphereMaterial;

      nonHandMeshes.push(sphere5);
      // buttonArray.push(sphere);

      sphere5.setParent(capsuleHandle);
    }

    if (device) {
      // GUI
      const advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI('UI', true, scene);

      // Create the "Explode" button
      const explodeButton = Button.CreateSimpleButton('explodeButton', 'EXPLODE');
      explodeButton.width = '150px';
      explodeButton.height = '40px';
      explodeButton.color = 'white';
      explodeButton.background = 'rgb(0, 0, 212)';
      explodeButton.cornerRadius = 20;
      explodeButton.thickness = 1;
      explodeButton.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
      explodeButton.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
      explodeButton.left = '50px';
      explodeButton.bottom = '10px';
      advancedTexture.addControl(explodeButton);

      // Button click action
      explodeButton.onPointerUpObservable.add(function () {
        // console.log('Explode button clicked!');
        toggleExplosionRef.current();
      });
    }

    // Positioning and creation parameters
    const sphereRadius = 0.01;

    const sphere = MeshBuilder.CreateSphere(`wristSphere`, { diameter: sphereRadius * 2 }, scene);
    sphere.position = new Vector3(0, 0, 0);
    leftWristRefHandMenu.current = sphere;
    leftWristRefHandMenu.current.isVisible = false;

    const planeBackground = MeshBuilder.CreatePlane('handPlaneBackground', { height: 0.2, width: 0.3 }, scene);
    planeBackground.setParent(sphere);

    leftWristPlaneRefHandMenuBackground.current = planeBackground;
    leftWristPlaneRefHandMenuBackground.current.isVisible = false;

    planeBackground.position = new Vector3(0.2, 0, 0);
    // planeBackground.rotate(new Vector3(0, 1, 0), Math.PI / 2);

    planeBackground.rotate(new Vector3(1, 0, 0), Math.PI / 2);

    // Create a dynamic texture for displaying text
    planeBackground.material = new StandardMaterial('handplaneMaterialBackground', scene);
    // Apply the dynamic texture to the plane's material
    planeBackground.material.diffuseColor = new Color3(1, 1, 1); // White color
    planeBackground.material.alpha = 1; // Transparency */

    const plane = MeshBuilder.CreatePlane('handPlane', { height: 0.2, width: 0.3 }, scene);
    plane.setParent(sphere);

    leftWristPlaneRefHandMenu.current = plane;
    leftWristPlaneRefHandMenu.current.isVisible = false;

    // Create a dynamic texture for displaying text
    const dynamicTexture = new AdvancedDynamicTexture(scene);

    // Create and configure the text block
    const textBlock = new TextBlock();
    textBlock.text = 'Hello world!';
    textBlock.fontSize = 100;
    textBlock.color = 'black';
    textBlock.lineSpacing = 30;
    textBlock.textWrapping = true;
    textBlock.width = 0.8; // Use 1 (100%) to utilize the full width of the texture
    textBlock.height = 0.5; // Adjust as needed, relative to the texture height

    dynamicTexture.addControl(textBlock);

    plane.position = new Vector3(0.2, 0, 0);
    plane.rotate(new Vector3(1, 0, 0), Math.PI / 2);

    // Apply the dynamic texture to the plane's material
    plane.material = new StandardMaterial('handplaneMaterial', scene);
    plane.material.diffuseTexture = dynamicTexture;

    // Set the diffuse color of the material to semi-transparent white
    // plane.material.diffuseColor = new Color3(1, 1, 1); // White color
    // plane.material.alpha = 0.3; // Transparency */

    textBlockRefHandMenu.current = textBlock;

    /* scene.onBeforeRenderObservable.add(() => {
      if (leftRefHandMenu.current) {
        sphere.position = leftRefHandMenu.current.potition;

        if (leftRefHandMenu.current.rotationQuaternion) {
          sphere.rotationQuaternion = leftRefHandMenu.current.rotationQuaternion;
        } else {
          sphere.rotation = leftRefHandMenu.current.rotation;
        }
      }
    }); */

    /*
    const DisableAllMeshesExceptPlaceholder = () => {
      // Iterate through all meshes in the scene
      nonHandMeshes.forEach((mesh) => {
        // Set visibility to false for each mesh
        mesh.isVisible = false;
      });

      const material = new StandardMaterial('material', scene);
      material.diffuseColor = new Color3(0, 0, 212 / 255); // Blue color
      material.alpha = 1;

      // Create a red cylinder
      const cylinder = MeshBuilder.CreateCylinder('cylinderPlaneStart', { height: 0.01, diameter: 0.5 }, scene);
      cylinder.position = new Vector3(0, 0, 0); // Position the cylinder 10cm above the ground

      const redMaterial = new StandardMaterial('redMaterial', scene);
      redMaterial.diffuseColor = new Color3(1, 0, 0); // Red color

      cylinder.material = material;

      // Create a sphere
      const sphere = MeshBuilder.CreateSphere('sphereButtonStart', { diameter: 0.1 }, scene);
      sphere.position = new Vector3(0, 0.5, 0); // Position the sphere 10cm above the cylinder
      sphere.material = material;
      // createPulsatingEffect(sphere);

      // Set the sphere as a child of the cylinder
      sphere.setParent(cylinder);

      cylinder.position = new Vector3(0, 1, 0);

      // nonHandMeshes.push(sphere);
      nonHandMeshes.push(cylinder);

      cylinder.metadata = {
        type: 'startPlane',
        onClick: {}
      };

      // Create a 3D arrow
      const arrow = Mesh.CreateCylinder('arrow', 0.1, 0.05, 0.05, 12, 1, scene);
      arrow.position = new Vector3(0, -0.1, 0);
      arrow.scaling = new Vector3(1, 1, 1);
      const cone = Mesh.CreateCylinder('arrowCone', 0.1, 0, 0.1, 12, 1, scene);
      cone.position = new Vector3(0, 0, 0);
      cone.scaling = new Vector3(1, 1, 1);
      // Create a white material
      const whiteDefaultMaterial = new StandardMaterial('whiteMaterial', scene);
      whiteDefaultMaterial.diffuseColor = new Color3(1, 1, 1); // Set diffuse color to white
      whiteDefaultMaterial.specularColor = new Color3(1, 1, 1); // Set specular color to black to disable reflections

      cone.material = whiteDefaultMaterial;
      arrow.material = whiteDefaultMaterial;

      // Combine arrow components
      const arrowMeshCombined = Mesh.MergeMeshes([arrow, cone], true, false, null, false, true);

      // Position and rotate the arrow
      arrowMeshCombined.position = new Vector3(0, 1.15, -0.1); // Position in world space
      arrowMeshCombined.rotation.x = Math.PI / 2; // Rotate to point in the desired direction
      arrowMeshCombined.rotation.y = Math.PI; // Rotate to point in the desired direction
      arrowMeshCombined.scaling = new Vector3(1.3, 1.3, 1.3);
      arrowMeshCombined.setParent(cylinder);
      nonHandMeshes.push(arrowMeshCombined);

      console.log(nonHandMeshes);

      const startScene = () => {
        capsuleHandle.position = new Vector3(cylinder.position.x, cylinder.position.y + 1, cylinder.position.z);

        nonHandMeshes.forEach((mesh) => {
          mesh.isVisible = true;
        });

        arrowMeshCombined.isVisible = false;
        sphere.isVisible = false;
        cylinder.isVisible = false;

        cylinder.dispose();
      };

      sphere.metadata = {
        type: 'button',
        onClick: startScene
      };
    }; */

    // Use the provided `transformToString` method to get the string representation
    stream.Body.transformToString('utf-8')
      .then(async (stringBody) => {
        try {
          const jsonData = JSON.parse(stringBody);
          console.log(jsonData);

          // Iterating over each property in jsonData
          for (const key in jsonData) {
            // eslint-disable-next-line no-prototype-builtins
            if (jsonData.hasOwnProperty(key)) {
              const value = jsonData[key];
              console.log(`Key: ${key}, Value:`, value);

              await InstantiateInScene(value).then(() => {
                // initialParent.current.position.z = 1.5;
                if (initialParent) {
                  const initialOffsetHandel = initialParent.current.position.subtract(capsuleHandle.position);

                  scene.onBeforeRenderObservable.add(() => {
                    const capsuleHandlePos = capsuleHandle.position;

                    if (settingRealScale) {
                      /* Calculate the new position with the initial offset
                      let newPosition = capsuleHandlePos.add(initialOffsetHandel);

                      // initialParent.current.setParent(null);
                      // Add the extra movement vector
                      newPosition = newPosition.add(new Vector3(0, 0, 5));

                      // console.log('real scale position: ', newPosition);
                      // Set the position of initialParent.current

                      // initialParent.current.position = newPosition;
                      initialParent.current.position = newPosition; */

                      initialParent.current.position.y = 0;

                      // initialParent.current.setParent(mesh0Parent);
                      // initialParent.current.position = capsuleHandlePos.add(initialOffsetHandel);
                    } else {
                      // initialParent.current.setParent(capsuleHandle);
                      initialParent.current.position = capsuleHandlePos.add(initialOffsetHandel);
                    }
                  });

                  nonHandMeshes.push(capsuleHandle);

                  setTimeout(() => {
                    setIsLoading(false); // This will be executed after a 3 second delay
                  }, 5000);
                  // animateToInitialValues();
                  // console.log(initialValues);
                  // DisableAllMeshesExceptPlaceholder();
                }
              });
              /* end CAPSULE HANDLE SETUP */
            }
          }
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      })
      .catch((error) => {
        console.error('Error transforming stream to string:', error);
      });

    /* start HANDTRACKING WEBXR EXPERIENCE */
    // Assuming 'scene' is your Babylon.js scene object

    // WebXR default experience setup
    const xr = await scene.createDefaultXRExperienceAsync({
      // floorMeshes: [ground], // Specify ground meshes for teleportation
      uiOptions: {
        sessionMode: 'immersive-ar'
      }
    });

    // console.log(xr);

    /* if (!device) {
      xr.baseExperience.sessionManager.onXRSessionInit.add(() => {
        console.log('Immersive AR session is initializing');
        nonHandMeshes.forEach((mesh) => {
          if (mesh !== capsuleHandle && mesh.metadata.type !== 'button') {
            initialValues[mesh.name] = {
              scaling: mesh.scaling.clone(),
              quaternion: !!mesh.rotationQuaternion,
              rotation: mesh.rotationQuaternion ? mesh.rotationQuaternion.clone() : mesh.rotation.clone(),
              position: mesh.position.clone()
            };
          }
        });
      });
    } */

    // eslint-disable-next-line no-constant-condition
    if (true) {
      nonHandMeshes.forEach((mesh) => {
        mesh.isPickable = true;
      });

      if (!device) {
        // After initializing the XR experience, disable the laser pointer / hand rays
        xr.pointerSelection.detach();
      }

      // Enable hand tracking with pointer selection feature
      const xrHandTracking = xr.baseExperience.featuresManager.enableFeature(WebXRFeatureName.HAND_TRACKING, 'latest', {
        xrInput: xr.input,
        jointMeshes: {
          // disableDefaultHandMesh: true
          invisible: false, // Set to true if you don't want to display the joints
          scale: 0.01 // Scale of the joint meshes
        }
      });

      xrHandTracking.onHandAddedObservable.add((newHand) => {
        if (newHand.xrController.inputSource.handedness === 'left') {
          // eslint-disable-next-line prefer-const
          let indexTip = newHand.getJointMesh('index-finger-tip');
          leftIndex.current = indexTip;
          // eslint-disable-next-line prefer-const
          let thumbTip = newHand.getJointMesh('thumb-tip');

          let initialOffset = null; // Variable to store the initial offset
          let grabbedObject = null;
          let firstGrab = true;
          let isTouched; // Initialize touched mesh name as empty

          const handWrist = newHand.getJointMesh('middle-finger-phalanx-proximal');
          console.log(handWrist);
          leftRefHandMenu.current = handWrist;
          const degrees = 200;
          const radians = degrees * (Math.PI / 180);

          scene.onBeforeRenderObservable.add(() => {
            if (leftRefHandMenu.current) {
              leftWristRefHandMenu.current.position = new Vector3(
                leftRefHandMenu.current.position.x,
                leftRefHandMenu.current.position.y,
                leftRefHandMenu.current.position.z
              );

              if (leftRefHandMenu.current.rotationQuaternion) {
                leftWristRefHandMenu.current.rotationQuaternion = new Quaternion(
                  leftRefHandMenu.current.rotationQuaternion.x,
                  leftRefHandMenu.current.rotationQuaternion.y,
                  leftRefHandMenu.current.rotationQuaternion.z,
                  leftRefHandMenu.current.rotationQuaternion.w
                );
              } else {
                leftWristRefHandMenu.current.rotation = new Vector3(
                  leftRefHandMenu.current.rotation.x,
                  leftRefHandMenu.current.rotation.y,
                  leftRefHandMenu.current.rotation.z
                );
              }

              // leftWristRefHandMenu.current.rotate(new Vector3(0, 0, 1), Math.PI);
              leftWristRefHandMenu.current.rotate(new Vector3(0, 0, 1), radians);
            }
          });
          // leftWristRefHandMenu.current.setParent(indexTip);
          // leftWristRefHandMenu.current.setParent(handWrist);

          // leftWristRefHandMenu.current.isVisible = true;
          // leftWristPlaneRefHandMenu.current.isVisible = true;
          // leftWristPlaneRefHandMenuBackground.current.isVisible = true;

          scene.onBeforeRenderObservable.add(() => {
            if (indexTip && thumbTip && nonHandMeshes) {
              const indexTipPosition = indexTip.position;
              const thumbTipPosition = thumbTip.position;

              // console.log(indexTipPosition, thumbTipPosition);

              const distance = Vector3.Distance(indexTipPosition, thumbTipPosition);
              // eslint-disable-next-line prefer-const
              let isPressed = distance < 0.015;

              if (grabbedObject && !isPressed) {
                firstGrab = true;
                // Release the grabbed object if pressed is no longer satisfied
                if (
                  grabbedObject !== capsuleHandle &&
                  grabbedObject.metadata.type !== 'button' &&
                  grabbedObject.metadata.type !== 'startPlane' &&
                  grabbedObject.metadata.type !== 'handler' &&
                  grabbedObject.metadata.type !== 'xz'
                ) {
                  grabbedObject.setParent(initialParent.current);
                } else if (grabbedObject.metadata.type === 'xz' && initialCubePos) {
                  grabbedObject.position = initialCubePos;
                }

                /* if (!isTouched && !textHitRight) {
                textBlock.text = 'XR menu';
                textHitLeft = false;
              } */

                grabbedObject = null;
                leftHandGrabbedObj.current = grabbedObject;

                initialOffset = null;

                initialDistanceRef.current = null;
                // initialScalingRef.current = null;

                // Update previous positions for the next frame's calculations
                // previousLeftIndexTipPosition.current = null;
                // previousRightIndexTipPosition.current = null;
              } else if (grabbedObject && isPressed) {
                // Continue moving the grabbed object with the hand
                //
                // grabbedObject.position = indexTipPosition.add(initialOffset);
                if (
                  grabbedObject.parent === initialParent.current &&
                  grabbedObject !== capsuleHandle &&
                  grabbedObject.metadata.type !== 'button' &&
                  grabbedObject.metadata.type !== 'startPlane' &&
                  grabbedObject.metadata.type !== 'handler' &&
                  grabbedObject.metadata.type !== 'xz' &&
                  !settingRealScale
                ) {
                  if (firstGrab) {
                    grabPlaySound();
                    firstGrab = false;
                  }
                  grabbedObject.setParent(thumbTip);
                } else if (
                  grabbedObject === capsuleHandle ||
                  grabbedObject.metadata.type === 'startPlane' ||
                  grabbedObject.metadata.type === 'handler'
                ) {
                  grabbedObject.position = indexTipPosition.add(initialOffset);
                } else if (grabbedObject.metadata.type === 'button') {
                  grabbedObject.metadata.onClick.method();
                } else if (grabbedObject.metadata.type === 'xz') {
                  const cubeOffset = new Vector3(initialOffset.x, initialOffset.y, initialOffset.z);
                  const yPos = grabbedObject.position.y;
                  grabbedObject.position = indexTipPosition.add(cubeOffset);

                  grabbedObject.position.y = yPos;
                }

                leftHandGrabbedObj.current = grabbedObject;

                if (
                  rightHandGrabbedObj.current !== null &&
                  rightHandGrabbedObj.current === leftHandGrabbedObj.current
                ) {
                  const rightIndexTipPosition = rightIndex.current.position;
                  const leftIndexTipPosition = indexTipPosition;

                  initialDistanceRef.current = Vector3.Distance(leftIndexTipPosition, rightIndexTipPosition);
                  // initialScalingRef.current = grabbedObject.scaling;

                  // Update previous positions for the next frame's calculations
                  // previousLeftIndexTipPosition.current = leftIndexTipPosition.clone();
                  // previousRightIndexTipPosition.current = rightIndexTipPosition.clone();
                }
              } else if (!grabbedObject) {
                // Check for new objects to grab
                nonHandMeshes.forEach((mesh) => {
                  if (mesh.intersectsMesh(indexTip, false)) {
                    grabbedObject = mesh;

                    initialOffset = grabbedObject.position.subtract(indexTipPosition);

                    if (isPressed) {
                      // Lock the grabbed object on first grab
                      // eslint-disable-next-line no-useless-return
                      return;
                    }
                  }
                });
              }
            }
          });
        } else if (newHand.xrController.inputSource.handedness === 'right') {
          // eslint-disable-next-line prefer-const
          let indexTip = newHand.getJointMesh('index-finger-tip');
          rightIndex.current = indexTip;
          // eslint-disable-next-line prefer-const
          let thumbTip = newHand.getJointMesh('thumb-tip');

          let initialOffset = null; // Variable to store the initial offset
          let grabbedObject = null;

          let firstGrab = true;

          let isTouched;

          scene.onBeforeRenderObservable.add(() => {
            if (indexTip && thumbTip && nonHandMeshes) {
              const indexTipPosition = indexTip.position;
              const thumbTipPosition = thumbTip.position;

              // console.log(indexTipPosition, thumbTipPosition);

              const distance = Vector3.Distance(indexTipPosition, thumbTipPosition);
              // eslint-disable-next-line prefer-const
              let isPressed = distance < 0.015;

              if (grabbedObject && !isPressed) {
                firstGrab = true;
                // Release the grabbed object if pressed is no longer satisfied
                if (
                  grabbedObject !== capsuleHandle &&
                  grabbedObject.metadata.type !== 'button' &&
                  grabbedObject.metadata.type !== 'startPlane' &&
                  grabbedObject.metadata.type !== 'handler' &&
                  grabbedObject.metadata.type !== 'xz'
                ) {
                  grabbedObject.setParent(initialParent.current);
                } else if (grabbedObject.metadata.type === 'xz' && initialCubePos) {
                  grabbedObject.position = initialCubePos;
                }

                textBlockRefHandMenu.current.text = '';
                leftWristPlaneRefHandMenu.current.isVisible = false;
                leftWristPlaneRefHandMenuBackground.current.isVisible = false;

                /* if (!isTouched && !textHitLeft) {
                textBlock.text = 'XR menu';
                textHitRight = false;
              } */

                grabbedObject = null;
                rightHandGrabbedObj.current = grabbedObject;

                initialOffset = null;

                initialDistanceRef.current = null;
                // initialScalingRef.current = null;

                // Update previous positions for the next frame's calculations
                // previousLeftIndexTipPosition.current = null;
                // previousRightIndexTipPosition.current = null;
              } else if (grabbedObject && isPressed) {
                // Continue moving the grabbed object with the hand
                // grabbedObject.position = indexTipPosition.add(initialOffset);
                if (
                  grabbedObject.parent === initialParent.current &&
                  grabbedObject !== capsuleHandle &&
                  grabbedObject.metadata.type !== 'button' &&
                  grabbedObject.metadata.type !== 'startPlane' &&
                  grabbedObject.metadata.type !== 'handler' &&
                  grabbedObject.metadata.type !== 'xz' &&
                  !settingRealScale
                ) {
                  if (firstGrab) {
                    grabPlaySound();
                    firstGrab = false;
                  }
                  grabbedObject.setParent(thumbTip);

                  if (grabbedObject.metadata.dsc) {
                    textBlockRefHandMenu.current.text = grabbedObject.metadata.dsc;
                    leftWristPlaneRefHandMenu.current.isVisible = true;
                    leftWristPlaneRefHandMenuBackground.current.isVisible = true;
                  }
                } else if (
                  grabbedObject === capsuleHandle ||
                  grabbedObject.metadata.type === 'startPlane' ||
                  grabbedObject.metadata.type === 'handler'
                ) {
                  grabbedObject.position = indexTipPosition.add(initialOffset);
                } else if (grabbedObject.metadata.type === 'button') {
                  grabbedObject.metadata.onClick.method();
                } else if (grabbedObject.metadata.type === 'xz') {
                  const cubeOffset = new Vector3(initialOffset.x, initialOffset.y, initialOffset.z);
                  const yPos = grabbedObject.position.y;
                  grabbedObject.position = indexTipPosition.add(cubeOffset);

                  grabbedObject.position.y = yPos;
                }

                rightHandGrabbedObj.current = grabbedObject;

                if (leftHandGrabbedObj.current !== null && leftHandGrabbedObj.current === rightHandGrabbedObj.current) {
                  const leftIndexTipPosition = leftIndex.current.position;
                  const rightIndexTipPosition = indexTipPosition;

                  initialDistanceRef.current = Vector3.Distance(leftIndexTipPosition, rightIndexTipPosition);
                  // initialScalingRef.current = grabbedObject.scaling;

                  // Update previous positions for the next frame's calculations
                  // previousLeftIndexTipPosition.current = leftIndexTipPosition.clone();
                  // previousRightIndexTipPosition.current = rightIndexTipPosition.clone();
                }
              } else if (!grabbedObject) {
                // Check for new objects to grab
                nonHandMeshes.forEach((mesh) => {
                  if (mesh.intersectsMesh(indexTip, false)) {
                    grabbedObject = mesh;

                    initialOffset = grabbedObject.position.subtract(indexTipPosition);

                    if (isPressed) {
                      // Lock the grabbed object on first grab
                      // eslint-disable-next-line no-useless-return
                      return;
                    }
                  }
                });
              }
            }
          });
        }
      });

      scene.onBeforeRenderObservable.add(() => {
        /* nonHandMeshes.forEach((mesh) => {
        console.log(`${mesh.name} :`, mesh.metadata);
      }); */

        if (leftIndex.current && rightIndex.current && nonHandMeshes) {
          nonHandMeshes.forEach((mesh) => {
            if (
              (mesh.intersectsMesh(leftIndex.current, false) || mesh.intersectsMesh(rightIndex.current, false)) &&
              mesh.metadata &&
              (mesh.metadata.type === 'button' || mesh.metadata.type === 'handler')
            ) {
              mesh.material.unfreeze();
              mesh.material.alpha = 1;
              mesh.material.freeze();
            } else if (mesh.metadata && (mesh.metadata.type === 'button' || mesh.metadata.type === 'handler')) {
              // Don't update text here, let's keep it as XR menu
              /* textHitRight = false;
            textHitLeft = false;

            textBlock.text = 'XR menu'; */

              mesh.material.unfreeze();
              mesh.material.alpha = 0.6;
              mesh.material.freeze();
            }
          });
        }
      });

      /*
    scene.onBeforeRenderObservable.add(() => {
      buttonArray.forEach((mesh) => {
        if (mesh.material.alpha === 1) {
          textBlock.text = mesh.name; // || 'XR menu';
        } else {
          textBlock.text = 'XR menu';
        }
      });
      /* if (leftIndex.current && rightIndex.current && buttonArray && textBlock) {
        buttonArray.forEach((mesh) => {
          console.warn(mesh.metadata.name);

          if (mesh.intersectsMesh(leftIndex.current, false) || mesh.intersectsMesh(rightIndex.current, false)) {
            textBlock.text = mesh.name; // || 'XR menu';
          } else {
            textBlock.text = 'XR menu';
          }
        });
      }
    }); */

      scene.onBeforeRenderObservable.add(() => {
        if (
          leftHandGrabbedObj.current !== null &&
          rightHandGrabbedObj.current !== null &&
          leftHandGrabbedObj.current !== capsuleHandle &&
          rightHandGrabbedObj.current !== capsuleHandle &&
          rightHandGrabbedObj.current.metadata.type !== 'button' &&
          leftHandGrabbedObj.current.metadata.type !== 'button' &&
          !settingRealScale
        ) {
          if (leftHandGrabbedObj.current === rightHandGrabbedObj.current) {
            const leftIndexTipPosition = leftIndex.current.position;
            const rightIndexTipPosition = rightIndex.current.position;

            console.log('Two handed scaling');

            const currentDistance = Vector3.Distance(leftIndexTipPosition, rightIndexTipPosition);

            const scaleFactor = currentDistance / initialDistanceRef.current;

            leftHandGrabbedObj.current.scaling.scaleInPlace(scaleFactor);

            initialDistanceRef.current = currentDistance;
          }
        } /* else if (
          leftHandGrabbedObj.current !== null &&
          rightHandGrabbedObj.current !== null &&
          leftHandGrabbedObj.current === capsuleHandle &&
          rightHandGrabbedObj.current === capsuleHandle
        ) {
          const grabbedObj = leftHandGrabbedObj.current; // Assuming both hands grab the same object
          // Logic for rotating the capsule handler around the Y axis
          const leftIndexTipPosition = leftIndex.current.position;
          const rightIndexTipPosition = rightIndex.current.position;

          // Assuming you have a way to track the previous positions of the index tips to calculate rotation
          const rotationAmount = calculateRotationAmount(
            leftIndexTipPosition,
            rightIndexTipPosition,
            previousLeftIndexTipPosition,
            previousRightIndexTipPosition
          );
          grabbedObj.rotation.y += rotationAmount;

          // Update previous positions for the next frame's calculations
          previousLeftIndexTipPosition.current = leftIndexTipPosition.clone();
          previousRightIndexTipPosition.current = rightIndexTipPosition.clone();
        } */
      });
    }
    /* end HANDTRACKING WEBXR EXPERIENCE */
  };

  return (
    <div className="vr-scene-container">
      <img src={logoImage} alt="Logo" className="logoImage" />
      <div className="ui-container">
        <button className="ui-button" onClick={() => (window.location.href = '/varo/manager')}>
          Back to Manager
        </button>
        {/* <button className="ui-button" onClick={() => toggleVRARRef.current()}>
          toggle
        </button> */}
      </div>

      <SceneComponent
        antialias
        onSceneReady={onSceneReady}
        id="my-canvas"
        className="renderCanvas"
        style={{ width: 'calc(100vw - 250px)', height: '100vh' }}
      />

      {isLoading && <SceneLoaderCSS percentage={downloadPercentage.current} />}
    </div>
  );
};

export default VR;
