/* eslint-disable no-unused-vars */
import { mediasService } from '@services/api/medias/medias.service';

class MediaInputHandler {
  constructor(userDataStore, sessionData) {
    this.userDataStore = userDataStore;
    this.sessionData = sessionData;
  }

  handleFileInput = async (e) => {
    const file = e.target.files[0];

    // Determine if the file is an image or a video
    let mediaType;
    if (file.type.startsWith('image/')) {
      mediaType = 'image';
    } else if (file.type.startsWith('video/')) {
      mediaType = 'video';
    } else {
      alert('Please upload a valid image or video file');
      return;
    }

    if (file) {
      const formData = new FormData();

      formData.append('file', file);
      formData.append('companyName', this.userDataStore.company);
      formData.append('medias_Id', this.userDataStore.mediasId);
      formData.append('mediaName', file.name);
      formData.append('mediaType', mediaType);
      formData.append('accessKeyId', this.sessionData.data.accessInformation.AccessKeyId);
      formData.append('secretAccessKey', this.sessionData.data.accessInformation.SecretAccessKey);
      formData.append('sessionToken', this.sessionData.data.accessInformation.SessionToken);
      formData.append('bucketName', 'exvirience-media');
      formData.append('accelerationStatus', 'true');

      try {
        const response = await mediasService.addMedia(formData);
        console.log(response.data);
      } catch (error) {
        console.error('Error uploading media', error);
      }
    } else {
      alert('Please upload a video or an image');
    }
  };

  handleFileInputSave = async (e, mediaId) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();

      formData.append('file', file);
      formData.append('medias_Id', this.userDataStore.mediasId);
      formData.append('mediaId', mediaId);
      formData.append('accessKeyId', this.sessionData.data.accessInformation.AccessKeyId);
      formData.append('secretAccessKey', this.sessionData.data.accessInformation.SecretAccessKey);
      formData.append('sessionToken', this.sessionData.data.accessInformation.SessionToken);
      formData.append('bucketName', 'exvirience-media');
      formData.append('accelerationStatus', 'true');

      try {
        const response = await mediasService.saveMedia(formData);
        console.log(response.data);
      } catch (error) {
        console.error('Error uploading media', error);
      }
    } else {
      alert('Please upload a video or an image');
    }
  };
}

export default MediaInputHandler;
