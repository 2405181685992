import { scenesService } from '@services/api/scenes/scenes.service';

class SceneDetails {
  constructor(sessionData, userDataStore, setSceneModels, setSceneImages, setSceneVideos) {
    this.sessionData = sessionData;
    this.userDataStore = userDataStore;
    this.setSceneModels = setSceneModels;
    this.setSceneImages = setSceneImages;
    this.setSceneVideos = setSceneVideos;
  }

  async importScene(sceneName) {
    this.setSceneModels(['Loading...']);
    this.setSceneImages(['Loading...']);
    this.setSceneVideos(['Loading...']);

    try {
      const Bucket = 'exvirience-scenes';
      const Key = `${sceneName}`;

      const awsInformation = {
        accessKeyId: this.sessionData.data.accessInformation.AccessKeyId,
        secretAccessKey: this.sessionData.data.accessInformation.SecretAccessKey,
        sessionToken: this.sessionData.data.accessInformation.SessionToken,
        bucketName: Bucket
      };

      const sceneBody = {
        scenes_Id: this.userDataStore.scenesId,
        sceneId: Key,
        awsInformation
      };

      const response = await scenesService.getScene(sceneBody);
      const sceneData = response.data;

      this.setSceneModels([]);
      this.setSceneImages([]);
      this.setSceneVideos([]);

      console.log(sceneData);

      // Directly iterate over the object and instantiate the scene
      for (const key in sceneData) {
        // eslint-disable-next-line no-prototype-builtins
        if (sceneData.hasOwnProperty(key)) {
          const value = sceneData[key];
          console.log(`Key: ${key}, Value:`, value);

          await this.InstantiateInScene(value);
        }
      }
    } catch (error) {
      console.error('Error fetching scene: ', error);
    }
  }

  async InstantiateInScene(value) {
    if (value.type === 'mesh') {
      this.handleModel(value);
    } else if (value.type === 'image') {
      this.handleImage(value);
    } else if (value.type === 'video') {
      this.handleVideo(value);
    }
  }

  handleModel(value) {
    this.setSceneModels((prevModels) => [...prevModels, value.name]);
  }

  handleImage(value) {
    this.setSceneImages((prevImages) => [...prevImages, value.url.mediaName]);
  }

  handleVideo(value) {
    this.setSceneVideos((prevVideos) => [...prevVideos, value.url.mediaName]);
  }
}

export default SceneDetails;
