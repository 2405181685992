import React from 'react';
import '@components/Modal windows/Modal window - return to manager/ConfirmReturn.scss';

import PropTypes from 'prop-types';

const ConfirmReturn = ({ cancelAction, setLoading }) => {
  return (
    <div className="modal-wrapper" onClick={() => cancelAction()}>
      <div className="confirm-return-container" onClick={(e) => e.stopPropagation()}>
        <h2>Scene not saved!</h2>
        <p>
          {
            'Are you sure to exit the scene without saving? To go back to editing press cancel or outside the modal window.'
          }
        </p>
        <div className="buttons-container">
          <button
            className="button-new"
            onClick={() => cancelAction()}
            style={{ cursor: 'pointer', color: 'rgba(255, 0, 0, 1)' }}
          >
            Cancel
          </button>
          <div className="divider"></div>
          <button
            className="button-new"
            onClick={() => {
              cancelAction();
              setLoading();
              window.location.assign('/user/');
            }}
            style={{ cursor: 'pointer' }}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

ConfirmReturn.propTypes = {
  cancelAction: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired
};

export default ConfirmReturn;
