import {
  Vector3,
  StandardMaterial,
  Color3,
  Color4,
  SceneLoader,
  MeshBuilder,
  DynamicTexture,
  Mesh
} from '@babylonjs/core';

import { modelsService } from '@services/api/models/models.service';

class AvatarReferenceManager {
  constructor(sceneInstance, C1M, loadingSpheresCreator, clickSimulator, Public_URL, camera, sessionData) {
    this.sceneInstance = sceneInstance;
    this.C1M = C1M;
    this.loadingSpheresCreator = loadingSpheresCreator;
    this.clickSimulator = clickSimulator;
    this.Public_URL = Public_URL;
    this.camera = camera;
    this.sessionData = sessionData;
  }

  async loadMesh(
    fileName,
    spheres = false,
    parent = null,
    scaling = new Vector3(1, 1, 1),
    rotation = new Vector3(0, 0, 0),
    position = new Vector3(0, 0, 0),
    material = null
  ) {
    if (!this.sceneInstance.current) return;

    const initialPosition = new Vector3(1, 0, 1);

    if (spheres) {
      this.loadingSpheresCreator.create(initialPosition);
    }

    const Bucket = 'exvirience-shared';
    const Key = fileName;
    const models_Id = 'I'; // this is required

    const awsInformation = {
      accessKeyId: this.sessionData.data.accessInformation.AccessKeyId,
      secretAccessKey: this.sessionData.data.accessInformation.SecretAccessKey,
      sessionToken: this.sessionData.data.accessInformation.SessionToken,
      bucketName: Bucket
    };

    const modelsBody = {
      models_Id,
      modelId: Key,
      awsInformation
    };

    const objectURL = await modelsService.getModelURL(modelsBody);

    // console.log(objectURL);

    const result = await SceneLoader.ImportMeshAsync('', '', objectURL.data.model, this.sceneInstance.current);
    const newMeshes = result.meshes;

    newMeshes[0].scaling = scaling;
    newMeshes[0].rotation = rotation;
    newMeshes[0].position = position;

    if (material) {
      newMeshes.forEach((mesh) => (mesh.material = material));
    }

    if (parent) {
      newMeshes[0].parent = parent;
    }

    newMeshes.forEach((value) => {
      value.metadata = {
        canToggleGizmo: true,
        canToggleFocus: true,
        targetPosition: newMeshes[0].position,
        position: value.position.clone(),
        rotation: value.rotation.clone(),
        scale: value.scaling.clone(),
        clone: newMeshes[0],
        isolatable: false
      };
      value.layerMask = this.C1M;
      value.renderingGroupId = 1;
    });

    if (spheres) {
      setTimeout(() => {
        this.loadingSpheresCreator.disposeSpheres();
      }, 1500);
    }
  }

  // functions to be used in the scaleReference method to generate the scale reference
  createReferenceBox(height, width, depth, position, scene) {
    const box = MeshBuilder.CreateBox('referenceBox', { height, width, depth }, scene);
    box.position = position;
    box.metadata = {
      canToggleGizmo: false,
      canToggleFocus: false,
      reference: true,
      targetPosition: box.position,
      position: box.position.clone(),
      rotation: box.rotation.clone(),
      scale: box.scaling.clone(),
      isolatable: false
    };
    box.layerMask = this.C1M;
    box.renderingGroupId = 1;

    const transparent = new StandardMaterial('transparentMaterial', scene);
    transparent.diffuseColor = new Color4(0, 0, 0, 0);
    transparent.alpha = 0;

    box.material = transparent;

    return box;
  }

  async scaleReferences(name) {
    if (this.sceneInstance.current) {
      const skin = new StandardMaterial('skinMaterial', this.sceneInstance.current);
      skin.diffuseColor = new Color3(232 / 255, 190 / 255, 172 / 255);
      skin.specularColor = new Color3(0, 0, 0);
      skin.ambientColor = new Color3(0, 0, 0);

      if (name === 'human') {
        const box = this.createReferenceBox(1.8, 2, 1.5, new Vector3(1, 0.9, 1), this.sceneInstance.current);

        await this.loadMesh(
          'human_body.glb',
          true,
          box,
          new Vector3(0.4, 0.4, 0.4),
          new Vector3(0, -Math.PI, 0),
          new Vector3(0, -0.9, 0),
          skin
        );

        // Line for the human mesh
        // eslint-disable-next-line prefer-const
        let humanHeight = [new Vector3(1.5, -0.9, 0), new Vector3(1.5, 0.9, 0)];
        // eslint-disable-next-line prefer-const
        let humanLine = MeshBuilder.CreateTube(
          'humanHeightLine',
          { path: humanHeight, radius: 0.01 },
          this.sceneInstance.current
        );

        // Create a material for the line
        // eslint-disable-next-line prefer-const
        let humanLineMaterial = new StandardMaterial('humanHeightLineMaterial', this.sceneInstance.current);
        humanLineMaterial.emissiveColor = new Color4(0, 0, 0, 0); // Set to black
        humanLineMaterial.diffuseColor = new Color4(0, 0, 0, 0); // Set to black
        humanLineMaterial.specularColor = new Color3(0, 0, 0); // No specular reflection
        humanLineMaterial.ambientColor = new Color3(0, 0, 0); // No ambient light

        // Apply the material to the line
        humanLine.material = humanLineMaterial;
        humanLine.layerMask = this.C1M;
        humanLine.renderingGroupId = 1;
        // Set the box as a parent
        humanLine.parent = box;

        // add the height textbox
        // Create a plane
        const plane = Mesh.CreatePlane('plane', 1.0, this.sceneInstance.current);

        // Create a dynamic texture
        const dynamicTexture = new DynamicTexture('dynamic texture', 512, this.sceneInstance.current, true);
        dynamicTexture.hasAlpha = true;

        // Create a material and set its diffuse texture to the dynamic texture
        const material = new StandardMaterial('Mat', this.sceneInstance.current);
        material.diffuseTexture = dynamicTexture;
        plane.material = material;

        // Get the context of the dynamic texture
        const ctx = dynamicTexture.getContext();

        // Set the font and style
        ctx.font = 'bold 100px monospace';

        // Clear the context
        ctx.clearRect(0, 0, 512, 512);

        // Draw the text on the dynamic texture
        ctx.fillText('180 cm', 75, 135, 512);
        dynamicTexture.update();

        // Now you can set the position, rotation and scaling of the plane
        plane.position = new Vector3(1.5, 0.9, 0);
        plane.rotation = new Vector3(0, 0, 0);
        plane.scaling = new Vector3(1, 1, 1);

        plane.layerMask = this.C1M;
        plane.renderingGroupId = 1;
        plane.parent = box;
      } else if (name === 'hand') {
        const box = this.createReferenceBox(0.3, 0.3, 0.3, new Vector3(1, 0.5, 1), this.sceneInstance.current);
        await this.loadMesh(
          'hand.glb',
          true,
          box,
          new Vector3(1, 1, 1),
          new Vector3(Math.PI / 4, 0, Math.PI / 4),
          new Vector3(0, 0, 0),
          skin
        );
      } else if (name === 'coin') {
        const box = this.createReferenceBox(0.03, 0.03, 0.03, new Vector3(1, 0.25, 1), this.sceneInstance.current);
        await this.loadMesh(
          '2_euro_coin.glb',
          true,
          box,
          new Vector3(1, 1, 1),
          new Vector3(Math.PI / 4 - Math.PI / 2, 0, 0),
          new Vector3(0, 0, 0),
          null
        );
      }
    }
  }

  async loadHuman(
    fileName,
    spheres = false,
    parent = null,
    scaling = new Vector3(1, 1, 1),
    rotation,
    position = new Vector3(0, 0, 0),
    material = null
  ) {
    if (!this.sceneInstance.current) return;

    // const initialPosition = new Vector3(0, 0, 0);

    /* if (spheres) {
      this.loadingSpheresCreator.create(initialPosition);
    } */

    const Bucket = 'exvirience-shared';
    const Key = fileName;
    const models_Id = 'I'; // this is required

    const awsInformation = {
      accessKeyId: this.sessionData.data.accessInformation.AccessKeyId,
      secretAccessKey: this.sessionData.data.accessInformation.SecretAccessKey,
      sessionToken: this.sessionData.data.accessInformation.SessionToken,
      bucketName: Bucket
    };

    const modelsBody = {
      models_Id,
      modelId: Key,
      awsInformation
    };

    const objectURL = await modelsService.getModelURL(modelsBody);

    // console.log(objectURL);

    const result = await SceneLoader.ImportMeshAsync('', '', objectURL.data.model, this.sceneInstance.current);
    const newMeshes = result.meshes;

    newMeshes[0].scaling = scaling;
    newMeshes[0].rotate(new Vector3(0, 1, 0), rotation);
    newMeshes[0].position = position;

    if (material) {
      newMeshes.forEach((mesh) => (mesh.material = material));
    }

    if (parent) {
      newMeshes[0].parent = parent;
    }

    newMeshes.forEach((value) => {
      value.metadata = {
        canToggleGizmo: false,
        canToggleFocus: false,
        targetPosition: newMeshes[0].position,
        position: value.position.clone(),
        rotation: value.rotation.clone(),
        scale: value.scaling.clone(),
        clone: newMeshes[0],
        isolatable: false
      };
      value.layerMask = this.C1M;
      value.renderingGroupId = 1;
    });

    /* if (spheres) {
      setTimeout(() => {
        this.loadingSpheresCreator.disposeSpheres();
      }, 1500);
    } */

    return newMeshes[0];
  }

  async humanReferences() {
    if (this.sceneInstance.current) {
      const box = this.createReferenceBox(1.8, 2, 1.5, new Vector3(0, 0.9, 0), this.sceneInstance.current);

      const skin = new StandardMaterial('skinMaterial', this.sceneInstance.current);
      skin.diffuseColor = new Color3(232 / 255, 190 / 255, 172 / 255);
      skin.specularColor = new Color3(0, 0, 0);
      skin.ambientColor = new Color3(0, 0, 0);

      const parent = await this.loadHuman(
        'human_body.glb',
        true,
        box,
        new Vector3(0.4, 0.4, 0.4),
        Math.PI,
        new Vector3(0, -0.9, 0),
        skin
      );

      // Line for the human mesh
      // eslint-disable-next-line prefer-const
      let humanHeight = [new Vector3(0.8, -0.9, 0), new Vector3(0.8, 0.9, 0)];
      // eslint-disable-next-line prefer-const
      let humanLine = MeshBuilder.CreateTube(
        'humanHeightLine',
        { path: humanHeight, radius: 0.01 },
        this.sceneInstance.current
      );

      // Create a material for the line
      // eslint-disable-next-line prefer-const
      let humanLineMaterial = new StandardMaterial('humanHeightLineMaterial', this.sceneInstance.current);
      humanLineMaterial.emissiveColor = new Color4(0, 0, 0, 0); // Set to black
      humanLineMaterial.diffuseColor = new Color4(0, 0, 0, 0); // Set to black
      humanLineMaterial.specularColor = new Color3(0, 0, 0); // No specular reflection
      humanLineMaterial.ambientColor = new Color3(0, 0, 0); // No ambient light

      // Apply the material to the line
      humanLine.material = humanLineMaterial;
      humanLine.layerMask = this.C1M;
      humanLine.renderingGroupId = 1;
      // Set the box as a parent
      humanLine.parent = box;

      // add the height textbox
      // Create a plane
      const plane = Mesh.CreatePlane('plane', 1.0, this.sceneInstance.current);

      // Create a dynamic texture
      const dynamicTexture = new DynamicTexture('dynamic texture', 512, this.sceneInstance.current, true);
      dynamicTexture.hasAlpha = true;

      // Create a material and set its diffuse texture to the dynamic texture
      const material = new StandardMaterial('Mat', this.sceneInstance.current);
      material.diffuseTexture = dynamicTexture;
      plane.material = material;

      // Get the context of the dynamic texture
      const ctx = dynamicTexture.getContext();

      // Set the font and style
      ctx.font = 'bold 100px monospace';

      // Clear the context
      ctx.clearRect(0, 0, 512, 512);

      // Draw the text on the dynamic texture
      ctx.fillText('180 cm', 75, 135, 512);
      dynamicTexture.update();

      // Now you can set the position, rotation and scaling of the plane
      plane.position = new Vector3(0.8, 0.9, 0);
      plane.rotation = new Vector3(0, 0, 0);
      plane.scaling = new Vector3(1, 1, 1);

      plane.layerMask = this.C1M;
      plane.renderingGroupId = 1;
      plane.parent = box;

      // add the height textbox
      // Create a plane
      const planeReverse = Mesh.CreatePlane('plane', 1.0, this.sceneInstance.current);

      // Create a dynamic texture
      const dynamicTextureReverse = new DynamicTexture('dynamic texture', 512, this.sceneInstance.current, true);
      dynamicTextureReverse.hasAlpha = true;

      // Create a material and set its diffuse texture to the dynamic texture
      const materialReverse = new StandardMaterial('Mat', this.sceneInstance.current);
      materialReverse.diffuseTexture = dynamicTexture;
      planeReverse.material = material;

      // Get the context of the dynamic texture
      const ctxReverse = dynamicTextureReverse.getContext();

      // Set the font and style
      ctxReverse.font = 'bold 100px monospace';

      // Clear the context
      ctxReverse.clearRect(0, 0, 512, 512);

      // Draw the text on the dynamic texture
      ctxReverse.fillText('180 cm', 75, 135, 512);
      dynamicTextureReverse.update();

      // Now you can set the position, rotation and scaling of the plane
      planeReverse.position = new Vector3(0.8, 0.9, 0);
      planeReverse.rotation = new Vector3(0, 0, 0);
      planeReverse.rotate(new Vector3(0, 1, 0), Math.PI);
      planeReverse.scaling = new Vector3(1, 1, 1);

      planeReverse.layerMask = this.C1M;
      planeReverse.renderingGroupId = 1;
      planeReverse.parent = box;

      parent.setParent(null);
      humanLine.setParent(parent);
      plane.setParent(parent);
      planeReverse.setParent(parent);

      box.dispose();

      /* this.camera
      this.sceneInstance.current.onBeforeRenderObservable.add(() => {
        // Get the position of the camera and the plane
        const cameraPosition = this.camera.current.position;
        const planePosition = plane.position;

        // Compute the direction vector from the plane to the camera
        const directionToCamera = cameraPosition.subtract(planePosition);
        directionToCamera.y = 0; // Ignore the y component for the direction

        // Calculate the angle for the y-axis rotation
        const angleY = Math.atan2(-directionToCamera.z, directionToCamera.x) + Math.PI / 2;

        // Set the plane's rotation, preserving the x and z rotations
        const planeXrotation = plane.rotation.x;
        const planeZrotation = plane.rotation.z;
        plane.rotation = new Vector3(planeXrotation, angleY, planeZrotation);
      }); */
    }
  }
}

export default AvatarReferenceManager;
