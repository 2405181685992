/* import React, { useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  StandardMaterial,
  Mesh,
  Vector3,
  Color3,
  UtilityLayerRenderer,
  BoundingBoxGizmo,
  Quaternion,
  Axis
} from '@babylonjs/core';

import { MdArrowBack } from 'react-icons/md';
import '@components/Tool bars/Handle section toolbar/SectionToolbar.scss';

import SliderToolbar from '@components/Tool bars/Handle section toolbar/SliderToolbar';
import { toggleMeshVisibility } from '@hooks/Mesh/hooks/ToogleMeshVisibility';
// import { quaternionToEuler } from '@hooks/Math/index';

const SectionToolbar = ({ sceneInstance, headBack, pickedMeshRef, cameraInstance }) => {
  // console.error('Section toolbar component rerendered');

  // eslint-disable-next-line prefer-const
  // let rootMesh = pickedMeshRef.current.metadata?.clone || pickedMeshRef.current;
  const rootMesh = useMemo(() => {
    return pickedMeshRef.current.metadata?.clone || pickedMeshRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickedMeshRef.current]);

  // bool to check if we need to use the rotation quaternion
  const useQuaternion = !!pickedMeshRef.current.metadata.clone;

  // variable to set the limit and render the sliders
  const SetLimitsRef = useRef(false);

  // setting the rotation variable based on useQuaternion
  // NOT CORRECT AS IT RERENDERS AND CHANGES VALUE: const rootMeshRot = useQuaternion ? rootMesh.rotationQuaternion : rootMesh.rotation;
  // Wrapping a component in a useMemo statement lets you cache the result of a calculation between re-renders
  const rootMeshRot = useMemo(() => {
    return useQuaternion ? rootMesh.rotationQuaternion : rootMesh.rotation;
  }, [rootMesh, useQuaternion]);

  console.log('RootMesh: ', rootMesh);
  console.log('RootMesh Rotation: ', rootMeshRot);

  // eslint-disable-next-line prefer-const
  let limits = {
    xLimitMax: 0,
    xLimitMin: 0,
    yLimitMax: 0,
    yLimitMin: 0,
    zLimitMax: 0,
    zLimitMin: 0,
    xAxisX: 0,
    yAxisX: 0,
    zAxisX: 0,
    xAxisY: 0,
    yAxisY: 0,
    zAxisY: 0,
    xAxisZ: 0,
    yAxisZ: 0,
    zAxisZ: 0
  };

  const limitsRef = useRef(limits);

  let firstRun = true;
  let originalMaterial = null;
  let newMaterial = null;

  // Creating the clonedMeshref to be able to pass it to the slider component as the cloned mesh changes: when i create the cloned mesh it creates 2 of them
  let clonedMesh = null;
  const cloneMeshRef = useRef(clonedMesh);

  // !! is a double negation operator in JavaScript. It's used to convert a value to a boolean. If pickedMeshRef.current.metadata exists and is truthy (i.e., not null, undefined, 0, an empty string, or false), !!pickedMeshRef.current.metadata becomes true. Otherwise, it becomes false.
  // eslint-disable-next-line prefer-const
  let useClonedMesh = !!pickedMeshRef.current.metadata && !!pickedMeshRef.current.metadata.clone;

  // eslint-disable-next-line prefer-const
  let config = { clonedMesh, useClonedMesh };

  // set the rootmesh rotation to 0,1,0,0
  if (useQuaternion) {
    // if i update the rotation the quaternion becomes null. only one can persist
    rootMesh.rotationQuaternion = new Quaternion(0, 1, 0, 0);
  }
  // Calling the clone mesh to clone and hide the previous original mesh
  clonedMesh = createClonedMesh(rootMesh, sceneInstance, config);

  // Reset the rotation values
  if (useQuaternion) {
    rootMesh.rotationQuaternion = rootMeshRot;
    // CANNOT PUT IT HERE AS THE CLONEDMESH TO WHICH THIS APPLIES TO IS DISPOSED
    // clonedMesh.rotationQuaternion = rootMeshRot;
    // console.log(clonedMesh.rotationQuaternion);
  }

  // console.log(limitsRef.current);

  if (clonedMesh.material) {
    newMaterial = clonedMesh.material;
  } else {
    newMaterial = new StandardMaterial('defaultMaterial', sceneInstance.current);
    clonedMesh.material = newMaterial;
  }

  //  Disable backfaceculling for the material in order to view inside the meshes
  newMaterial.backFaceCulling = false;

  // Function to clone and assign new material
  if (firstRun && newMaterial) {
    originalMaterial = clonedMesh.material;
    firstRun = false;
  }

  // check to dispose of double created clones
  useEffect(() => {
    const timer = setTimeout(() => {
      const clonedMeshes = sceneInstance.current.meshes.filter((mesh) => mesh.name === 'clonedMesh');
      // console.log(clonedMeshes);
      if (clonedMeshes && clonedMeshes.length > 1) {
        // change all of the meshes rotations
        clonedMeshes.forEach((mesh) => {
          // Reset the rotation values
          if (useQuaternion) {
            mesh.rotationQuaternion = rootMeshRot;

            mesh.position = rootMesh.position;

            // Assuming 'mesh' is your object/mesh and it uses quaternion for rotation
            // eslint-disable-next-line prefer-const
            let quaternion = Quaternion.RotationAxis(Axis.Y, Math.PI);
            mesh.rotationQuaternion = mesh.rotationQuaternion.multiply(quaternion);

            // console.log(limits);
            // console.log(mesh.rotation);
            // console.log(mesh.rotationQuaternion);
          }
        });

        // Dispose of all but one
        clonedMeshes.slice(1).forEach((mesh) => {
          mesh.dispose();
        });
        console.log('disposed');
      }
      // Set clonedMesh to the remaining mesh
      if (clonedMeshes.length > 0) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clonedMesh = clonedMeshes[0];
        cloneMeshRef.current = clonedMesh;

        //  Disable backfaceculling for the material in order to view inside the meshes
        cloneMeshRef.current.material.backFaceCulling = false;
        // cloneMeshRef.current.material.wireframe = true;

        // Calculating the limits with the correct cloned mesh
        const newLimits = calculateLimitsMesh(clonedMesh);
        limitsRef.current = newLimits;

        // eslint-disable-next-line react-hooks/exhaustive-deps
        SetLimitsRef.current = true;

        // function that adds the bounding box to the mesh in order to correctly debug the scene (true or false toggles the boundingbox)
        debugBoundingBox(sceneInstance.current, cameraInstance.current, cloneMeshRef.current, true);
      }
    }, 200); // 200 milliseconds delay

    // Cleanup function to clear the timer
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Dependency array

  // Update the cloneMeshRef when i change clonedMesh (happens when i delete the doubled clone)
  useEffect(() => {
    cloneMeshRef.current = clonedMesh;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clonedMesh]); // Dependency array

  // Function to revert to original material and dispose of the cloned one
  const revertAndDisposeMaterial = (mesh) => {
    if (mesh.material && originalMaterial) {
      mesh.material.dispose(); // Dispose the cloned material
      mesh.material = originalMaterial; // Revert back to the original material
      originalMaterial = null; // Clear the reference
    }
  };

  // Resetting everything to head back
  const resetClippingPlanes = () => {
    revertAndDisposeMaterial(clonedMesh);
    if (clonedMesh) {
      sceneInstance.current.clipPlane = null;
      sceneInstance.current.clipPlane2 = null;
      sceneInstance.current.clipPlane3 = null;
    }

    // Finding all of the clonedMeshes in the scene and disposing of them (done for security of disposing of all meshes)
    const clonedMeshes = sceneInstance.current.meshes.filter((mesh) => mesh.name === 'clonedMesh');
    clonedMeshes.forEach((mesh) => mesh.dispose());

    // Eliminating the clonedMesh variable
    clonedMesh = null;

    // calling the function to set the original mesh's visibility to false
    toggleMeshVisibility(rootMesh, true);
    // console.log('rootMesh: ' + rootMesh);
    // console.log('clonedMesh: ' + clonedMesh);

    // heading back to the deault toolbar
    headBack();
  };

  const handleClick = (e) => {
    e.stopPropagation(); // Prevents the event from propagating further
  };

  if (!sceneInstance || !sceneInstance.current) {
    console.log('No sceneInstance or pickedMeshRef provided');
    return null;
  }

  return (
    <div className="ShortcutsToolbar-container" onClick={handleClick}>
      <SliderToolbar
        limits={limitsRef}
        cloneMeshRef={cloneMeshRef}
        newMaterial={newMaterial}
        sceneIntance={sceneInstance}
        setLimitsRef={SetLimitsRef}
      />
      <div className="ShortcutsToolbar-element" onClick={() => resetClippingPlanes()}>
        <div className="ShortcutsToolbar-icon">
          <MdArrowBack />
        </div>
        <div className="ShortcutsToolbar-text">Back</div>
      </div>
    </div>
  );
};

SectionToolbar.propTypes = {
  sceneInstance: PropTypes.any.isRequired,
  headBack: PropTypes.func.isRequired,
  pickedMeshRef: PropTypes.any.isRequired,
  cameraInstance: PropTypes.any.isRequired
};

/* ----------------------------------------------------------------------------------------------------------------------------------- */
// Functions used in the SectioToolbar component
/* ----------------------------------------------------------------------------------------------------------------------------------- */

/*
const calculateLimitsMesh = (mesh) => {
  // let xMax, xMin, yMax, yMin, zMax, zMin;

  // Assuming 'object' is your 3D object
  const worldMatrix = mesh.getWorldMatrix();
  // console.log(worldMatrix);

  // Extract local X-axis
  const localXAxis = new Vector3(worldMatrix.m[0], worldMatrix.m[1], worldMatrix.m[2]);
  const localYAxis = new Vector3(worldMatrix.m[4], worldMatrix.m[5], worldMatrix.m[6]);
  const localZAxis = new Vector3(worldMatrix.m[8], worldMatrix.m[9], worldMatrix.m[10]);

  // Normalize the axis to get the correct normal
  const planeNormalX = localXAxis.normalize();
  const planeNormalY = localYAxis.normalize();
  const planeNormalZ = localZAxis.normalize();

  // Force compute the world matrix if it's not up to date
  mesh.computeWorldMatrix(true);

  // Update the bounding info
  mesh.refreshBoundingInfo();

  // The dimensions of the bounding box can be found in the boundingInfo property
  const boundingInfo = mesh.getBoundingInfo();
  // console.log(boundingInfo);

  const xMax =
    parseFloat(
      (planeNormalX.x * boundingInfo.boundingBox.centerWorld.x + boundingInfo.boundingBox.extendSizeWorld.x).toFixed(3)
    ) +
    parseFloat((planeNormalX.y * boundingInfo.boundingBox.centerWorld.y).toFixed(3)) +
    parseFloat((planeNormalX.z * boundingInfo.boundingBox.centerWorld.z).toFixed(3));

  const xMin =
    parseFloat(
      (planeNormalX.x * boundingInfo.boundingBox.centerWorld.x - boundingInfo.boundingBox.extendSizeWorld.x).toFixed(3)
    ) +
    parseFloat((planeNormalX.y * boundingInfo.boundingBox.centerWorld.y).toFixed(3)) +
    parseFloat((planeNormalX.z * boundingInfo.boundingBox.centerWorld.z).toFixed(3));

  const yMax =
    parseFloat((planeNormalX.x * boundingInfo.boundingBox.centerWorld.x).toFixed(3)) +
    parseFloat(
      (planeNormalX.y * boundingInfo.boundingBox.centerWorld.y + boundingInfo.boundingBox.extendSizeWorld.y).toFixed(3)
    ) +
    parseFloat((planeNormalX.z * boundingInfo.boundingBox.centerWorld.z).toFixed(3));

  const yMin =
    parseFloat((planeNormalX.x * boundingInfo.boundingBox.centerWorld.x).toFixed(3)) +
    parseFloat(
      (planeNormalX.y * boundingInfo.boundingBox.centerWorld.y - boundingInfo.boundingBox.extendSizeWorld.y).toFixed(3)
    ) +
    parseFloat((planeNormalX.z * boundingInfo.boundingBox.centerWorld.z).toFixed(3));

  const zMax =
    parseFloat((planeNormalX.x * boundingInfo.boundingBox.centerWorld.x).toFixed(3)) +
    parseFloat((planeNormalX.y * boundingInfo.boundingBox.centerWorld.y).toFixed(3)) +
    parseFloat(
      (planeNormalX.z * boundingInfo.boundingBox.centerWorld.z + boundingInfo.boundingBox.extendSizeWorld.z).toFixed(3)
    );

  const zMin =
    parseFloat((planeNormalX.x * boundingInfo.boundingBox.centerWorld.x).toFixed(3)) +
    parseFloat((planeNormalX.y * boundingInfo.boundingBox.centerWorld.y).toFixed(3)) +
    parseFloat(
      (planeNormalX.z * boundingInfo.boundingBox.centerWorld.z - boundingInfo.boundingBox.extendSizeWorld.z).toFixed(3)
    );
  return {
    xLimitMax: xMax + 0.5,
    xLimitMin: xMin - 0.5,
    yLimitMax: yMax + 0.5,
    yLimitMin: yMin - 0.5,
    zLimitMax: zMax + 0.5,
    zLimitMin: zMin - 0.5,
    xAxisX: planeNormalX.x,
    yAxisX: planeNormalX.y,
    zAxisX: planeNormalX.z,
    xAxisY: planeNormalY.x,
    yAxisY: planeNormalY.y,
    zAxisY: planeNormalY.z,
    xAxisZ: planeNormalZ.x,
    yAxisZ: planeNormalZ.y,
    zAxisZ: planeNormalZ.z
  };
};

// Method to toggle the mesh's and its children's visibility
const createClonedMesh = (rootMesh, sceneInstance, { clonedMesh, useClonedMesh }) => {
  if (rootMesh) {
    if (useClonedMesh) {
      let meshesToMerge;

      if (useClonedMesh) {
        const familyId = rootMesh.metadata.familyID;
        meshesToMerge = sceneInstance.current.meshes.filter(
          (mesh) => mesh && mesh.metadata && mesh.metadata.familyID === familyId && mesh !== rootMesh
        );
      } else {
        // If not using cloned mesh, just use the rootMesh
        meshesToMerge = [rootMesh];
      }

      // console.log(meshesToMerge);
      if (meshesToMerge.length > 0 && meshesToMerge.every((mesh) => mesh instanceof Mesh)) {
        clonedMesh = Mesh.MergeMeshes(meshesToMerge, false, true, undefined, false, true);
        clonedMesh.name = 'clonedMesh';
      }
    } else {
      clonedMesh = rootMesh.clone('clonedMesh');
    }

    // clonedMesh.position = rootMesh.position;

    // Setting mesh properties
    clonedMesh.layerMask = 1;
    clonedMesh.renderingGroupId = 1;

    // calling the function to set the original mesh's visibility to false
    toggleMeshVisibility(rootMesh, false);

    return clonedMesh;
  }
};

const debugBoundingBox = (scene, camera, mesh, debug) => {
  if (debug) {
    const utilityLayer = new UtilityLayerRenderer(scene);
    utilityLayer.utilityLayerScene.autoClearDepthAndStencil = true;
    utilityLayer.layerMask = 1;
    utilityLayer.setRenderCamera(camera);

    const color = new Color3(1, 0, 0); // Red color

    const boundingBoxGizmo = new BoundingBoxGizmo(color, utilityLayer);
    boundingBoxGizmo.attachedMesh = mesh;
  }
};

/*
const snapToValue = (value, snapPoints, proximity) => {
  const closestPoint = snapPoints.reduce((prev, curr) =>
    Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
  );
  return Math.abs(closestPoint - value) < proximity ? closestPoint : value;
}; */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import { Mesh, Color3, UtilityLayerRenderer, BoundingBoxGizmo, Quaternion, Vector3 } from '@babylonjs/core';

import { MdArrowBack } from 'react-icons/md';
import '@pages/user/editor/Tool bars/Handle section toolbar/SectionToolbar.scss';

import SliderToolbar from '@pages/user/editor/Tool bars/Handle section toolbar/SliderToolbar';

import {
  CreateClippingPlaneFromBoundingbox,
  // eslint-disable-next-line no-unused-vars
  hideCertainMeshes,
  unhideCertainMeshes,
  getClonedMeshFromFamilyId,
  getClonedMeshBoxFromFamilyId
} from '@hooks/Mesh/index';
import { ConsoleLog } from '@hooks/General';
// import { quaternionToEuler } from '@hooks/Math/index';
// import { ConsoleLog } from '@hooks/General/index';

const SectionToolbar = ({ sceneInstance, headBack, pickedMeshRef, C1M, CameraTarget }) => {
  // enable debugging logs
  // ConsoleLog.debug = true;
  // ConsoleLog.log('Initialized');
  // console.error('Section toolbar component rerendered');
  // toggle to true if in debug mode to show boundingboxes and console.logs

  // Wrapping a component in a useMemo statement lets you cache the result of a calculation between re-renders
  const rootMesh = useMemo(() => {
    return pickedMeshRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickedMeshRef.current]);

  /* bool to check if we need to use the rotation quaternion
  const useQuaternion = useMemo(() => {
    return !!pickedMeshRef.current.rotationQuaternion;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickedMeshRef.current]);

  // setting the rootMesh initial rotation quaternion
  const rootMeshRot = useMemo(() => {
    if (useQuaternion) {
      return rootMesh.rotationQuaternion;
    } else {
      return rootMesh.rotation;
    }
  }, [rootMesh, useQuaternion]);

  // initialize the clonedMesh variable to store the clonedMesh
  // eslint-disable-next-line prefer-const
  let clonedMesh = null;

  // eslint-disable-next-line prefer-const
  let planes;
  let processplanes; */

  // set the familyId to pass it to the sliderToolbar
  const familyID = useMemo(() => {
    try {
      if (rootMesh.metadata && rootMesh.metadata.familyID) {
        return rootMesh.metadata.familyID;
      } else {
        headBack();
      }
    } catch (error) {
      ConsoleLog.error(error);
      headBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ClonedMesh = useMemo(() => {
    if (getClonedMeshFromFamilyId(sceneInstance.current, familyID)) {
      return getClonedMeshFromFamilyId(sceneInstance.current, familyID);
    } else {
      return createClone(rootMesh, familyID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ClonedMeshBox = useMemo(() => {
    return getClonedMeshBoxFromFamilyId(sceneInstance.current, familyID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  try {
    if (ClonedMeshBox) {
      CameraTarget(ClonedMeshBox.position);
    } else {
      CameraTarget(rootMesh.position);
    }
  } catch (error) {
    ConsoleLog.error(error);
  }

  // eslint-disable-next-line no-unused-vars
  function disposeClipPlaneByName(scene, planeName) {
    const planeToDispose = scene.getMeshByName(planeName);

    if (planeToDispose) {
      planeToDispose.dispose();
      console.log(`Disposed of clip plane "${planeName}"`);
    } else {
      console.warn(`Clip plane "${planeName}" not found in the scene.`);
    }
  }

  // Resetting everything to head back
  const resetClippingPlanes = () => {
    unhideCertainMeshes(sceneInstance.current, familyID);

    sceneInstance.current.clipPlane = null;
    sceneInstance.current.clipPlane2 = null;
    sceneInstance.current.clipPlane3 = null;

    ConsoleLog.log(rootMesh);
    // heading back to the deault toolbar
    headBack();
  };

  const limits = useMemo(() => {
    if (ClonedMesh && ClonedMeshBox) {
      return CreateClippingPlaneFromBoundingbox(ClonedMeshBox, sceneInstance.current);
    } else {
      return CreateClippingPlaneFromBoundingbox(rootMesh, sceneInstance.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // ConsoleLog.log(limits);

  hideCertainMeshes(sceneInstance.current, familyID);

  /*
  const config = {
    rootMesh,
    rootMeshRot,
    familyID,
    sceneInstance,
    useQuaternion,
    cameraInstance,
    clonedMesh
  };

  // calculate the planes
  // eslint-disable-next-line prefer-const, no-unused-vars
  planes = calculateBoundingBox(config);
  console.log(planes);

  // eslint-disable-next-line prefer-const
  processplanes = processPlanes(planes);
  console.log(processplanes);
  */

  const handleClick = (e) => {
    e.stopPropagation(); // Prevents the event from propagating further
  };

  if (!sceneInstance || !sceneInstance.current) {
    console.log('No sceneInstance or pickedMeshRef provided');
    return null;
  }

  function createClone(newMeshes, newId) {
    let clonedMesh;

    try {
      clonedMesh = newMeshes.clone('ClonedMesh');
    } catch (error) {
      ConsoleLog.error(error);
      return;
    }

    clonedMesh.metadata = {
      canToggleGizmo: false,
      canToggleFocus: false,
      targetPosition: newMeshes.position,
      position: clonedMesh.position.clone(),
      rotation: clonedMesh.rotation.clone(),
      scale: clonedMesh.scaling.clone(),
      clone: newMeshes,
      isolatable: false,
      familyID: newId,
      isIsolated: false,
      importable: true,
      clippable: true
    };

    clonedMesh.layerMask = C1M;
    clonedMesh.renderingGroupId = 1;

    clonedMesh.parent = newMeshes;

    clonedMesh.position = new Vector3(0, 0, 0);
    clonedMesh.rotation = new Vector3(0, 0, 0);

    return clonedMesh;
  }

  return (
    <div className="ShortcutsToolbar-container" onClick={handleClick}>
      <SliderToolbar limits={limits} sceneInstance={sceneInstance} mesh={ClonedMesh} root={!ClonedMeshBox} />
      <div className="ShortcutsToolbar-element" onClick={() => resetClippingPlanes()}>
        <div className="ShortcutsToolbar-icon">
          <MdArrowBack />
        </div>
        <div className="ShortcutsToolbar-text">Back</div>
      </div>
    </div>
  );
};

SectionToolbar.propTypes = {
  sceneInstance: PropTypes.any.isRequired,
  headBack: PropTypes.func.isRequired,
  pickedMeshRef: PropTypes.any.isRequired,
  C1M: PropTypes.any.isRequired,
  CameraTarget: PropTypes.any.isRequired
};

/* ----------------------------------------------------------------------------------------------------------------------------------- */
// Functions used in the SectioToolbar component
/* ----------------------------------------------------------------------------------------------------------------------------------- */

/*
const calculateBoundingInfo = (mesh) => {
  // Force compute the world matrix if it's not up to date
  mesh.computeWorldMatrix(true);

  // Update the bounding info
  mesh.refreshBoundingInfo();

  // The dimensions of the bounding box can be found in the boundingInfo property
  const boundingInfo = mesh.getBoundingInfo();

  return {
    boundingInfo
  };
};

// Method to toggle the mesh's and its children's visibility
const calculateBoundingBox = ({ rootMesh, familyID, rootMeshRot, sceneInstance, useQuaternion, clonedMesh }) => {
  if (useQuaternion) {
    let meshesToMerge;

    // first we need to set the rotation back to null as to create the cloned mesh
    rootMesh.rotationQuaternion = new Quaternion(0, 1, 0, 0);

    // set the meshes to merge into a single variable
    // eslint-disable-next-line prefer-const, no-unused-vars
    meshesToMerge = sceneInstance.current.meshes.filter(
      (mesh) => mesh && mesh.metadata && mesh.metadata.familyID === familyID && mesh !== rootMesh
    );

    // merge the meshes
    if (meshesToMerge.length > 0 && meshesToMerge.every((mesh) => mesh instanceof Mesh)) {
      clonedMesh = Mesh.MergeMeshes(meshesToMerge, false, true, undefined, false, true);
      console.log(clonedMesh);
      clonedMesh.name = 'clonedMesh';
    } else {
      clonedMesh = rootMesh.clone('clonedMesh');
      console.error('No cloned mesh created');
    }

    // set the rotationQuaternion back to the original
    rootMesh.rotationQuaternion = rootMeshRot;
  } else {
    clonedMesh = rootMesh.clone('clonedMesh');
  }

  // now let's calculate the boundingBox info for the clone
  const boundingInformation = calculateBoundingInfo(clonedMesh);

  // now we can dispose of all of the meshes in the scene with a clonedMesh name. this is to be sure to dispose all of them
  disposeMeshByName('clonedMesh', sceneInstance);

  // now we calculate the limits
  if (useQuaternion) {
    // eslint-disable-next-line no-unused-vars
    const planes = calculateLimits(boundingInformation, rootMeshRot);

    return planes;
  } else {
    // eslint-disable-next-line no-unused-vars
    const planes = calculateLimitsRotation(boundingInformation, rootMeshRot);

    return planes;
  }
};

// eslint-disable-next-line no-unused-vars
const disposeMeshByName = (name, sceneInstance) => {
  const clonedMeshes = sceneInstance.current.meshes.filter((mesh) => mesh.name === name);
  clonedMeshes.forEach((mesh) => {
    mesh.dispose();
    console.log('disposed: ', mesh);
  });
};

// eslint-disable-next-line no-unused-vars
const calculateLimits = (boundingInformation, rootMeshRot) => {
  // lets first find the local normals of the mesh
  const normals = [
    [1, 0, 0],
    [-1, 0, 0],
    [0, 1, 0],
    [0, -1, 0],
    [0, 0, 1],
    [0, 0, -1]
  ];

  const center = boundingInformation.boundingInfo.boundingBox.centerWorld;
  // console.log(center);

  // set the planes variable
  // eslint-disable-next-line prefer-const
  let planes = [];

  for (const normal of normals) {
    // Transform normal vector using the quaternion
    const transformedNormal = rotateVectorByQuaternion(normal, rootMeshRot);
    console.log(normal);
    console.log(rootMeshRot);
    console.log(transformedNormal);

    // Calculate d using the transformed normal and the center of the bounding box
    const d = -(transformedNormal.x * center.x + transformedNormal.y * center.y + transformedNormal.z * center.z);
    console.log(d);

    planes.push([transformedNormal.x, transformedNormal.y, transformedNormal.z, d]);
  }

  return {
    planes
  };
};

// eslint-disable-next-line no-unused-vars
const calculateLimitsRotation = (boundingInformation, rootMeshRot) => {
  // lets first find the local normals of the mesh
  const normals = [
    [1, 0, 0],
    [-1, 0, 0],
    [0, 1, 0],
    [0, -1, 0],
    [0, 0, 1],
    [0, 0, -1]
  ];

  const center = boundingInformation.centerWorld;

  // set the planes variable
  // eslint-disable-next-line prefer-const
  let planes = [];

  for (const normal of normals) {
    // Transform normal vector using the quaternion
    const transformedNormal = rotateVectorByQuaternion(normal, rootMeshRot);

    // Calculate d using the transformed normal and the center of the bounding box
    const d = -(transformedNormal[0] * center.x + transformedNormal[1] * center.y + transformedNormal[2] * center.z);

    planes.push([...transformedNormal, d]);
  }

  return {
    planes
  };
};

function rotateVectorByQuaternion(vector, quaternion) {
  // Convert the vector to a quaternion with a zero scalar part
  // eslint-disable-next-line prefer-const
  let vecQuat = [vector[0], vector[1], vector[2], 0];
  console.log(vecQuat[0]);

  // Calculate the conjugate of the quaternion
  // eslint-disable-next-line prefer-const
  let quatConjugate = [quaternion.x, quaternion.y, quaternion.z, -quaternion.w];
  console.log(quatConjugate[0]);

  // First multiplication: quaternion * vector
  // eslint-disable-next-line prefer-const
  let tempResult = multiplyQuaternions([quaternion.x, quaternion.y, quaternion.z, quaternion.w], vecQuat);
  console.log(tempResult);

  // Second multiplication: (quaternion * vector) * quaternion conjugate
  // eslint-disable-next-line prefer-const
  let finalResult = multiplyQuaternions(tempResult, quatConjugate);
  console.log(finalResult);

  // Returning only the vector part
  return {
    x: finalResult[0],
    y: finalResult[1],
    z: finalResult[2]
  };
}

function multiplyQuaternions(a, b) {
  console.log(a, b);

  const x = a[0] * b[0] - a[1] * b[1] - a[2] * b[2] - a[3] * b[3];
  const y = a[0] * b[1] + a[1] * b[0] + a[2] * b[3] - a[3] * b[2];
  const z = a[0] * b[2] - a[1] * b[3] + a[2] * b[0] + a[3] * b[1];
  const w = a[0] * b[3] + a[1] * b[2] - a[2] * b[1] + a[3] * b[0];

  console.log([x, y, z, w]);
  return [x, y, z, w];
}

// eslint-disable-next-line no-unused-vars
const debugBoundingBox = (scene, camera, mesh, debug) => {
  if (debug) {
    const utilityLayer = new UtilityLayerRenderer(scene);
    utilityLayer.utilityLayerScene.autoClearDepthAndStencil = true;
    utilityLayer.layerMask = 1;
    utilityLayer.setRenderCamera(camera);

    const color = new Color3(1, 0, 0); // Red color

    const boundingBoxGizmo = new BoundingBoxGizmo(color, utilityLayer);
    boundingBoxGizmo.attachedMesh = mesh;
  }
};

function processPlanes(planes) {
  const uniquePlanes = [];

  for (const plane of planes.planes) {
    const normal = [plane[0], plane[1], plane[2]];
    const dValue = plane[3];

    // Check if this normal or its opposite is already in uniquePlanes
    const existingIndex = uniquePlanes.findIndex(
      (up) =>
        (up.normal[0] === normal[0] && up.normal[1] === normal[1] && up.normal[2] === normal[2]) ||
        (up.normal[0] === -normal[0] && up.normal[1] === -normal[1] && up.normal[2] === -normal[2])
    );

    if (existingIndex === -1) {
      uniquePlanes.push({ normal, dmin: dValue, dmax: dValue });
    } else {
      uniquePlanes[existingIndex].dmin = Math.min(uniquePlanes[existingIndex].dmin, dValue);
      uniquePlanes[existingIndex].dmax = Math.max(uniquePlanes[existingIndex].dmax, dValue);
    }
  }

  return uniquePlanes;
} */

/*
const snapToValue = (value, snapPoints, proximity) => {
  const closestPoint = snapPoints.reduce((prev, curr) =>
    Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
  );
  return Math.abs(closestPoint - value) < proximity ? closestPoint : value;
}; */

export default SectionToolbar;
