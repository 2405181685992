import './VerifyUserAccount.scss';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Input from '@components/input/Input';
import Button from '@components/button/Button';
import { authService } from '@services/api/auth/auth.service';
import { Utils } from '@services/utils/utils.service';

const VerifyUserAccount = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasResponse, setHasResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [code, setCode] = useState('');

  const verifyUser = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const response = await authService.verifyUser({
        // eslint-disable-next-line object-shorthand
        email: email,
        code,
        authPasscode: 'internalExvirienceAuthPassword0321'
      });
      setLoading(false);
      setEmail('');
      setHasError(false);
      setHasResponse(true);
      setAlertType('alert-success');
      setResponseMessage(response?.data?.message);
      setErrorMessage('');

      console.log('successful');

      Utils.redirectToPageAfterDelay(navigate, '/auth', 3);
    } catch (error) {
      setHasError(true);
      setHasResponse(false);
      setLoading(false);
      setAlertType('alert-error');
      setResponseMessage('');
      setErrorMessage(error?.response?.data.message);
    }
  };

  return (
    <div className="auth-inner-login" style={{ height: '550px' }}>
      <form className="auth-form-login" onSubmit={verifyUser}>
        <div className="form-input-container-login">
          <Input
            id="email"
            name="email"
            type="email"
            value={email}
            labelText="Email"
            placeholder="Enter email"
            style={{ border: `${hasError ? '1px solid #fa9b8a' : ''}` }}
            handleChange={(event) => setEmail(event.target.value)}
          />
          <Input
            id="numberInput"
            name="numberInput"
            type="number"
            value={code}
            labelText="Verification code"
            placeholder="Enter Code"
            style={{ border: `${hasError ? '1px solid #fa9b8a' : ''}` }}
            handleChange={(event) => setCode(event.target.value)}
          />
          {hasError && errorMessage && (
            <div className={`alerts ${alertType}`} role="alert">
              {errorMessage}
            </div>
          )}
          {hasResponse && responseMessage && (
            <div className={`alerts ${alertType}`} role="alert">
              {responseMessage}
            </div>
          )}
          <Button
            label={`${loading ? 'VERIFYING EMAIL...' : 'VERIFY EMAIL'}`}
            className="auth-button button"
            disabled={!email}
          />
        </div>
      </form>
    </div>
  );
};

export default VerifyUserAccount;
