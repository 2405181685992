import { Color3, MeshBuilder, StandardMaterial } from '@babylonjs/core'; // Ensure the necessary BabylonJS modules are imported.

class ShadowPlaneCreator {
  constructor(sceneInstance, C1M, shadowPlanes) {
    this.sceneInstance = sceneInstance;
    this.C1M = C1M;
    this.shadowPlanes = shadowPlanes;
  }

  create(mesh) {
    // Compute the bounding box of the mesh
    mesh.computeWorldMatrix(true); // force a world matrix computation
    // eslint-disable-next-line prefer-const
    let boundingInfo = mesh.getBoundingInfo();

    // Get the dimensions of the bounding box
    // eslint-disable-next-line prefer-const
    let extendSize = boundingInfo.boundingBox.extendSize;
    // eslint-disable-next-line prefer-const
    let width = extendSize.x * 2;
    // eslint-disable-next-line prefer-const
    let height = extendSize.z * 2; // Note that we use 'z' here because the plane will be rotated

    // Create a plane of the correct size
    // eslint-disable-next-line prefer-const
    let shadowPlane = MeshBuilder.CreateDisc(
      'shadowPlane' + mesh.name,
      {
        radius: (Math.max(width, height) / 2) * 1.5,
        tessellation: 50
      },
      this.sceneInstance.current
    );

    shadowPlane.metadata = {
      editor: true
    };

    shadowPlane.layerMask = this.C1M;

    // Position the plane to match the mesh
    shadowPlane.position = mesh.position.clone();

    shadowPlane.position.y = 0; // Keep it on the ground

    // Rotate the plane so it's flat
    shadowPlane.rotation.x = Math.PI / 2;

    // Create a material for the shadow plane
    // eslint-disable-next-line prefer-const
    let material = new StandardMaterial('shadowPlaneMaterial', this.sceneInstance.current);
    material.diffuseColor = Color3.Black();
    material.alpha = 0.05; // Semi-transparent

    // Apply the material to the shadow plane
    shadowPlane.material = material;

    shadowPlane.initialY = 0; // Store the initial Y position

    // Inside the createMesh function, after creating the shadow plane
    this.shadowPlanes.set(mesh, shadowPlane);

    // Attach an observable to the mesh's position property
    mesh.registerAfterWorldMatrixUpdate(() => {
      if (this.shadowPlanes.has(mesh)) {
        const shadowPlane = this.shadowPlanes.get(mesh);
        shadowPlane.position.x = mesh.position.x;
        shadowPlane.position.z = mesh.position.z;
        // Keep the shadow plane's y position constant
        shadowPlane.position.y = 0;
      }
    });

    return shadowPlane;
  }
}

export default ShadowPlaneCreator;
