import React from 'react';
import '@components/Modal windows/Modal window - see description/ConfirmSeeDescription.scss';

import PropTypes from 'prop-types';

const ConfirmSeeDescription = ({ cancelAction, initialName = '' }) => {
  return (
    <div className="modal-wrapper-description" onClick={() => cancelAction()}>
      <div
        className="confirm-rename-container-description"
        style={{ height: '250px', width: '450px' }}
        onClick={(e) => e.stopPropagation()}
      >
        <h2>Object Description</h2>
        <p>{'Current description:'}</p>
        <p>{`${initialName || 'No current description set on object.'}`}</p> {/* Add this line */}
        <div className="buttons-container-description">
          <button
            className="button-new-description"
            style={{ cursor: 'default', color: 'rgba(255, 0, 0, 1)' }}
          ></button>
          <button
            className="button-new-description"
            onClick={() => cancelAction()}
            style={{ cursor: 'pointer', color: 'rgba(0, 0, 212, 1)' }}
          >
            Return
          </button>
          <button
            className="button-new-description"
            style={{ cursor: 'default', color: 'rgba(255, 0, 0, 1)' }}
          ></button>
        </div>
      </div>
    </div>
  );
};

ConfirmSeeDescription.propTypes = {
  cancelAction: PropTypes.func.isRequired,
  initialName: PropTypes.string
};

export default ConfirmSeeDescription;
