import React, { useEffect, useRef } from 'react';
import { SceneLoader, Scene, Engine, ArcRotateCamera, Vector3, ArcRotateCameraPointersInput } from '@babylonjs/core'; // Ensure the necessary BabylonJS modules are imported.
import '@babylonjs/loaders/glTF';

import SetSceneColor from '@hooks/Scene setup/hooks/SetSceneColor';

import PropTypes from 'prop-types';

import { useSessionStorage } from '@hooks/General';
import { modelsService } from '@services/api/models/models.service';

const ScenePreviewer = ({ modelId, setPercentage }) => {
  const canvasRef = useRef(null);
  const engineRef = useRef(null);

  const sessionData = useSessionStorage('sessionData', 'get');
  const userDataStore = useSessionStorage('userData', 'get');

  // background color: either blue or baige or white
  const backgroundColor = 'blue';
  const colorX = 150;
  const colorY = 150;
  const colorZ = 150;

  const readFileAndLoadMesh = (arrayBuffer, contentType) => {
    return new Promise((resolve, reject) => {
      try {
        const byteArray = new Uint8Array(arrayBuffer);
        const base64String = btoa(byteArray.reduce((data, byte) => data + String.fromCharCode(byte), ''));
        const dataUrl = `data:${contentType};base64,${base64String}`;
        resolve(dataUrl);
      } catch (error) {
        reject(error);
      }
    });
  };

  useEffect(() => {
    if (!canvasRef.current) return;

    // Create Babylon.js engine
    const engine = new Engine(canvasRef.current, true);
    engineRef.current = engine;

    // Create the Babylon.js scene
    const scene = new Scene(engine);

    // Create a camera and position it
    const camera = new ArcRotateCamera('camera', Math.PI / 2, Math.PI / 4, 0.5, new Vector3(0, 0, 0), scene);
    camera.attachControl(canvasRef.current, true);
    camera.position = new Vector3(1, 1, -1);
    // eslint-disable-next-line prefer-const
    let cameraInputManager = new ArcRotateCameraPointersInput();
    cameraInputManager.buttons = [0, 1, 2];

    // Adjust camera button inputs
    camera.inputs.attached.pointers.buttons[0] = undefined;
    camera.inputs.attached.pointers.buttons[2] = undefined;
    camera._panningMouseButton = cameraInputManager.buttons[2];

    camera.wheelPrecision = 10;
    camera.inputs.attached.pointers._useCtrlForPanning = true;

    // sets the color of the scene
    // eslint-disable-next-line no-unused-vars
    const sceneConfig = new SetSceneColor(scene, backgroundColor, colorX, colorY, colorZ);

    // Load the model
    if (modelId) {
      // import the model and download it
      const Bucket = 'exvirience-objects';
      const Key = modelId;
      const models_Id = userDataStore.modelsId; // this has to be the users models id

      const awsInformation = {
        accessKeyId: sessionData.data.accessInformation.AccessKeyId,
        secretAccessKey: sessionData.data.accessInformation.SecretAccessKey,
        sessionToken: sessionData.data.accessInformation.SessionToken,
        bucketName: Bucket
      };

      const modelsBody = {
        models_Id,
        modelId: Key,
        awsInformation
      };

      (async () => {
        const response = await modelsService.getModel(modelsBody, setPercentage);

        console.log(response.data);

        const dataURL = await readFileAndLoadMesh(response.data, response.headers['content-type']);

        console.log(dataURL);

        const result = await SceneLoader.ImportMeshAsync(null, '', dataURL, scene);

        const newMeshes = result.meshes;
        newMeshes[0].scaling = new Vector3(1, 1, 1);
        newMeshes[0].position = new Vector3(0, 0, 0);
      })();
    }

    // Start the render loop
    engine.runRenderLoop(() => {
      scene.render();
    });

    // Handle window resize
    window.addEventListener('resize', () => {
      engine.resize();
    });

    // Cleanup function
    return () => {
      engine.dispose();
      window.removeEventListener('resize', () => engine.resize());
    };
  }, [modelId]);

  return <canvas ref={canvasRef} style={{ width: '100%', height: '200px' }} />;
};

ScenePreviewer.propTypes = {
  modelId: PropTypes.string,
  setPercentage: PropTypes.any
};

export default ScenePreviewer;
