import React from 'react';
import PropTypes from 'prop-types';
import '@components/cube-spinner/Cube-loader-container.scss';

import CubeSpinner from '@components/cube-spinner/Load-spinner';
import logoOriginal from '@assets/images/Logo_Exvirience_Original.png';
import logoWhite from '@assets/images/Logo_Exvirience_White.png';
import logoBlack from '@assets/images/Logo_Exvirience_Black.png';

import { defaultLoadingTime } from '@root/App';

const CubeLoaderContainer = ({ type, isImporting }) => {
  let logoImage;
  let sType;
  let sColor;

  const fadeOutTime = (defaultLoadingTime - 1000) / 1000;

  switch (type) {
    case 'white-r':
      logoImage = logoWhite;
      sColor = 'white';
      sType = 'ring';

      break;
    case 'black-r':
      logoImage = logoBlack;
      sColor = 'black';
      sType = 'ring';

      break;
    case 'original-r':
      logoImage = logoOriginal;
      sColor = 'black';
      sType = 'ring';

      break;
    case 'white-l':
      logoImage = logoWhite;
      sColor = 'white';
      sType = 'ellipsis';

      break;
    case 'black-l':
      logoImage = logoBlack;
      sColor = 'black';
      sType = 'ellipsis';

      break;
    case 'original-l':
    default:
      logoImage = logoOriginal;
      sColor = 'black';
      sType = 'ellipsis';

      break;
  }

  const fadeOutStyle = {
    animation: `fadeOut 1s ${fadeOutTime}s forwards, unblur 1s ${fadeOutTime}s forwards, transparentBackground 1s ${fadeOutTime}s forwards`
  };

  return (
    <div className={isImporting ? 'page-loader' : 'page-loader'} style={fadeOutStyle}>
      <div className="container-logo" style={{ backgroundImage: `url(${logoImage})` }}></div>
      <div className="spinner-container-cube">
        <CubeSpinner type={sType} mode={sColor} />
      </div>
    </div>
  );
};

CubeLoaderContainer.propTypes = {
  type: PropTypes.oneOf(['original-l', 'white-l', 'black-l', 'original-r', 'white-r', 'black-r']),
  isImporting: PropTypes.bool
};

CubeLoaderContainer.defaultProps = {
  type: 'original-l',
  isImporting: false
};

export default CubeLoaderContainer;
