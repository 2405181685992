import React from 'react';
import '@pages/404/ErrorPage.scss';
import logoOriginal from '@assets/images/Logo_Exvirience_Original.png';

const ErrorPage = () => {
  return (
    <div className="error-page-container">
      <a href="https://www.exvirience.com/">
        <img src={logoOriginal} alt="Logo" className="logo" />
      </a>
      <div className="error-content">
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
        <p>Please return to the exvirience site.</p>
      </div>
    </div>
  );
};

export default ErrorPage;
