import React from 'react';
import PropTypes from 'prop-types';

import { RiSave3Line } from 'react-icons/ri';
import {
  MdSaveAlt,
  /* MdOutlineQuiz , MdLightbulbOutline, */ MdListAlt,
  MdOutlineImage,
  MdOutlineVideoCameraBack
} from 'react-icons/md';
import { HiOutlineCube } from 'react-icons/hi';
import { LiaCubesSolid /*, LiaEyeSlashSolid, LiaEyeSolid */ } from 'react-icons/lia';
import { PiVirtualReality, PiVirtualRealityFill } from 'react-icons/pi';

import '@pages/user/editor/Tool bars/Default toolbar/GeneralToolbar.scss';

const GeneralToolbar = ({
  addModel,
  addEnvironment,
  addImage, // this method should be to add to the scene metadata an object that contains the key and bucket value of the file in order to upload it when initializing the vr scene
  addVideo, // this method should be to add to the scene metadata an object that contains the key and bucket value of the file in order to upload it when initializing the vr scene
  viewAvatar,
  saveScene,
  isSaved, // To toggle the save icons states
  isHidden, // To toggle the view avatar states
  viewList,
  addCommands,
  isVRCommand
}) => {
  // const imageInputRef = useRef(null);
  // const videoInputRef = useRef(null);

  /* const handelImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = function (loadEvent) {
        const imageUrl = loadEvent.target.result;
        // Here you have the image URL (base64), you can use it as needed
        console.log(imageUrl); // For demonstration

        // Now, you can trigger your existing upload to S3 method from here
        // For example:
        // uploadImageToS3(imageUrl);

        (async () => {
          const Bucket = 'exvirience-media';
          const Key = file.name;
          const Body = file;
          await AWSPut(Bucket, Key, Body);
        })();

        // addImage={(params) =>
        // meshCreator.createCanvasBox(params.type, params.boxes, params.bucketName, params.keyName)
        // }
        addImage('image', 'exvirience-media', file.name);
      };

      reader.readAsDataURL(file);
    }
  };

  const handelVideo = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = function (loadEvent) {
        const videoUrl = loadEvent.target.result;
        // Here you have the image URL (base64), you can use it as needed
        console.log(videoUrl); // For demonstration

        // Now, you can trigger your existing upload to S3 method from here
        // For example:
        // uploadImageToS3(imageUrl);

        (async () => {
          const Bucket = 'exvirience-media';
          const Key = file.name;
          const Body = file;
          await AWSPut(Bucket, Key, Body);
        })();

        addVideo('video', 'exvirience-media', file.name);
      };

      reader.readAsDataURL(file);
    }
  }; */

  return (
    <div className="GeneralToolbar-container">
      <div className="GeneralToolbar-element" onClick={addModel}>
        <div className="GeneralToolbar-icon">
          <HiOutlineCube />
        </div>
        <div className="GeneralToolbar-text">Add Model</div>
      </div>

      <div className="GeneralToolbar-element" onClick={addEnvironment}>
        <div className="GeneralToolbar-icon">
          <LiaCubesSolid />
        </div>
        <div className="GeneralToolbar-text">Add Environment</div>
      </div>

      <div className="GeneralToolbar-element" onClick={viewList}>
        <div className="GeneralToolbar-icon">
          <MdListAlt />
        </div>
        <div className="GeneralToolbar-text">Object List</div>
      </div>

      <div className="GeneralToolbar-element" onClick={addImage}>
        <div className="GeneralToolbar-icon">
          <MdOutlineImage />
        </div>
        <div className="GeneralToolbar-text">Add Image</div>
      </div>

      <div className="GeneralToolbar-element" onClick={addVideo}>
        <div className="GeneralToolbar-icon">
          <MdOutlineVideoCameraBack />
        </div>
        <div className="GeneralToolbar-text">Add Video</div>
      </div>

      {/* <div className="GeneralToolbar-element" onClick={addElement}>
        <div className="GeneralToolbar-icon">
          <MdLightbulbOutline />
        </div>
        <div className="GeneralToolbar-text">Generate lighting</div>
      </div>

      <div className="GeneralToolbar-element" onClick={addQuiz}>
        <div className="GeneralToolbar-icon">
          <MdOutlineQuiz />
        </div>
        <div className="GeneralToolbar-text">Add Quiz</div>
      </div>

       <div className="GeneralToolbar-element" onClick={viewAvatar}>
        <div className="GeneralToolbar-icon">{!isHidden ? <LiaEyeSolid /> : <LiaEyeSlashSolid />}</div>
        <div className="GeneralToolbar-text">{!isHidden ? 'View Scale' : 'Hide Scale'}</div>
      </div> */}

      <div className="GeneralToolbar-element" onClick={addCommands}>
        <div className="GeneralToolbar-icon">{isVRCommand ? <PiVirtualReality /> : <PiVirtualRealityFill />}</div>
        <div className="GeneralToolbar-text">{isVRCommand ? 'Enable VR' : 'VR Enabled'}</div>
      </div>

      <div className="GeneralToolbar-element" onClick={saveScene}>
        <div className="GeneralToolbar-icon">{isSaved ? <MdSaveAlt /> : <RiSave3Line />}</div>
        <div className="GeneralToolbar-text">{isSaved ? 'Saved' : 'Save Scene'}</div>
      </div>

      {/*       <input
        type="file"
        accept="image/png, image/jpeg"
        ref={imageInputRef}
        style={{ display: 'none' }}
        onChange={handelImage}
      />
      <input type="file" accept="video/mp4" ref={videoInputRef} style={{ display: 'none' }} onChange={handelVideo} /> */}
    </div>
  );
};

GeneralToolbar.propTypes = {
  addModel: PropTypes.func.isRequired,
  addEnvironment: PropTypes.func.isRequired,
  addImage: PropTypes.func.isRequired,
  addVideo: PropTypes.func.isRequired,
  viewAvatar: PropTypes.func.isRequired,
  saveScene: PropTypes.func.isRequired,
  isSaved: PropTypes.bool.isRequired,
  isHidden: PropTypes.bool.isRequired,
  viewList: PropTypes.func.isRequired,
  addCommands: PropTypes.func.isRequired,
  isVRCommand: PropTypes.bool.isRequired
};

export default GeneralToolbar;
