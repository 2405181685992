import React from 'react';
import PropTypes from 'prop-types';

import { MdArrowBack } from 'react-icons/md';
import { LiaCubeSolid } from 'react-icons/lia';
import { TbSphere, TbCylinder } from 'react-icons/tb';

import '@pages/user/editor/Tool bars/Handle model toolbar/Default shapes/DefaultShapeToolbar.scss';

const DefaultShapeToolbar = ({ headBack, cube, sphere, cylinder }) => {
  return (
    <div className="DefaultShapeToolbar-container">
      <div className="DefaultShapeToolbar-element" onClick={cube}>
        <div className="DefaultShapeToolbar-icon">
          <LiaCubeSolid />
        </div>
        <div className="DefaultShapeToolbar-text">Cube</div>
      </div>

      <div className="DefaultShapeToolbar-element" onClick={sphere}>
        <div className="DefaultShapeToolbar-icon">
          <TbSphere />
        </div>
        <div className="DefaultShapeToolbar-text">Sphere</div>
      </div>

      <div className="DefaultShapeToolbar-element" onClick={cylinder}>
        <div className="DefaultShapeToolbar-icon">
          <TbCylinder />
        </div>
        <div className="DefaultShapeToolbar-text">Cylinder</div>
      </div>

      <div className="DefaultShapeToolbar-element" onClick={headBack}>
        <div className="DefaultShapeToolbar-icon">
          <MdArrowBack />
        </div>
        <div className="DefaultShapeToolbar-text">Back</div>
      </div>
    </div>
  );
};

DefaultShapeToolbar.propTypes = {
  headBack: PropTypes.func.isRequired,
  cube: PropTypes.func.isRequired,
  sphere: PropTypes.func.isRequired,
  cylinder: PropTypes.func.isRequired
};

export default DefaultShapeToolbar;
